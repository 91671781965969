'use strict'

angular.module 'nn.task.reassign.directives', []

  .directive 'nnReassignTasksContainer', (OrphanedTaskService) ->
    scope: { type: '=', page: '=?', schema: '=?' }
    template: '''
      <div class="list-group bg-trans" ng-if="tasks.length && !schema.activeHole.task">
        <div class="page-element-header">
          <h6 class="alignc">Reassign orphaned {{schema.activeHoleType}} tasks</h6>
        </div>
        <div class="media-list whitebg" ng-repeat="(key, issues) in orphanedTasks track by key" ng-if="issues[schema.activeHoleType].length" ng-init="arrIndex = $index">
          <nn-reassign-task ng-repeat="task in issues[schema.activeHoleType] track by $index" ng-if="task" arr-index="arrIndex" task-id="task" page="page" active="page.active" schema="schema"></nn-reassign-task>
        <div>
      </div>'''
    controller: ($scope) ->
      $scope.tasks = []

      OrphanedTaskService.getOrphanedTasks().then (data) ->
        $scope.orphanedTasks = data

        if $scope.schema?.activeHoleType
          for key, issues of $scope.orphanedTasks
            $scope.tasks.push issues[$scope.schema.activeHoleType]

  .directive 'nnReassignTask', (PageBuilderModuleTypes, TaskService, OrphanedTaskService) ->
    templateUrl: '/templates/reassign-task.html'
    restrict: 'E'
    scope: { arrIndex: '=', taskId: '=', active: '=', page: '=', schema: '=' }
    link: (scope) ->
      scope.moduleTypes = PageBuilderModuleTypes
      scope.taskInst = TaskService

      taskSuccess = (task) ->
        scope.task = task

      taskError = (error) ->
        scope.error = true
        scope.errorMsg = error

      scope.taskInst.one(scope.taskId, scope.schema.activeHoleType).then taskSuccess, taskError

      scope.reassignTask = (id, type) ->
        scope.schema.activeHole.assignId id, scope.schema.active.number, scope.schema.activeOverrideHole?
        OrphanedTaskService.removeTask(scope.taskId, scope.arrIndex, scope.schema.activeHoleType)

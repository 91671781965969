'use strict'

angular.module 'nn.chartbeat-list.directives', []

  .directive 'nnChartbeatList', ->
    templateUrl: '/templates/chartbeat-list.html'
    restrict: 'E'
    scope:
      'publication': '='
      'limit': '@'
    controller: ($scope, $timeout, Chartbeat, Fullscreen) ->

      chartbeat = Chartbeat.getInstance $scope.publication.website
      $scope.data = chartbeat.data

      $scope.$watch 'publication', (newPub, oldPub) ->
        if newPub?.website?
          chartbeat.domain = newPub.website
          chartbeat.getPages()

      $scope.toggleAutoRefresh = ->
        chartbeat.toggleAutoRefresh 'pages', $scope.autoRefreshState

      $scope.goFullscreen = ->
        if Fullscreen.isEnabled()
          Fullscreen.cancel()
        else
          Fullscreen.enable( document.getElementById('chartbeat') )
        return

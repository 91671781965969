'use strict'

angular.module('nn.print-stories-table.services', [])
  .factory 'PrintStoryTableService', ($http, $q, Auth, HOSTS) ->
    commands =
      getStoriesWithStatus: (issueId, orgId) ->
        deferred = $q.defer()
        Auth.token().then (token) ->
          $http
            method: 'GET',
            url: "#{HOSTS.sochi}/proxy/copyfit/stories?issueId=#{issueId}&orgId=#{orgId}"
            cache: false
            headers: {
              "Content-type": "application/json"
              'Authorization': "Bearer #{token}"
            }
            auth: true
          .success (response) ->
            try
              deferred.resolve response
            catch err
              deferred.reject {message: err.message}
          .error (data, status, headers, config) ->
            if (status == 401)
              deferred.reject {message: "Unauthorized"}
            else if (status == 404)
              deferred.reject {message: "HTTP 404 - Service is disabled"}
            else if (status >= 500)
              deferred.reject {message: "HTTP #{status} - Server is down"}
            else if data
              message = data
              if status
                message = data + " HTTP " + status
              deferred.reject {message: message}
            else
              if status
                deferred.reject {message: "Error in request: HTTP " + status}
              deferred.reject {message: "Error in request: HTTP"}

        deferred.promise

      getTasks: (taskIds) ->
        deferred = $q.defer()
        Auth.token().then (token) ->
          $http
            method: 'GET',
            url: "#{HOSTS.silverstone}/api/v2/task/?ids=#{taskIds}"
            cache: false
            headers: {
              "Content-type": "application/json"
              'Authorization': "Bearer #{token}"
            }
            auth: true
          .success (response) ->
            try
              deferred.resolve response
            catch err
              deferred.reject {message: err.message}
          .error (data, status, headers, config) ->
            if (status == 401)
              deferred.reject {message: "Unauthorized"}
            else if (status == 404)
              deferred.reject {message: "HTTP 404 - Service is disabled"}
            else if (status >= 500)
              deferred.reject {message: "HTTP #{status} - Silverstone Service is down"}
            else if data
              message = data
              if status
                message = data + " HTTP " + status
              deferred.reject {message: message}
            else
              if status
                deferred.reject {message: "Error in request: HTTP " + status}
              deferred.reject {message: "Error in request: HTTP"}

        deferred.promise

      trimTitle: (title) ->
        if (title and title.length > 100)
          return title.slice(0, 97) + '...'
        return title

      transformStoriesWithStatus: (stories) ->
        transformedStories = {}
        for story in stories
          transformedStories[story.id] = Object.assign(story, {title: commands.trimTitle(story.title)})
        return transformedStories

      getStoriesFromTasks: (tasks, taskPageNum) ->
        stories = []
        for task in tasks
          story = {}
          if task.content_object?.type? and task.content_object?.id? and task.content_object.type == "story"
            story.id = task.content_object.id
            story.title = commands.trimTitle(task.content_object.title)
            story.status = "-"
            story.pageNum = taskPageNum[task.id]
            stories.push(story)
        return stories.sort((a, b) => a.pageNum - b.pageNum)

      filterStoryForStatus: (storiesInStoryHole, storiesWithCopyfitStatus) ->
        return storiesInStoryHole.map((story) =>
          copyfitStory = storiesWithCopyfitStatus[story.id]
          if copyfitStory
            if not story.title
              story.title = copyfitStory.title
            story.status = copyfitStory.status
          return story
        )


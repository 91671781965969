###
 
  Form editor (for templates)
  ==========================

###
angular.module("nn.form-editor.directives", [])
#
# Field editor directive. This simply pulls the template onto
# the form. The selectedItem is used in the controller is used to
# populate the items in the template...
#  
.directive "nnFormEditor", ->   
    restrict: "E"
    scope: {}
    templateUrl: "/templates/form-editor.html"
    controller: ($scope, FormModel, StorageService) ->
        # Here we just pass the form model up to the
        # view...
        $scope.formModel = FormModel


'use strict'

angular.module 'nn.account-explorer.services', []

  .factory 'AccountExplorer', ->
    menuItems: false
    menuItemSelected: null

  .constant 'AccountSections', [
      { 'id':'profile', label:'Profile', icon:'fa-user', state:'app.profile' }
      { 'id':'feedback', label:'Feedback', icon:'fa-envelope-o', state:'feedback', target:'_blank' }
      { 'id':'health', label:'Health Check', icon:'fa-heartbeat', state:'app.health' }
      { 'id':'signout', label:'Sign Out', icon:'fa-sign-out', state:'logout' }
    ]

'use strict'

angular.module 'nn.auth', [
    'nn.auth.services'
  ]


.config ($httpProvider) ->
  $httpProvider.interceptors.push 'authInterceptor'


# centralise the login/logout/redirect flow
.run ($state, $rootScope, Auth, Roles, AppState) ->

  loginRedirectState = null

  $rootScope.$on '$stateChangeStart', (event, toState, toParams, fromState, fromParams) ->

    if loginRedirectState
      redirect_url = $state.href(loginRedirectState.name, loginRedirectState.params)
      AppState.login.redirect_url = redirect_url

    # if the user is unauthorised and attempting to visit a restricted state
    # and a redirect state hasn't yet been set, store the target state
    if !Auth.isAuthorised() and toState.name not in ['login', 'logout']
      if !loginRedirectState? and toState.name isnt 'app.dashboard'
        loginRedirectState =
          name: toState.name
          params: toParams or {}

    # if the user has just logged in and a redirect state exists,
    # cancel the current state transition & proceed to the redirect state
    else if Auth.isAuthorised() and fromState.name is 'login'
      Roles.attachRoles()
      if loginRedirectState?
        event.preventDefault()
        {name, params} = loginRedirectState
        loginRedirectState = null
        $state.go name, params

    # if the user visits login but is already authorised, redirect to dashboard
    else if Auth.isAuthorised() and toState.name is 'login'
      event.preventDefault()
      $state.go 'app.dashboard'

    # on logout, redirect to login
    else if toState.name is 'logout'
      event.preventDefault()
      Auth.logout().then ->
        Roles.flushRoles()
        $state.go 'login'

'use strict'

angular.module 'nn.file-manager.routes', []

    .config ($stateProvider) ->

        $stateProvider

            .state 'app.files',
                abstract: true
                url: ''
                resolve:
                    acl: (Roles, $q) ->
                        checkRole = ->
                            if !Roles.canGo('files') then $q.reject 'unauthorized'
                        if Roles.hasRoles() then checkRole() else Roles.attachRoles().then -> checkRole()
                onEnter: (AppState, Explorer, Sections) ->
                    AppState.section = Sections[6]

            .state 'app.files.file',
                url: 'file/:file'
                onEnter: ($stateParams, AppState, Files) ->
                    AppState.canvas = 'file'
                    AppState.file =
                        uri: ''
                    Files.getInstance().one($stateParams.file).then (file) ->
                        AppState.file = file

'use strict'

angular.module 'nn.story-snapshots.services', []

    .factory 'Snapshots', ($http, Auth, HOSTS, SilverstoneConfig) ->
        Snapshots = ->
            snapshots = null
            all: ->
                snapshots
            refresh: (story_id) ->
                snapshot_list = []
                fetchSnapshots = (url = null) ->
                    if !url?
                        url = "#{SilverstoneConfig.api_url}story/#{story_id}/snapshots/"
                    $http
                        method: 'GET'
                        url: url
                        cache: false
                        auth: true
                    .success (data) ->
                        return data
                fetchAllSnapshots = (url = null) ->
                    fetchSnapshots(url).then (result) ->
                        snapshot_list.push result.data.results...
                        if result.data.next?
                            return fetchAllSnapshots(result.data.next)
                        else
                            return snapshots = snapshot_list
                fetchAllSnapshots()
                @
            approvedWebSnapshot: ->
                return if !snapshots?.length
                filter = (snapshot) -> snapshot.available_to?.length and 'web' in snapshot.available_to and snapshot.status is 'published'
                filteredSnapshots = snapshots.filter filter
                filteredSnapshots[0]
            approvedPrintSnapshot: ->
                return if !snapshots?.length
                filter = (snapshot) -> snapshot.available_to?.length and 'print' in snapshot.available_to and snapshot.status is 'published'
                filteredSnapshots = snapshots.filter filter
                filteredSnapshots[0]
            forApprovalWebSnapshot: ->
                return if !snapshots?.length
                filter = (snapshot) -> snapshot.available_to?.length and 'web' in snapshot.available_to and snapshot.status is 'waiting'
                filteredSnapshots = snapshots.filter filter
                filteredSnapshots[0]
            forApprovalPrintSnapshot: ->
                return if !snapshots?.length
                filter = (snapshot) -> snapshot.available_to?.length and 'print' in snapshot.available_to and snapshot.status is 'waiting'
                filteredSnapshots = snapshots.filter filter
                filteredSnapshots[0]
        getInstance: ->
            new Snapshots
'use strict'

angular.module 'nn.site-editor', [
        'nn.site-editor.directives',
        'nn.site-editor.services'
    ]

    .constant 'SiteDeviceBreakpoints', [
        {name: 'Desktop', value: '100%'},
        {name: 'Tablet', value: '900px'},
        {name: 'Mobile', value: '500px'},
    ]

    .constant 'SitebuilderBreapointEvent', 'SITEBUILDER_BREAKPOINT'
#
#   Template builder
#   =====================================
#
#   The application front end consists of a form, a FieldMenu panel
#   and a file menu. Items can be added to the form by using the
#   field menu. Items can also be removed from the form.
#
#   MVC structure
#   ===============#
#   Views:  FieldMenu |  Menu                 | Form  |
#   Ctrls:  FieldMenu |  Load/Save -> Storage | Form  |
#   Model:  FieldMenu |------\----------------| Form  | -> Field -> Element
#
#
angular.module "nn.form-builder", [
    "nn.form-builder.routes"
    'nn.form-builder.services'
    "nn.form"
    # Providers and models...
    "nn.form-field"
    "nn.form-element"
    "nn.form-storage"
    # main editor to switch between
    # edit/submit modes
    "nn.form-editor-main"
    # form template explorer
    "nn.form-editor"
    "nn.form-search"
    "nn.form-list"
    "nn.form-editor-menu"
    # submission explorer
    "nn.form-submit-editor"
    "nn.form-submit-search"
    "nn.form-submit-list"
    # right hand side panel
    "nn.form-info"
    "nn.form-meta-panel"
    "nn.form-editor-panel"
    "nn.form-editor-toggle"
    # helpers
    "nn.form-img-upload"
]

'use strict'

angular.module 'nn.task.info.directives', []

  .directive 'nnTaskInfo', ($state, Auth, Files, Roles, Uploader, $timeout) ->
    templateUrl: '/templates/task-info.html'
    restrict: 'E'
    require: 'ngModel'
    scope: { type: '=', schema: '=?', showOnly: '=?', printPreviewPaths: '=?' }
    controller: ($scope, $parse) ->
      $scope.minDate = moment().subtract(1, 'days').toDate()
      $scope.files = Uploader.getInstance()
      $scope.isUploading = false

      $scope.workflows = data: null

      $scope.workflow = id: null, customWorkflowSelected: false
      $scope.origWorkflowData = id: null, customWorkflowSelected: false

      $scope.form = searchList: false
      $scope.link = state: null, link: null

      $scope.toggleUploading = (state) ->
        $scope.isUploading = state

      Auth.user().then (user) ->
        $scope.user = user

      if $scope.schema?.issue?.date?
        $scope.defaultdate = moment($scope.schema.issue.date, 'YYYY-MM-DD').subtract(1, 'days').add(15, 'hours').format('DD/MM/YYYY h:mm A')

      $scope.searchListToggle = (value, force = false) ->
        if $scope.task?.content_object?.id or force or $scope.type is 'furniture'
          $scope.form.searchList = value
          $scope.setHrefLink(false)

      $scope.dirtyTheTask = () ->
        if $scope.task?.content_object?.original_id is null or !$scope.task?.content_object?.original_id
          $scope.taskInfo?.$setDirty()   
          $scope.task.content_object = null
          $scope.setHrefLink(false)
        else
          $scope.searchListToggle(true, true)

      $scope.setHrefLink = (toggle = true) ->
        if $scope.type is 'story'
            if $scope.task.content_object?.id
              $scope.link = state: "app.stories.story", params: { story: $scope.task.content_object.id }
            else
              $scope.link = state: "app.stories.new.forTask", params: { task: $scope.task.id }

        if $scope.type is 'furniture' and $scope.task.content_object?.original_id
          $scope.link = state: "app.files.file", params: { file: $scope.task.content_object.original_id }

          Files.getInstance().one($scope.link.params.file).then (file) ->
            $scope.file = file

    link: (scope, element, attrs, ngModel, $attr) ->
      scope.readOnly = not Roles.can 'modifyTask'

      ngModel.$render = ->
        if not ngModel.$viewValue
          scope.task = resetTask()
          scope.newTask = true

          if scope.type is 'furniture'
            scope.searchListToggle true, true

          scope.workflow = id: null

          scope.origTaskData = resetTask()
          scope.origWorkflowData = id: null
        else
          scope.origTaskData = ngModel.$viewValue
          scope.task = angular.copy ngModel.$viewValue

          $timeout ->
            scope.taskInfo?.$setPristine()

          if scope.workflow.check
            scope.workflow.check()

          scope.newTask = false
          scope.setHrefLink()

      scope.validate = (object = null) ->
        if scope.type is 'story'
          scope.invalidTask = object
          scope.taskInfo?.$setValidity 'storyAssigned', not object?

      scope.$watch 'schema.activeHoleId', (newHole, oldHole) ->
        if newHole isnt oldHole and scope.newTask
          scope.task = resetTask()
          scope.workflow.refreshOrgMembers?()

      resetTask = ->
        deadline: moment(scope.defaultdate, 'DD/MM/YYYY h:mm A').format('YYYY-MM-DDTHH:mm:ssZ')
        available_to: ['web', 'print']

      scope.getLinkUrl = ->
        $state.href scope.link.state, scope.link.params

  .directive 'nnTaskInfoField', ->
    restrict: 'E'
    templateUrl: (element, attrs) ->
      '/templates/task-' + attrs.template + '.html'
  
  .directive 'nnTaskInfoFurniture', (Uploader, $q) ->
    templateUrl: '/templates/task-furniture.html'
    scope: true
    link: (scope) ->
      scope.fileUpload = ->
        selectItem = (item) ->
          $q.when
            id: scope.task.content_object?.id
            title: item.title
            url: item.url
            uri: item.uri
            original_id: item.id
            type: scope.type

        scope.files.upload().then (data) ->
          selectItem(data[0]).then (data) ->
            scope.task.content_object = data
            scope.toggleUploading false
            scope.createTask()

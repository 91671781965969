'use strict'

angular.module 'adonis.issue.collections.spreadlayouts', [
	'adonis.issue.collections.spreadlayouts.elements'
]

.factory 'spreadLayoutsRepo', (BasePresenter) ->
  class spreadLayoutsRepo extends BasePresenter
    constructor: ($queryStream, initData) ->
      super $queryStream, initData

      for pageId, pageTemplate of initData
        if @[pageId]
          @[pageId].sync pageTemplate, pageId
        else @[pageId] = @getEntity 'spreadLayouts', pageTemplate, pageId

.factory 'spreadLayoutsEntity', (BasePresenter, spreadLayoutElements) ->
  class spreadLayoutsEntity extends BasePresenter
    constructor: ($queryStream, initData, holeId) ->
      super $queryStream, initData

      @sync initData, holeId

      @$queryStream.listen (query) =>
        query.data.unshift holeId
        query

    sync: (initData, holeId) ->
      @template = initData.template?.v?.id or null

      if @elements
        @elements.sync initData.storyHoles, initData.furnitureHoles
      else
        @elements = new spreadLayoutElements @$queryStream, initData.storyHoles, initData.furnitureHoles

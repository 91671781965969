'use strict'

angular.module 'adonis.layoutpage', []

.factory 'LayoutPagePlacementPresenter', ($timeout, $q, BasePresenter, AdonisService) ->
  class Model
    constructor: (data) ->
      @data = data

    update: (data) ->
      @data = data

  class LayoutPagePlacementPresenter extends BasePresenter
    constructor: ($queryStream, initData) ->
      super $queryStream, initData

      @_presenter = 'LayoutPagePlacementPresenter'
      @layoutPageMap = []

    getSubscription: (id) ->
      if not @layoutPageMap.hasOwnProperty id
        @layoutPageMap[id] ?= data: null, subs: 0, defer: $q.defer()

        @subscribe @_presenter, id, (data) =>
          if not @layoutPageMap[id].data
            @layoutPageMap[id].data = new Model(data)
            @layoutPageMap[id].defer.resolve @layoutPageMap[id].data
          else @layoutPageMap[id].data.update data

      @layoutPageMap[id].subs++
      @layoutPageMap[id].defer.promise

    deleteSubscription: (id) ->
      if @layoutPageMap.hasOwnProperty id
        @layoutPageMap[id].subs--

        if @layoutPageMap[id].subs is 0
          # give 2 seconds to see if still required
          # on next route change pb -> storypad
          $timeout =>
            if @layoutPageMap[id]?.subs is 0
              @unsubscribe @_presenter, id
              delete @layoutPageMap[id]
          , 2000, false

      @

    close: ->
      super()
      @data = null

  new LayoutPagePlacementPresenter AdonisService.$queryStream


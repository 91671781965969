'use strict'

angular.module 'nn.story-list.directives', []

    .directive 'nnStoryList', ->
        templateUrl: '/templates/story-list.html'
        restrict: 'E'
        scope:
            stories: '='
        controller: ($scope, $state, Auth, Explorer, AppState, PageBuilderModuleTypes, Favourites, FavouritesType, NotificationService, Organization) ->

            $scope.organizations = []
            if not Organization.all()
                Organization.refresh()

            Auth.user().then (data) ->
                $scope.user = data

            Favourites.all(FavouritesType.STORY).then (data) ->
                $scope.favourites = data

            $scope.favouritesType = FavouritesType

            $scope.isActive = (story) ->
                $state.includes "**.story.**", story:String(story.id)

            $scope.select = (story) ->
                $state.go 'app.stories.story', story:story.id

            $scope.selectSnapshot = (story, snapshot, event) ->
                if snapshot
                    $state.go 'app.stories.story.snapshot', {story, snapshot}
                    event.preventDefault()
                    event.stopPropagation()
                    Explorer.data.collapsed = true

            $scope.copyText = (text, event) ->
                event.preventDefault()
                event.stopPropagation()
                navigator.clipboard.writeText(text).then ->
                    NotificationService.success 'Story ID copied to clipboard.', '', 1000

            $scope.$watch (->
                Organization.all()
            ), (newVal) ->
                if newVal
                    $scope.organizations = newVal

            $scope.getOrganization = (id) ->
                id = parseInt(id)
                org = $scope.organizations.find (org) ->
                    org.id == id
                if org
                    return org.name

            # $scope.assign = (story) ->
            #     if CommunicationService('task-form')
            #       CommunicationService('task-form').send('newItem', PageBuilderModuleTypes.STORY, story)

###
 
  Form editor (for templates)
  ==========================

###
angular.module("nn.form-editor-main.directives", [])
#
# Field editor directive. This simply pulls the template onto
# the form. The selectedItem is used in the controller is used to
# populate the items in the template...
#
.directive "nnFormEditorMain", ->
    restrict: "E"
    scope: {}
    templateUrl: "/templates/form-editor-main.html"
    controller: ($scope, $state, $q, FormModel, StorageService, Requests) ->
        # Here we just pass the form model up to the
        # view...
        $scope.formModel = FormModel
        #
        # Saves the form edit data to the server...
        #
        $scope.save = (templateName) ->
            successMsg = ""
            #
            promise = FormModel.deflate().then (data) ->
                if FormModel.isValid()
                    successMsg = "Updated successfully."
                    promise = StorageService.update data, FormModel.id
                else
                    successMsg = "Saved successfully."
                    promise = StorageService.save data
                promise
            .then (id) ->
                #
                # Here we are preloading the search list when a
                # save is made - though this may not be necessary...
                #
                #StorageService.buildList()
                FormModel.id = id
                FormModel.message successMsg
                #
                # remain in the edit mode, but ensure that the id
                # is updated correctly if changed...
                #
                $state.go 'app.forms.search.form.edit', form: FormModel.id
                return
            , (reason) ->
                FormModel.message "Failed to save: " + reason, false
                console.log "Failed: " + reason
                return
            return
        #
        # Submit the non-edit submission data to the server
        # using the Storage module
        #
        $scope.submit = ->
            successMsg = ""
            if FormModel.fieldsValid()
                #
                # First we need to ensure that all images have been uploaded to valencia...
                #
                promises = []
                for field in FormModel.fields
                    if field.preSubmit?
                        #
                        # add only existing pre-submit objects, and we
                        # wrap in a when object to ensure they can behave
                        # as promises...
                        #
                        promises.push $q.when field.preSubmit()
                #
                # tie all the promises together and wait...
                #
                $q.all(promises).then (res) ->
                    return
                .then () ->
                    #
                    # Now we can submit the rest of the form...
                    #
                    FormModel.deflateData()
                .then (payload) ->
                    if FormModel.isSubmitValid()
                        successMsg = "Updated successfully."
                        promise = StorageService.submitUpdate payload, FormModel.submitId
                    else
                        successMsg = "Submitted successfully."
                        promise = StorageService.submit payload
                    promise
                .then (id) ->
                    #
                    # update the id just in case..
                    #
                    FormModel.submitId = id
                    FormModel.message successMsg
                    #
                    # remain in the edit mode, but ensure that the id
                    # is updated correctly if changed...
                    #
                    $state.go 'app.forms.submissions.search.form', form: FormModel.submitId
                , (reason) ->
                    FormModel.message "Failed to submit: " + reason, false
                    return
                return
            else
                FormModel.message "Some fields are not complete.", false
            return
        #=================
        #
        # Examples of multiple $q promises...
        #
        # The multiple promises above are executed asynchronously. However, it may
        # be necessary in the future to run them synchronously, chaining the .then
        # calls dynamically. This can be done with the reduce() function. Example
        # is below...
        #
        #=================
        # $scope.asyncPromises = () ->
        #     #
        #     # This works by blasting all promises at once. Note that this requires
        #     # that the upload function can be called asynchronously 
        #     #
        #     all = $q.all([$scope.files.upload(), $scope.files2.upload()])
        #     all.then (results) ->
        #             return results
        #         , (results) ->
        #             return results
        #     .then (results) ->
        #         console.log 'FINAL'
        #         console.log results
        #
        # #
        # # ALTERNATIVE. This works using a reducer to force sequential
        # # processing of the promises.
        # #
        # results = []
        # arr = [$scope.files.upload, $scope.files.upload, $scope.files.upload]
        # promise = arr.reduce (promise, element) ->
        #     return promise.then (res) ->
        #         console.log "Received result:"
        #         console.log res
        #         results.push res
        #         return element()
        # , $q.when({});
        #
        # promise.then (final) ->
        #     results.push final
        #     console.log final
        #     console.log('all done');  

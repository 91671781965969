'use strict'

angular.module 'nn.story-info-pagebuilder.directives', []

  .directive 'nnStoryInfoPageBuilder', ($state, $filter, HOSTS, Roles, AdonisData, IssuePresenter, nnSidenavService, AppState, StoryUtils) ->
    templateUrl: '/templates/story-info-page-builder.html'
    scope:
      story: '='
      task: '='
      page: '=?'
      issue: '=?'
      active: '=?'
      schema: '='
    controller: ($scope) ->
      $scope.gotoPrintSnapshot = ->
        { approved, latest } = $scope.story.snapshot_pointers.print
        snapshotId = approved or latest
        storyId = $scope.story.id
        $state.go 'app.stories.story.snapshot', { story:storyId, snapshot:snapshotId }

      $scope.msideCtrl = nnSidenavService 'mside'
      $scope.schema = {}

      $scope.gotoPage = ->
        if Roles.canGo 'pages'
          {book, number} = $scope.active
          {date, pub} = $scope.issue
          $state.go 'app.pages.issue.page', book: book, page: number, date: date, pub: pub

      $scope.doUnsubscribe = ->
        if $scope.issue?
          IssuePresenter.deleteSubscription $scope.issue.pub + '-' + $scope.issue.date

      $scope.togglePreview = ->
        if $scope.showPreview and not $scope.hybridMode
          $scope.hybridMode = true
        else
          $scope.hybridMode = false
          $scope.showPreview = !$scope.showPreview

      $scope.$watch 'data.meta.pageLayouts[page.details.layout()]', (nv, ov) ->
        if nv and nv.elements.story[$scope.schema?.activeHoleId]
          $scope.schema.activeHole = nv.elements.story[$scope.schema.activeHoleId]

      $scope.$watch 'task', (newTask, oldTask) ->
        if newTask?.id
          if oldTask and newTask.id isnt oldTask.id
            $scope.loaded = false
            $scope.doUnsubscribe()

          IssuePresenter.getPageSubscriptionByTaskId(newTask.id).then (response) ->
            $scope.loaded = true

            angular.extend $scope, response
            $scope.schema.active = response.active

            if response.data?.books
              $scope.page = $scope.spread?.pages?[$scope.active?.side] or $scope.spread?.page
            else $scope.page = null

        else
          $scope.meta = null
          $scope.page = null

      $scope.$watch 'story', (nv, ov) ->
        if nv?.id?
          $scope.storyIsWebOnly = StoryUtils.storyIsWebOnly $scope.story

      $scope.$on '$destroy', -> $scope.doUnsubscribe()

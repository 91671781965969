'use strict'

angular.module 'nn.file-manager', [
        'nn.file-manager.routes'
        'nn.file-manager.services'
        # components
        'nn.file-explorer'
        'nn.file-search'
        'nn.file-editor'
        'nn.file-info'
        'nn.file-list'
        'nn.file-upload'
    ]

    .config ($compileProvider) ->
        $compileProvider.imgSrcSanitizationWhitelist /^\s*(http|https|blob):/
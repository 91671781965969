'use strict'

angular.module 'nn.newslist-explorer.directives', []

.directive 'nnNewslistExplorer', ->
    templateUrl: '/templates/newslist-explorer.html'
    restrict: 'E'
    scope: {}
    controller: ($scope, $state, AppState, NEWSLIST_PILOT_MODE_ENABLED, NEWSLIST_PILOT_ORGANISATION_IDS, NEWS_SECTIONS, NEWS_SECTIONS_NAV, NewsExplorer, moment) ->
        $scope.pilotModeEnabled = NEWSLIST_PILOT_MODE_ENABLED
        $scope.pilotOrganisationIds = NEWSLIST_PILOT_ORGANISATION_IDS
        $scope.sections = NEWS_SECTIONS_NAV
        $scope.state = NewsExplorer
        $scope.selection =
            organisation: null

        $scope.selectIssue = (organisation) ->
            $state.go 'app.newslist.runsheetsRunsheet', org: organisation.name

        $scope.selectSection = (section) ->
            NewsExplorer.menuItems = false
            switch section.id
                when NEWS_SECTIONS.IDEAS.id
                    $state.go 'app.newslist.ideasList'

        $scope.back = ->
            NewsExplorer.menuItems = false
            NewsExplorer.menuItemSelected = null

'use strict'

angular.module 'nn.site-editor.services', []
    .factory 'Sites', ($http, $q, Auth, HOSTS, User, SiteDeviceBreakpoints, $rootScope) ->
        Site = =>
            site = ''
            loading = false
            isLoaded= false
            markup = ''
            breakpoint = SiteDeviceBreakpoints[0].value
            breakpointName = SiteDeviceBreakpoints[0].name
            get: (url) ->
                deferred = $q.defer()
                isLoaded = false
                site = url
                breakpoint = SiteDeviceBreakpoints[0].value
                breakpointName = SiteDeviceBreakpoints[0].name
                Auth.token().then (token) ->
                    loading = true
                    $http
                        method: 'GET',
                        url: url + "?m=editmode",
                        headers:
                            'Authorization': "Bearer #{token}",
                            'Cache-Control': 'no-store'
                    .success (data) ->
                        $q.all([User.getSites(), User.getOrganizations()]).then ([sites, orgs]) ->
                            unQualifiedUrl = url.replace(/(^\w+:|^)\/\//, '')
                            userSite = sites.find (site) -> site.domain == unQualifiedUrl
                            siteOrg = orgs.find (org) -> org.id == userSite.organization
                            isSuzuka2Site = siteOrg?.feature_tags.filter((tag) -> tag == "force-suzuka2").length > 0

                            hosts = Object.assign({}, HOSTS)
                            if (isSuzuka2Site)
                                hosts.suzuka = hosts.suzuka.replace(/suzuka/, 'suzuka2')
                            parser = new DOMParser();
                            doc = parser.parseFromString(data, "text/html")

                            jsScript = document.createElement('script');
                            jsScript.type = 'application/javascript';
                            jsScript.src = "#{HOSTS.sitebuilder}/js/main.js";

                            hostsJsScript = document.createElement('script');
                            hostsJsScript.text = "var __HOSTS__ = #{JSON.stringify(hosts)};"

                            cssScript = document.createElement('link');
                            cssScript.rel = "stylesheet"
                            cssScript.type = 'text/css';
                            cssScript.href = "#{HOSTS.sitebuilder}/css/main.css";

                            doc.getElementsByTagName('head')[0].prepend(hostsJsScript)
                            doc.getElementsByTagName('head')[0].append(cssScript)
                            doc.getElementsByTagName('body')[0].append(jsScript)

                            updatedData = doc.documentElement.innerHTML
                            markup = updatedData
                            deferred.resolve updatedData
                    .then ->
                        isLoaded = true
                        loading = false
                deferred.promise
            isLoading: ->
                loading
            isLoaded: ->
                isLoaded
            site: ->
                site
            setBreakpoint: (device) ->
                newBreakpoint =  SiteDeviceBreakpoints.find((x) => x.name == device)
                if newBreakpoint
                    breakpoint = newBreakpoint.value
                    breakpointName = newBreakpoint.name
                # HACK: Unfortunately looks like calling this method
                # doesnt seems to cause a digest/apply to occur on the
                # nnSiteEditor directive. Just force it.
                $rootScope.$apply()
                breakpoint
            breakpoint: ->
                breakpoint
            breakpointName: ->
                breakpointName
        getInstance: ->
            new Site


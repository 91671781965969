'use strict'

angular.module 'nn.publication-select.directives', []

    .directive 'nnPublicationSelect', ->
        templateUrl: '/templates/publication-select.html'
        restrict: 'E'
        scope:
            selected: '='
            options: '='
            cookie: '@'
            placeholder: '@'
            clearable: '='
            panels: '@'
        controller: ($scope, $attrs, $cookieStore, User, FeatureAccess, PUB_SELECT_MSG) ->
            # restore previously selected publication
            restoreFromCookie = ->
                if $attrs.cookie?
                    savedPubCode = $cookieStore.get $scope.cookie
                    savedPub = pub for pub in $scope.publications when pub.code is savedPubCode
                    if savedPub?
                        $scope.selected = savedPub
                    else if $attrs.autoSelect? and $scope.publications?.length
                        $scope.selected = $scope.publications[0]
            # save selected publication
            saveToCookie = ->
                if $attrs.cookie?
                    $cookieStore.put $scope.cookie, $scope.selected.code

            # load publications
            loadPublications = ->
                if $attrs.options?
                    # show all publications
                    $scope.publications = $scope.options
                    $scope.placeholderMsg = PUB_SELECT_MSG.CHOOSE
                    restoreFromCookie()
                else
                    # retrieve the user's publications
                    User.getPublications().then (publications) ->
                        $scope.publications = publications
                        $scope.placeholderMsg = $scope.placeholder or PUB_SELECT_MSG.CHOOSE
                        restoreFromCookie()
                    , (errorMsg) ->
                        $scope.placeholderMsg = errorMsg


            # clear selected publication
            $scope.clear = ($event) ->
                $event.stopPropagation()
                $scope.selected = undefined
                saveToCookie()                        
            # init
            $scope.changePublication = -> saveToCookie()
            loadPublications()

    .constant 'PUB_SELECT_MSG',
        LOADING: 'Loading publications...'
        CHOOSE: 'Choose a publication'

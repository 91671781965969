'use strict'

angular.module 'nn.pubsub.services', []

.factory 'PubSub', ->
  event = {}
  callbacks = {}

  debug = false

  idCounter = 0

  uniqueId = (prefix = '') ->
    id = ++idCounter
    String(prefix) + id

  publish: (topic, args...) ->
    console.log 'PUBLISH', topic, args  if debug
    event[topic] = event[topic] or cache: undefined, listeners: []

    angular.forEach event[topic].listeners, (listener) ->
      callbacks[listener].fn.apply this, args

    event[topic].cache = args
    return

  subscribe: (topic, callback) ->
    console.log 'SUBSCRIBE', topic, callback  if debug
    event[topic] = event[topic] or cache: undefined, listeners: []

    listener = uniqueId topic

    event[topic].listeners.push listener
    callbacks[listener] = fn: callback, topic: topic

    listener

  unsubscribe: (listener) ->
    console.log 'UNSUBSCRIBE', listener  if debug

    topic = callbacks[listener] and callbacks[listener].topic
    event[topic].listeners = event[topic].listeners.filter (item) -> item is listener
    delete callbacks[listener]

  getCache: (topic) ->
    console.log 'CACHE', topic  if debug
    event[topic] && event[topic].cache

'use strict'

angular.module 'nn.notification.services', []

.service 'NotificationService', ($q, $timeout, PubSub, NotificationConfig) ->

  @create = (type, title, body, timeout, bodyOutputType, fullscreen=false) ->
    deferred = $q.defer()
    notification =
      type: type
      title: title
      body: body
      fullscreen: fullscreen
      timeout: timeout
      bodyOutputType: bodyOutputType
      deferred: deferred
    $timeout ->
      PubSub.publish 'Notifications', 'newNotification', notification
    deferred.promise

  @createGet = (type, title, body, timeout, bodyOutputType, fullscreen=false) ->
    deferred = $q.defer()
    notification =
      type: type
      title: title
      body: body
      fullscreen: fullscreen
      timeout: timeout
      bodyOutputType: bodyOutputType
      deferred: deferred
    $timeout ->
      PubSub.publish 'Notifications', 'newNotification', notification
    notification

  @clear = ->
    PubSub.publish 'Notifications', 'clearNotifications'

  @clearWith = (notification) ->
    PubSub.publish 'Notifications', 'clearNotifications', notification

  @confirm = (title, body, fullscreen) =>
    @create 'confirm', title, body, 0, 'confirm', fullscreen
  
  @confirmWithHyperlink = (title, body, fullscreen) =>
    @create 'custom', title, body, 0, 'trustedHtml', fullscreen

  @confirmWithCustomAction = (title, body, fullscreen) =>
    @create 'custom', title, body, 0, 'trustedHtmlCustomAction', fullscreen
  
  @confirmWithSendingBreakingNews = (title, body, fullscreen) =>
    @create 'custom', title, body, 0, 'confirmSendBreakingNews', fullscreen
  
  @confirmWithNotSendingBreakingNews = (title, body, fullscreen) =>
    @create 'custom', title, body, 0, 'confirmNotSendBreakingNews', fullscreen
    
  angular.forEach NotificationConfig['icon-classes'], (alias, method) =>
    @[method] = (title, body, timeout, bodyOutputType) =>
      @create method, title, body, timeout, bodyOutputType

  @
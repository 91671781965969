'use strict'

angular.module 'nn.task', [
  'nn.task.info'
  'nn.task.container'
  'nn.task.directives'
  'nn.task.services'
  'nn.task.list'
  'nn.task.flag'
  'nn.task.assignd'
  'nn.task.assignee'
  'nn.task.workflow'
  'nn.task.channel'
  'nn.task.buttons'
  'nn.task.reassign'
  'nn.task.unlink'
  'nn.task.comments-list'
  'nn.task.search'
]

'use strict'

angular.module 'adonis.issue.collections.spreadlayouts.elements', [
  'adonis.issue.collections.pagelayouts.elements.furniture',
  'adonis.issue.collections.pagelayouts.elements.stories',
  'adonis.issue.collections.pagelayouts.elements.barcode'
]

.factory 'spreadLayoutElements', ($q, BasePresenter, pageLayoutFurniture, pageLayoutStories) ->
  class spreadLayoutElements extends BasePresenter
    constructor: ($queryStream, storyHoles, furnitureHoles) ->
      super $queryStream

      @sync storyHoles, furnitureHoles

    sync: (storyHoles, furnitureHoles) ->

      cleanFurniture = =>
        @furniture = {}
        @$furnitureTypeLeft = null
        @$furnitureTypeRight = null
        @$type = "spread"

        for id, furn of furnitureHoles

          if @furniture[id]
            @furniture[id].sync furn, id
          else @furniture[id] = new pageLayoutFurniture @$queryStream, furn, id

          @furniture[id].spread = true

          furnitureType = (furn.placement?.v?.generatable?.v?.left or furn.placement?.v?.generatable)?.v?.template?.id

          if furnitureType
            if @$furnitureTypeLeft
              @$furnitureTypeRight = furnitureType.substring 0, furnitureType.lastIndexOf('-')
            else
              @$furnitureTypeLeft = furnitureType.substring 0, furnitureType.lastIndexOf('-')

      cleanStories = =>
        @story = {}

        for id, story of storyHoles
          if @story[id]
            @story[id].sync story, id
          else @story[id] = new pageLayoutStories @$queryStream, story, id

          @story[id].spread = true

      $q.all [cleanFurniture(), cleanStories()]

'use strict'

angular.module 'nn.publication-explorer.services', []

    .factory 'PublicationExplorer', ->
        menuItems: false
        menuItemSelected: null

    .constant 'PublicationSections', [
            { 'id':'search', label:'Lookup Publication', icon:'fa-search' }
        ]

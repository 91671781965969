'use strict'

angular.module 'nn.profile.services', []


  .factory 'ProfileSettings', (HOSTS) ->
    API_URL: "#{HOSTS.silverstone}/api/v2/author/"
    MAX_PROFILES: 1
    MUGSHOT_DIMENSIONS: { MIN_WIDTH:400, MIN_HEIGHT:400 }

  .constant 'ProfileSocialAccounts', [
    { name:'instagram',   label:'Instagram', default:true, placeholder:'Username', type:'string' },
    { name:'facebook',    label:'Facebook',  default:true,  placeholder:'Username', type:'string' },
    { name:'tiktok',      label:'Tiktok',    default:true,  placeholder:'Username', type:'string' },
    { name:'twitter',     label:'Twitter',   default:true,  placeholder:'Username', type:'string' },
    { name:'web',         label:'Web',       default:true, placeholder:'URL',      type:'url'    },
    { name:'googleplus',  label:'Google+',   default:true,  placeholder:'Username', type:'string' },
    { name:'pinterest',   label:'Pinterest', default:false, placeholder:'Username', type:'string' },
    { name:'youtube',     label:'YouTube',   default:false, placeholder:'Username', type:'string' },
    { name:'snapchat',    label:'Snapchat',  default:false, placeholder:'URL',      type:'url'    },
    { name:'livefyre',    label:'Livefyre',  default:false, placeholder:'Username', type:'string' },
    { name:'reddit',      label:'Reddit',    default:false, placeholder:'Username', type:'string' },
    { name:'disqus',      label:'Disqus',    default:false, placeholder:'Username', type:'string' },
    { name:'tumblr',      label:'Tumblr',    default:false, placeholder:'URL',      type:'url'    }
  ]


  .constant 'ProfileNotifications', {
    CREATE_SUCCESS:
      msg: 'Your profile has been created!'
      status: 'success'
    CREATE_ERROR:
      msg: 'There was a problem creating your profile, please try again.'
      status: 'alert'
    UPDATE_SUCCESS:
      msg: 'Your profile has been updated.'
      status: 'success'
    UPDATE_ERROR:
      msg: 'There was a problem updating your profile, please try again.'
      status: 'alert'
  }

  .constant 'ProfileRoles', [
    { value:'dp',  label:'Digital & Print',    default:true },
    { value:'d',   label:'Digital',            default:false },
    { value:'p',   label:'Print',              default:false },
  ]


  .factory 'Profile', ($q, $timeout, ProfileAPI, ProfileSettings, ProfileNotifications, Uploader) ->
    class Profile
      constructor: (@data) ->
        @uploader = Uploader.getInstance()
        # states
        @editing = false
        @creating = false
        @saving = false
        @removing = false
        @notification = null
        # change tracking
        @originalData = angular.copy @data
        @changedData = {}
        @hasChanged = false
        @imageInvalid = false
      create: =>
        deferred = $q.defer()
        @creating = true
        @notification = null
        create = =>
          ProfileAPI.create(@data)
            .then (response) =>
              @creating = false
              @notification = ProfileNotifications.CREATE_SUCCESS
              @originalData = response
              angular.extend @data, response
              deferred.resolve response
            .catch (error) =>
              @creating = false
              @notification = ProfileNotifications.CREATE_ERROR
              deferred.reject error
              console.debug 'error', error
        if @uploader.isSelected()
          @uploader.upload()
            .then ([{uri}]) =>
              @uploader.removeAll()
              @data.mugshot = uri
              create()
        else
          create()
        deferred.promise
      save: =>
        @saving = true
        @notification = null
        update = =>
          @diff()
          ProfileAPI.update(@data.id, @changedData)
            .then =>
              @saving = false
              @editing = false
              @notification = ProfileNotifications.UPDATE_SUCCESS
              angular.extend @originalData, @changedData
              @diff()
            .catch (error) =>
              @saving = false
              @notification = ProfileNotifications.UPDATE_ERROR
              console.debug 'error', error
        if @uploader.isSelected()
          @uploader.upload()
            .then ([{uri}]) =>
              @uploader.removeAll()
              @data.mugshot = uri
              update()
            .catch (error) =>
              @saving = false
              console.debug 'error', error
        else
          update()
      remove: =>
        deferred = $q.defer()
        @removing = true
        ProfileAPI.delete(@data.id)
          .then =>
            @removing = false
            deferred.resolve()
          .catch (error) =>
            @removing = false
            console.debug 'error', error
            deferred.reject error
        deferred.promise
      reset: =>
        @imageInvalid = false
        @notification = null
        $timeout => @editing = false
        angular.extend @data, @originalData
        if @uploader.isSelected()
          @uploader.removeAll()
      diff: =>
        changes = {}
        for prop of @originalData
          newValue = @data[prop]
          if @originalData[prop] isnt newValue
            changes[prop] = newValue
        @hasChanged = Object.keys(changes).length > 0
        @changedData = changes
      selectImage: (files) =>
        if files.length
          @uploader.select files
          @data.mugshot = null
          @imageInvalid = false
          profile = this
          img = new Image()
          img.onload = ->
            { MIN_WIDTH, MIN_HEIGHT } = ProfileSettings.MUGSHOT_DIMENSIONS
            validWidth = this.width > MIN_WIDTH
            validHeight = this.height > MIN_HEIGHT
            $timeout ->
              profile.imageInvalid = !validWidth or !validHeight
          img.src = window.URL.createObjectURL files[0]
      clearImage: =>
        @imageInvalid = false
        if @uploader.isSelected()
          @uploader.removeAll()
          if @data.mugshot is null
            @data.mugshot = ''
        else if @data.mugshot
          @data.mugshot = ''
    getInstance: (data) ->
      new Profile data


  .factory 'Profiles', (Profile) ->
    class Profiles
      constructor: ->
        @data = []
      addProfile: (config) =>
        profile = Profile.getInstance config
        @data.push profile
      removeProfile: (profile) =>
        filter = (p) => p.data.id isnt profile.data.id
        @data = @data.filter filter
    getInstance: (data) ->
      new Profiles


  .factory 'UserProfile', ($q, ProfileAPI) ->
    load: (user_id) ->
      deferred = $q.defer()
      onProfilesLoadSuccess = (profiles) ->
        if profiles?.length
          deferred.resolve profiles[0]
        else
          deferred.reject()
      onProfilesLoadError = ->
        deferred.reject()
      if user_id?
        ProfileAPI.loadByUser(user_id)
          .then onProfilesLoadSuccess
          .catch onProfilesLoadError
      else
        ProfileAPI.load()
          .then onProfilesLoadSuccess
          .catch onProfilesLoadError
      deferred.promise


  .factory 'ProfileAPI', ($http, $q, HOSTS, Auth, ProfileSettings) ->
    PROFILE_API_URL = ProfileSettings.API_URL
    load: (id='self') ->
      deferred = $q.defer()
      $http
        method: 'GET'
        url: "#{PROFILE_API_URL}#{id}/"
        auth: true
        cache: false
      .success (response) ->
        if response?.results?.length
          profiles = response.results
        else
          profiles = []
        deferred.resolve profiles
      .error (error) ->
        deferred.reject error
      deferred.promise
    loadByUser: (user_id) ->
      deferred = $q.defer()
      $http
        method: 'GET'
        url: "#{PROFILE_API_URL}?user=#{user_id}/"
        auth: true
        cache: false
      .success (response) ->
        if response?.results?.length
          profiles = response.results
        else
          profiles = []
        deferred.resolve profiles
      .error (error) ->
        deferred.reject error
      deferred.promise
    update: (id, data) ->
      deferred = $q.defer()
      $http
        method: 'PATCH'
        url: "#{PROFILE_API_URL}#{id}/"
        data: data
        auth: true
        cache: false
      .success ->
        deferred.resolve()
      .error (error) ->
        deferred.reject error
      deferred.promise
    create: (data) ->
      deferred = $q.defer()
      Auth.user()
        .then ({id}) ->
          angular.extend data, user:id
          $http
            method: 'POST'
            url: PROFILE_API_URL
            data: data
            auth: true
            cache: false
          .success (response) ->
            deferred.resolve response
          .error (error) ->
            deferred.reject error
      deferred.promise
    delete: (id) ->
      deferred = $q.defer()
      $http
        method: 'DELETE'
        url: "#{PROFILE_API_URL}#{id}/"
        auth: true
        cache: false
      .success ->
        deferred.resolve()
      .error (error) ->
        deferred.reject error
      deferred.promise

'use strict'

angular.module 'nn.newslist-explorer.services', []

.factory 'NewsExplorer', ->
    menuItems: false
    menuItemSelected: null

.constant 'NEWS_SECTIONS',
    IDEAS: { id:'idea-list', label:'Story ideas', icon:'fa-lightbulb-o' }

.factory 'NEWS_SECTIONS_NAV', (NEWS_SECTIONS) ->
    return [
        NEWS_SECTIONS.IDEAS
    ]

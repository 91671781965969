'use strict'

angular.module 'adonis.presenters', []

.service 'AdonisData', ($timeout, $q, AdonisService, elementsParser, moment) ->
  templateRequest = $q.defer()

  # formats story, furniture elements to theur simplest form to keep html simple
  formatTemplateElements = (data, isSpread = false) ->
    data.templates = data.favouriteTemplates.map((t) ->
      t.isFavourited = true
      t
    ).concat data.templates
    data.templates.map (template) ->
      template.elements = story: {}, furniture: {}

      template.storyHoleTemplateRefs.map ([position, {id}]) ->
        storyHole = data.storyHoleTemplates[id]
        if storyHole?
          element = elementsParser.createHole(storyHole.boundingBox, position)
          element.elements = elementsParser.format(storyHole, null)[0]
          template.elements.story[id] = element

      for i, el of template.furnitureHoles
        element = elementsParser.createHole(el[1].boundingBox, el[0])
        element.name = el[1].name
        template.elements.furniture[i] = element

      template.spread = isSpread
      template

  checkEditorialSpace = (editorialSpace) ->
    switch editorialSpace
      # Half page verticals
      when "0600-0624-1200-1224"                                            then "0000-0001-0601-0624-1200-1224"
      when "0000-0024-0600-0624"                                            then "0000-0024-0601-0624-1200-1201"
      when "0000-0002-0602-0624-1200-1224", "0000-0003-0603-0624-1200-1224" then "0000-0001-0601-0624-1200-1224"
      when "0000-0024-0602-0624-1200-1202", "0000-0024-0603-0624-1200-1203" then "0000-0024-0601-0624-1200-1201"
      # L-shape ad stacks https://docs.google.com/spreadsheets/d/1FpymrbZ3vPk4ctGGxpgt6hZ7OwtfBTdnttHjTCJbQ70
      when "0600-0621-1200-1221"                                            then "0000-0001-0601-0621-1200-1221"
      when "0600-0618-1200-1218"                                            then "0000-0001-0601-0618-1200-1218"
      when "0600-0612-1200-1212"                                            then "0000-0001-0601-0612-1200-1212"
      when "0000-0021-0600-0621"                                            then "0000-0021-0601-0621-1200-1201"
      when "0000-0018-0600-0618"                                            then "0000-0018-0601-0618-1200-1201"
      when "0000-0012-0600-0612"                                            then "0000-0012-0601-0612-1200-1201"
      # Fall back for when no ad is on page
      when "Empty" then "0000-0001-1200-1201"
      else editorialSpace

  getPublicationPresenter: (publicationCode) ->
    AdonisService.once('PublicationPresenter', publicationCode, false)

  getStoryIssuesPresenter: (task) ->
    storyIssuePresenter = $q.defer()

    AdonisService.once('StoryRenderLookupPresenter', 'task-' + task).then (result) ->
      if result?.fitViews? and result.fitViews.length
        currentIndex = 0

        results = result.fitViews.map ([issue, [{pageNum, storyFit, layout, org}]], index, array) ->
          [issuePub, issueYear, issueMonth, issueDay] =  issue.id.split '-'
          [layoutPub, layoutYear, layoutMonth, layoutDay] =  layout.v.id.split '-'

          issueDate = new Date issueYear, issueMonth, issueDay
          layoutDate = new Date layoutYear, layoutMonth, layoutDay

          if issueDate.getTime() is layoutDate.getTime() and issuePub is layoutPub
            currentIndex = index

          issue: issue.id
          page: pageNum
          layout: layout.v.id
          holeId: storyFit.holeId.value
          spreadId: storyFit.spread.id
          side: if storyFit.side.t is 'LeftSidePage' then 'left' else 'right'

        storyIssuePresenter.resolve results[currentIndex]

      else storyIssuePresenter.reject()

    storyIssuePresenter.promise

  #  Get Templates for Id
  #      AdonisData.getTemplates(234234-23432).then (data) ->
  #        $scope.templates = data

  getTemplates: (editorialSpace, spreadHash, query) ->
    if templateRequest
      templateRequest.resolve null
      templateRequest = $q.defer()

    hashes = [checkEditorialSpace(editorialSpace), spreadHash]
    #hashes.push spreadHash.split('++').map(checkEditorialSpace)...

    templatesRequest = $q.all(
      hashes
        .filter (elem, pos, arr) ->
          arr.indexOf(elem) is pos
        .map (hash) ->
          AdonisService.query('user-templates', hash+query).then (data) ->
            data.spread = hash is spreadHash
            data
      )

    templatesRequest
      .then (results) ->
        templateRequest.resolve results.reduce (result, row, idx) ->
          result.templates.push formatTemplateElements(row, row.spread)...
          result.remaining += parseInt row.remaining, 10
          result
        , templates: [], remaining: 0
      .catch (data) ->
        templateRequest.reject()

    templateRequest.promise

  buildTasks: ([activePageElements, adjoiningPageElements]) ->
    defer = $q.defer()
    tasks = story: [], furniture: [], modules: 0, count: 0

    hasTasks = 0
    hasModules = 0

    addTask = (key, task = null) ->
      tasks[key].push task
      hasTasks++ if task

    resolveTasks = ->
      if hasModules > 0
        tasks.count = hasTasks
        tasks.modules = hasModules
        defer.resolve tasks
      else defer.resolve null

    { story, furniture } = activePageElements

    if adjoiningPageElements
      angular.extend story, adjoiningPageElements.story
      angular.extend furniture, adjoiningPageElements.furniture

    length = Object.keys(story).length + Object.keys(furniture).length

    angular.forEach { furniture: furniture, story: story }, (elem, key) ->
      angular.forEach elem, (item, id) =>
        addTask key, item.task
        hasModules++

      if hasModules is length
        resolveTasks()

    defer.promise

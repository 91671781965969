'use strict'

angular.module 'nn.site-explorer.directives', []

.directive 'nnSiteExplorer', ->
    templateUrl: '/templates/site-explorer.html'
    restrict: 'E'
    scope: {}
    controller: ($scope, $state, AppState, SITE_SECTIONS, SITE_SECTIONS_NAV, SiteExplorer, moment, User, HOSTS) ->
        $scope.sections = SITE_SECTIONS_NAV
        $scope.state = SiteExplorer
        $scope.selection =
            site: null

        # here is to verify if the current env is DEV or not
        $scope.isDevEnv = HOSTS.silverstone == 'https://silverstone-dev.dev.newsnow.io'
        $scope.selectSite = (site) ->
            if $scope.dynSites is 'dev'
                $state.go 'app.site.url', site: site.domain
            else
                $state.go 'app.site.dyn', site: site.domain
        $scope.dynSites = 'dev'
        $scope.changeDynEnv = () ->
            if $('#dyn').val().toLowerCase() is 'dev'
                $scope.dynSites = 'dev'
            else
                User._dyn = ''
                tempVal = $('#dyn').val().toLowerCase()
                tempVal = tempVal.replace("/", "-")
                tempVal = "https://suzuka-#{tempVal}.dev.newsnow.io"
                User._dyn = tempVal
                $scope.dynSites = tempVal

        $scope.back = ->
            SiteExplorer.menuItems = false
            SiteExplorer.menuItemSelected = null

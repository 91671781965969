###

Field module
============
Contains a constants object for initial default settings and allowed types
for Fields. Fields contain elements, and fields belong to a form. An example
of a field would be a text element that can have a title, a label, default
text for the user, and tags associated with it.

###     
angular.module("nn.form-field", [])
#
#  FieldMenu constant. This controls the type and defaults
#  of the items that can be placed on the forms...
#
.constant "FieldTypes",
    #
    #  The field types control the type of entries that the
    #  user can place onto the form. This would be elements
    #  such as Text fields, checkboxes etc.
    #
    TEXT:
        type: "text"
        name: "Text"
        class: "text-field"
        viewTemplate: "/templates/text-view.html"
        editTemplate: "/templates/text-edit.html"
        enabled: true
    IMAGE:
        type: "img"
        name: "Image"
        class: "image-field"
        viewTemplate: "/templates/img-view.html"
        editTemplate: "/templates/img-edit.html"
        enabled: true
    NUMBER:
        type: "number"
        name: "Number"
        class: "number-field"
        viewTemplate: "/templates/number-view.html"
        editTemplate: "/templates/number-edit.html"
        enabled: true
    DROPDOWN:
        type: "dropdown"
        name: "Dropdown List"
        class: "dropdown-field"
        viewTemplate: "/templates/dropdown-view.html"
        editTemplate: "/templates/dropdown-edit.html"
        enabled: false
    TITLE:
        type: "title"
        name: "Title"
        class: "title-field"
        viewTemplate: "/templates/title-view.html"
        editTemplate: "/templates/title-edit.html"
        enabled: false
#
# This a basic utility service to help with Field types...
#
.service "FieldUtil", (FieldTypes) ->
    self = this
    self.getFieldType = (str) ->
        for i of FieldTypes
            return FieldTypes[i] if FieldTypes[i].type is str
        console.log "WARNING: Type not found! ", str
        null
    return self
#
# This is the main model object for a field.
#
# A form has a list of fields,
# and a field has a list of elements.
# An element can be of type text or checkbox
# and this will control the field.
#
# This is a basic field which has no elements. The class
# descendants hold specific fields e.g. image, text etc.
# BaseField is essentially an abstract base class...
#
.factory "BaseField", (FieldTypes, ElementModel, ElementTypes) ->
        #
    # The field model is a class object with features to control
    # the list of elements etc...
    #
    class BaseField
        #
        # Generate a random id...
        #
        _genID = ->
            Math.random().toString(36).substring 7
        #
        # Helper to convert the elements
        # of an obj to an array...
        #
        updateList: ->
            @elemList = []
            for i of @elements
                @elemList.push @elements[i] if @elements.hasOwnProperty(i)
            return
        #
        # This function is used to update the invalid status of
        # the current field.
        #
        updateCheck: ->
            #
            # This should be replaced with a rule element
            # with regular expressions defined for each object.
            # This function can then apply all the regular
            # expressions for the particular rule element.
            #
            m = ""
            #
            # this checks the required property...
            #
            if @elements.hasOwnProperty("required")
                if @elements.required.data
                    if (not @data) or (@data.length == 0)
                        @invalid.status = true
                        @invalid.msg = "" 
            #
            # this checks the max character property...
            #
            if @elements.hasOwnProperty("maxChar")
                m = @elements.maxChar
                if (@data) and (m.data)
                    if @data.length > m.data
                        @invalid.status = true
                        @invalid.msg = "Maximum " + m.data + " characters."
                        return
            #
            # This checks the minimum character property...
            #
            if @elements.hasOwnProperty("minChar")
                m = @elements.minChar
                # first check that the elements exist
                if (@data) and (m.data)
                    # only require less than...
                    if @data.length < m.data
                        @invalid.status = true
                        @invalid.msg = "Requires at least " + m.data + " characters."
                        return
            @invalid.status = false
            @invalid.msg = ""
            return
        #
        #  Field constructor...
        #  ====================
        #
        constructor: (fieldType, editable=true) ->
            #
            # The main funciton of the field is that it
            # holds and manages a list of elements...
            #
            @elements = {}
            #
            # This is the object form of the above. Note that
            # this is useful for keeping the elements in order,
            # and also so that orderBy and filters can be applied
            # on elements...
            #
            @elemList = []
            #
            # This holds the submission data for the field...
            #
            @data = null
            #
            # The Field display object
            #
            @type = fieldType.type
            #
            # The Field display name object
            #
            @name = fieldType.name
            #
            # The Field type
            #
            @fieldType = fieldType
            #
            # Whether this element is editable or not
            #
            @editable = editable
            #
            # A preSubmit function that will be called prior to
            # the submission (after the button is pressed). Currently
            # only the image service uses this to upload to Valencia
            #
            @preSubmit = null
            #
            # This holds the current validity of the field. This
            # can be done automatically, but it is more efficient
            # to update this property manually on an ng-change. This
            # will be necessary if regex are used to define rules...
            #
            @invalid =
                status: false
                msg: ""
            #
            # The ID is the unique ID for this element...
            #
            @id = _genID()

            return
#
# The TEXT element contains all text input entries for:
#
#    - Title
#    - Tag
#    - Default Text
#    - Description
#    - Maximum Characters
#    - Minimum Characters
#    - Required Checkbox
#
.factory "TextField", (BaseField, ElementModel, ElementTypes) ->
    class TextField extends BaseField
        constructor: (fieldType, editable=true) ->
            super(fieldType, editable)   
            @elements =
                title:
                    new ElementModel(ElementTypes.TEXT, 0, "Name", false)
                tag:
                    new ElementModel(ElementTypes.TEXT, 1, "Tags", true)
                defaultText:
                    new ElementModel(ElementTypes.TEXT, 2, "Default text", false)
                desc:
                    new ElementModel(ElementTypes.TEXT, 3, "Help text", false)
                maxChar:
                    new ElementModel(ElementTypes.NUMBER, 4, "Max chars", true)
                minChar:
                    new ElementModel(ElementTypes.NUMBER, 5, "Min chars", true)
                required:
                    new ElementModel(ElementTypes.CHECKBOX, 6, "Required", false)
            # update the passive helper functions...
            @updateCheck()
            @updateList()
#
# The IMAGE element contains all text input entries for:
#
#     - Title
#     - Tag
#     - Button Text
#     - Description
#     - Required
#
.factory "ImageField", (BaseField, ElementModel, ElementTypes) ->
    class ImageField extends BaseField
        constructor: (fieldType, editable=true) ->
            super(fieldType, editable)    
            @elements =
                title:
                    new ElementModel(ElementTypes.TEXT, 0, "Name", false)
                tag:
                    new ElementModel(ElementTypes.TEXT, 1, "Tags", true)
                button:
                    new ElementModel(ElementTypes.TEXT, 2, "Button text", false)
                desc:
                    new ElementModel(ElementTypes.TEXT, 3, "Help text", false)
                required:
                    new ElementModel(ElementTypes.CHECKBOX, 4, "Required", false)
            # update the passive helper functions...
            @updateCheck()
            @updateList()
#
# The NUMBER element contains all text input entries for:
#
#   - Title
#   - Tag
#   - Default Number
#   - Description
#   - Required
#
.factory "NumberField", (BaseField, ElementModel, ElementTypes) ->
    class NumberField extends BaseField
        constructor: (fieldType, editable=true) ->
            super(fieldType, editable)   
            @elements =
                title:
                    new ElementModel(ElementTypes.TEXT, 0, "Name", false)
                tag:
                    new ElementModel(ElementTypes.TEXT, 1, "Tags", true)
                defaultNumber:
                    new ElementModel(ElementTypes.TEXT, 2, "Default number", false)
                desc:
                    new ElementModel(ElementTypes.TEXT, 3, "Help text", false)
                required:
                    new ElementModel(ElementTypes.CHECKBOX, 4, "Required", false)
            # update the passive helper functions...
            @updateCheck()
            @updateList()
#
# The DROPDOWN element contains all text input entries for:
#
#   - Title
#   - Tag
#   - DefaultElement
#   - Description
#   - Required
#
.factory "DropDownField", (BaseField, ElementModel, ElementTypes) ->
    class DropDownField extends BaseField
        constructor: (fieldType, editable=true) ->
            super(fieldType, editable)   
            @elements =
                title:
                    new ElementModel(ElementTypes.TEXT, 0, "Name", false)
                tag:
                    new ElementModel(ElementTypes.TEXT, 1, "Tags", true)
                defaultElement:
                    new ElementModel(ElementTypes.DROPDOWN, 2, "Default element", false)
                desc:
                    new ElementModel(ElementTypes.TEXT, 3, "Help text", false)
                required:
                    new ElementModel(ElementTypes.CHECKBOX, 4, "Required", false)
            # update the passive helper functions...
            @updateCheck()
            @updateList()
#
# The TITLE field contains all text input entries for:
#
#   - Title
#   - Organization
#   - Description
#
.factory "TitleField", (BaseField, ElementModel, ElementTypes) ->
    class TitleField extends BaseField
        constructor: (fieldType, editable=true) ->
            super(fieldType, editable)   
            @elements =
                name:
                    new ElementModel(ElementTypes.TEXT, 0, "Title", false)
                org: 
                    new ElementModel(ElementTypes.TEXT, 1, "Organization", false)
                desc:
                    new ElementModel(ElementTypes.TEXT, 2, "Form Description", false)
                tags:
                    new ElementModel(ElementTypes.ARRAY, 3, "Search Tags", true)
            @editable = false
            # the title is special as it holds a search tags object...
            # update the passive helper functions...
            @updateCheck()
            @updateList()
#
# The Field model is a factory method for building fields based on
# and input type parameter...
#
.factory "FieldModel", (FieldTypes, TextField, ImageField, NumberField, TitleField, DropDownField) ->
    class Field
        create: (fieldType, editable=true) ->
            switch fieldType
                when FieldTypes.TEXT then new TextField(fieldType, editable)
                when FieldTypes.IMAGE then new ImageField(fieldType, editable)
                when FieldTypes.NUMBER then new NumberField(fieldType, editable)
                when FieldTypes.TITLE then new TitleField(fieldType, editable)
                when FieldTypes.DROPDOWN then new DropDownField(fieldType, editable)
                else
                    console.log "ERROR: Invalid type found: " + fieldType + " " + FieldTypes.TEXT
                    throw "ERROR: Invalid field type found."

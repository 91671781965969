'use strict'

angular.module 'nn.report-manager.services', []

    .factory 'EventLog', ($http, $q, Auth, HOSTS) ->
        API_URL = "#{HOSTS.silverstone}/api/v2/events/"
        PAGE_SIZE = 50
        encodeUrl = (params) => 
            encParams = Object.keys(params).filter((k) -> params[k]).map((k) -> 
                "#{encodeURIComponent(k)}=#{encodeURIComponent(params[k])}").join('&')
            if encParams then "#{API_URL}?#{encParams}" else API_URL
        Events = ->
            isLoading = false
            events = []
            params = {
                model: 'tasks.Task'
                event_type: 'RED_FLAG_ADDED'
            }
            remove: ->
                events = []                
            get: ->
                Auth.token().then (token) ->
                    isLoading = true
                    $http
                        method: 'GET'
                        url: encodeUrl params
                        headers: 'Authorization':"Bearer #{token}"
                        cache: false
                        auth: true
                    .success (data) ->
                        events = data.results
                        data.results
                    .then ->
                        isLoading = false
            all: ->
                events
            params: ->
                params
            isLoading: ->
                isLoading
            remove: ->
                events = []                
        getInstance: ->
            new Events


    .factory 'Reports', ($http, $q, Auth, HOSTS, REPORT_FIELDS, moment) ->
        API_URL = "#{HOSTS.silverstone}/api/v2/reports/"
        encodeUrl = (params) -> 
            encParams = Object.keys(params).filter((k) -> params[k]).map((k) -> 
                "#{encodeURIComponent(k)}=#{encodeURIComponent(params[k])}").join('&')
            if encParams then "#{API_URL}?#{encParams}" else API_URL
        mapReportFields = (reports) ->
            # We need to convert the object to a representation
            # that can maintain order on iteration hence we 
            # use an array [key, value] (cant use Map). Also clean
            # field names.
            if reports.length
                reports.map (report) ->
                    report.rows = report.rows.map (row) ->
                        Object.keys(row).filter((k)->REPORT_FIELDS.hasOwnProperty(k))
                            .map (key) ->                                                                               
                                [REPORT_FIELDS[key].name or key, row[key]]
                reports
            else
                []
        Reports = ->
            isLoading = false
            origReports = []
            reports = []
            params = {
                page_size: 200
            }
            remove: ->
                reports = []            
            get: ->
                Auth.token().then (token) ->
                    isLoading = true
                    $http
                        method: 'GET'
                        url: encodeUrl params
                        headers: 'Authorization':"Bearer #{token}"
                        cache: false
                        auth: true
                    .success (data) ->
                        angular.copy(data.results, origReports)
                        reports = mapReportFields data.results
                    .then ->
                        isLoading = false
            all: ->
                reports
            params: ->
                params
            accumulate: ->
                mergeReportRows = (row1, row2) ->
                    props = {}
                    Object.keys(REPORT_FIELDS).forEach (field, index) ->
                        if field is 'publication'
                            props[field] = row1[field]
                        else
                            props[field] = parseInt(row1[field],10) + parseInt(row2[field], 10)
                    props
                if reports.length
                    acc = [origReports.reduce((prev, cur) ->
                        cur.title = 'Publications Roundup Aggregated'
                        cur.date_from = moment(params.range_from, 'DD/MM/YYYY').format('YYYY-MM-DD')
                        cur.date_to = moment(params.range_to, 'DD/MM/YYYY').format('YYYY-MM-DD')                    
                        cur.rows = cur.rows.map (curRow) ->
                            matchPubPrev = prev.rows.filter (prevRow) -> prevRow.publication is curRow.publication
                            # We remove the current match from prev.rows
                            prev.rows = prev.rows.filter (prevRow) ->  prevRow.publication != curRow.publication                      
                            if matchPubPrev.length then return mergeReportRows(matchPubPrev[0], curRow) else return curRow
                        # Any rows left if prev.rows get added back into cur.rows as it means the current report
                        # did not have those pubs in it and those values are still the same.
                        cur.rows = cur.rows.concat(prev.rows)                     
                        cur 
                    , {rows: []})]
                    reports = mapReportFields(acc)
            range: ->
                reports = origReports
            isLoading: ->
                isLoading
        getInstance: ->
            new Reports                     
'use strict'

angular.module 'nn.form-submit-list.directives', []

.directive 'nnFormSubmitList', ->
    templateUrl: '/templates/form-submit-list.html'
    restrict: 'E'
    scope:
        submissions: '='
        filter: '='
    controller: ($scope, $state, StorageService, PageBuilderModuleTypes, FormModel) ->
        #
        # Check that the state is active...
        #
        $scope.isActive = (form) ->
            $state.includes "**.form.**", form:form.id.toString()
        #
        # Selection. The loading is controlled by the state router...
        #
        $scope.select = (data) ->
            if data.id
                $state.go 'app.forms.submissions.search.form', form: data.id
            else
                console.log "Failed to locate submission" + data.id
        #
        # get the status class
        #
        $scope.getClass = (status) ->
            switch status
                when 'd' then 'secondary'
                when 'r' then 'alert'
                when 'p' then 'success'

'use strict'

angular.module 'adonis.issue.collections.pagelayouts.elements', [
  'adonis.issue.collections.pagelayouts.elements.furniture',
  'adonis.issue.collections.pagelayouts.elements.stories',
  'adonis.issue.collections.pagelayouts.elements.barcode'
]

.factory 'pageLayoutElements', ($q, BasePresenter, pageLayoutFurniture, pageLayoutStories) ->
  class pageLayoutElements extends BasePresenter
    constructor: ($queryStream, storyHoles, furnitureHoles, spread) ->
      super $queryStream

      @sync storyHoles, furnitureHoles, spread

    sync: (storyHoles, furnitureHoles, spread) ->

      cleanFurniture = =>
        @furniture = {}
        @$furnitureType = null
        @$type = "page"

        for id, furn of furnitureHoles
          if @furniture[id]
            @furniture[id].sync furn, id
          else @furniture[id] = new pageLayoutFurniture @$queryStream, furn, id

          @furniture[id].spread = false

          furnitureType = (furn.placement?.v?.generatable?.v?.left or furn.placement?.v?.generatable)?.v?.template?.id

          if furnitureType
            @$furnitureType = furnitureType.substring 0, furnitureType.lastIndexOf('-')

      cleanStories = =>
        @story = {}

        for id, story of storyHoles
          if @story[id]
            @story[id].sync story, id
          else @story[id] = new pageLayoutStories @$queryStream, story, id

          @story[id].spread = false

      $q.all [cleanFurniture(), cleanStories()]

'use strict'
DateRangePicker = require('react-date-range').DateRangePicker
require('react-date-range/dist/styles.css')
require('react-date-range/dist/theme/default.css')

angular.module 'nn.story-search.directives', []

    .directive 'nnStorySearch', ->
        templateUrl: '/templates/story-search.html'
        restrict: 'E'
        controller: ($scope, $attrs, Auth, Stories, StoryStatus, StoryUsers, StoryTime, StoryTags, User) ->
            # stories
            stories = Stories.getInstance()
            if $attrs.userStories?
                Auth.user().then (user) ->
                    stories.params().user = value:user.id
                    $scope.stories = stories.search()
            else
                $scope.stories = stories.search()
            # filters
            $scope.users = StoryUsers.refresh(stories.params()?.organization)
            $scope.times = StoryTime.refresh()
            StoryTags.refresh(true).then (data) ->
                $scope.tags = ["All Tags"]
                $scope.tags = $scope.tags.concat data
                stories.params().tag = $scope.tags[0]
            # $scope.statuses = StoryStatus.refresh()
            User.getOrganizations().then (data) ->
                $scope.organizations = [{name:'All Orgs', id:""}]
                $scope.organizations = $scope.organizations.concat data
                stories.params().organization = $scope.organizations[0]

            # set maximum number of tags displayed in UI
            $scope.tagsLimit = 500
            $scope.handleOrgChange = (org) ->
                stories.params().user = ''
                StoryUsers.refresh(org?.id)
                stories.search()

            # Date Range picker
            $scope.rangePickerModel = {
                dateRangeType: 'publish_from'
                dateRange: null
            }
            $scope.applyFilters = false
            $scope.pickerOpen = false
            dateRangeOnChange = (item) ->
                $scope.rangePickerProps.selectionRange = [item.selection]
                $scope.rangePickerModel.dateRange = item.selection
            defaultEndDate = new Date()
            defaultStartDate = new Date(new Date().setDate(defaultEndDate.getDate() - 30))
            $scope.rangePickerProps = {
                selectionRange: [{
                    startDate: defaultStartDate,
                    endDate: defaultEndDate,
                    key: 'selection',
                }],
                months: 2,
                onChange: dateRangeOnChange,
                direction: 'horizontal',
            }
            $scope.togglePicker = ->
                $scope.pickerOpen = !$scope.pickerOpen
            $scope.applyRangeFilters = ->
                $scope.applyFilters = true
                type = $scope.rangePickerModel.dateRangeType
                range = $scope.rangePickerModel.dateRange
                if range
                    if range.startDate == range.endDate
                        range = range.startDate.toISOString().substring(0, 10)
                    else
                        range = "#{range.startDate.toISOString().substring(0, 10)},#{range.endDate.toISOString().substring(0, 10)}"
                stories.params()[type] = range if range
                stories.search()
                $scope.pickerOpen = false
            $scope.cancelRangeFilters = ->
                $scope.applyFilters = false
                stories.params().publish_from = ''
                stories.params().created_on = ''
                stories.search()
                $scope.pickerOpen = false

    .directive 'nnDateRangePicker', (reactDirective) ->
        reactDirective(DateRangePicker, ['ranges', ['onChange', {watchDepth: 'reference'}], 'months', 'direction'])

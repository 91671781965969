'use strict'

angular.module 'adonis.issue.collections.spreads.spread', [
  'adonis.issue.collections.spreads.spread.details',
  'adonis.issue.collections.spreads.spread.tops',
  'adonis.issue.collections.spreads.spread.info',
  'adonis.issue.collections.spreads.spread.output'
]

.factory 'spreadEntity', ($interpolate, spreadsDetails, OrphanedTaskService, PageExplorer, AdonisData, BasePresenter, NotificationService, spreadsInfo, spreadsTops, spreadsOutput, spreadsBarcode) ->
  class spreadEntity extends BasePresenter
    constructor: ($queryStream, page, spreadId, adverts, edspace, advertsPreview, furniturePreview, layoutId, barcode, layout, side) ->
      super $queryStream

      @page = page.folioNumber
      @pages = page.folioNumbers

      @spreadId = spreadId

      @sync page, adverts, edspace, layout

      @details = new spreadsDetails @$queryStream, side.toLowerCase(), (layoutId or spreadId or '').split '-'
      @info = new spreadsInfo @$queryStream, page

      @output = [
        new spreadsOutput @$queryStream, spreadId + '/left', advertsPreview[0], furniturePreview[0]
        new spreadsOutput @$queryStream, spreadId + '/right', advertsPreview[1], furniturePreview[1]
      ]

      if side.toLowerCase() is 'spread'
        @type = 'spread'

        if !page.topsData
          alert 'Business Logic Error - This is more likely caused by a spread template assigned to the cover or the back page.'
          # debugger;

        @topsData =
          leftPage: page.topsData?.leftPage
          rightPage: page.topsData?.rightPage

        page.topsData = @topsData

        @tops = [
          new spreadsTops @$queryStream, @topsData?.leftPage
          new spreadsTops @$queryStream, @topsData?.rightPage
        ]

      else
        @type = 'page'
        @tops = [
          new spreadsTops @$queryStream, page
        ]

      @barcode = new spreadsBarcode @$queryStream, spreadId, barcode

      @$queryStream.listen (query) =>
        if (query.command).includes '{{page}}'
          if query.command in ["clear{{page}}Template", "assign{{page}}Template"]
            query.data.unshift @spreadId
            query.command = $interpolate(query.command)(page: side + 'Page')
          else
            query.data.unshift @spreadId
            query.command = $interpolate(query.command)(page: side + 'Page')
        else if query.command in ["clearSpreadTemplate", "assignSpreadTemplate"]
          query.data.unshift @spreadId
        else if query.command in ["clearBarcode", "restoreDefaultBarcode", "setBarcode"]
          # query.data.unshift @spreadId
        else
          query.data.unshift @details.spreadLayout()
        query

    sync: (obj, adverts, edspace, layout) ->
      @edspace = edspace
      @adverts = adverts
      @layout = layout

      @margins =
        top: obj.topMargin.value
        bottom: obj.bottomMargin.value
        inside: obj.insideMargin.value
        outside: obj.outsideMargin.value

      @height = obj.imageHeight.value
      @width = obj.imageWidth.value

    setTemplate: (value, pages) ->
      spreadOrPage = if value.spread then 'Spread' else '{{page}}'

      if @layout.template
        NotificationService.confirm('When changing a template, you would lose existing data.', 'Would you like to proceed?').then =>
          elements = [ @layout.elements ]
          AdonisData.buildTasks(elements).then (tasks) =>
            if tasks.count > 0
              OrphanedTaskService.storeTasks tasks
            @query "assign#{spreadOrPage}Template", value.ref.v.id
      else @query "assign#{spreadOrPage}Template", value.ref.v.id

      PageExplorer.showPages()

    clearTemplate: (isSpread, pages) ->
      spreadOrPage = if isSpread then 'Spread' else '{{page}}'

      NotificationService.confirm('When removing a template, you would lose existing data.', 'Would you like to proceed?').then =>
        @query "clear#{spreadOrPage}Template"

    update: ([page, spreadId, adverts, edspace, advertsPreview, furniturePreview, layoutId, barcode, layout, side]) ->
      @sync page, adverts, edspace, layout

      @details.sync side.toLowerCase(), (layoutId or spreadId or '').split '-'

      @info.sync page

      if @tops.length == 1
        @tops[0].sync page
      else
        @tops[0].sync page.topsData.leftPage
        @tops[1].sync page.topsData.rightPage

      @output[0]?.sync @spreadId + '/left', advertsPreview[0], furniturePreview[0]
      @output[1]?.sync @spreadId + '/right', advertsPreview[1], furniturePreview[1]

      @barcode.sync @spreadId, barcode

      return null

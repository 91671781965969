'use strict'

angular.module 'nn.publication-lookup.directives', []

.directive 'nnPublicationLookup', (PublicationService, NotificationService) ->
  restrict: 'E',
  templateUrl: '/templates/publication-lookup.html'
  controller: ($scope, $state) ->

    $scope.lookup = (code) ->
      pubcode = code.toUpperCase()
      PublicationService.retrievePublication(pubcode).then (publication) ->
        if publication.name
          $state.go 'app.publications.publication', { pubcode: pubcode }
        else
          NotificationService.confirm("A publication wasn't found with this code, would you like to create it?").then ->
            $state.go 'app.publications.new', { code: pubcode }


'use strict'

angular.module 'nn.site-builder.routes', []
    .config ($stateProvider) ->
        $stateProvider
            .state 'app.site',
                abstract: true
                url: ''
                resolve:
                    acl: (Roles, $stateParams, $q) ->
                        checkRole = ->
                            if !Roles.canGo('site') then $q.reject 'unauthorized'
                        if Roles.hasRoles() then checkRole() else Roles.attachRoles().then -> checkRole()
                onEnter: (AppState, Explorer, Sections) ->
                    AppState.section = Sections[3]
                    AppState.canvas = 'site'
            .state 'app.site.url',
                url: 'site/:site'
                resolve:
                  acl: (User, Roles, $state, $stateParams, $q) ->
                    deferred = $q.defer()
                    User.getSites(view="newsnow").then (result) ->
                        if (result.map( (v) -> v.domain).indexOf($stateParams.site) == -1)
                          $state.go("app.dashboard")
                          deferred.reject("unauthorized")
                        else
                          deferred.resolve()
                      .catch (e) ->
                        $state.go("app.dashboard")
                        deferred.reject("unauthorized")

                    deferred.promise

                  onEnter: ($stateParams, AppState) ->
                    AppState.site = $stateParams.site

            .state 'app.site.dyn',
                url: 'dyn/:site'
                resolve:
                  acl: (User, Roles, $state, $stateParams, $q) ->
                    deferred = $q.defer()
                    User.getDynSites(User._dyn).then (result) ->
                        if (result.map( (v) -> v.domain).indexOf($stateParams.site) == -1)
                          $state.go("app.dashboard")
                          deferred.reject("unauthorized")
                        else
                          deferred.resolve()
                      .catch (e) ->
                        $state.go("app.dashboard")
                        deferred.reject("unauthorized")

                    deferred.promise

                  onEnter: ($stateParams, AppState) ->
                    AppState.site = $stateParams.site

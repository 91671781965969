'use strict'

angular.module 'nn.file-manager.services', []

    .filter 'getPreviewURL', (FileUtils, HOSTS) ->
        (uri, width=150, height=150, fitMode='fmax') ->
            extension = FileUtils.getExtension uri
            switch extension
                when 'jpg','jpeg','pdf','png'
                    FileUtils.getTransformURL uri, width, height, fitMode
                else
                    "#{HOSTS.cdn}#{uri}"

    .factory 'FileUtils', (HOSTS) ->
        return {
            getExtension: (uri) ->
                uri.split('.').pop().toLowerCase()
            getType: (uri) ->
                extension = @getExtension uri
                switch extension
                    when 'jpg','jpeg','gif'
                        'image'
                    when 'pdf'
                        'pdf'
            getURL: (uri) ->
                "#{HOSTS.cdn}#{uri}"
            getTransformURL: (uri, width=150, height=150, fitMode='fmax') ->
                "#{HOSTS.transform}/transform/v1/resize/frm#{uri}/w#{width}_h#{height}_#{fitMode}.jpg"
        }

    .factory 'Files', ($http, $q, Auth, HOSTS) ->
        API_URL = "#{HOSTS.valencia}/api/v1/object/"
        SEARCH_API_URL = "#{HOSTS.valencia}/api/v1/search/"
        ITEMS_PER_PAGE = 10
        getRefreshURL = (params) ->
            if params.query?.trim() is ''
                url = "#{API_URL}?"
            else
                url = "#{SEARCH_API_URL}?"
                url += "q=#{params.query}"
            url += "&limit=#{params.limit}"
            url += "&offset=#{params.offset}"
            url += "&created_by=#{params.user.value}" if params.user?.value
            url += "&time=#{params.time.value}" if params.time?.value
            url += "&organization=#{params.organization.id}" if params.organization?.id
            url += "&bucket__name=#{params.bucketName}" if params.bucketName?
            if params.type?.value
                types = params.type?.value.split('/')
                type = types[0]
                subtype = types[1]
                url += "&media_type=#{type}" if type?
                url += "&media_subtype=#{subtype}" if subtype?
            url
        Files = ->
            loading = false
            files = []
            meta = null
            params =
                limit: ITEMS_PER_PAGE
                offset: 0
                query: ''
                organization: ''
            search: (nextPage=false) ->
                if nextPage
                    params.offset += ITEMS_PER_PAGE
                else
                    files = []
                    params.offset = 0
                Auth.token().then (token) ->
                    loading = true
                    $http
                        method: 'GET'
                        url: getRefreshURL params
                        headers: 'Authorization':"Bearer #{token}"
                        cache: false
                        auth: true
                    .success (data) ->
                        files = files.concat data.objects
                        meta = data.meta
                    .then ->
                        loading = false
                @
            one: (id) ->
                deferred = $q.defer()
                Auth.token().then (token) ->
                    loading = true
                    $http
                        method: 'GET',
                        url: "#{API_URL}#{id}/"
                        headers: 'Authorization':"Bearer #{token}"
                        cache: false
                        auth: true
                    .success (data) ->
                        deferred.resolve data
                    .then ->
                        loading = false
                deferred.promise
            all: ->
                files
            more: ->
                @search true
            params: ->
                params
            meta: ->
                meta
            isLoading: ->
                loading
        getInstance: ->
            new Files

    .factory 'FileType', ->
        filetypes = null
        return {
            all: ->
                filetypes
            refresh: ->
                filetypes = null
                filetypes = [
                        { name:'all file types', value:null }
                        { name:'image', value:'image' }
                        { name:'pdf', value:'application/pdf' }
                    ]
                @
        }

    .factory 'FileUser', (Auth) ->
        users = null
        return {
            all: ->
                users
            refresh: ->
                Auth.user().then (user) =>
                    users = null
                    users = [
                            { name:'all files', value:null }
                            { name:'my files', value:user.id }
                        ]
                @
        }

    .factory 'FileTime', ->
        times = null
        return {
            all: ->
                times
            refresh: ->
                times = null
                times = [
                        { name:'any time', value:null }
                        { name:'past 24 hours', value:'-1d' }
                        { name:'past week', value:'-1w' }
                        { name:'past month', value:'-1m' }
                        { name:'past year', value:'-1y' }
                    ]
                @
        }


    .factory 'Bucket', ($http, $q, HOSTS, Auth) ->
        BUCKET_API_URL = "#{HOSTS.valencia}/api/v1/bucket/"
        return {
            get: (deferred=null, customBucket=null) ->
                if !deferred?
                    deferred = $q.defer()
                Auth.user().then (user) =>
                    bucketName = customBucket or user.username
                    $http
                        method: 'GET'
                        url: "#{BUCKET_API_URL}#{bucketName}/"
                        cache: false
                        auth: true
                    .success (data) ->
                        deferred.resolve data
                    .error =>
                        @create(customBucket).then =>
                            @get deferred
                deferred.promise
            create: (customBucket=null) ->
                deferred = $q.defer()
                Auth.user().then (user) ->
                    if !bucketName?
                      bucketName = customBucket or user.username
                    $http
                        method: 'POST'
                        url: BUCKET_API_URL
                        data: {name:bucketName}
                        cache: false
                        auth: true
                    .success ->
                        deferred.resolve()
                deferred.promise
        }

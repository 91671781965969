angular.module 'nn.dragndropsvg.mouse', []

.factory 'mouseCapture', ($rootScope) ->
  $element = undefined
  mouseCaptureConfig = undefined

  mouseMove = (evt) ->
    if mouseCaptureConfig and mouseCaptureConfig.mouseMove
      mouseCaptureConfig.mouseMove evt
      $rootScope.$digest()
    return

  mouseUp = (evt) ->
    if mouseCaptureConfig and mouseCaptureConfig.mouseUp
      mouseCaptureConfig.mouseUp evt
      $rootScope.$digest()
    return

  registerElement: (element) ->
    $element = element
    return

  acquire: (evt, config) ->
    @release()

    mouseCaptureConfig = config

    $element.bind 'mousemove', mouseMove
    $element.bind 'mouseup', mouseUp
    return

  release: ->
    if mouseCaptureConfig
      if mouseCaptureConfig.released
        mouseCaptureConfig.released()
      mouseCaptureConfig = null

    $element.unbind 'mousemove', mouseMove
    $element.unbind 'mouseup', mouseUp

    return

.directive 'mouseCapture', ->
  restrict: 'A'
  controller: ($scope, $element, $attrs, mouseCapture) ->
    mouseCapture.registerElement $element
    return

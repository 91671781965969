'use strict'

angular.module 'nn.task.search.directives', []


    .factory 'TASK_ORGANIZATION_DEFAULTS', (TASK_ORGANIZATION) ->
        [
            { name:'All orgs', id:TASK_ORGANIZATION.ALL }
        ]

    .factory 'TASK_ASSIGNEE_DEFAULTS', (TASK_ASSIGNEE) ->
        [
            { name:'Mine', id:TASK_ASSIGNEE.USER }
            { name:'Everyone', id:TASK_ASSIGNEE.ALL }
        ]

    .factory 'TASK_STATUS_DEFAULTS', (TASK_STATUS) ->
        [
            { name:'Any status', id:TASK_STATUS.ALL }
            { name:'Open', id:TASK_STATUS.OPEN }
            { name:'Archived', id:TASK_STATUS.ARCHIVED }
        ]


    .directive 'nnTaskSearch', ->
        templateUrl: '/templates/task-search.html'
        restrict: 'E'
        scope: {
            'heading': '@'
        }
        controller: ($scope, $attrs, $q, TaskServiceProvider, TASK_ASSIGNEE_DEFAULTS, TASK_STATUS_DEFAULTS, TASK_ORGANIZATION_DEFAULTS, TASK_ASSIGNEE, TASK_STATUS, TASK_ORGANIZATION, Auth, User) ->

            tasks = TaskServiceProvider.getInstance()

            if $attrs.flagged?
                tasks.flagged = true

            if $attrs.limit?
                tasks.page_size = $attrs.limit

            if $attrs.toolbarMini?
                $scope.toolbarMini = true

            if $attrs.statusArchived?
                tasks.status = TASK_STATUS.ARCHIVED
            else if $attrs.statusOpen?
                tasks.status = TASK_STATUS.OPEN

            if $attrs.assigneeUser?
                tasks.assignee = TASK_ASSIGNEE.USER

            $scope.tasks = tasks
            $scope.toolbarEnabled = false
            $scope.toolbarCheckboxID = Math.random().toString(36).substring(5)

            $scope.refreshOrgs = ->
                deferred = $q.defer()
                $scope.organizations = TASK_ORGANIZATION_DEFAULTS
                User.getOrganizations().then (organizations) ->
                    $scope.organizations = $scope.organizations.concat organizations
                    # tasks.organization = organizations[0]?.id
                    deferred.resolve organizations
                deferred.promise

            $scope.refreshUsers = ->
                deferred = $q.defer()
                $scope.users = TASK_ASSIGNEE_DEFAULTS
                if tasks.organization? and tasks.organization isnt TASK_ORGANIZATION.ALL
                    User.getMembers(tasks.organization).then (users) ->
                        $scope.users = $scope.users.concat users
                        deferred.resolve users
                else
                    deferred.resolve()
                deferred.promise

            # $scope.refreshStatii = ->
            #     deferred = $q.defer()
            #     $scope.statii = TASK_STATUS_DEFAULTS
            #     deferred.resolve()
            #     deferred.promise

            $scope.refreshOrgs().then ->
                $scope.refreshUsers().then ->
                    tasks.refresh()


'use strict'

angular.module 'nn.user-voice', []

    .constant 'USER_VOICE_SCRIPT',
        '''
        UserVoice=window.UserVoice||[];(function(){var uv=document.createElement('script');uv.type='text/javascript';uv.async=true;uv.src='//widget.uservoice.com/jmNtQUQ8ztXosUNFYH4vA.js';var s=document.getElementsByTagName('script')[0];s.parentNode.insertBefore(uv,s)})();
        '''

    .directive 'nnUserVoice', (Auth, USER_VOICE_SCRIPT) ->
        restrict: 'A'
        scope: {}
        link: (scope, element) ->
            element.html USER_VOICE_SCRIPT
            Auth.user().then (user) ->
                userConfig =
                    id: user.id
                    email: user.email
                    name: "#{user.first_name} #{user.last_name}"
                    created_at: user.last_login
                colourConfig =
                    accent_color: '#448dd6'
                    trigger_color: 'white'
                    trigger_background_color: '#448dd6'
                return if !UserVoice?
                UserVoice.push ['set', colourConfig]
                UserVoice.push ['identify', userConfig]
                UserVoice.push ['addTrigger', {mode:'contact', trigger_position:'bottom-right'}]
                UserVoice.push ['autoprompt', {}]
'use strict'

angular.module 'nn.publication-explorer.directives', []

.directive 'nnPublicationExplorer', ->
  templateUrl: '/templates/publication-explorer.html'
  restrict: 'E'
  scope: {}
  controller: ($scope, $state, Explorer, AppState, Publications, PublicationSections, PublicationExplorer) ->

      $scope.sections = PublicationSections
      $scope.state = PublicationExplorer

      $scope.selectSection = (section) ->
          PublicationExplorer.menuItems = true
          PublicationExplorer.menuItemSelected = PublicationSections.indexOf(section)

      $scope.back = ->
          PublicationExplorer.menuItems = false
          PublicationExplorer.menuItemSelected = null

      $scope.openAdonisAdmin = ->
        PublicationExplorer.menuItems = false
        PublicationExplorer.menuItemSelected = null
        $state.go 'app.adonis.editorialLayouts'

      $scope.createPublication = ->
          $state.go 'app.publications.new'

      $scope.openFeaturesAdmin = ->
          $state.go 'app.publications.features'



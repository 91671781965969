'use strict'

angular.module 'nn.site-select.directives', []
    .directive 'nnSiteSelect', ->
        templateUrl: '/templates/site-select.html'
        restrict: 'E'
        scope:
            selected: '='
            options: '='
            placeholder: '@'
            cookie: "@"
        controller: ($scope, $attrs, $cookieStore, User, FeatureAccess, SITE_SELECT_MSG) ->
            $scope.cookie = 'savedOrgId'
            # restore previously selected sites
            restoreFromCookie = ->
                savedSiteId = $cookieStore.get $scope.cookie
                savedSite = site for site in $scope.sites when site.id is savedSiteId
                if savedSite?
                    $scope.selected = savedSite
                else if $attrs.autoSelect? and $scope.sites?.length
                    $scope.selected = $scope.sites[0]
            # save selected organization
            saveToCookie = ->
                $cookieStore.put $scope.cookie, $scope.selected.id
            clearCookie = ->
                $cookieStore.put $scope.cookie, ''
            # load sites
            loadSites = ->
                $scope.placeholderMsg = SITE_SELECT_MSG.LOADING
                if $attrs.options?
                    # show all sites
                    sites = $scope.options
                    $scope.placeholderMsg = SITE_SELECT_MSG.CHOOSE
                    restoreFromCookie()
                else
                    # retrieve the user's sites
                    User.getSites().then (sites) ->
                        $scope.sites = sites
                        $scope.placeholderMsg = $scope.placeholder or SITE_SELECT_MSG.CHOOSE
                        restoreFromCookie()
                    , (errorMsg) ->
                        $scope.placeholderMsg = errorMsg
            # clear selected organization
            $scope.clear = ($event) ->
                $event.stopPropagation()
                $scope.selected = undefined
                $scope.sites = undefined
                clearCookie()
                loadSites()
            # init
            $scope.changeSite = ->
                saveToCookie()
            loadSites()


    .constant 'SITE_SELECT_MSG',
        LOADING: 'Loading sites...'
        CHOOSE: 'Choose a site'

'use strict'

angular.module('nn.story-assistance.services', [])
  .factory 'StoryAssistanceService', ($http, $q, Auth, HOSTS) ->
    commands =
      promptAI: (content, username, storyId, orgId) ->
        deferred = $q.defer()
        Auth.token().then (token) ->
          data = {
            "content": content,
            "user": username
            "storyId": parseInt(storyId)
            "orgId": orgId
            "masthead": commands.getMastHeadName(orgId)
          }
          $http
            method: 'POST',
            url: "#{HOSTS.sochi}/proxy/seo-title-desc/"
            cache: false
            headers: {
              "Content-type": "application/json"
              'Authorization': "Bearer #{token}"
            }
            auth: true
            data: JSON.stringify(data)
          .success (response) ->
            try
              content = response.choices[0].message.content
              deferred.resolve content
            catch err
              deferred.reject {message: err.message , retry: false}
          .error (data, status, headers, config) ->
            if (status == 401)
              deferred.reject {message: "Unauthorized", retry: false}
            else if (status == 404)
              deferred.reject {message: "HTTP 404 - Service is disabled", retry: false}
            else if (status >= 500)
              deferred.reject {message: "HTTP #{status} - AI Service is down", retry: true}
            else if data
              message = data
              if status
                message = data + " HTTP " + status
              deferred.reject {message: message, retry: false}
            else
              if status
                deferred.reject {message: "Error in request: HTTP " + status, retry: false}
              deferred.reject {message: "Error in request: HTTP", retry: false}

        deferred.promise
      getMastHeadName: (orgId) ->
        organizations = JSON.parse(localStorage.getItem("organizations"))
        masthead = organizations
          .filter((org) => org.organization.id == orgId)
          .map((o) => o.organization.name)[0]
        return masthead
      convertToParagraph: (text) ->
        if text != "<br>"
          return "<p>" + text + "</p>"
        return ""
      convertToHeading: (text, level) ->
        return "<h#{level}>#{text}</h#{level}>"
      convertToDiv: (text) ->
        return "<div>#{text}</div>"
      convertToList: (listType, items) ->
        listTypes = {
          "ordered": "ol",
          "unordered": "ul"
        }
        list = listTypes[listType]
        text = ""
        items.forEach (item) ->
          text = text + "<li>#{item}</li>"
        return "<#{list}>#{text}</#{list}>"
      getArticleText: (elements) ->
        # Need to fetch all the story elements
        text = ""
        elements.forEach (element) ->
          if element.type == "paragraph"
            text = text + commands.convertToParagraph(element.text)
          else if element.type == "heading"
            text = text + commands.convertToHeading(element.text, element.level)
          else if (element.type == "topic" or element.type == "subhead")
            text = text + commands.convertToDiv(element.text)
          else if element.type == "list"
            text = text + commands.convertToList(element.list_type, element.items)

        if text.length
          return "<article>" + text + "</article>"
        return ""
      getCharsCount: (text) ->
        if (text)
          return text.length
        return 0
      setPreviewText: (text, maxLength) ->
        if (text.length > maxLength)
          return text.substring(0, maxLength) + "..."
        return text
      trim: (text) ->
        text = text.trim()
        if text.startsWith('"')
          text = text.substr(0, text.length - 1)
        if text.endsWith('"')
          text = text.substr(0, text.length - 1)
        return text
      jsonify: (text) ->
        try
          text = text.replace("```json", "").replaceAll("```", "")
          jsonResponse = JSON.parse(text)
          return jsonResponse
        catch
          return {hasError: true}
      getSeoTitles: (contents) ->
        titles = []
        contents.forEach (content, index) ->
          title = content.seoMetaTitle
          if index < 3
            titles.push({
              id: 'title-' + index,
              count: commands.getCharsCount(title),
              text: title
            })
        return titles
      getSeoDescriptions: (contents) ->
        descriptions = []
        contents.forEach (content, index) ->
          description = content.seoMetaDescription
          if index < 3
            descriptions.push({
              id: 'description-' + index,
              count: commands.getCharsCount(description),
              text: description
            })
        return descriptions
      beautify: (text) ->
        text.trim().replaceAll("\n", "<br><br>")
    commands

  .constant 'StoryAssistanceMessageType',
    DISPLAY_STORY_ASSISTANCE: 'sa-DisplayStoryAssistance'
    DISPLAYING_STORY_ASSISTANCE: 'sa-DisplayingStoryAssistance'
    GET_LATEST_BODY: 'sa-GetLatestBody'
    REVERT_STATUS: 'sa-RevertStatus'
    SAVE_STORY: 'sa-SaveStory'
    SET_SEO: 'sa-SetSeo'
    SHOW_NEW_SEO_TITLE: 'sa-ShowNewSeoTitle'
    SHOW_OLD_SEO_TITLE: 'sa-ShowOldSeoTitle'
    UNSET_SEO_DESCRIPTION: 'sa-UnsetSeoDescription'
    UNSET_SEO_TITLE: 'sa-UnsetSeoTitle'
    UPDATE_SEO_DESCRIPTION: 'sa-UpdateSeoDescription'
    UPDATE_SEO_TITLE: 'sa-UpdateSeoTitle'
    UPDATE_DIGITAL_PACKAGE_ACCESS: 'sa-UpdateDigitalPackageAccess'
    UPDATE_DIGITAL_QUALITY_CHECKLIST: 'sa-UpdateDigitalQualityChecklist'

  .constant 'StoryAssistanceRequestType',
    ACTIVATE_STORY_ASSISTANCE: 'sa-ActivateStoryAssistance'
    DISPLAYING_SETTINGS_PANEL: 'sa-DisplayingSettingsPanel'
    RECEIVED_LATEST_BODY: 'sa-ReceivedLatestBody'
    RESET_STORY_ASSISTANCE: 'sa-ResetStoryAssistance'
    SOURCE_UPDATED: 'sa-SourceUpdated'
    STORY_LOADED: 'sa-StoryLoaded'
    STORY_ON_SAVE_IS_INVALID: 'sa-StoryOnSaveIsInvalid'
    UPDATE_PHASE: 'sa-UpdatePhase'
    LATEST_DIGITAL_QUALITY_CHECKLIST_ELEMENTS: 'sa-LatestDigitalQualityChecklistElements'
    LATEST_READABILITY_SCORE: 'sa-LatestReadabilityScore'
'use strict'

angular.module 'nn.publication-manager.routes', []

  .config ($stateProvider) ->

      $stateProvider

          .state 'app.publications',
              abstract: true
              url: ''
              resolve:
                  acl: (Roles, $q) ->
                      checkRole = ->
                          if !Roles.canGo('admin') then $q.reject 'unauthorized'
                      if Roles.hasRoles() then checkRole() else Roles.attachRoles().then -> checkRole()
              onEnter: (AppState, Explorer, Sections) ->
                  AppState.section = Sections[9]

          .state 'app.publications.new',
              url: 'publication/new'
              resolve:
                  acl: (Roles, $q) ->
                      checkRole = ->
                          if !Roles.canGo('admin') or !Roles.can('administerPublication') then $q.reject 'unauthorized'
                      if Roles.hasRoles() then checkRole() else Roles.attachRoles().then -> checkRole()
              params:
                    code: null
              onEnter: ($stateParams, AppState, Explorer, PublicationService) ->
                  Explorer.data.active = true
                  Explorer.data.collapsed = true
                  PublicationService.setPublication(null, {})
                  AppState.canvas = 'admin'
                  AppState.publication = null

          .state 'app.publications.publication',
              url: 'publication/{pubcode}'
              onEnter: ($stateParams, AppState, Explorer) ->
                  Explorer.data.active = true
                  Explorer.data.collapsed = true
                  AppState.canvas = 'admin'
                  AppState.publication = $stateParams.pubcode

          .state 'app.publications.clone',
              url: 'publication/new/{pubcode}'
              resolve:
                  acl: (Roles, $q) ->
                      checkRole = ->
                          if !Roles.canGo('admin') or !Roles.can('administerPublication') then $q.reject 'unauthorized'
                      if Roles.hasRoles() then checkRole() else Roles.attachRoles().then -> checkRole()
              onEnter: ($stateParams, AppState, Explorer) ->
                  Explorer.data.active = true
                  Explorer.data.collapsed = true
                  AppState.canvas = 'admin'
                  AppState.publication = null

          .state 'app.publications.features',
              url: 'admin/features'
              onEnter: ($stateParams, AppState, Explorer) ->
                  Explorer.data.active = true
                  Explorer.data.collapsed = true
                  AppState.canvas = 'adminFeatures'

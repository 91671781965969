'use strict'

angular.module 'nn.report-events.directives', []

    .directive 'nnReportEvents', ->
        templateUrl: '/templates/report-events.html'
        restrict: 'E'
        scope: {}
        controller: ($scope, EventLog, Stories) -> 
            events = EventLog.getInstance()
            $scope.events = events
            
            stories = Stories.getInstance()
            $scope.stories = stories

            $scope.selected = {}
            $scope.handleStoryChange = (story) -> 
                events.params().story_id = story.id
                events.get()

            $scope.search = (query) ->
                if query
                    stories.params().query = query
                    stories.search()
            
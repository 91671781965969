'use strict'

angular.module 'nn.form-img-upload.directives', []

.directive 'nnFormImgUpload', ->
    templateUrl: '/templates/form-img-upload.html'
    restrict: 'E'
    scope: {
        'field': '='
        'editMode': '='
    }
    controller: ($scope, Uploader, $q, $timeout, FileUtils) ->
        $scope.files = Uploader.getInstance()
        #
        # Here we set the pre-submit function for the
        # image item. This will allow the image to be
        # submitted to valencia before the form is
        # submitted...
        #
        $scope.field.preSubmit = () ->
            # We must test that this data is new or old. A
            # fresh file drop will have nothing in the field.data.
            # In addition, if the user wants to update the image, they
            # can delete the current data with clearData(), and this
            # will allow a re-upload...
            if !$scope.field.data
                #
                # Now we simply upload the file and add the Valencia
                # response to the field data.
                #
                $scope.files.upload().then (results) ->
                    $scope.field.data = results
                    return
        #
        # Here we simply clear the data completely
        # to drop back to the file loader...
        #
        $scope.clearData = () ->
            $scope.field.data = null
        #
        # Drop handler...
        #
        $scope.dropHandler = (data, event) ->
            #
            # When the file is dropped from an existing valencia object
            # you can simply assign the data. This is because the data is
            # the same as what is saved to the server with a regular upload.
            #
            obj = data['json/file-object']
            if obj
                $scope.field.data = [obj]
            else
                $scope.field.data = null


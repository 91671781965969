'use strict'

angular.module 'nn.story-assistance.directives', []

  .directive 'nnStoryAssistance', ->
    templateUrl: '/templates/story-seo-title-description.html'
    restrict: 'E'
    scope:
      isMobile: '='
      storypad: '='
      story: '='
    controller: (
      $scope,
      $rootScope,
      $timeout,
      $interval,
      AppState,
      FeatureAccess,
      FeatureAccessTags,
      DigitalQualityChecklistService,
      PyodideService,
      StoryAssistanceService,
      StoryAssistanceMessageType,
      StoryAssistanceRequestType,
      STORY_STATUS,
      STORY_CHANNEL,
      StorypadMessageType,
      StoryUtils,
      nnSidenavService,
      NotificationService
    ) ->

      resetAssistanceComponent = (resetAttempt=true) ->
        if resetAttempt
          attempt =  0
        else
          attempt = $scope.seoAssistance.attempt
        $scope.seoAssistance = {
          seoTitles : []
          seoDescriptions : []
          isFetching : false
          timer: 10
          attempt: attempt
          hasError: false
          errorMessage: 'Issue on Request. Please report it (Link and screenshot)'
          retry: false
          helpUrl: "https://docs.google.com/document/d/1cPIY0on2u1hQs1Cyon949Qch4nig9-QFQVeBA_xUv8E"
        }
      resetDialogComponent = ->
        $scope.seoDialog = {
          seoTitle: ''
          seoDescription: ''
          seoTitleCharsCount : 0
          seoDescriptionCharsCount : 0
          isSeoTitleValid: false
          isSeoDescriptionValid: false
          seoTitleSelectedIndex: -1
          seoDescriptionSelectedIndex: -1
          action : "APPROVED"
          isButtonDisabled: true
        }

      resetAppState = ->
        AppState.storyPublishState = {
          status: ''
          publishTo: ''
          content: ''
          org_id: ''
        }

      resetStyle = ->
        ["#story-seo-title", "#story-seo-meta-description"].forEach((elementId) =>
          $(elementId).removeClass("valid")
        )

      resetStoryAssistanceState = ->
        $scope.storyAssistance = {
          phase: {
            isCreate: false
            isDraft: false
            isEdit: false
          }
          showAssistanceDialog: false
          isFetchingLatestBody: false
          stopTimeout: null
          stopInterval: null
          MIN_SEO_TITLE_CHAR_LENGTH: 55
          MAX_SEO_TITLE_CHAR_LENGTH: 65
          MIN_SEO_DESCRIPTION_CHAR_LENGTH: 100
          MAX_SEO_DESCRIPTION_CHAR_LENGTH: 160
        }

      # TODO: What about story that is directly approved? - It doesn't have metric
      resetDigitalQualityChecklistMetric = ->
        $scope.digitalQualityChecklistMetric = {
          readability_score: 0
          elements: []
        }

      resetState = (includeAppState = true) ->
        $rootScope.$broadcast(StoryAssistanceMessageType.DISPLAYING_STORY_ASSISTANCE, false)
        resetStoryAssistanceState()
        resetDialogComponent()
        resetAssistanceComponent()
        resetStyle()
        if includeAppState == true
          resetAppState()

      resetFeatureFlag = ->
        $scope.storyAssistanceFlag = {hasDigitalPackageAccess: false}

      resetState()
      resetFeatureFlag()
      resetDigitalQualityChecklistMetric()

      $scope.local = {storyModel: null}
      $scope.msideCtrl = nnSidenavService 'mside'

      unsetSeoTitle = ->
        $scope.storypad.sendMessage StorypadMessageType.UPDATE_SEO_TITLE, ''
        $scope.storypad.sendMessage StorypadMessageType.UNSET_SEO_TITLE

      unsetSeoDescription = ->
        $scope.storypad.sendMessage StorypadMessageType.UPDATE_SEO_DESCRIPTION, ''
        $scope.storypad.sendMessage StorypadMessageType.UNSET_SEO_DESCRIPTION

      $scope.$on StoryAssistanceRequestType.STORY_LOADED, (_, storyModel) ->
        if storyModel
          $scope.local.storyModel = storyModel
          FeatureAccess.hasFeatureAccess(FeatureAccessTags.DIGITAL_PACKAGE, org: storyModel.organization).then (result) ->
            $scope.storyAssistanceFlag.hasDigitalPackageAccess = !!result
            if isNewSeoPhase()
              if $scope.local.storyModel?.status == STORY_STATUS.DRAFT.value and !isWebPublished()
                resetDialogComponent()
                unsetSeoDescription()
                unsetSeoTitle()
                $scope.storypad.updateStoryModelField 'seo_title', ''
                $scope.storypad.updateStoryModelField 'seo_description', ''
                $scope.storypad.sendMessage StorypadMessageType.SHOW_NEW_SEO_TITLE, isWebPublished()
              else
                $scope.storypad.sendMessage StorypadMessageType.SHOW_NEW_SEO_TITLE, isWebPublished() or isSeoDescriptionSet()
                if !isSeoDescriptionSet()
                  if $scope.storypad
                    $scope.storypad.updateStoryModelField 'seo_title', ''
                  unsetSeoTitle()
              updatePhase()

      $scope.$on StoryAssistanceRequestType.SOURCE_UPDATED, (_, org_id) ->
        FeatureAccess.hasFeatureAccess(FeatureAccessTags.DIGITAL_PACKAGE, org: org_id).then (result) ->
          AppState.storyPublishState.org_id = org_id.toString()
          $scope.storyAssistanceFlag.hasDigitalPackageAccess = !!result
          $rootScope.$broadcast(StoryAssistanceMessageType.UPDATE_DIGITAL_PACKAGE_ACCESS, !!result)
          if isNewSeoPhase()
            $scope.storypad.sendMessage StorypadMessageType.SHOW_NEW_SEO_TITLE, isWebPublished() or isSeoDescriptionSet()
            if !isSeoDescriptionSet()
              if $scope.storypad
                $scope.storypad.updateStoryModelField 'seo_title', ''
              unsetSeoTitle()
            updatePhase()
          else
            unsetSeoDescription()
            $scope.storypad.sendMessage StorypadMessageType.SHOW_OLD_SEO_TITLE, ''

      $scope.$on StoryAssistanceRequestType.LATEST_DIGITAL_QUALITY_CHECKLIST_ELEMENTS, (_, elements) ->
        $scope.digitalQualityChecklistMetric.elements = elements

      $scope.$on StoryAssistanceRequestType.LATEST_READABILITY_SCORE, (_, score) ->
        if score?
          $scope.digitalQualityChecklistMetric.readability_score = score.toString()
        else
          console.error("Invalid score received")
          $scope.digitalQualityChecklistMetric.readability_score = '0' # set to 0 so it's not blank in UI

      sendMetricsAndSaveStory = (metrics) ->
        $scope.storypad.sendMessage StorypadMessageType.SET_DIGITAL_QUALITY_CHECKLIST, metrics
        $rootScope.$broadcast(StoryAssistanceMessageType.SAVE_STORY, AppState.storyPublishState)
        resetState()

      calculateMetricsAndSaveStory = ->
        metrics = {version: "1.0"}
        bodyText = null

        for element in $scope.digitalQualityChecklistMetric.elements
          field = element.type.toLowerCase().replaceAll("-", "_")
          [value, percent_value] = DigitalQualityChecklistService.getValue(
            element.currentCount,element.maxCount,element.type, false)
          metrics[field] = value
          if (percent_value)
            metrics[field + "_percent"] = percent_value
          if field == "body" and element.text?
            bodyText = element.text

        # Presence of bodyText indicates, updated story content
        if bodyText
          PyodideService.execute(bodyText).then (result) ->
            metrics.readability_score = result
            sendMetricsAndSaveStory(metrics)
        else
          metrics.readability_score = $scope.digitalQualityChecklistMetric.readability_score
          sendMetricsAndSaveStory(metrics)

      hasDigitalPackageAccess = ->
        return $scope.storyAssistanceFlag.hasDigitalPackageAccess

      updatePhase = ->
        $scope.storyAssistance.phase = {
          isCreate: hasDigitalPackageAccess() and !isWebPublished() and !isSeoDescriptionSet() and isPublishingToWeb() and (isStoryReadyForApproval() or isStoryGoingToBePublished()),
          isDraft: hasDigitalPackageAccess() and !isWebPublished() and (!$scope.local.storyModel? or $scope.local.storyModel.status == STORY_STATUS.DRAFT.value) and !(isStoryReadyForApproval() or isStoryGoingToBePublished()),
          isEdit: hasDigitalPackageAccess() and isSeoDescriptionSet(),
        }

      $scope.$on StoryAssistanceRequestType.DISPLAYING_SETTINGS_PANEL, ->
        if hasDigitalPackageAccess()
          $scope.storypad.sendMessage StorypadMessageType.SHOW_NEW_SEO_TITLE, isWebPublished() or isSeoDescriptionSet()
        else
          $scope.storypad.sendMessage StorypadMessageType.SHOW_OLD_SEO_TITLE, ''

      updateButtonDisabledState = ->
        $scope.seoDialog.isButtonDisabled = !(
          $scope.seoDialog.seoTitleCharsCount > 0 and
            $scope.seoDialog.seoDescriptionCharsCount > 0
        )

      isWebPublished = ->
        return $scope.local.storyModel?.snapshot_pointers?.web?.approved?

      isPublishingToWeb = ->
        return AppState.storyPublishState.publishTo == STORY_CHANNEL.WEB.value or
          AppState.storyPublishState.publishTo == STORY_CHANNEL.ALL.value

      isStoryGoingToBePublished = ->
        return AppState.storyPublishState.status == STORY_STATUS.APPROVED.value

      isStoryReadyForApproval = ->
        return AppState.storyPublishState.status == STORY_STATUS.WAITING.value

      isStoryWebOnly = ->
        return AppState.storyPublishState.publishTo == STORY_CHANNEL.WEB.value or
          (!AppState.storyPublishState.publishTo and StoryUtils.storyIsWebOnly($scope.local.storyModel))

      isSeoTitleDescriptionSet = ->
        return $scope.local.storyModel?.body?.title_seo?.length > 0 and $scope.local.storyModel?.body?.seo_description?.length > 0

      isSeoDescriptionSet = ->
        return $scope.local.storyModel?.body?.seo_description?.length > 0

      isNewSeoPhase = ->
        return hasDigitalPackageAccess() and
          (!isWebPublished() or(isWebPublished() and isSeoDescriptionSet()))

      getOrganizationId = ->
        if $scope.story?.id?
          return parseInt($scope.local.storyModel.organization)
        return parseInt(AppState.storyPublishState.org_id)

      storyAssistance = ->
        orgId = getOrganizationId()
        storyId = $scope.story.id
        if !storyId?
          # Story which hasn't been saved in database
          storyId = -1

        $scope.storyAssistance.stopInterval = $interval ->
          if $scope.seoAssistance.timer > 0
            $scope.seoAssistance.timer -= 1
        , 1000
        , 10
        onPromptSuccess = (response) ->
          # In case Cancel is clicked
          if !$scope.seoAssistance.isFetching
            return
          $scope.seoAssistance.isFetching = false
          $interval.cancel $scope.storyAssistance.stopInterval
          data = StoryAssistanceService.jsonify(response)
          if data.hasError
            resetAssistanceComponent(false)
            $scope.seoAssistance.hasError = true
            $scope.seoAssistance.retry = true
            NotificationService.error "Parsing Error"
            $scope.seoAssistance.errorMessage = "[Attempt ##{$scope.seoAssistance.attempt}] Couldn't generate SEO options. Could you please "
          else
            $scope.seoAssistance.seoTitles = StoryAssistanceService.getSeoTitles(data.seo)
            $scope.seoAssistance.seoDescriptions = StoryAssistanceService.getSeoDescriptions(data.seo)
            if $scope.seoDialog.seoTitleCharsCount == 0
              $scope.copyContent('title', 0)
            if $scope.seoDialog.seoDescriptionCharsCount == 0
              $scope.copyContent('description', 0)
        onPromptError = (data) ->
          # In case Cancel is clicked
          if !$scope.seoAssistance.isFetching
            return
          $scope.seoAssistance.isFetching = false
          $interval.cancel $scope.storyAssistance.stopInterval
          $scope.seoAssistance.hasError = true
          NotificationService.error data.message
          if data.retry
            if $scope.seoAssistance.attempt >= 3
              $scope.seoAssistance.errorMessage = "[Attempt ##{$scope.seoAssistance.attempt}] AI service is not responding. Please fill the SEO headline and meta description manually. Sorry for the inconvenience."
            else
              $scope.seoAssistance.retry = true
              $scope.seoAssistance.errorMessage = "[Attempt ##{$scope.seoAssistance.attempt}] AI service is down. Please try again in a minute. "
        $scope.seoAssistance.isFetching = true
        $scope.seoAssistance.hasError = false
        $scope.seoAssistance.retry = false
        $scope.seoAssistance.attempt += 1
        loggedInUser = JSON.parse(localStorage.getItem("user"))
        username = loggedInUser.first_name + " " + loggedInUser.last_name
        StoryAssistanceService.promptAI(AppState.storyPublishState.content, username, storyId, orgId).then onPromptSuccess, onPromptError

      $scope.copyContent = (type, index) ->
        if type == "title"
          obj = $scope.seoAssistance.seoTitles[index]
          $scope.seoDialog.seoTitle = obj.text
          $scope.seoDialog.seoTitleSelectedIndex = index
          $scope.updateSeoTitleCount()
        else
          obj = $scope.seoAssistance.seoDescriptions[index]
          $scope.seoDialog.seoDescription = obj.text
          $scope.seoDialog.seoDescriptionSelectedIndex = index
          $scope.updateSeoDescriptionCount()

      revertStoryStatus = ->
        if $scope.local.storyModel
          AppState.storyPublishState.status = $scope.local.storyModel.status
          $scope.storypad.sendMessage StorypadMessageType.UPDATE_STORY_STATUS, $scope.local.storyModel.status
        else
          AppState.storyPublishState.status = STORY_STATUS.DRAFT.value
          $scope.storypad.sendMessage StorypadMessageType.UPDATE_STORY_STATUS, STORY_STATUS.DRAFT.value

      $scope.closeSeoTitleDesc = ->
        if $scope.storyAssistance.stopInterval
          $interval.cancel $scope.storyAssistance.stopInterval
        if $scope.storyAssistance.stopTimeout
          $timeout.cancel $scope.storyAssistance.stopTimeout
        revertStoryStatus()
        resetState(false)
        if $scope.msideCtrl.active()
          $scope.msideCtrl.close()

      $scope.$on StoryAssistanceRequestType.STORY_ON_SAVE_IS_INVALID, ->
        if isNewSeoPhase()
          revertStoryStatus()

      $scope.performAction = ->
        $scope.storypad.sendMessage StorypadMessageType.UPDATE_SEO_TITLE, $scope.seoDialog.seoTitle
        $scope.storypad.sendMessage StorypadMessageType.UPDATE_SEO_DESCRIPTION, $scope.seoDialog.seoDescription
        $scope.storypad.updateStoryModelField 'seo_title', $scope.seoDialog.seoTitle
        $scope.storypad.updateStoryModelField 'seo_description', $scope.seoDialog.seoDescription
        $scope.storypad.sendMessage StorypadMessageType.SET_SEO, $scope.storypad.storyModel
        $scope.storypad.sendMessage StorypadMessageType.SHOW_NEW_SEO_TITLE, isSeoDescriptionSet()
        if isStoryWebOnly() and (isWebPublished() or isStoryGoingToBePublished())
          calculateMetricsAndSaveStory()
        else
          $rootScope.$broadcast(StoryAssistanceMessageType.SAVE_STORY, AppState.storyPublishState)
          resetState()
        if $scope.msideCtrl.active()
          $scope.msideCtrl.close()

      $scope.$watch 'story', (newStory, oldStory) ->
        if !newStory?.id? or (newStory?.id and (newStory?.id != oldStory?.id))
          if newStory?.id? and $scope.local?.storyModel?.id? and (parseInt(newStory?.id) == parseInt($scope.local.storyModel.id))
            resetState(false)
          else
            $scope.local.storyModel = null
            resetState()

      $scope.updateSeoTitleCount = ->
        if $scope.seoDialog.seoTitle
          $scope.seoDialog.seoTitleCharsCount = StoryAssistanceService.getCharsCount($scope.seoDialog.seoTitle)
        else
          $scope.seoDialog.seoTitleCharsCount = 0
        $rootScope.$broadcast(StoryAssistanceMessageType.UPDATE_SEO_TITLE, $scope.seoDialog.seoTitle)
        $scope.seoDialog.isSeoTitleValid = (
          $scope.seoDialog.seoTitleCharsCount >= $scope.storyAssistance.MIN_SEO_TITLE_CHAR_LENGTH and
            $scope.seoDialog.seoTitleCharsCount <= $scope.storyAssistance.MAX_SEO_TITLE_CHAR_LENGTH
        )
        updateButtonDisabledState()

      $scope.updateSeoDescriptionCount = ->
        if $scope.seoDialog.seoDescription
          $scope.seoDialog.seoDescriptionCharsCount = StoryAssistanceService.getCharsCount($scope.seoDialog.seoDescription)
        else
          $scope.seoDialog.seoDescriptionCharsCount = 0
        $rootScope.$broadcast(StoryAssistanceMessageType.UPDATE_SEO_DESCRIPTION, $scope.seoDialog.seoDescription)
        $scope.seoDialog.isSeoDescriptionValid = (
          $scope.seoDialog.seoDescriptionCharsCount >= $scope.storyAssistance.MIN_SEO_DESCRIPTION_CHAR_LENGTH and
            $scope.seoDialog.seoDescriptionCharsCount <= $scope.storyAssistance.MAX_SEO_DESCRIPTION_CHAR_LENGTH
        )
        updateButtonDisabledState()

      $scope.$on StoryAssistanceRequestType.RECEIVED_LATEST_BODY, (_, elements) ->
        $('#story-assistance').focus()
        if $scope.storyAssistance.isFetchingLatestBody
          # TODO: Bring the focus back to main window from storypad and then the view is updated
          $scope.storyAssistance.isFetchingLatestBody = false
          if $scope.storyAssistance.stopTimeout
            $timeout.cancel $scope.storyAssistance.stopTimeout
          if hasDigitalPackageAccess() and !isWebPublished() and !isSeoDescriptionSet() and isPublishingToWeb() and (isStoryReadyForApproval() or isStoryGoingToBePublished())
            content = ''
            if elements and elements.length > 0
              content = StoryAssistanceService.getArticleText(elements)
            if content.length > 0
              AppState.storyPublishState.content = content
              storyAssistance()
            else
              $scope.seoAssistance.hasError = true
              $scope.seoAssistance.errorMessage = "Empty Content. Please manually fill in the SEO headline and meta description if content is not required. Otherwise, please fill in the story content and try again after you are done with the story."
              # Required to bring the focus back to main window and update the view
              $("#sa-layout").click().click()

      $scope.$on '$destroy', ->
        resetState()

      $scope.retry = ->
        resetAssistanceComponent(false)
        storyAssistance()

      setupDialog = ->
        if isSeoTitleDescriptionSet()
          $scope.seoDialog.seoTitle = $scope.local.storyModel.body.title_seo
          $scope.updateSeoTitleCount()
          $scope.seoDialog.seoDescription = $scope.local.storyModel.body.seo_description
          $scope.updateSeoDescriptionCount()
        else if $scope.local.storyModel?.body?.title_seo?.length > 0
          $scope.seoDialog.seoTitle = $scope.local.storyModel.body.title_seo
          $scope.updateSeoTitleCount()
        if isStoryReadyForApproval()
          $scope.seoDialog.action = "SAVE"

      setupStoryAssistance = ->
        $rootScope.$broadcast(StoryAssistanceMessageType.DISPLAY_STORY_ASSISTANCE)
        $scope.storyAssistance.isFetchingLatestBody = true
        $rootScope.$broadcast(StoryAssistanceMessageType.DISPLAYING_STORY_ASSISTANCE, true)
        setupDialog()
        $scope.storyAssistance.showAssistanceDialog = true
        $scope.storypad.sendMessage StorypadMessageType.GET_LATEST_BODY, ''
        elements = []
        if $scope.story?.id?
          elements = AppState.story.storyDetail.body.elements
        # This would insure if we don't receive the latest body
        # the previous body state is used
        $scope.storyAssistance.stopTimeout = $timeout ->
          $scope.$broadcast(StoryAssistanceRequestType.RECEIVED_LATEST_BODY, elements)
        , 2000

      $scope.$on StoryAssistanceRequestType.UPDATE_PHASE, ->
        updatePhase()
        if $scope.storyAssistance.phase.isEdit
          $scope.seoDialog.seoTitle = $scope.local.storyModel.body.title_seo
          $scope.updateSeoTitleCount()
          $scope.seoDialog.seoDescription = $scope.local.storyModel.body.seo_description
          $scope.updateSeoDescriptionCount()
          $scope.seoDialog.action = "SAVE"

      $scope.$on StoryAssistanceRequestType.ACTIVATE_STORY_ASSISTANCE,  ->
        updatePhase()
        if $scope.local.storyModel
          if $scope.storyAssistance.phase.isCreate
            setupStoryAssistance()
          else
            if isNewSeoPhase()
              $scope.storypad.sendMessage StorypadMessageType.SET_SEO, $scope.storypad.storyModel
              if isStoryWebOnly() and (isWebPublished() or isStoryGoingToBePublished())
                calculateMetricsAndSaveStory()
              else
                $rootScope.$broadcast(StoryAssistanceMessageType.SAVE_STORY, AppState.storyPublishState)
                resetState()
            else
              unsetSeoDescription()
              $rootScope.$broadcast(StoryAssistanceMessageType.SAVE_STORY, AppState.storyPublishState)
              resetState()
        else
          # Special case AM and PM newsletters tag a story 'editors-pick-summary'
          # with no body copy, no summary and no byline. Hence, cannot reject it.
          if hasDigitalPackageAccess() and !$scope.local.storyModel and isPublishingToWeb() and (isStoryReadyForApproval() or isStoryGoingToBePublished())
            setupStoryAssistance()
          else
            unsetSeoDescription()
            $rootScope.$broadcast(StoryAssistanceMessageType.SAVE_STORY, AppState.storyPublishState)

      $scope.$on StoryAssistanceRequestType.RESET_STORY_ASSISTANCE, ->
        $scope.closeSeoTitleDesc()

'use strict'

angular.module 'nn.adonis-manager-ui.directives', ['adonis.service']

    .directive 'nnAdonisManagerUi', ->
        templateUrl: '/templates/adonis-manager-ui.html'
        restrict: 'E'
        scope: {}
        controller: ($scope, $rootScope, $state, User, NotificationService, AdonisService) ->
            currentRoute = name: $state.current.name, url: $state.href($state.current.name).replace(/^\//g,'')
            $scope.api =
                Notification: NotificationService
                User: User
#                AdonisService: AdonisService
                fetchOnce: (presenter, id) ->
                  AdonisService.once(presenter, id)
                setFurnitureMetadata: (id, mastheadCodes, themeNames, sectionNames, cb) ->
                  AdonisService.setFurnitureMetadata(id, mastheadCodes, themeNames, sectionNames, cb)
                initialState: currentRoute
                onRouteChange: (toRoute, fromRoute) -> toRoute: toRoute, fromRoute: fromRoute

            $scope.api.onRouteChange currentRoute, currentRoute
            
            removeRouteChangeHook = $rootScope.$on '$stateChangeSuccess', 
                (event, toState, toParams, fromState, fromParams, options) ->
                    #Changing routes outside of react, doesnt trigger a route change inside.
                    #Workaround for now is to pass a route change event in to notify the react app
                    #the route has changed.
                    toRoute = name: toState.name, url: $state.href(toState.name).replace(/^\//g,'')
                    fromRoute = name: fromState.name, url: $state.href(fromState.name).replace(/^\//g,'')
                    $scope.api.onRouteChange(toRoute, fromRoute) if toRoute.url.indexOf('adonis') > -1

            $scope.$on '$destroy', ->
                removeRouteChangeHook()

    .directive 'adonisManagerUiReactApp', (reactDirective) ->
        if window.adonis and window.adonis.default
            reactDirective(window.adonis.default.Adonis, ['api'])
        else
            () -> ({})

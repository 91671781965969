'use strict'

angular.module 'nn.login-form.directives', []

  .directive 'nnLoginForm', ->
    templateUrl: '/templates/login-form.html'
    restrict: 'E'
    controller: ($scope, $state, Auth, Roles, COGNITO_REDIRECT_URL, AppState, COGNITO_IDP, CognitoClientId, CognitoLoginUrl, NEWSNOW_AUTH_SERVICE) ->
      $scope.loginError = false
      $scope.loggingIn = false
      $scope.passwordResetURL = Auth.urls.PASSWORD_RESET
      $scope.NNLoginService = NEWSNOW_AUTH_SERVICE
      $scope.loginForm = {
        form: null,
        username: null,
        password: null
      }

      switchLogin = (cognitoConfig) ->
          service = null
          if cognitoConfig.disabled
            service = 'monza'
          else
            service = 'cognito'
          $scope.NNLoginService = service
          NEWSNOW_AUTH_SERVICE = service
      $scope.cognitoLogin = AppState.login
      scope = "email openid profile"
      $scope.loginUri = "#{CognitoLoginUrl}?identity_provider=#{COGNITO_IDP}&redirect_uri=#{COGNITO_REDIRECT_URL}&response_type=CODE&client_id=#{CognitoClientId}&scope=#{scope}"
      if AppState.login.redirect_url
        $scope.loginUri = $scope.loginUri + "&state=#{AppState.login.redirect_url}"
      onConfigSucccess = (config) ->
        $scope.cognitoConfigLoading = false
        $scope.cognitoConfig = config
        switchLogin(config)
      onConfigError = ->
        $scope.cognitoConfigLoading = false
        $scope.cognitoConfig = null
        $scope.cognitoConfigError = true
      $scope.cognitoConfigLoading = true
      Auth.cognitoConfig().then onConfigSucccess, onConfigError

      $scope.submit = ->
        if $scope.loginForm.form and $scope.loginForm.form.$valid
          $scope.loggingIn = true
          $scope.loginError = false
          onLoginSuccess = ->
            $state.go 'app.dashboard'
          onLoginError = ->
            $scope.loggingIn = false
            $scope.loginError = true
          Auth.login($scope.loginForm.username, $scope.loginForm.password).then onLoginSuccess, onLoginError

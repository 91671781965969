'use strict'

angular.module 'nn.profile.directives', []


  .directive 'nnProfilePic', ->
    templateUrl: '/templates/profile-pic.html'
    restrict: 'E'
    replace: true
    scope:
      user: '@'
      email: '@'
    controller: ($scope, $attrs, UserProfile) ->
      $scope.loading = true
      UserProfile.load($attrs.user)
        .then (profile) ->
          $scope.profile = profile
          $scope.loading = false
        .catch ->
          $scope.loading = false

  .directive 'nnDefaultProfilePic', ->
    template: '<img ng-src="//www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&f=y"/>'
    restrict: 'E'
    replace: true
    scope: {}

  .directive 'nnProfileCard', ->
    templateUrl: '/templates/profile-card.html'
    restrict: 'E'
    replace: true
    scope:
      profile: '='


  .directive 'nnProfileWidget', ->
    templateUrl: "/templates/profile-widget.html"
    restrict: 'E'
    replace: true
    scope:
      user: '='
    controller: ($scope, Profile, ProfileAPI) ->
      { first_name, last_name, email } = $scope.user
      defaultUserData =
        name: "#{first_name} #{last_name}"
        email: email
        position: ''
        mugshot: ''
      ProfileAPI.load().then (profiles) ->
        userData = profiles?[0] or defaultUserData
        $scope.profile = Profile.getInstance userData


  .directive 'nnProfileForm', ->
    templateUrl: '/templates/profile-form.html'
    restrict: 'E'
    replace: true
    scope:
      profile: '='
      create: '&'
      remove: '&'
      title: '@'
    controller: ($scope, $attrs, Profile, ProfileSocialAccounts, ProfileRoles, FeatureAccess) ->
      $scope.socialAccounts = ProfileSocialAccounts
      $scope.removable = $attrs.removable?
      $scope.organizations = []
      FeatureAccess.loadOrganizations().then (organizations) ->
        $scope.organizations = organizations
      $scope.roles = ProfileRoles
      $scope.$watchCollection 'profile.data', $scope.profile.diff


  .directive 'nnProfileAdmin', ->
    templateUrl: "/templates/profile-admin.html"
    restrict: 'E'
    replace: true
    scope:
      user: '='
    controller: ($scope, Profile, Profiles, ProfileAPI) ->
      ProfileAPI.load().then (profiles) ->
        if profiles.length
          profileConfig = profiles[0]
        else
          { first_name, last_name, email } = $scope.user
          profileConfig =
            name: "#{first_name} #{last_name}"
            email: email
            position: ''
            mugshot: ''
        $scope.profile = Profile.getInstance profileConfig


  .directive 'nnProfileAdminMulti', ->
    templateUrl: "/templates/profile-admin-multi.html"
    restrict: 'E'
    replace: true
    scope:
      user: '='
    controller: ($scope, Profile, Profiles, ProfileAPI, ProfileSettings) ->
      $scope.settings = ProfileSettings
      $scope.defaultProfile = Profile.getInstance
        name: $scope.user.first_name + ' ' + $scope.user.last_name
        email: $scope.user.email
        position: ''
        mugshot: ''
      ProfileAPI.load().then (data) ->
        profilesService = Profiles.getInstance()
        profilesService.data = data.map (config) => Profile.getInstance config, profilesService
        $scope.profiles = profilesService
      $scope.createProfile = (profile) ->
        profile.create()
          .then (response) ->
            $scope.profiles.addProfile response
            profile.reset()
      $scope.removeProfile = (profile) ->
        profile.remove()
          .then ->
            $scope.profiles.removeProfile profile


  .directive 'notUrl', ->
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attr, ctrl) ->
      notUrlValidator = (ngModelValue) ->
        ctrl.$setValidity 'notUrlValidator', !/(https?:\/\/)|(www.)/.test(ngModelValue)
        ngModelValue
      ctrl.$parsers.push notUrlValidator

'use strict'

angular.module 'nn.organization-select.directives', []

    .directive 'nnOrganizationSelect', ->
        templateUrl: '/templates/organization-select.html'
        restrict: 'E'
        scope:
            selected: '='
            options: '='
            placeholder: '@'
            cookie: '@'
            features: '@'
        controller: ($scope, $attrs, $cookieStore, User, ORG_SELECT_MSG, FeatureAccess) ->
            $scope.cookie = 'savedOrgId'
            # restore previously selected organization
            restoreFromCookie = ->
                savedOrgId = $cookieStore.get $scope.cookie
                savedOrg = org for org in $scope.organizations when org.id is savedOrgId
                if savedOrg?
                    $scope.selected = savedOrg
                else if $attrs.autoSelect? and $scope.organizations?.length
                    $scope.selected = $scope.organizations[0]
            # save selected organization
            saveToCookie = ->
                $cookieStore.put $scope.cookie, $scope.selected.id
            clearCookie = ->
                $cookieStore.put $scope.cookie, ''

            # load organizations
            loadOrganizations = ->
                $scope.placeholderMsg = ORG_SELECT_MSG.LOADING
                if $scope.features && $scope.features.length>0
                    features = $scope.features.split ','
                else
                    features = []

                if $attrs.options?
                    # show all organizations
                    if features.length > 0
                        $scope.organizations = FeatureAccess.filterOrgByFeatures($scope.options , features)
                    else
                        $scope.organizations = $scope.options
                    $scope.placeholderMsg = ORG_SELECT_MSG.CHOOSE
                    restoreFromCookie()
                else
                    FeatureAccess.loadOrganizations(features).then (organizations) ->
                        $scope.organizations = organizations
                        $scope.placeholderMsg = $scope.placeholder or ORG_SELECT_MSG.CHOOSE
                        restoreFromCookie()
                    , (errorMsg) ->
                        $scope.placeholderMsg = errorMsg
            # clear selected organization
            $scope.clear = ($event) ->
                $event.stopPropagation()
                $scope.selected = undefined
                $scope.organizations = undefined
                clearCookie()
                loadOrganizations()
            # init
            $scope.changeOrganization = ->
                saveToCookie()
            loadOrganizations()


    .constant 'ORG_SELECT_MSG',
        LOADING: 'Loading organizations...'
        CHOOSE: 'Choose an organization'

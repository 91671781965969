'use strict'

angular.module 'nn.page-builder.directives', []

  .directive 'canvasSchema', ($filter, $timeout, nnSidenavService, PageBuilderDropTypes, NotificationService, PageBuilderModuleTypes, AppState) ->
    restrict: 'EA'
    controllerAs: 'schema'
    controller: ($scope) ->
      @activeHole = null
      @activeHoleType = null
      @activeHoleId = null

      @setActiveHole = (item = null, type = null, id = null, override = null, update = false) ->

        asidePanel = nnSidenavService('aside')
        pageInfoPanel = nnSidenavService('pageinfo')

        if (id? and @activeHoleId?) and (id is @activeHoleId) and not update
          item = type = id = override = null
          asidePanel.close() if asidePanel
        else if item
          pageInfoPanel.open()
          asidePanel.open()
        else
          asidePanel.close()

        @previousHoleType = @activeHoleType

        @activeHole = item
        @activeHoleType = type
        @activeHoleId = id
        @activeHoleOverride = override

      return

  .directive 'nnLayoutThumbElements', ($filter, storyElementColours, furnitureElementColours, advertElementColours, $compile, $q) ->
    restrict: 'E'
    scope:
      adv: '='
      furn: '='
      story: '='
    template: '''
      <div ng-repeat="elem in furn" ng-style="elementStyle(elem, 'furniture', elem.task || elem.override.task)" class="page-element"></div>
      <div ng-repeat="elem in story" ng-style="elementStyle(elem, 'story', elem.task)" class="page-element story-hole">
          <div ng-repeat="el in elem.elements" ng-style="elementStyle(el, null, null)" class="page-element"></div>
      </div>
      <div ng-repeat="elem in adv" ng-style="elementStyle(elem, 'advert', elem.status)" class="page-element"></div>
    '''
    link: (scope, element, attrs) ->
      mscale = 0.16623376725123 # need to fix this.

      scope.elementStyle = (elem, type, colour) ->
        height: elem.height * mscale
        width: elem.width * mscale
        top: elem.y * mscale
        left: elem.x * mscale
        'background-color': $filter('holeAssignedColours')(colour, type)
        border: '1px solid #ccc'

  .directive 'watchHoleAssigned', ($filter) ->
    link: (scope, element, attrs) ->
      attrs.$observe 'watchHoleAssigned', (value) ->
        element.attr 'fill', $filter('holeAssignedColours')(value, scope.type)

  .directive 'svgViewBox', ->
    link: (scope, element, attrs) ->
      attrs.$observe 'svgViewBox', (value) ->
        element.get(0).setAttribute 'viewBox', value

  .directive 'svgHole', ->
    scope: { hole: '=', offset: '=', type: '=', watch: '=' }
    templateNamespace: 'svg'
    template: '<rect stroke="#aaa" stroke-width="1px" style="cursor: pointer" watch-hole-assigned="{{watch}}" ng-attr-height="{{hole.height}}" ng-attr-width="{{hole.width}}" ng-attr-x="{{hole.spread ? hole.x + offset.x : hole.x }}" ng-attr-y="{{hole.y}}"></rect>'

  .directive 'svgImage', ($timeout) ->
    restrict: 'A'
    scope: { image: '=', hole:"=", offset: "=?", bg: '=?' }
    templateNamespace: 'svg'
    template: '''<image ng-if="image && _width && _height" ng-href="{{image | pagePreviews:_width:_height:60}}" xlink:href="" ng-attr-x="{{hole.x + offset.x}}" ng-attr-y="{{hole.y + offset.y}}" ng-attr-width="{{hole.width + offset.width}}" ng-attr-height="{{hole.height + offset.height}}"></image>'''
    link: (scope, element) ->
      parentsParent = element.parent().parent() # no idea why....
      timeoutPromise = null

      calculateImage = ->
        if parentsParent.width() isnt scope._width or parentsParent.height() isnt scope._height
          scope._width = parentsParent.width()
          scope._height = parentsParent.height()

      # i thought it would be better this way than to
      # binding to the resize event for 60 elements...
      scope.$watch ->
        if timeoutPromise
          $timeout.cancel timeoutPromise

        timeoutPromise = $timeout ->
          calculateImage()
          return
        , 500
        return

      calculateImage()

      return

  .directive 'svgPath', (copyfitWebWorker) ->
    replace: true
    scope: { elements: '=', offset: '=?' }
    templateNamespace: 'svg'
    template: '<path stroke="#aaa" stroke-width="1.5px" fill="none" ng-attr-d="{{path}}"></path>'
    link: (scope, element) ->
      offset = x: scope.offset.x, y: scope.offset.y
      scope.$watch 'elements', (nv) ->
        copyfitWebWorker.run('compileStoryElements', [nv, offset]).then (data) ->
          scope.path = data

'use strict'

angular.module 'nn.publication-features.directives', []

    .directive 'nnPublicationFeatures', () ->
        templateUrl: '/templates/publication-features.html'
        restrict: 'E'
        scope: {}
        controller: ($scope, NotificationService) ->
            $scope.api =
                Notification: NotificationService

    .directive 'nnAdminFeaturesReactApp', (reactDirective) ->
        reactDirective(window.admin.FeatureAdmin, ['api'])
'use strict'

angular.module 'nn.print-stories-table.directives', []

  .directive 'nnPrintStoriesTable', ($q) ->
    templateUrl: '/templates/page-print-stories-table.html'
    restrict: 'E'
    scope:
      issue: '='
      publicationName: '='
      organizationId: '='
      tasksPageNum: '=?'
      explorerState: '='
    controller: (
      $scope,
      $rootScope,
      $interval,
      PrintStoryTableService,
      NotificationService,
    ) ->
      $scope.state = {
          isFetching: false
      }
      $scope.tasks = {}
      $scope.stories = {data: []}
      $scope.storyStatus = {}

      resetState = ->
          $scope.state.timer = 5
          $scope.state.stopInterval = null
          $scope.stories.issueId = $scope.issue.pub + '-' + $scope.issue.date
          $scope.stories.data = []
          $scope.storyStatus.data = []
          $scope.tasks.data = []
          $scope.tasks.ids = Object.keys($scope.tasksPageNum).join(',')


      getStories = ->
        resetState()
        if not $scope.tasks.ids
          return
        $scope.state.isFetching = true
        $scope.stories.stopInterval = $interval ->
          if $scope.state.timer > 0
            $scope.state.timer -= 1
        , 1000
        , 5
        $q.all(
          [
            PrintStoryTableService.getStoriesWithStatus($scope.stories.issueId, $scope.organizationId),
            PrintStoryTableService.getTasks($scope.tasks.ids)
          ]
        )
        .then ([storyStatuses, taskStories]) ->
          $scope.storyStatus.data = PrintStoryTableService.transformStoriesWithStatus(
            storyStatuses)
          $scope.tasks.data = PrintStoryTableService.getStoriesFromTasks(
            taskStories.results, $scope.tasksPageNum)
          $scope.stories.data = PrintStoryTableService.filterStoryForStatus(
            $scope.tasks.data, $scope.storyStatus.data)

          $scope.state.isFetching = false
          $interval.cancel $scope.stories.stopInterval

        , (reason) ->
          $interval.cancel $scope.stories.stopInterval
          $scope.state.isFetching = false
          NotificationService.error reason

      getStories()

      $scope.stories.refetch = -> 
        getStories()


      $scope.hidePrintStories = ->
        $scope.explorerState.copyfitStoriesState = false

'use strict'

angular.module 'nn.chartbeat.services', []

    .factory 'ChartbeatLiveQuery', ($http, $q, $interval) ->
        API_KEY = '1c1744dba92320c6841fd421445e7f95'
        API_URL = "https://api.chartbeat.com/live/quickstats/v4/?apikey=#{API_KEY}"

        Query: (hostname, path) ->
            deferred = $q.defer()
            $http
                method: 'GET'
                url: "#{API_URL}&host=#{hostname}&path=#{path}&now_on=1&all_platforms=1&loyalty=1&types=1&metrics=account_type"
                cache: false
            .success (data) ->
                deferred.resolve data
            .error (data) ->
                deferred.reject()
            deferred.promise

    .factory 'ChartbeatAdvancedQuery', ($http, $q, $interval) ->
        API_KEY = '1c1744dba92320c6841fd421445e7f95'
        API_URL_QueryId = "https://api.chartbeat.com/query/v2/submit/page/?apikey=#{API_KEY}"
        API_URL_Query = "https://api.chartbeat.com/query/v2/fetch/?apikey=#{API_KEY}&format=json"

        Query: (hostname, query_id) ->
            deferred = $q.defer()
            $http
                method: 'GET'
                url: "#{API_URL_Query}&host=#{hostname}&query_id=#{query_id}"
                cache: false
            .success (data) ->
                deferred.resolve data
            .error (data) ->
                deferred.reject()
            deferred.promise
        
        getQueryId: (hostname, path, publishDate, today) ->
            deferred = $q.defer()
            $http
                method: 'GET'
                url: "#{API_URL_QueryId}&sort_column=page_total_time&sort_order=desc&start=#{publishDate}&end=#{today}&endpoint=page&host=#{hostname}&limit=1000&tz=Australia%2FSydney&dimensions=author,dynamic_title,path,publish_date,device,referrer_type&metrics=page_total_time,page_avg_time,page_uniques,page_views,page_views_loyal,page_views_quality&path=#{path}"
                cache: false
            .success (data) ->
                deferred.resolve data.query_id
            .error (data) ->
                deferred.reject()
            deferred.promise

    .factory 'Chartbeat', ($http, $q, $interval) ->
        API_URL = 'https://api.chartbeat.com/live/'
        API_KEY = '1c1744dba92320c6841fd421445e7f95'
        class Chartbeat
            constructor: (@domain) ->
                @data =
                    pages: null
                    stats: null
                @intervals =
                    pages: null
                    stats: null
            getPages: =>
                $http
                    method: 'GET',
                    url: "#{API_URL}toppages/v3/?apikey=#{API_KEY}&host=#{@domain}",
                    cache: false
                .success (response) =>
                    @data.pages = response.pages
                @
            getStats: =>
                $http
                    method: 'GET',
                    url: "#{API_URL}quickstats/v3/?apikey=#{API_KEY}&host=#{@domain}",
                    cache: false
                .success (response) =>
                    @data.stats = response
                @
            toggleAutoRefresh: (type, activate) ->
                if activate
                    switch type
                        when 'pages'
                            @intervals.pages = $interval @getPages, 2000
                        when 'stats'
                            @intervals.stats = $interval @getStats, 2000
                else
                    $interval.cancel @intervals[type]

        getInstance: (domain) ->
            new Chartbeat domain

'use strict'

angular.module 'nn.task.directives', []

.directive 'nnTaskGetPage', (AdonisData, $timeout) ->
  link: (scope, element) ->
    onSuccess = (data) ->
      [pub, y, m, d, book] = data.layout.split '-'
      scope.active = pub: pub, date: "#{y}-#{m}-#{d}", book: book, page: data.page

    onError = -> scope.active = true

    doClick = ->
      element.off 'click', doClick
      AdonisData.getStoryIssuesPresenter(scope.task.id).then onSuccess, onError

    element.on 'click', doClick

    scope.$on '$destroy', -> element.off 'click', doClick

.directive 'listGroupLoading', ->
  restrict: 'A'
  transclude: true
  template: '''
    <div ng-show="isLoading" class="list-groups">
      <span class="loading bg-white alignc"><i class="fa fa-spin fa-spinner"></i> Loading...</span>
    </div>
    <div ng-hide="isLoading" ng-transclude></div>'''
  link: (scope, element, attrs, fn) ->
    scope.isLoading = false

    if attrs.listGroupLoading
      scope.$watch attrs.listGroupLoading, (loading) ->
        scope.isLoading = loading

.constant 'listTemplate',
  '<span class="list-group-item">
      <div class="media-left">
        <span ng-if="!grav" class="fa-stack fa-lg">
          <i class="fa {{class}} {{icon}} fa-stack-1x" title="{{title}}"></i>
        </span>
        <gravatar-image ng-if="grav" alt="{{grav.first_name}} {{grav.last_name}}"  gravatar-email="{{grav.email}}"></gravatar-image>
      </div>
      <div class="media-body">
        <label class="page-element-label" ng-if="label">{{label}}</label>
        <div ng-transclude></div>
      </div>
  </span>'

.directive 'listItem', (listTemplate) ->
  restrict: 'E'
  replace: true
  transclude: true
  scope: { label: '=?', icon: '=?', class: '=?circleIcon', grav: '=?', title: '=?' }
  template: listTemplate

.directive 'listItemNoLabel', (listTemplate) ->
  restrict: 'E'
  replace: true
  transclude: true
  template: listTemplate

.directive 'listSearch', (
  $document,
  $timeout,
  User,
  TaskService,
  ListTime,
  ListFurnitureUser,
  ListStoriesUser,
  Story,
  Stories,
  Files,
  Auth,
  NotificationService,
  STORY_CHANNEL,
  STORY_STATUS,
  FeatureAccess,
  FeatureAccessTags,
  CopyfitAssistantService
) ->
  restrict: 'E'
  require: 'ngModel'
  scope: { hide: '&', type: '@', validate: '&'}
  templateUrl: '/templates/list-search.html'
  controller: ($scope, $element, $attrs) ->
    $scope.search = closed: true
    $scope.times = ListTime

    User.getOrganizations().then (data) ->
      $scope.orgs = data

    if $scope.type is 'story'
      $scope.api = Stories.getInstance()
      $scope.users = ListStoriesUser

    if $scope.type is 'furniture'
      $scope.api = Files.getInstance()
      $scope.api.params().type = name:'pdf', value:'application/pdf'
      $scope.users = ListFurnitureUser

    Auth.user().then (user) ->
      $scope.users[1].value = user.id

  link: (scope, element, attrs, ngModel) ->

    ngModel.$render = ->
      if ngModel.$viewValue?.id?
        scope.oldObject = angular.copy ngModel.$viewValue
        ngModel.$setViewValue undefined
      scope.validate()
      scope.openSearch()

    unlockStoryFromTask = (item) ->
      msg = 'This story is already linked to a task. By clicking \'YES\', you will remove it from the task and any page(s) that it has already been placed on. Are you sure?'
      taskId = item.storytask
      removeStory = ->
        TaskService.removeStory(taskId).then ->
          item.storytask = null
          scope.selectSearch item
        FeatureAccess.hasFeatureAccess(FeatureAccessTags.PRINT_PACKAGE, {user: 'self', org: item.organization}).then (result) ->
          if result and item.id
            CopyfitAssistantService.delete(item.id, parseInt(item.organization)).then resolve, reject
      NotificationService.confirm(msg).then removeStory

    createMultiChannelStorySnapshot = (story) ->
      props =
        available_to: [STORY_CHANNEL.WEB.value, STORY_CHANNEL.PRINT.value]
        status: STORY_STATUS.DRAFT.value
      # create new web & print snapshot
      Story.createSnapshot story.id, story.snapshot, props

    scope.cancelSearch = ->
      if scope.oldObject
        ngModel.$setViewValue scope.oldObject
        scope.hide()
      scope.search.closed = true
      scope.validate()

    scope.selectSearch = (item) ->
      if scope.type is 'story'
        if item.storytask
          # scope.validate object: item.storytask
          unlockStoryFromTask item
        else
          scope.validate()
          NotificationService.success "Story is selected"
          ngModel.$setViewValue
            title: item.title
            id: item.id
            type: scope.type
          scope.search = closed: true
          scope.hide()
      if scope.type is 'furniture'
        ngModel.$setViewValue
          id: scope.oldObject?.id
          title: item.title
          bucket: item.bucket
          filename: item.name
          url: item.url
          uri: item.uri
          original_id: item.id
          type: scope.type
        NotificationService.success "File is selected"
        scope.search = closed: true
        scope.hide()

    onOutsideClick = (event) ->
      isChild = element.has(event.target).length > 0
      isSelf = element[0] is event.target
      if not isChild or not isSelf and scope.close and scope.search.closed is false
        scope.$apply scope.cancelSearch()

    scope.openSearch = ->
      if scope.search.closed
        scope.items = scope.api.search()
        scope.search.closed = false
        $timeout -> $document.bind 'click', onOutsideClick
      else
        scope.close = false
    # scope gets destroyed with ng-if="searchList" turning to false
    scope.$on '$destroy', -> $document.unbind 'click', onOutsideClick

.directive 'nnFocus', ($timeout) ->
  (scope, element) ->
    $timeout ->
      element[0].focus()
    , 1000, false

.directive 'nnContenteditable', ($sce, $sanitize) ->
  restrict: 'A'
  require: '?ngModel'
  link: (scope, element, attrs, ngModel) ->
    element.attr 'contenteditable', true

    updateElement = ->
      if element.text() == attrs.placeholder
        element.html attrs.placeholder
      else
        value = element.html()
        value = value.replace /[^\u0000-\u007E]/g, ''
        value = value.replace /<br\s*[\/]?>/gi, '\\n '
        value = value.replace /^(\\n)*|(\\n)*$/g, ''
        scope.$apply -> ngModel.$setViewValue value

    ngModel.$render = ->
      if ngModel.$viewValue
        element.removeClass 'has-placeholder'
        element.html ngModel.$viewValue.replace /\\n/g, '<br>'
      else
        element.html attrs.placeholder
        element.addClass 'has-placeholder'

    element.bind 'keyup change', ->
      updateElement()

    element.bind 'focus', ->
      if element.text() == attrs.placeholder
        element.html '&#8203;'

        ngModel.$setViewValue ''
        element.removeClass 'has-placeholder'

    element.bind 'blur', ->
      updateElement()
      ngModel.$render()

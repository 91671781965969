angular.module 'nn.dragndropsvg.list', []

.directive 'dndList', ($parse, $timeout, $interval, dndHelpers) ->
  link: (scope, element, attr) ->

    isDropAllowed = (event) ->
      if not dndHelpers.dragTypeWorkaround.isDragging
        return false

      if attr.dndAllowedTypes and dndHelpers.dragTypeWorkaround.isDragging
        allowed = scope.$eval attr.dndAllowedTypes

        if angular.isArray(allowed) and allowed.indexOf(dndHelpers.dragTypeWorkaround.dragType) is -1
          return false

      if attr.dndDisableIf and scope.$eval attr.dndDisableIf
        return false

      true

    stopDragover = ->
      element.removeClass 'dndDragover'
      true

    invokeCallback = (expression, event, item) ->
      $parse(expression) scope,
        event: event
        item: item or dndHelpers.dragTypeWorkaround.block
        external: not dndHelpers.dragTypeWorkaround.isDragging
        type: if dndHelpers.dragTypeWorkaround.isDragging then dndHelpers.dragTypeWorkaround.dragType else undefined

    _onDragOver = (event) ->
      event = event.originalEvent or event

      return true unless isDropAllowed event

      invokeCallback attr.dndDragover, event
      element.addClass 'dndDragover'

      if event.preventDefault
        event.preventDefault()
        event.stopPropagation()

      false

    element.on 'dragover', _onDragOver

    _onDragDrop = (event) ->
      event = event.originalEvent or event

      return true unless isDropAllowed event

      if event.preventDefault
        event.preventDefault()

      if attr.dndDrop
        transferredObject = invokeCallback attr.dndDrop, event, transferredObject

        if not transferredObject
          return stopDragover true

      if event.dataTransfer.dropEffect is 'none'
        if event.dataTransfer.effectAllowed is 'copy' or event.dataTransfer.effectAllowed is 'move'
          dndHelpers.dropEffectWorkaround.dropEffect = event.dataTransfer.effectAllowed
        else dndHelpers.dropEffectWorkaround.dropEffect = if event.ctrlKey then 'copy' else 'move'
      else dndHelpers.dropEffectWorkaround.dropEffect = event.dataTransfer.dropEffect

      stopDragover true

      if event.stopPropagation
        event.stopPropagation()

      false

    element.on 'drop', _onDragDrop

    _onDragLeave = (event) ->
      event = event.originalEvent or event
      element.removeClass 'dndDragover'

      $timeout ->
        if not element.hasClass 'dndDragover'
          if attr.dndLeave
            invokeCallback attr.dndLeave, event
      , 0

    element.on 'dragleave', _onDragLeave

    scope.$on '$destroy', ->
      element.off 'dragover', _onDragOver
      element.off 'dragleave', _onDragLeave
      element.off 'drop', _onDragDrop

#
# Menu module
# =============
#
angular.module "nn.form-search.directives", []
#
# This is used to load the list of forms and to select. These functions
# either control the current FormModel on the display, or handle
# the Storage Service for saving and loading...
#
.directive "nnFormSearch", ->
    templateUrl: "/templates/form-search.html"
    restrict: "E"
    scope: {}
    controller: ($scope, FormsUser, FormsTime, Organization, StorageService, FormModel) ->
        #$scope.templates = null
        $scope.users = FormsUser.refresh()
        $scope.times = FormsTime.refresh()
        $scope.organizations = Organization.refresh()
        
        $scope.loader = StorageService.templateLoader
        $scope.loader.setParser StorageService.templateParser
        #
        # start it up when controller is initialized...
        #
        $scope.loader.search()
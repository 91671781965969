'use strict'

angular.module 'nn.story-explorer.services', []

    .factory 'StoryExplorer', ->
        menuItems: false
        menuItemSelected: null

    .constant 'StorySections', [
            { 'id':'search', label:'Search stories', icon:'fa-search' }
            { 'id':'my-stories', label:'My stories', icon:'fa-search' }
            # { 'id':'assignments', label:'My assignments', icon:'fa-calendar-o', badgeCount:2 }
            { 'id':'deadlines', label:'My deadlines', icon:'fa-calendar', badgeCount:8 }
            { 'id':'redflags', label:'My red flags', icon:'fa-flag-o', badgeCount:1 }
            { 'id':'favourites', label:'My favourites', icon:'fa-star', badgeCount:5 }
            # { 'id':'history', label:'My history', icon:'fa-clock-o' }
        ]

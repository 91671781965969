'use strict'

angular.module 'adonis.issue.templates.advertholestemplates', []

.factory 'advertHoleTemplatesTemplate', (BasePresenter) ->
  class advertHoleTemplatesTemplate extends BasePresenter
    constructor: ($queryStream, initData) ->
   	  super $queryStream, initData

      initData.map (value) =>
      	@[value[0].id] = @getEntity 'adverts', value[1]

.factory 'advertsEntity', (BasePresenter) ->
  class advertsEntity extends BasePresenter
    constructor: ($queryStream, initData) ->
      super $queryStream, initData

      for own key, item of initData
        @[key] = item?.value or item 

'use strict'

angular.module 'nn.form-explorer.directives', []
    #
    .directive 'nnFormExplorer', ->
        templateUrl: '/templates/form-explorer.html'
        restrict: 'E'
        scope: {}
        controller: ($scope, AppState, FormSections, FormExplorer, FieldTypes, FormModel) ->
            $scope.sections = FormSections
            $scope.state = FormExplorer
            #
            # Here we clear the form to make way for a new
            # one.
            #
            $scope.newForm = () ->
                FormModel.initialize()
                FormModel.enabled = true
                return

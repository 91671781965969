angular.module 'ngQuickDate.directives', []

.directive 'quickDatepicker', (ngQuickDateDefaults, $filter, $sce) ->
    restrict: 'E'
    templateUrl: '/templates/datepicker.html'
    require: '?ngModel'
    scope: { dateHighlighter: '=?', dateFilter: '=?', onChange: '&', required: '@', minDate: '=', maxDate: '=', readOnly: '=?', onMonthChange: '=?' }
    replace: true
    link: (scope, element, attrs, ngModelCtrl) ->

        # INITIALIZE VARIABLES AND CONFIGURATION
        # ================================

        initialize = ->
            setConfigOptions() # Setup configuration variables
            scope.toggleCalendar(false) # Make sure it is closed initially
            scope.weeks = [] # Nested Array of visible weeks / days in the popup
            scope.inputDate = null # Date inputted into the date text input field
            scope.inputTime = null # Time inputted into the time text input field
            scope.invalid = true
            if typeof(attrs.initValue) is 'string'
                ngModelCtrl.$setViewValue(parseDateString(attrs.initValue))
            if !scope.defaultTime
                templateDate = moment()
                scope.datePlaceholder = $filter('moment')(templateDate, scope.dateFormat)
                scope.timePlaceholder = $filter('moment')(templateDate, scope.timeFormat)
            setCalendarDate()
            refreshView()

        # Copy various configuration options from the default configuration to scope

        setConfigOptions = ->
            for key, value of ngQuickDateDefaults
                if key.match(/[Hh]tml/)
                    scope[key] = $sce.trustAsHtml(ngQuickDateDefaults[key] or '')
                else if !scope[key] and attrs[key]
                    scope[key] = attrs[key]
                else if !scope[key]
                    scope[key] = ngQuickDateDefaults[key]
            if !scope.labelFormat
                scope.labelFormat = scope.dateFormat
                unless scope.disableTimepicker
                    scope.labelFormat += ' ' + scope.timeFormat
            if attrs.iconClass and attrs.iconClass.length
                scope.buttonIconHtml = $sce.trustAsHtml '<i ng-show="iconClass" class="#{attrs.iconClass}"></i>'
            scope.buttonClass = (if attrs.buttonClass and attrs.buttonClass.length > 0 then false else true)

        # VIEW SETUP
        # ================================

        # This code listens for clicks both on the entire document and the popup.
        # If a click on the document is received but not on the popup, the popup
        # should be closed

        datepickerClicked = false

        wasDatepickerClicked = (e) ->
            e.preventDefault()
            datepickerClicked = true

        outsideDatepickerClicked = ->
            if scope.calendarShown and ! datepickerClicked
                scope.toggleCalendar(false)
                scope.$apply()
            datepickerClicked = false

        window.document.addEventListener 'click', outsideDatepickerClicked
        angular.element(element[0])[0].addEventListener 'click', wasDatepickerClicked

        scope.$on '$destroy', ->
            angular.element(element[0])[0].removeEventListener 'click', wasDatepickerClicked
            window.document.removeEventListener 'click', outsideDatepickerClicked

        # SCOPE MANIPULATION Methods
        # ================================

        # Refresh the calendar, the input dates, and the button date

        refreshView = ->
            date = if ngModelCtrl.$modelValue then parseDateString(ngModelCtrl.$modelValue) else null
            setupCalendarView()
            setInputFieldValues(date)
            scope.mainButtonStr = if date then $filter('moment')(date, scope.labelFormat) else scope.placeholder
            scope.invalid = ngModelCtrl.$invalid


        # Set the values used in the 2 input fields

        setInputFieldValues = (val) ->
            if val
                scope.inputDate = val.format(scope.dateFormat)
                scope.inputTime = val.format(scope.timeFormat)
            else
                scope.inputDate = null
                scope.inputTime = null

        # Set the date that is used by the calendar to determine which month to show
        # Defaults to the current month

        setCalendarDate = (val=null) ->
            d = if val then moment(val) else moment()
            if (d is undefined or !d.isValid())
                d = moment()
            scope.calendarDate = d.startOf('month')

        # Setup the data needed by the table that makes up the calendar in the popup
        # Uses scope.calendarDate to decide which month to show

        setupCalendarView = ->
            offset = scope.calendarDate.day()
            daysInMonth = scope.calendarDate.daysInMonth()
            numRows = Math.ceil((offset + daysInMonth) / 7)
            weeks = []
            curDate = moment(scope.calendarDate)
            curDate.add(offset * -1, 'd')
            for row in [0..(numRows-1)]
                weeks.push([])
                for day in [0..6]
                    d = moment(curDate)
                    if scope.defaultTime
                        time = scope.defaultTime.split(':')
                        d.hours(time[0] or 0)
                        d.minutes(time[1] or 0)
                        d.seconds(time[2] or 0)
                    selected = ngModelCtrl.$modelValue and d and datesAreEqual(d, ngModelCtrl.$modelValue)
                    today = datesAreEqual(d, moment())
                    weeks[row].push({
                        date: d
                        selected: selected
                        disabled: (if (typeof(scope.dateFilter) is 'function') then !scope.dateFilter(d) else false) or (scope.disableOther and d.month() != scope.calendarDate.month()) or (scope.minDate and d < scope.minDate) or (scope.maxDate and d > scope.maxDate)
                        highlighted: if (typeof(scope.dateHighlighter) == 'function') then scope.dateHighlighter(d) else false
                        other: d.month() != scope.calendarDate.month()
                        today: today
                    })
                    curDate.add 1, 'd'

            scope.weeks = weeks

        # PARSERS AND FORMATTERS
        # =================================
        # When the model is set from within the datepicker, this will be run
        # before passing it to the model.

        checkIfValid = (viewVal = null) ->
            validity =
                required: true
                minDate: true
                maxDate: true

            value = null

            if scope.required and !viewVal?
                validity.required = false
            else if angular.isDate(viewVal)
                value = moment(viewVal)
            else if angular.isString(viewVal)
                value = parseDateString(moment(viewVal))

            if scope.minDate and scope.minDate > viewVal
                validity.minDate = false

            if scope.maxDate and scope.maxDate < viewVal
                validity.maxDate = false

            ngModelCtrl.$setValidity('minDate', validity.minDate)
            ngModelCtrl.$setValidity('maxDate', validity.maxDate)
            ngModelCtrl.$setValidity('required', validity.required)

            value

        ngModelCtrl.$parsers.push (viewVal) ->
            return checkIfValid(viewVal)

        # When the model is set from outside the datepicker, this will be run
        # before passing it to the datepicker

        ngModelCtrl.$formatters.push (modelVal) ->
            if scope.minDate and scope.minDate > modelVal
              undefined

            if scope.maxDate and scope.maxDate < modelVal
              undefined

            if angular.isDate(modelVal)
              moment(modelVal)
            else if angular.isString(modelVal)
              parseDateString(modelVal)
            else undefined

        # HELPER METHODS
        # =================================
        dateToString = (date, format) ->
            $filter('moment')(date, format)

        stringToDate = (date) ->
            if typeof date is 'string'
                parseDateString(date)
            else date

        parseDateString = ngQuickDateDefaults.parseDateFunction

        datesAreEqual = (d1, d2, compareTimes= false) ->
            if compareTimes
                (d1 - d2) is 0
            else
                d1 = stringToDate(d1)
                d2 = stringToDate(d2)
                d1 and d2 and (d1.year() is d2.year()) and (d1.month() is d2.month()) and (d1.date() is d2.date())

        datesAreEqualToMinute = (d1, d2) ->
            return false unless d1 and d2
            d1.year() is d2.year() and d1.month() is d2.month() and d1.date() is d2.date() and d1.hour() is d2.hour() and d1.minute() is d2.minute()

        # DATA WATCHES
        # ================================is
        # Called when the model is updated from outside the datepicker

        ngModelCtrl.$render = ->
            setCalendarDate(ngModelCtrl.$viewValue)
            checkIfValid(ngModelCtrl.$viewValue)
            refreshView()

        # Called when the model is updated from inside the datepicker,
        # either by clicking a calendar date, setting an input, etc

        ngModelCtrl.$viewChangeListeners.unshift ->
            setCalendarDate(ngModelCtrl.$viewValue)
            refreshView()
            if scope.onChange
                scope.onChange()

        # When the popup is toggled open, select the date input

        scope.$watch 'calendarShown', (newVal, oldVal) ->
            if newVal
                dateInput = angular.element(element[0].querySelector('.quickdate-date-input'))[0]
                dateInput.select()

        scope.$watch 'minDate', (newVal, oldVal) ->
            if newVal and ngModelCtrl.$modelValue < newVal
                ngModelCtrl.$setViewValue(newVal)
                ngModelCtrl.$render()

            refreshView()

        scope.$watch 'maxDate', (newVal, oldVal) ->
            if newVal and ngModelCtrl.$modelValue > newVal
                ngModelCtrl.$setViewValue(newVal)
                ngModelCtrl.$render()

            refreshView()

        scope.$watch 'dateFilter', (newVal, oldVal) ->
            if newVal
                refreshView()

        scope.$watch 'dateHighlighter', (newVal, oldVal) ->
          if newVal
            refreshView()        


        # VIEW ACTIONS
        # ================================is

        scope.toggleCalendar = (show = true) ->
            if !!scope.readOnly
                scope.calendarShown = false
            else if isFinite show
                scope.calendarShown = show
            else
                refreshView()
                scope.selectDateFromInput(false)
                scope.calendarShown = not scope.calendarShown

        # Select a new model date. This is called in 3 situations:
        #     * Clicking a day on the calendar or from the `selectDateFromInput`
        #     * Changing the date or time inputs, which call the `selectDateFromInput` method, which calls this method.
        #     * The clear button is clicked

        scope.selectDate = (date, closeCalendar = false, disabled = false) ->
            if (disabled) then return false

            if typeof(scope.dateFilter) is 'function' and !date.isValid()
                return false

            date = if date then date.toDate() else null
            ngModelCtrl.$setViewValue(date)

            true

        # This is triggered when the date or time inputs have a blur or enter event.

        scope.selectDateFromInput = (closeCalendar = false) ->
          tmpDate = parseDateString(moment(scope.inputDate, 'DD/MM/YYYY'))

          if !tmpDate
              scope.inputDateErr = true
              return

          if moment(scope.inputDate, 'DD/MM/YYYY').isBefore(scope.minDate)
              scope.inputDateErr = true
              return

          if !scope.disableTimepicker and scope.inputTime and scope.inputTime.length and tmpDate
              tmpTime = if scope.disableTimepicker then '12:00 pm' else moment(scope.inputTime, scope.timeFormats).format(scope.timeFormat)

              if tmpTime is 'Invalid date'
                  scope.inputTimeErr = true
                  return

              tmpDateAndTime = parseDateString(moment("#{scope.inputDate} #{tmpTime}", 'DD/MM/YYYY h:mm A'))

              if !tmpDateAndTime
                  scope.inputTimeErr = true
                  return

              tmpDate = tmpDateAndTime

          if !scope.selectDate(tmpDate, false)
            scope.inputDateErr = true
            return

          scope.inputDateErr = false
          scope.inputTimeErr = false

        # When tab is pressed from the date input and the timepicker
        # is disabled, close the popup

        scope.onDateInputTab = ->
            if scope.disableTimepicker
                scope.toggleCalendar(false)
            true

        # When tab is pressed from the time input, close the popup

        scope.onTimeInputTab = ->
            scope.toggleCalendar(false)
            true

        # View the next and previous months in the calendar popup

        scope.nextMonth = ->
            date = moment(scope.calendarDate).add(1, 'M')
            setCalendarDate(date)
            scope.onMonthChange?(date)
            refreshView()

        scope.prevMonth = ->
            date = moment(scope.calendarDate).subtract(1, 'M')
            setCalendarDate(date)
            scope.onMonthChange?(date)
            refreshView()

        # Set the date model to null
        scope.clear = ->
            clearDate = if scope.defaultCalendarDate then moment(scope.defaultCalendarDate, ['DD/MM/YYYY', 'DD/MM/YYYY h:mm A']) else null
            scope.selectDate(clearDate, true)

        initialize()

.directive 'ngEnter', ->
    (scope, element, attr) ->
        element.bind 'keydown keypress', (e) ->
            if (e.which is 13)
                scope.$apply(attr.ngEnter)
                e.preventDefault()

.directive 'onTab', ->
    restrict: 'A',
    link: (scope, element, attr) ->
        element.bind 'keydown keypress', (e) ->
            if (e.which is 9) and !e.shiftKey
                scope.$apply(attr.onTab)

.filter 'moment', ->
    (momentObj, formatStr) ->
        momentObj.format(formatStr)

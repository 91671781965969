'use strict'

angular.module 'nn.modal-dialog.services', []

  .service 'ModalDialogService', ($q, $timeout, PubSub) ->

    @open = (body, fullscreen=false) ->
      deferred = $q.defer()
      notification =
        body: body
        fullscreen: fullscreen
        deferred: deferred
      $timeout ->
        PubSub.publish 'ModalDialog', 'open', notification
      deferred.promise

    @close = ->
      PubSub.publish 'ModalDialog', 'close'
      
    @
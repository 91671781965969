'use strict'

require 'react'
require 'react-dom'
require 'jquery'
require 'angular'
require 'ngReact'
require 'moment'
require 'angular-resource'
require 'angular-animate'
require 'angular-ui-router'
require 'angular-ui-select'
require 'angular-md5'
require 'angular-fullscreen'
require 'angular-mocks'
require 'angular-sanitize'
require 'angular-cookies'
require 'angular-gauge'
require 'angular-file-upload-html5-shim'
require 'angular-file-upload'
require 'angulartics'
require 'angulartics-ga'
require 'angulartics-debug'
require 'angular-moment'
require 'gauge'


angular.module 'nn.app', [
    # plugins
    'ngResource'
    'ngAnimate'
    'ngSanitize'
    'ngCookies'
    'ui.router'
    'ui.select'
    'angular-md5'
    'ngQuickDate'
    'angularMoment'
    'angulartics'
    'angulartics.google.analytics'
    'react'
    # 'angularStats'
    # app
    'nn.app.routes'
    'nn.app.services'
    # 'nn.templates'
    'nn.common'
    # 'nn.form-builder'
    'nn.file-manager'
    'nn.story-manager'
    'nn.page-builder'
    'nn.publication-manager'
    'nn.report-manager'
    'nn.newslist-builder',
    'nn.adonis-manager',
    'nn.site-builder',
    'nn.sports-manager',
]

    .config ($compileProvider) ->
        # disable angular's debug annotations in production for performance
        # (this debug info is required for protractor to run)
        $compileProvider.debugInfoEnabled false

    .run (amMoment) ->
        amMoment.changeLocale 'en-au'

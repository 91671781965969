'use strict'

angular.module 'nn.common', [
  'nn.common.services'
  'nn.common.directives'
  'nn.common.filters'
  # components
  # 'mocks'
  'nn.auth'
  'nn.roles'
  'nn.gravatar'
  'nn.adonis'
  # metrics
  'nn.google-analytics'
  'nn.chartbeat'
  'nn.sentry'
  # 'nn.user-voice'
  'nn.newrelic'
  'nn.sidebar'
  'nn.task'
  'nn.favourites-list'
  'nn.form-explorer'
  'nn.account-explorer'
  'nn.dashboard'
  'nn.profile'
  'nn.pubsub'
  'nn.activity-list'
  'nn.dragndrop'
  'nn.login-form'
  'nn.notification'
  'nn.modal-dialog'
  'nn.user-select'
  'nn.publication-select'
  'nn.organization-select'
  'nn.dragndropsvg'
  'nn.site-select'
  'nn.dyn-select'
  'nn.health-check'
]

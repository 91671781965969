#
# Form editor toggle button.
# ===========================
# This will switch the state
# of the application using the stateProvider...
#  
angular.module "nn.form-editor-toggle.directives", []
#
# Field editor directive. This simply pulls the template onto
# the form. The selectedItem in the controller is used to
# populate the items in the template...
#  
.directive "nnFormEditorToggle", ->
    restrict: "E"
    scope: {}
    templateUrl: "/templates/form-editor-toggle.html"
    controller: ($scope, $state, FormModel, FormExplorer) ->
        #
        # Here we just allow the toggle button to
        # switch the edit state...
        #
        $scope.fm = FormModel
        $scope.toggle = (value) ->
            FormModel.refreshFields()
            FormModel.editMode = value
            #
            # Here we need a mini-state machine on the toggle button.
            # This simply directs the stateProvider to the appropriate
            # location...
            #
            if value
                if FormModel.isValid()
                    $state.go 'app.forms.search.form.edit', form:FormModel.id
                else
                    $state.go 'app.forms.new'
            else
                #
                # When switching back from edit mode, it is necessary to manually 
                # update the FormExplorer to reload the menu.
                # Otherwise the FormExplorer will not update itself.
                #
                if FormModel.isValid()
                    $state.go 'app.forms.search.form', form:FormModel.id
                    FormExplorer.menuItems = true
                    FormExplorer.menuItemSelected = 0
                else
                    $state.go 'app.forms.home'
            return

'use strict'

angular.module 'nn.task.info', [
    'nn.task.info.directives'
    'nn.task.info.services'
  ]

  .constant 'ListStoriesUser', [
      { name:'all stories', value: null }
      { name:'my stories', value: 'mine' }
  ]

  .constant 'ListFurnitureType', [
      { name:'pdf', value: 'pdf' }
      { name:'png', value: 'png' }
      { name:'jpg', value: 'jpg' }
  ]

  .constant 'ListFurnitureUser', [
      { name:'all files', value: null }
      { name:'my files', value: 'mine' }
  ]

  .constant 'ListTime', [
      { name:'any time', value:'' }
      { name:'past 24 hours', value:'-1d' }
      { name:'past week', value:'-1w' }
      { name:'past month', value:'-1m' }
      { name:'past year', value:'-1y' }
  ]

'use strict'

angular.module 'nn.file-upload.services', [
    'angularFileUpload'
]
.factory 'Uploader', ($upload, HOSTS, Auth, Bucket, $q) ->
    STATE =
        IDLE: 'idle'
        SELECTED: 'selected'
        UPLOADING: 'uploading'
        COMPLETE: 'complete'
    return {
        getInstance: (bucketName)->
            files = []
            results = []
            state = STATE.IDLE
            filesProcessed = 0
            return {
                all: ->
                    files
                filesSuccessful: ->
                    files.filter (file) -> file.uploadSuccess 
                selectUpload: (file) ->
                    @select file
                    @upload()
                select: (selectedFiles) ->
                    if selectedFiles.length > 0
                        state = STATE.SELECTED
                        files = selectedFiles
                        filesProcessed = 0
                        angular.forEach files, (file) ->
                            file.src = window.URL.createObjectURL file
                remove: (file) ->
                    window.URL.revokeObjectURL file.src
                    files.splice files.indexOf(file), 1
                    if files.length is 0
                        state = STATE.IDLE
                removeAll: ->
                    state = STATE.IDLE
                    while files.length
                        @remove files[0]
                removeResults: ->
                  results = []
                upload: ->
                    #
                    # Here we upload the files to Valencia. If results are required
                    # we pass back a promise object. The results object is used to
                    # hold the resulting upload return tickets. Once the file count
                    # is complete, the promise object is returned...
                    #
                    deferred = $q.defer()
                    filesProcessed = 0
                    Bucket.get(null, bucketName).then (bucket) ->
                        state = STATE.UPLOADING
                        # just send back an empty array if there are no valid
                        # files...
                        unless files.length
                            state = STATE.COMPLETE
                            deferred.resolve []
                        #
                        # Here we connect to valencia and attempt to upload
                        # the files sequentially. The promise object is returned
                        # once all files have been processed...
                        #
                        for file in files
                            do (file) ->
                                $upload.upload(
                                    url: "#{HOSTS.valencia}/upload/v1/#{bucket.name}/"
                                    file: file
                                    method: 'POST'
                                    auth: true
                                    data:
                                        title: file.customName or file.name or ''
                                        description: file.description or ''
                                        organization: ''
                                    fileFormDataName: 'blob'
                                )
                                .progress (evt) ->
                                    #
                                    percent = parseInt(100.0 * evt.loaded / evt.total)
                                    file.percent = percent
                                .success (data, status, headers, config) ->
                                    #
                                    # for some reason this returns in an array of one
                                    # element, so we remove the array bounds...
                                    #
                                    results.push data[0]
                                    file.uploadSuccess = true
                                    filesProcessed++
                                    if filesProcessed is files.length
                                        state = STATE.COMPLETE
                                        filesProcessed = 0
                                        #
                                        # Here we notify the promise object that an
                                        # upload has been successful...
                                        #
                                        deferred.resolve results
                                .error ->
                                    #
                                    # Here we just add null for an error upload so
                                    # as to not affect the successful uploads...
                                    #
                                    results.push null
                                    file.uploadError = true
                                    filesProcessed++
                                    if filesProcessed is files.length
                                        state = STATE.COMPLETE
                                        filesProcessed = 0
                                        #
                                        # if there is an error, we still want to return
                                        # the results, as there are multple elements, it
                                        # is up to the reciever to determine the success
                                        # of the uploads...
                                        #
                                        deferred.resolve results
                    # just return the promise object if
                    # array results are required...
                    deferred.promise
                isIdle: ->
                    state is STATE.IDLE
                isSelected: ->
                    state is STATE.SELECTED
                isUploading: ->
                    state is STATE.UPLOADING
                isComplete: ->
                    state is STATE.COMPLETE
            }
    }

'use strict'

angular.module 'nn.user-select.directives', []

    .directive 'nnUserSelect', ->
        templateUrl: '/templates/user-select.html'
        restrict: 'E'
        scope: true
        controller: ($scope, $attrs, $cookieStore, User, USER_SELECT_MSG) ->
            $scope.cookie = 'savedUserId'
            # restore previously selected.value user
            restoreFromCookie = ->
                savedUserId = $cookieStore.get $scope.cookie
                savedUser = user for user in $scope.users when user.id is savedUserId
                if savedUser?
                    $scope.selected.value = savedUser
                else if $attrs.autoSelect? and $scope.users?.length
                    $scope.selected.value = $scope.users[0]
            # save selected.value user
            saveToCookie = ->
                $cookieStore.put $scope.cookie, $scope.selected.value
            clearCookie = ->
                $cookieStore.put $scope.cookie, ''
            # load users
            loadUsers = (organisationId) ->
                $scope.placeholderMsg = USER_SELECT_MSG.LOADING
                if $attrs.options?
                    # show all users
                    $scope.users = $scope.options
                    $scope.placeholderMsg = USER_SELECT_MSG.CHOOSE
                    restoreFromCookie()
                else
                    # retrieve the users list
                    User.getMembers(organisationId).then (users) ->
                        $scope.users = users
                        $scope.placeholderMsg = $scope.placeholder or USER_SELECT_MSG.CHOOSE
                        restoreFromCookie()
                    , (errorMsg) ->
                        $scope.placeholderMsg = errorMsg
            # clear selected.value user
            $scope.clear = ($event) ->
                $event.stopPropagation()
                $scope.selected.value = undefined
                $scope.users = []
                clearCookie()
                loadUsers()
            # init
            $scope.changeUser = ->
                saveToCookie()

            if $scope.organisationId
              loadUsers($scope.organisationId)

            $scope.$watch("organisationId", (ov, nv) ->
              if (nv && (ov != nv))
                loadUsers(nv)
            )


    .constant 'USER_SELECT_MSG',
        LOADING: 'Loading users...'
        CHOOSE: 'Choose a user'

###

 Form module
 ============
 Controls the main form. This consists of a singleton
 model, and a main controller. The model consists of
 a list of objects and an index counter. The objects
 can be added and removed through the view + controller.
 Most of the functions are on the model, as this
 model is manipulated by other controllers including
 the Menu and the Field Menu.

###
angular.module "nn.form", [
    "nn.form-field"
    "nn.form-storage"
]
#
# Form model as a singleton. Here we use a factory for a singleton
# instead of a service as we can pull in the dependencies at the
# top, rather than through individual functions.
#
# Methods are in here to allow manipulation by storage and menu controllers...
#
.factory "FormModel", (FieldModel, FieldTypes, FieldUtil, StorageService, FormTypes, $timeout, Auth) ->
    new class FormModel
        #
        # FormModel constructors...
        #
        constructor: () ->
            #
            # FormModel elements...
            # ======================
            # Array of fields.
            @fields = []
            # URL id
            @id = -1
            # Submit id
            @submitId = -1
            # index for the current DOM selection
            @selectedIndex = 0
            # the currently selected item...
            @selectedItem = null
            # testing edit mode
            @editMode = false
            # whether this fields data is presentable or not...
            @enabled = false
            # factory
            @fieldFactory = new FieldModel
            # messages
            @msg = ""
            @showMessage = false
            @msgOK = true
            # call the initialization on instantiation...
            @initialize()
            return
        #
        # FormModel methods...
        # ====================
        #
        # This method to selects the item to edit.
        # Selection simply implies which field the edit
        # panel refers to...
        #
        selectItem: (index) ->
            @selectedIndex = index
            @_updateSelection()
            return
        #
        # Is the form connected to a URL
        #
        isValid: () ->
            return @id != -1
        #
        # Is the form connected to a submission or not...
        #
        isSubmitValid: () ->
            return @submitId != -1
        #
        # Reset the form id
        #
        resetId: () ->
            @id = -1
        #
        # Reset the submit id
        #
        resetSubmitId: () ->
            @submitId = -1
        #
        # Return the current form name assigned. This
        # uses the special title element, which is fixed
        # to the first element...
        #
        getName: () ->
            if @fields.length
                f = @fields[0]
                return f.elements.name.data
            else
                return ""
        #
        # Return the current form name assigned. This
        # uses the special title element, which is fixed
        # to the first element...
        #
        getTags: () ->
            if @fields.length
                f = @fields[0]
                f.elements.tags.showData
            else
                return ""
        #
        # Return the current form name assigned. This
        # uses the special title element, which is fixed
        # to the first element...
        #
        getOrg: () ->
            if @fields.length
                f = @fields[0]
                return f.elements.org.data
            else
                return ""
        #
        # Return the current form name assigned...
        #
        getDesc: () ->
            if @fields.length
                f = @fields[0]
                return f.elements.desc.data
            else
                return ""
        #
        # Helper function to update the current selection...
        #
        _updateSelection: () ->
            if !@fields.length
                return
            if @selectedIndex >= @fields.length
                return
            if @selectedIndex < 0
                @selectedIndex = 0
            #
            # Now simply select the correct item...
            #
            @selectedItem = @fields[@selectedIndex]
            return
        #
        # Updates all the field elements
        #
        refreshFields: () ->
            for item in @fields
                item.updateCheck()
            return
        #
        # Checks that all the fields are valid
        #
        fieldsValid: () ->
            for item in @fields
                if item.invalid.status
                    return false
            return true
        #
        # Add the Field widgets to the object list...
        #
        addField: (obj) ->
            fm = {}
            # first we add a blank field object...
            try
                fm = @fieldFactory.create obj
            catch err
                console.log "Failed to add field. " + err
                throw "Failed to add field. " + err
            #
            # Here we add the field into the main form
            # field array...
            #
            @fields.push fm
            return
        #
        # Helper function to get the last added object...
        #
        _lastField: () ->
            @fields[@fields.length - 1]
        #
        # Remove item functionality...
        #
        removeField: (index) ->
            #
            # can't delete the title field...
            #
            if index == 0
                return
            #
            # if you've removed the selected field,
            # then default to the title field...
            #
            if index is @selectedIndex
                @selectedIndex = 0
            else @selectedIndex -= 1 if index < @selectedIndex
            # if the index is ok, remove it...
            @fields.splice index, 1 if index > -1
            # if there is nothing left, reset the selectedIndex...
            # @selectedIndex = -1 if @fields.length is 0
            @_updateSelection()
            return
        #
        # Delete all the fields in this field...
        #
        clearFields: () ->
            @fields = []
            @selectedIndex = 0
            @index = 0
            @_updateSelection()
            return
        #
        # Message blob
        #
        message: (msg, isOK = true) ->
            @showMessage = true
            @msg = msg
            @msgOK = isOK
            self = @
            $timeout () ->
                self.showMessage = false
            , 3000
        #
        # Construct the form from an object. This is used
        # when loading a form...
        #
        inflate: (obj) ->
            # Here we expect the object to have the
            #     following look:
            #      {
            #       form_def: {
            #           id
            #           type
            #           elements: {
            #               name1: data1,
            #               ....
            #           }
            #        },
            #       form_meta : {
            #           name:
            #           organization:
            #           url:
            #           created_on:
            #           updated_on:
            #       }
            #      }
            #      and need to turn it to...
            #      {
            #       name: <search_meta>
            #       organization: <payload>
            #       fields: {
            #          type: fieldType,
            #          display: {},
            #          id: self.index,
            #          elements: {
            #               fieldObject1,
            #               fieldObject2,
            #                ...
            #            },
            #          elemList: {}
            #        }
            #      }
            #
            throw "Failed to parse meta data." unless obj.form_meta
            throw "Failed to parse form data." unless obj.form_def
            meta = angular.fromJson obj.form_meta
            data = angular.fromJson obj.form_def
            # first clear what we currently have...
            @clearFields()
            # the URL id value
            @id = obj.id
            # collect the created stats...
            @created_on = meta.created_on
            @created_by = meta.created_by
            # now start rebuilding the fields
            for item in data
                f = {}
                try
                    typ = FieldUtil.getFieldType item.type
                    if typ
                        @addField typ
                    else
                        throw "Illegal type."
                catch err
                    console.log "Illegal type found: " + item.type
                    throw "Illegal type"
                # now populate the field data with the actual data...
                f = @_lastField()
                # just check that there are actually fields there...
                break unless f
                # now populate the element's data with the server
                # data...
                for j of f.elements
                    f.elements[j].data = item.elements[j]
                    # refresh the element...
                    f.elements[j].initialize()
                f.id = item.id
                f.updateCheck()
            throw "Failed to reconstruct fields." if Object.keys(data).length isnt @fields.length
            # enable the form...
            @enabled = true
            return
        #
        # Load model from server...
        #
        load: (id) ->
            # use the storage service to grab the
            # objects...
            self = @
            promise = StorageService.load id, FormTypes.TEMPLATE
            promise.then (layout) ->
                self.inflate layout
                self.selectItem(0)
                return
            , (reason) ->
                self.message "Failed to collect template data.", false
                console.log "Failed: " + reason
                return
            return
        #
        # This is the submission load service. From a single
        # submit id, this function must:
        #  - grab the form data JSON from the backend
        #  - extract the template id
        #  - grab the template JSON from the backend
        #  - load the template model
        #  - load the submission model
        #
        loadSubmission: (id) ->
            #
            # First we grab the submission JSON blob from the
            # backend...
            #
            promise = StorageService.load id, FormTypes.SUBMIT
            submitData = {}
            self = @
            promise.then (payload) ->
                #
                # Once we've recieved the submit data, we extract
                # the id of the parent template...
                #
                parent_id = StorageService._getID payload.nnform
                #
                # store the submitData for later use...
                #
                submitData = payload
                #
                # Now we need to grab the template from the server
                # using the parent id
                #
                return StorageService.load parent_id, FormTypes.TEMPLATE
            , (reason) ->
                self.message "Failed to load submission data.", false
                console.log "Failed: " + reason
                return
            .then (layout) ->
                #
                # Now that we have both the template and the submit
                # data we can build the model and insert the submit
                # data...
                #
                if layout
                    # First load the relevant template...
                    self.inflate layout
                    # Next populate the data from this submission...
                    self.inflateData submitData
            , (reason) ->
                self.message "Failed to load the template data", false
                console.log "Failed again."
            return
        #
        # Strips out unnecessary entries for fields.
        # This is used for storing on the server. Much
        # of the form data stored is for display purposes,
        # and this is not required to be stored on the server...
        #
        deflate: () ->
            # Here we expect the object to have the
            #      following look:
            #        {
            #          name: <search_meta>
            #          organization: <payload>
            #          fields: {
            #             type: fieldType,
            #             display: {},
            #             id: self.index,
            #             elements: {
            #                   fieldObject1,
            #                   fieldObject2,
            #                   ...
            #               },
            #             elemList: {}
            #           }
            #        }
            #       and need to turn it to...
            #       {
            #         form_def: {
            #           id
            #           type
            #           elements: {
            #               name1: data1,
            #               ....
            #           }
            #         form_meta : {
            #           name:
            #           organization:
            #           url:
            #           created_on:
            #           updated_on:
            #         }
            #       },
            #    }
            head = @_buildHeader()
            fields = @_buildFields()
            self = @
            # load user data...
            Auth.user().then (usr) ->
                #
                # add the dates to the JSON blob
                #
                self._buildDates(head, usr)
                #
                # Now we can construct the result...
                #
                result =
                    form_meta: head
                    form_def: fields
                if self.isValid()
                    # this is an update...
                    result.id = self.id
                result
        #
        # Helper function to build the header for the
        # deflated data...
        #
        _buildHeader: () ->
            head = {}
            # the header fields consist of a name, date
            # and organization field...
            head.name = @getName()
            head.organization = @getOrg()
            head.desc = @getDesc()
            head.tags = @getTags()
            head
        #
        # Helper function to build the deflated fields
        # to be stored...
        #
        _buildFields: () ->
            # now if fields are available, we strip out the
            # necessary data...
            fields = [] # main data blob...
            if @fields
                # we want the same fields, though we only want
                # the type id and the element data.
                for field in @fields
                    f = {}
                    # grab type and id off the object...
                    f.type = field.type
                    f.id = field.id
                    # now pull the data out of the editor object...
                    f.elements = {}
                    for key of field.elements
                        f.elements[key] = field.elements[key].data
                    # Add to the field storage and reset the temp object...
                    fields.push f
            fields
        #
        # Add the dates to the existing header object...
        #
        _buildDates: (header, user, submit = false) ->
            header.updated_on = (new Date()).toISOString()
            header.updated_by = user
            # we only need to set the created on if it does
            # not exist...
            if submit and @isSubmitValid()
                header.created_by = @submitted_by
                header.created_on = @submitted_on
            else if !submit and @isValid()
                header.created_by = @created_by
                header.created_on = @created_on
            else
                # this is the only case that requires that
                # we refresh the created on...
                header.created_by = header.updated_by
                header.created_on = header.updated_on
            return
        #
        # This function pulls the current submission data
        # from the form for the submission server. Note that
        # the ID is not the full URL. This must be added
        # by the storage provider...
        #
        deflateData: () ->
            self = @
            Auth.user().then (usr) ->
                data = {}
                #
                # data.results = {'f8ggwifg': 'Field one entry', 'hgou34ihdg': 'Field two entry'}
                # data.created_on
                # data.created_by
                # data.updated_on
                # data.updated_by
                # data.desc
                # data.tags
                # data.name
                #
                # first build
                #
                data = self._buildHeader()
                #
                # Next we build up the object submission map...
                #
                store = {}
                for field in self.fields
                    store[field.id] = field.data
                data.results = store
                #
                # add the user/submit dates
                #
                self._buildDates data, usr, true
                #
                # return the payload as a promise...
                #
                payload =
                    nnform: self.id
                    form_data: data
                payload
        #
        # This needs to re-populate the current
        # form and update.
        #
        inflateData: (obj) ->
            map = {}
            f = @fields
            if !obj.form_data
                console.log "Failed to build data"
                return
            @submitId = obj.id
            # pull the data out as a JS object...
            data = angular.fromJson obj.form_data
            @submitted_on = data.created_on
            @submitted_by = data.created_by
            # first add the fields to the
            # hash table with the id as a key
            # and the index as the value...
            for i of f
                map[f[i].id] = i
            # now go through the submitted object
            # and add into the fields...
            # console.log @fields
            for key of data.results
                index = map[key]
                # now populate the submission list...
                if @fields[index]
                    @fields[index].data = data.results[key]
                else
                    console.log "Failed to find index " + key + " : " + index
            # now refresh the checkers...
            @refreshFields()
            @_updateSelection()
            return
        #
        # note that we will have a default object that
        # holds the title and organization...
        #
        setTitle: (title, org) ->
            if @fields.length
                f = @fields[0]
                f.elements.title.data = title
                f.elements.org.data = org
        #
        # Initialization. Here we add the default title
        # element and update the selection.
        #
        initialize: () ->
            @enabled = false
            @id = -1
            @submitId = -1
            @clearFields()
            @addField FieldTypes.TITLE
            @_updateSelection()

#
# FormItem directive. This controls the DOM display for the
# different types of form elements that can be added to the
# main view ...
#
.directive "nnFormField", ($http, $compile, FieldTypes, FieldUtil, $templateCache) ->
    # On initialization, we want to index the urls of
    # the field templates by type...
    item = 0
    url = ""
    type = {}
    #
    # Linker to load the templates. Here the attribute
    # tells the linker which url template to load...
    #
    linker = (scope, element, attrs) ->
        type = FieldUtil.getFieldType(scope.field.type)
        throw "Illegal type found" unless type
        # Depending on the mode of the application,
        # we choose which template to show on the
        # screen...
        if scope.editMode
            url = type.editTemplate
        else
            url = type.viewTemplate
        # the editable item object for use in the templates,
        # just for brevity
        scope.elements = scope.field.elements
        # This function is used so that we can
        # manually update the 'invalid' status
        # of the current field.
        scope.updateCheck = (fm) ->
            fm.updateCheck()
            return
        # if the url is valid, load the template...
        if url
            $http
                method: 'GET'
                url: url
                cache: $templateCache
                auth: true
            .success (data) ->
                element.html data
                $compile(element.contents()) scope
                return
            .error (data) ->
                console.log "Failed to locate resource " + url
                return
        return
    return (
        restrict: "E"
        scope:
            field: "="
            editMode: "="
        link: linker
    )

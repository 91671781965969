'use strict'

angular.module 'nn.file-list.directives', []

.directive 'nnFileList', ->
  templateUrl: '/templates/file-list.html'
  restrict: 'E'
  scope:
      files: '='
      filter: '='
  controller: ($filter, $scope, $attrs, $state, Explorer, PageBuilderModuleTypes, Favourites, FavouritesType) ->
    $scope.files?.search() if $attrs.refresh?
    $scope.favouritesType = FavouritesType

    $scope.isActive = (file) ->
        $state.includes "**.file.**", file:file.id.toString()

    $scope.select = (file) ->
      Explorer.data.collapsed = true
      $state.go 'app.files.file', file:file.id

    Favourites.all('files').then (data) ->
        $scope.favourites = data

'use strict'

angular.module 'nn.dragndrop.directives', []

.directive 'draggable', ->
  restrict: 'A'
  link: (scope, element, attrs) ->
    domElement = element[0]
    effectAllowed = attrs.effectAllowed
    draggableData = attrs.draggableData
    draggableType = attrs.draggableType
    draggable = (if attrs.draggable is 'false' then false else true)
    domElement.draggable = draggable
    return  unless draggable
    domElement.addEventListener 'dragstart', (e) ->
      e.stopPropagation()
      e.dataTransfer.effectAllowed = effectAllowed or e.dataTransfer.effectAllowed
      data = scope.$eval(draggableData)
      jsonData = angular.toJson(data)
      e.dataTransfer.setData 'json/' + draggableType, jsonData

.directive 'drop', ($parse) ->
  restrict: 'A'
  link: (scope, element, attrs) ->
    dragOverListener = (event) ->
      return false  unless accepts(attrs.dropAccept, event)
      element.addClass dragOverClass  if dragOverClass
      event.dataTransfer.dropEffect = dropEffect or event.dataTransfer.dropEffect
      scope.$apply ->
        dragOverHandler scope,
          $event: event
      event.preventDefault()

    dropListener = (event) ->
      data = getData(event)
      removeDragOverClass()
      scope.$apply ->
        dropHandler scope,
          $data: data
          $event: event
      event.preventDefault()

    removeDragOverClass = ->
      element.removeClass dragOverClass

    accepts = (type, event) ->
      return type  if typeof type is 'boolean'
      return accepts(type.toString().split(','), event) if typeof type is 'string'
      if Array.isArray(type)
        return accepts((types) ->
          types.some (_type) ->
            type.indexOf(_type) isnt -1
        , event)
      return type(toArray(event.dataTransfer.types))  if typeof type is 'function'
      false

    getData = (event) ->
      types = toArray(event.dataTransfer.types)
      types.reduce (collection, type) ->

        data = event.dataTransfer.getData(type)

        format = /(.*)\//.exec(type)
        format = (if format then format[1] else null)

        data = JSON.parse(data)  if format is 'json'
        collection[type] = data
        collection
      , {}

    toArray = (collection) ->
      Array::slice.call collection

    domElement = element[0]
    dropEffect = attrs.dropEffect
    dropAccept = attrs.dropAccept
    dragOverClass = attrs.dragOverClass
    dragOverHandler = $parse attrs.dragOver
    dropHandler = $parse attrs.drop

    domElement.addEventListener 'dragover', dragOverListener
    domElement.addEventListener 'drop', dropListener
    domElement.addEventListener 'dragleave', removeDragOverClass

    scope.$on '$destroy', ->
      domElement.removeEventListener 'dragover', dragOverListener
      domElement.removeEventListener 'drop', dropListener
      domElement.removeEventListener 'dragleave', removeDragOverClass
'use strict'

angular.module 'nn.page-tops.directives', []

  .directive 'nnPageTops', (PageFurnitureSections, PageFurnitureIdentifiers) ->
    restrict: 'E'
    scope:
      layout: '='
      tops: '='
      type: '='
      side: '@?'
    templateUrl: '/templates/page-tops.html'
    link: (scope) ->
      scope.sectionNames = Object.keys(PageFurnitureSections).sort()
      scope.sections = PageFurnitureSections
      scope.types = PageFurnitureIdentifiers
      scope.banners = null
      checkType = ->
        switch scope.type
          when PageFurnitureIdentifiers.PAGE_BANNER
            scope.banners = scope.sections[scope.tops.title]
          when PageFurnitureIdentifiers.SECTION_FRONT
            scope.banners = scope.sectionNames
          else
            scope.banners = null

      scope.$watchGroup ['type','tops.title'], -> checkType()

  .directive 'nnPageTopInput', ->
    restrict: 'E'
    scope:
      data: '='
      type: '@'
      label: '@'
      options: '='
      isEditable: '='
      change: '&onChange'
      reset: '&onReset'
    templateUrl: '/templates/page-top-input.html'

  .directive 'uiSelectWrapper', ($timeout) ->
    restrict: 'A'
    scope: true
    link: (scope, element, attrs) ->
      uiSelectCtrl = element.find('.select2').controller 'ui-select'
      scope.selectChoicesVisible = false
      scope.toggleSelectChoices = ->
        if scope.selectChoicesVisible = !scope.selectChoicesVisible
          $timeout ->
            uiSelectCtrl.activate()
        else
          uiSelectCtrl.close()
      scope.onSelectClose = ->
        if scope.selectChoicesVisible
          scope.toggleSelectChoices()
  .directive 'clickOutside', ($document) ->
    restrict: 'A'
    scope:
      clickActive: '='
    link: (scope, element, attrs) ->
      onClick = (event) ->
        isChild = $(element).has(event.target).length > 0
        isSelf = element[0] == event.target
        isInside = isChild || isSelf
        if (!isInside)
          scope.$apply () -> scope.clickActive = false

      scope.$watch('clickActive', (newValue, oldValue) ->
        if newValue != oldValue && newValue == true
          $document.bind 'click', onClick
        else if (newValue != oldValue && newValue == false)
          $document.unbind 'click', onClick
      )
'use strict'

angular.module 'nn.task.assignd', []

.directive 'nnTaskAssigned', (PageBuilderModuleTypes, TaskService) ->
  templateUrl: '/templates/task-assigned.html'
  restrict: 'E'
  scope: { taskForm: '=', taskId: '=' }
  controller: ($scope, $timeout) ->
    $scope.moduleTypes = PageBuilderModuleTypes
    $scope.taskInst = TaskService

    taskSuccess = (task) ->
      $scope.task = task

    taskError = (error) ->
      $scope.error = true
      $scope.errorMsg = error

    $scope.remove = ->
      $scope.taskInst.remove($scope.taskId)
      $scope.taskForm.$invalid = false
      $scope.taskForm.$error = {}
      $scope.taskForm.storyid.$invalid = false
      $scope.taskId = null

    $scope.taskInst.one($scope.taskId).then taskSuccess, taskError

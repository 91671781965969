'use strict'

angular.module 'nn.common.filters', []

  .filter 'titleCase', ->
    (input) ->
      input.charAt(0).toUpperCase() + input.slice(1).toLowerCase() if input

  .filter 'properCase', ->
    (input) ->
      if input?
        input.replace /\w\S*/g, (txt) -> txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      else
        input

  .filter 'trustUrl', ($sce) ->
    (url) ->
      $sce.trustAsResourceUrl url

  .filter "reverse", ->
    (input) ->
      input.slice().reverse()

  .filter 'nnStoryStatus', (STORY_STATUS) ->
    { DRAFT, WAITING, APPROVED  } = STORY_STATUS
    (status) ->
      switch status
        when DRAFT.value
          DRAFT.label
        when WAITING.value
          WAITING.label
        when APPROVED.value
          APPROVED.label
        else
            ''

  .filter 'nnStoryChannel', (STORY_CHANNEL) ->
    { ALL, WEB, PRINT } = STORY_CHANNEL
    (channels) ->
      return '' if !channels?
      if channels.length > 1
        ALL.label
      else if channels[0] is WEB.value
        WEB.label
      else if channels[0] is PRINT.value
        PRINT.label
      else
        ''

  .filter 'nnStoryChannelToken', (STORY_CHANNEL, STORY_STATUS) ->
    { ALL, WEB, PRINT } = STORY_CHANNEL
    statusTitles = {
        "published": "- approved",
        "waiting": "- ready-for-approval",
        "in_progress": ""
    }
    (snapshot) ->
      return '' if !snapshot.available_to?
      { available_to, status } = snapshot
      availableToWeb = if WEB.value in available_to then 'available' else ''
      availableToPrint = if PRINT.value in available_to then 'available' else ''
      webTitle = 'Web' + (if availableToWeb then " #{statusTitles[status]}" else '')
      printTitle = 'Print' + (if availableToPrint then " #{statusTitles[status]}" else '')
      str = ''
      str += "<div class='token web #{status} #{availableToWeb}' title='#{webTitle}'>w</div>"
      str += "<div class='token print #{status} #{availableToPrint}' title='#{printTitle}'>p</div>"
      str

  .filter 'nnStoryVisibility', (STORY_VISIBILITY) ->
    { PUBLIC, RETRACTED } = STORY_VISIBILITY
    (visibility) ->
      switch visibility
        when PUBLIC.value
          PUBLIC.label
        when RETRACTED.value
          RETRACTED.label
        else
          ''

  .filter 'nnUserFullName', ->
    (details) ->
      if details?.first_name? and details?.last_name?
        "#{details.first_name} #{details.last_name}"
      else if details?.first_name?
        details.first_name
      else
        ''

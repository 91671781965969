'use strict'

angular.module 'nn.task.flag', []

  .directive 'nnTaskFlag', (Roles) ->
    template: '''
      <span class="list-group-item">
        <div class="media-left">
          <span class="fa-stack fa-lg">
            <i ng-class="{'fa-flag redColor': task.flagged, 'fa-flag': !task.flagged}" class="fa fa-stack-1x"></i></span>
        </div>
        <div class="media-body redflagged-switch">
          <label class="page-element-label">
            <span>Red flag this task</span>
            <div class="switch redflag  inline-block-i">
              <input
                type="checkbox"
                id="flagged"
                name="flagged"
                ng-model="task.flagged"
                ng-change="checkPristine()"
                ng-disabled="flagDisabled()"
                class="cmn-toggle cmn-toggle-round-flat">
              <label class="left redflag-label nomargin" for="flagged"></label>
            </div>
          </label>
        </div>
      </span>
      <!--<list-item ng-if="task.flagged" ng-init="task.notes ? '' : task.notes = []">
        <textarea
          name="flagdescription"
          rows="2"
          placeholder="Why was the task Red Flagged?"
          ng-model="task.notes[0].text"
          ng-init="task.notes[0].type = 'red_flagged'"
          ng-readonly="flagDisabled()"
          class="text-lg description nomargin">
        </textarea>
      </list-item>-->

    '''
    restrict: 'E'
    link: (scope) ->
      scope.checkPristine = ->
        if scope.origTaskData?.flagged is scope.task.flagged
          scope.task.notes = angular.copy scope.origTaskData.notes
          scope.taskInfo.flagged.$setPristine()
        else if not scope.task.flagged
          scope.task.notes = []

      scope.flagDisabled = ->
        cantUnflagFlaggedTask = scope.origTaskData?.flagged && !Roles.can('Unflag')
        scope.task.flagged and (scope.readOnly or cantUnflagFlaggedTask)

'use strict'

angular.module 'nn.story-manager.routes', []

    .config ($stateProvider) ->

        $stateProvider

            .state 'app.stories',
                abstract: true
                url: ''
                resolve:
                    acl: (Roles, $q) ->
                        checkRole = ->
                            if !Roles.canGo('stories') then $q.reject 'unauthorized'
                        if Roles.hasRoles() then checkRole() else Roles.attachRoles().then -> checkRole()
                onEnter: (AppState, Sections) ->
                    AppState.section = Sections[2]
                onExit: (AppState) ->
                    if AppState.story.activeSubscription
                        AppState.story.activeSubscription.unsubscribeAll()
                        AppState.story = {}

            .state 'app.stories.new',
                url: 'story/new'
                onEnter: (AppState) ->
                    AppState.canvas = 'story'
                    AppState.story = {}

            .state 'app.stories.new.forTask',
                url: '/task/:task'
                onEnter: ($stateParams, AppState) ->
                    AppState.canvas = 'story'
                    AppState.story = {}
                    AppState.story = task: Number($stateParams.task)

            .state 'app.stories.new.clone',
                url: '/{story:[0-9]*}'
                onEnter: ($state, $stateParams, AppState, Story) ->
                    AppState.canvas = 'story'
                    AppState.story = {}
                    Story.clone($stateParams.story).then (clonedStory) ->
                        $state.go 'app.stories.story', story:clonedStory.id

            .state 'app.stories.story',
                url: 'story/{story:[0-9]*}'
                onEnter: ($stateParams, AppState) ->
                    AppState.canvas = 'story'
                    AppState.story =
                        id: $stateParams.story

            .state 'app.stories.story.snapshot',
                url: '/snapshot/{snapshot:[0-9]*}'
                onEnter: ($stateParams, AppState) ->
                    AppState.canvas = 'story'
                    AppState.story =
                        id: $stateParams.story
                        snapshot: $stateParams.snapshot



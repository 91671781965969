'use strict'

###
    Usage:
    Add this <script> tag to the <head> of main template:
    <script nn-new-relic></script>
###

angular.module 'nn.newrelic', []

    .constant 'NEW_RELIC_LICENSE_KEY',
        PRODUCTION: 'b2bc89fc76'
        UAT: '5a3c9b8aba'
        STAGING: '5a3c9b8aba'
        DEV: '5a3c9b8aba'

    .constant 'NEW_RELIC_APP_ID',
        PRODUCTION: '99152831'
        UAT: '6080283'
        STAGING: '67137127'
        DEV: '67137252'

    .constant 'NEW_RELIC_API_KEY',
        PRODUCTION: 'd57aa2274b869a5f1ad7db58279c9d781b39edeb44d3d3e'
        UAT: '3af46fe37c009582051a50c5f538e21727344cda2dbc3f5'
        STAGING: '3af46fe37c009582051a50c5f538e21727344cda2dbc3f5'
        DEV: '3af46fe37c009582051a50c5f538e21727344cda2dbc3f5'

    .factory 'NewRelicAppID', (Environment, ENVIRONMENTS, NEW_RELIC_APP_ID) ->
        switch Environment
            when ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.PRODUCTION_INTERNAL, ENVIRONMENTS.PRODUCTION_TEMP, ENVIRONMENTS.PRODUCTION_DR
                NEW_RELIC_APP_ID.PRODUCTION
            when ENVIRONMENTS.UPDATE, ENVIRONMENTS.UPDATE_INTERNAL
                NEW_RELIC_APP_ID.PRODUCTION
            when ENVIRONMENTS.UAT, ENVIRONMENTS.TRAINING, ENVIRONMENTS.UAT2, ENVIRONMENTS.UAT3
                NEW_RELIC_APP_ID.UAT
            when ENVIRONMENTS.STAGING, ENVIRONMENTS.STAGING2
                NEW_RELIC_APP_ID.STAGING
            when ENVIRONMENTS.DEV, ENVIRONMENTS.DEV2
                NEW_RELIC_APP_ID.DEV
            else
                NEW_RELIC_APP_ID.DEV

    .factory 'NewRelicLicenseKey', (Environment, ENVIRONMENTS, NEW_RELIC_LICENSE_KEY) ->
        switch Environment
            when ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.PRODUCTION_INTERNAL, ENVIRONMENTS.PRODUCTION_TEMP, ENVIRONMENTS.PRODUCTION_DR
                NEW_RELIC_LICENSE_KEY.PRODUCTION
            when ENVIRONMENTS.UPDATE, ENVIRONMENTS.UPDATE_INTERNAL
                NEW_RELIC_LICENSE_KEY.PRODUCTION
            when ENVIRONMENTS.UAT, ENVIRONMENTS.TRAINING, ENVIRONMENTS.UAT2, ENVIRONMENTS.UAT3
                NEW_RELIC_LICENSE_KEY.UAT
            when ENVIRONMENTS.STAGING, ENVIRONMENTS.STAGING2
                NEW_RELIC_LICENSE_KEY.STAGING
            when ENVIRONMENTS.DEV, ENVIRONMENTS.DEV2
                NEW_RELIC_LICENSE_KEY.DEV
            else
                NEW_RELIC_LICENSE_KEY.DEV

    .factory 'NewRelicAPIKey', (Environment, ENVIRONMENTS, NEW_RELIC_API_KEY) ->
        switch Environment
            when ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.PRODUCTION_INTERNAL, ENVIRONMENTS.PRODUCTION_TEMP, ENVIRONMENTS.PRODUCTION_DR
                NEW_RELIC_API_KEY.PRODUCTION
            when ENVIRONMENTS.UPDATE, ENVIRONMENTS.UPDATE_INTERNAL
                NEW_RELIC_API_KEY.PRODUCTION
            when ENVIRONMENTS.UAT, ENVIRONMENTS.TRAINING, ENVIRONMENTS.UAT2, ENVIRONMENTS.UAT3
                NEW_RELIC_API_KEY.UAT
            when ENVIRONMENTS.STAGING, ENVIRONMENTS.STAGING2
                NEW_RELIC_API_KEY.STAGING
            when ENVIRONMENTS.DEV, ENVIRONMENTS.DEV2
                NEW_RELIC_API_KEY.DEV
            else
                NEW_RELIC_API_KEY.DEV

    .config ($provide) ->
        if NREUM?
            $provide.decorator '$exceptionHandler', ($delegate) ->
                (exception, cause) ->
                    $delegate exception, cause
                    NREUM.noticeError exception

    .directive 'nnNewRelic', (NEW_RELIC_LICENSE_KEY, NewRelicAppID, NewRelicLicenseKey) ->
        restrict: 'A'
        scope: {}
        link: (scope, element) ->
            NREUM?.info =
                licenseKey: NewRelicLicenseKey
                applicationID: NewRelicAppID
                sa: 1
                beacon: "bam.nr-data.net"
                errorBeacon: "bam.nr-data.net"
                agent: "js-agent.newrelic.com/nr-spa-1130.min.js"

'use strict'

angular.module 'nn.file-explorer.directives', []

    .directive 'nnFileExplorer', ->
        templateUrl: '/templates/file-explorer.html'
        restrict: 'E'
        scope: {}
        controller: ($scope, AppState, FileSections, Favourites, Explorer, FileExplorer, Auth, Files) ->
            $scope.sections = FileSections
            $scope.state = FileExplorer
            Auth.user().then (user) =>
                files = Files.getInstance()
                files.params().user = {value:user.id}
                files.search()
                $scope.userFiles = files
            Favourites.all('files').then (data) ->
                $scope.favourites = data
            $scope.selectSection = (section) ->
                FileExplorer.menuItems = true
                FileExplorer.menuItemSelected = FileSections.indexOf(section)
            $scope.back = ->
                FileExplorer.menuItems = false
                FileExplorer.menuItemSelected = null

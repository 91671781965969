'use strict'

angular.module 'nn.report-viewer', [
        'nn.report-viewer.directives'
    ]

.constant 'REPORT_FIELDS', {
    'publication':
        name: 'Publication'
    'page_count':
        name: 'Page Count'
    'stories_web':
        name: 'Digital'
    'stories_print':
        name: 'Print'
    'stories_web_print':
        name: 'Digital & Print'
    'red_flagged_stories':
        name: 'Redflags'
    'different_orgs':
        name: 'Shared With'
        tooltip: 'Shared with multiple mastheads'
    'multi_target_lists':
        name: 'Sources'
        tooltip: 'Different source (volume of shared content)'
}
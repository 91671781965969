'use strict'

angular.module 'nn.notification.directives', []

.directive 'nnNotificationContainer', (PubSub, $compile, $timeout, $sce, NotificationConfig, NotificationService) ->
  replace: true
  restrict: 'EA'
  scope: true
  templateUrl: '/templates/notification.html'
  link: (scope, elm, attrs) ->
    addNotification = (notification) ->
      notification.type = mergedConfig['icon-classes'][notification.type]
      notification.type = mergedConfig['icon-class']  unless notification.type
      id++
      angular.extend notification, id: id

      notification.bodyOutputType = notification.bodyOutputType or mergedConfig['body-output-type']

      switch notification.bodyOutputType
        when 'trustedHtml'
          notification.html = $sce.trustAsHtml notification.body
        when 'trustedHtmlCustomAction'
          notification.html = $sce.trustAsHtml notification.body
        when 'template'
          notification.bodyTemplate = notification.body or mergedConfig['body-template']

      scope.configureTimer notification

      if mergedConfig['newest-on-top'] is true
        scope.notifications.unshift notification
        scope.notifications.pop()  if mergedConfig['limit'] > 0 and scope.notifications.length > mergedConfig['limit']
      else
        scope.notifications.push notification
        scope.notifications.shift()  if mergedConfig['limit'] > 0 and scope.notifications.length > mergedConfig['limit']

    setTimeout = (notification, time) ->
      notification.timeout = $timeout ->
        notification.deferred.reject()
        scope.removeNotification notification.id
      , time

    id = 0

    mergedConfig = angular.extend {}, NotificationConfig, scope.$eval(attrs.notificationOptions)

    scope.config =
      position: mergedConfig['position-class']
      title: mergedConfig['title-class']
      message: mergedConfig['message-class']
      tap: mergedConfig['tap-to-dismiss']
      closeButton: mergedConfig['close-button']

    scope.configureTimer = configureTimer = (notification) ->
      timeout = (if typeof (notification.timeout) is 'number' then notification.timeout else mergedConfig['time-out'])
      setTimeout notification, timeout  if timeout > 0

    scope.notifications = []

    PubSub.subscribe 'Notifications', (event, data) ->
      if event == 'newNotification'
        addNotification data
      else if event == 'clearNotifications'
        scope.notifications.splice 0, scope.notifications.length

  controller: ($scope, $element, $attrs) ->
      $scope.stopTimer = (notification) ->
        if notification.timeout
          $timeout.cancel notification.timeout
          notification.timeout = null

      $scope.restartTimer = (notification) ->
        $scope.configureTimer notification  unless notification.timeout

      $scope.removeNotification = (id) ->
        $scope.notifications = $scope.notifications.filter (item) -> item.id isnt id

      $scope.click = (notification, confirmed, $event, multipleButtons) ->
        $event.stopPropagation()

        if confirmed
          notification.deferred.resolve notification:notification
        else
          notification.deferred.reject notification:notification
        $scope.removeNotification notification.id

      $scope.clickWithMultipleButtons = (notification, confirmed, $event, multipleButtons) ->
        $event.stopPropagation()

        if confirmed
          notification.deferred.resolve notification:notification, type: multipleButtons
        else
          notification.deferred.reject notification:notification
        $scope.removeNotification notification.id
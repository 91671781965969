'use strict'

angular.module 'nn.site-editor.directives', []

    .directive 'nnSiteEditor', ->
        templateUrl: '/templates/site-editor.html'
        restrict: 'E'
        scope: {
            site: '='
        }
        controller: ($scope, $sce, $window, Sites, User, Auth) ->
            $scope.siteService = Sites.getInstance()
            $scope.isAutumn = false
            # Bit of a hack due to sitebuilders implementation.
            # Need to have access to reload the page / change the iframe
            # from inside the app.
            $window.siteService = $scope.siteService
            $scope.$watch 'site', (nv, ov) ->
              if nv != ov or nv != $scope.siteService.site()
                if /(www)(.*)(com|net)/g.test(nv)
                    Auth.token().then (token) ->
                        console.log 'loading monaco site...'
                        console.log nv
                        $scope.isAutumn = true
                        $scope.src = "https://#{nv}/?m=editmode&token=#{token}"
                        $scope.markup = () -> $sce.trustAsResourceUrl($scope.src)
            
            $scope.$watch 'dyn', (nv, ov) ->
                console.log(nv, ov)
                if nv != ov or nv != $scope.siteService.site()
                    if /(www)(.*)(com|net)/g.test(nv)
                        Auth.token().then (token) ->
                            console.log 'loading autumn site...'
                            console.log nv
                            $scope.isAutumn = true
                            $scope.src = "https://#{nv}/?m=editmode&token=#{token}"
                            $scope.markup = () -> $sce.trustAsResourceUrl($scope.src)

    .directive 'nnSiteIframe', (HOSTS, User, $q) ->
        restrict: 'A'
        scope: {
            site: '='
        }
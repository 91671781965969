'use strict'

angular.module 'nn.report-manager.routes', []

    .config ($stateProvider, $urlMatcherFactoryProvider) ->

        $urlMatcherFactoryProvider.type 'dateUrlFormat',
            encode: (date) ->
                return date
            decode: (date) ->
                return date

        $stateProvider

            .state 'app.reports',
                url: 'reports'
                resolve:
                    acl: (Roles, $q) ->
                        checkRole = ->
                            if !Roles.canGo('reports') then $q.reject 'unauthorized'
                        if Roles.hasRoles() then checkRole() else Roles.attachRoles().then -> checkRole()
                onEnter: (AppState, Sections, Explorer) ->
                    AppState.section = Sections[10]
                    AppState.canvas = 'reports'

            .state 'app.reports.date',
                url: '/{range_from:dateUrlFormat}/{range_to:dateUrlFormat}?pubs',
                onEnter: ($stateParams, AppState) ->
                    AppState.reports = {}
                    AppState.reports =
                        range_from: $stateParams.range_from,
                        range_to: $stateParams.range_to
                        pubs: $stateParams.pubs

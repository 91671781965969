'use strict'

angular.module 'nn.comments-list.directives', []

  .directive 'nnCommentsList', ->
    templateUrl: '/templates/comments-list.html'
    restrict: 'E'
    scope: {
      'page':'=page'
      'limit':'=limit'
    }
    controller: ($scope, Auth) ->
      Auth.user().then (data) ->
        $scope.user = data
    	# console.log $scope.page

###

 Storage module
 ==============
 Controls the local storage. This module uses
 the browser localStorage to keep the saved
 templates...

###
angular.module("nn.form-storage", [])
#
# The StorageService controls the
# store and save into the local memory. The
# storage service maintains the store, but also the
# list of keys for the store. This saves having
# to load in all the form data when querying files...
#
.service "urls", (HOSTS) ->
    backend: "#{HOSTS.silverstone}/api/v2/forms/"
    backendList: "#{HOSTS.silverstone}/api/v2/forms/"
    backendSubmit: "#{HOSTS.silverstone}/api/v2/formdata/"
    # backend: "#{HOSTS.bathurst}/api/forms/"
    # backendList: "#{HOSTS.bathurst}/api/forms/"
    # backendSubmit: "#{HOSTS.bathurst}/api/formdata/"
#
# Http request types...
#
.constant "Requests",
    POST: "POST"
    PUT: "PUT"
#
# Form types...
#
.constant "FormTypes",
    TEMPLATE: "template"
    SUBMIT: "submit"
#
# This is a generic loader to pull down a list of objects
# from the backend. The list[] object holds the results
# of the query. The pagination URL parameters will need to
# be standardized for this to be generalizable.
#
# In addition, the pagination is not functional on the
# backend yet...
#
.factory 'ListLoader', ($http, $q, Auth, HOSTS) ->
    ITEMS_PER_PAGE = 10
    return {
        getInstance: ->
            list = []
            loading = false
            parser = (x) ->
                return x
            params =
                URL: ''
                itemsPerPage: ITEMS_PER_PAGE
                limit: ITEMS_PER_PAGE
                offset: 0
                query: ''
                status: ''
                organization: ''
            return {
                all: () ->
                    list
                setParser: (p) ->
                    parser = p
                search: (next=false) ->
                    #
                    # build the url parameter list...
                    #
                    if next
                        params.offset += params.itemsPerPage
                        # REMOVE! offset doesn't work yet, but when it does, REMOVE!
                        params.limit += params.itemsPerPage
                    else
                        params.offset = 0
                    #
                    url = "#{params.URL}?"
                    url += "&page_size=#{params.limit}"
                    url += "&offset=#{params.offset}"
                    url += "&q=#{params.query}" if params.query
                    url += "&pub_status=#{params.status.value}" if params.status?.value
                    url += "&user=#{params.user.value}" if params.user?.value
                    url += "&updated=#{params.time.value}" if params.time?.value
                    url += "&organization=#{params.organization.id}" if params.organization?.id
                    #
                    # collect the data from the server...
                    #
                    # console.log "storage.coffee >>> ", url
                    loading = true
                    $http
                        method: 'GET'
                        url: url
                        cache: false
                        auth: true
                    .success (data, status, headers, config) ->
                        loading = false
                        # console.log "storage.coffee >>> data", data
                        list = parser data
                    .error (data, status, headers, config) ->
                        loading = false
                more: ->
                    #params.offset += params.itemsPerPage
                    # params.limit += params.itemsPerPage
                    @search true
                params: ->
                    params
                isLoading: ->
                    loading
            }
    }

#
# The storage service is used to push and pull forms
# and data from the server. Here the data that is
# passed and returned is what was on the server - any
# data massaging is to be done in other layers. The
# responsibility of the StorageService is to handle
# the POST and GET requests to the backend and return
# $q promise objects where appropriate.
#
.service "StorageService", ($http, $q, urls, Auth, Requests, FormTypes, ListLoader) ->
    # The keys object is used to populate the load file
    # list. The main functions are save, load, and buildList.
    #
    # Service fields
    #
    self = this
    self.keys = [] # keys contains a list of objects with {name, url, created_on, updated_on}
    self.keysSubmit = []
    #
    # This is the template loader object
    #
    self.templateLoader = ListLoader.getInstance()
    self.templateLoader.params().URL = urls.backend
    #
    # This is the submission loader object
    #
    self.submitLoader = ListLoader.getInstance()
    self.submitLoader.params().URL = urls.backendSubmit
    #
    # Service functions...
    #
    self.save = (data) ->
        self._save data, urls.backend, Requests.POST

    self.update = (data, id) ->
        self._save data, urls.backend + id + '/', Requests.PUT
    #
    # Submit to the backend for submission...
    #
    self.submit = (data) ->
        # note that the data url address must be prefixed with
        # the full url. e.g.
        #  {
        #    nnform: 1
        #    form_data: <data>
        #  }
        # must become
        #  {
        #    nnform: http://something.com/1/
        #    form_data: <data>
        #  }
        payload = data
        payload.nnform = urls.backend + data.nnform + "/"
        self._save payload, urls.backendSubmit, Requests.POST

    self.submitUpdate = (data, id) ->
        payload = data
        payload.nnform = urls.backend + data.nnform + "/"
        self._save payload, urls.backendSubmit + id + '/', Requests.PUT
    #
    # The save function posts a stripped Form model
    # to the server. This is currently done asynchronously,
    # though could be extended to return messages etc.
    #
    self._save = (data, url, requestType = Requests.POST) ->
        deferred = $q.defer()
        # console.log "Saving the following to the server..."
        # console.log data
        $http
            url: url
            method: requestType
            data: angular.toJson(data)
            auth: true
        .success (data, status, headers, config) ->
            # console.log "Data saved to server successfully."
            deferred.resolve data.id
            return
        .error (data, status, headers, config) ->
            console.log "Failed to save data to server."
            deferred.reject "Failed to save data to server."
            return
        deferred.promise

    self.templateParser = (data) ->
        # Check that the results array is present in the response...
        #
        unless data.results
            #deferred.reject "Could not find valid results data."
            return null
        # First we remove the existing keys.
        # Note that here we need to pop the
        # key elements to keep the same reference
        # otherwise angular will lose the ref.
        # Popping from an array is fast in JS.
        #self.keys.pop() while self.keys.length > 0
        list = []
        # next we load the keys in from the server...
        for item in data.results
            # check if failed...
            unless item.form_meta
                failed = true
                break
            # translate the object into a JS object
            obj = angular.fromJson item.form_meta
            # add the id to the object for reference...
            obj.id = item.id
            # add the data to the keys array for the menu...
            #self.keys.push obj
            list.push obj
        #
        # Return a reject if the metadata failed...
        #
        if failed
            #deferred.reject "Failed to decode data."
            return null
        else
            #
            # If everything is ok, we sort the keys here by update date...
            #
            #self.keys.sort (a, b) ->
            list.sort (a, b) ->
                new Date(b.updated_on) - new Date(a.updated_on)
            # return the promise...
            #deferred.resolve()
        return list # self.keys

    self.submissionParser = (data) ->
        # On success we want to ensure that the data is
        # valid, and then we need to populate the keys
        # for display up the chain...
        #
        # Check that the results array is present in the response...
        unless data.results
            console.log "No data present in payload"
            #deferred.reject "Could not find valid submission data."
            return null
        # output array...
        list = []
        #
        # next we load the keys in from the server...
        #
        for submission in data.results
            # check if failed...
            unless submission.form_data
                console.log "Failed to parse submission data."
                #deferred.reject "Failed to parse submission data."
                break
            # translate the object into a JS object
            obj = angular.fromJson submission
            id = self._getID obj.nnform
            # add the id to the object for reference...
            if id
                list.push
                    id: obj.id
                    template: id
                    submission: angular.fromJson obj.form_data
        #
        # If everything is ok, we sort the keys here by update date...
        #
        list.sort (a, b) ->
            new Date(b.submission.updated_on) - new Date(a.submission.updated_on)
        return list
    #
    # Extract the id from the url...
    #
    self._getID = (url) ->
        regex = '([0-9]+)/$'
        results = url.match(regex)
        if results
            return results.pop()
        else
            return null
    #
    # This is the load object function, that will pull
    # a single form from the server. This will be called
    # with a single key from the self.keys list.
    #
    self.load = (id, submitType = FormTypes.TEMPLATE) ->
        # This function must use the defer object to wait for
        # the server response before attempting to build the object.
        #
        obj = {}
        deferred = $q.defer()
        if submitType is FormTypes.TEMPLATE
            baseURL = urls.backend
        else
            baseURL = urls.backendSubmit
        if id < 0
            deferred.reject "Invalid URL"
        else
            $http
                url: baseURL + id + "/"
                method: "GET"
                auth: true
            .success (data, status, headers, config) ->
                # console.log "Data loaded from the server:"
                # console.log data
                if data
                    obj = angular.fromJson data
                    deferred.resolve obj
                else
                    # console.log "Invalid data recieved from server."
                    deferred.reject "Invalid data recieved from server."
                return
            .error (data, status, headers, config) ->
                # console.log "Failed to load data from the server."
                deferred.reject "Failed to load resource."
                return
        deferred.promise
    return self
#
# Saves the file to local storage, but
# also must update the keys array. This
# is done by inserting the new key into
# the correct position
#
.service "LocalStorageService", ->
    #
    # Public variables...
    # ===================
    self = this
    self.store = {}
    self.keys = []
    #
    # Private variables...
    # ====================
    #
    # Insert the item into the ordered array in the
    # correct position...
    _insert = (item, array) ->
        loc = _indexOf(item, array, 0, array.length)
        array.splice loc + 1, 0, item
        array
    #
    # Binary search for location of item
    #
    _indexOf = (item, array, start, end) ->
        size = end - start
        mid = Math.floor(start + size / 2)
        return mid    if array[mid] is item
        return (if array[mid] > item then mid - 1 else mid)    if size <= 1
        if array[mid] < item
            _indexOf item, array, mid, end
        else
            _indexOf item, array, start, mid
    #
    # Save the template to local storage...
    #
    self.save = (name, data) ->
        self.clearAll()
        if self.store[name]
            # Here we handle the overwriting. Here nothing is
            # done, but a general ok/cancel confirmation could
            # be used here...
            #
            self.store[name] = data
        else
            #
            # Here we add the data into the store, and also
            # maintain the keys. Here the keys are sorted on the
            # fly. It may be faster to simply insert the new
            # element in order. However, this is still faster than
            # using orderBy, and sorting each view refresh.
            #
            self.store[name] = data
            _insert name, self.keys
        # write the full store to local storage in the browser...
        localStorage.storageService = angular.toJson(self.store)
        return
    #
    # Complete refresh of the in-memory store
    #
    self.restore = ->
        # complete refresh...
        if localStorage.storageService
            # First we remove the keys.
            # Note that here we need to pop the
            # key elements to keep the same reference
            # otherwise angular will lose the ref.
            # Popping from an array is fast in JS.
            self.keys.pop() while self.keys.length > 0
            self.store = angular.fromJson(localStorage.storageService)
            for item of self.store
                _insert item, self.keys
        return
    #
    # Load a template from local storage...
    #
    self.load = (name) ->
        # just in case, we refresh, then load. This
        # is because the user can remove items from
        # the store with delete...
        self.restore()
        self.store[name]
    #
    # Clear the current item from the browser
    #
    self.clearStorage = ->
        localStorage.removeItem localStorage.storageService
        return
    #
    # Clear the browser completely...
    #
    self.clearAll = ->
        localStorage.clear()
        return
    #
    # Initialization. Here we query the local storage of the
    # browser to see if any previous models have been saved.
    # If so, we can rebuild the key sets.
    #
    if localStorage.storageService
        self.store = angular.fromJson(localStorage.storageService)
        for item of self.store
            _insert item, self.keys
    return self



'use strict'

angular.module 'nn.task.comments-list.services', []

  .factory 'Note', ($http, $q, HOSTS, Auth) ->
    API_URL = "#{HOSTS.silverstone}/api/v2/storytask/"
    create: (task, comment) ->
      deferred = $q.defer()
      $http
        method: 'POST',
        url: "#{API_URL}#{task.id}/note/"
        data: comment
        cache: false
        auth: true
      .success (response) ->
        deferred.resolve response
      .error (response) ->
        deferred.reject response
      deferred.promise
'use strict'

angular.module 'nn.file-explorer.services', []

    .factory 'FileExplorer', ->
        menuItems: false
        menuItemSelected: null

    .constant 'FileSections', [
            { 'id':'upload', label:'Upload files', icon:'fa-upload' }
            { 'id':'search', label:'Search files', icon:'fa-search' }
            { 'id':'recent', label:'My recent files', icon:'fa-calendar' }
            { 'id':'favourites', label:'My favourites', icon:'fa-star' }
        ]

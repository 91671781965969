'use strict'

angular.module 'adonis.issue.collections.spreads.spread.info', []

.factory 'spreadsInfo', (BasePresenter, PageWorkFlows, NotificationService) ->
  class spreadsInfo extends BasePresenter
    constructor: ($queryStream, layout) ->
      super $queryStream

      @sync layout

    sync: (obj) ->
      {workflow, @isFlagged, @comments, @deadline} = obj

      workflow = (workflow?.t).replace('PageEntity.', '') or null

      if workflow is 'PrintReady'
        @published = false
      else if workflow is 'Published'
        workflow = 'PrintReady'
        @published = true
      else @published = undefined

      @workflow = PageWorkFlows[workflow]
      @defaultWorkflow = workflow

      @deadline = @deadline?.value or null

    #flag
    removeFlag: -> @query 'set{{page}}UnFlagged'
    addFlag: -> @query 'set{{page}}Flagged'

    flag: -> if @isFlagged then @removeFlag() else @addFlag()

    #deadline
    setDeadline: -> @query 'set{{page}}Deadline', @deadline.toString()

    validateCopyfit: (copyfitData) ->
      taskIds = Object.keys copyfitData

      publishable: not taskIds.filter((taskId) ->
        not copyfitData[taskId].publishable).length

      overset: taskIds.filter((taskId) ->
        copyfitData[taskId].elements?.length).length

    #workflow
    changeWorkflow: (copyfit) ->
      updateWorkflow = =>
        @query 'set{{page}}To' + @workflow.code

        if @workflow.msg
          NotificationService[@workflow.msg.type] 'Page', @workflow.msg.text

      resetWorkflow = =>
        @workflow = PageWorkFlows[@defaultWorkflow]

      validationRequired = @workflow.code is PageWorkFlows.PrintReady.code or @workflow.code is PageWorkFlows.PendingApproval.code

      if validationRequired
        workflowBody = ''
        workflowTitle = ' Are you sure you wish to send this page to press?'

        { publishable, overset } = @validateCopyfit copyfit.data
        valid = publishable and not overset

        if not valid
          if not publishable
            workflowBody += 'There are unapproved Stories on this page'

          if overset
            if not publishable
              workflowBody += ', and '

            workflowBody += 'some text elements on this page are overset.'

          NotificationService.confirm(workflowBody, workflowTitle).then updateWorkflow, resetWorkflow
          return

      updateWorkflow()

    #comments
    addComment: (value) -> @query 'AddComment', text: value
    removeComment: (value) -> @query 'RemoveComment', id: value

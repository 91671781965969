'use strict'

angular.module 'adonis.issue.collections.spreads', [
  'adonis.issue.collections.spreads.spread'
]

.factory 'spreadsRepo', (BasePresenter, spreadEntity, $rootScope, $q, $filter) ->
  class spreadsRepo extends BasePresenter
    constructor: ($queryStream, initData, initMeta) ->
      super $queryStream

      @sync initData, initMeta

    $bookNames: null

    sync: (data, { advertHoleTemplates, pageLayouts, spreadLayouts }) ->
      @$bookNames = []

      cleanAdvert = (advert) ->
        height: advert[1].height?.value or null
        width: advert[1].width?.value or null
        x: advert[0].x.value
        y: advert[0].y.value
        spread: false
        override: advert[1].resourceOverride

      setupSpreadObject = (spread) =>
        @[spread.bookName].inside ?= []

        spreadObject = @[spread.bookName].inside[spread.index - 1] ?= {}

        spreadObject.info =
          spread_id: spread.ref?.id
          is_spread: spread.hasSharedContent or spread.spreadLayout isnt null
          layout_spread: spread.hasSharedLayoutTemplate
          edspace: spread.spreadEditorialHash

        true

      angular.forEach data, (spread, id) =>
        if spread.index?
          @[spread.bookName] ?= {}

          if spread.index isnt 0
            setupSpreadObject(spread)

          advertsCount = 0

          adverts = left: {}, right: {}, spread: {}

          spread.advertHoles.map (advert) =>
            id = advert[1]?.advert?.id or advertsCount

            cleanedAdvert = cleanAdvert advert
            cleanedAdvert.info = advertHoleTemplates[id]

            if spread.hasSharedContent or spread.spreadLayout isnt null
              cleanedAdvert.spread = true
              adverts.spread[id] = cleanedAdvert
            else if advert[0].x.value < spread.leftPage.width.value
              adverts.left[id] = cleanedAdvert
            else
              adverts.right[id] = cleanedAdvert
              adverts.right[id].x = (advert[0].x.value - spread.rightPage.imageWidth.value - (spread.rightPage.insideMargin.value + spread.rightPage.outsideMargin.value))

            advertsCount++

          { leftPage, rightPage } = spread

          if spread.hasSharedContent or spread.spreadLayout isnt null
            if spread.spreadLayout
              layout = spreadLayouts[spread.spreadLayout.id]

            calc = (str) ->
              leftPage[str].value + rightPage[str].value

            spread.spreadPage = angular.copy leftPage

            angular.extend spread.spreadPage,
              imageWidth:
                value: calc('imageWidth') + calc('insideMargin')
            @parseSpreadSide('spread', spread, adverts, layout)
          else
            if spread.leftPageLayout
              layout = pageLayouts[spread.leftPageLayout.id]
              @parseSpreadSide('left', spread, adverts, layout)

            if spread.rightPageLayout
              layout = pageLayouts[spread.rightPageLayout.id]
              @parseSpreadSide('right', spread, adverts, layout)

          if @$bookNames.indexOf(spread.bookName) is -1
            @$bookNames.push spread.bookName

    spreadPreviews: (spread, type) ->
      left = @previewsPositioning(spread.leftPage, spread['left' + type + 'Preview'])
      right = @previewsPositioning(spread.rightPage, spread['right' + type + 'Preview'])

      left.offsets.x = 0
      right.offsets.x = spread.leftPage.imageWidth.value +
                        spread.leftPage.insideMargin.value +
                        spread.rightPage.insideMargin.value

      [ left, right ]

    previewsPositioning: (page, preview) ->
      preview: preview

      offsets:
        height: if page?.bottomMargin?.value? and page?.topMargin?.value? then page.bottomMargin.value + page.topMargin.value else null
        width: if page?.outsideMargin?.value? and page?.insideMargin?.value? then page.outsideMargin.value + page.insideMargin.value else null

      hole:
        height: if page?.imageHeight?.value? then page.imageHeight.value else null
        width: if page?.imageWidth?.value? then page.imageWidth.value else null

    parseSpreadSide: (side, spread, adverts, layout) ->
      { index, bookName } = spread

      barcode =
        if side is 'left'
          if spread.barcode
            # barcode exists, use it
            spread.barcode
          else
            # barcode doesn't exist
            if index is 0
              # but it's the back page so it can exist
              {}
            else
              # not back page so can't exist
              null
        else
          # barcode can't exist on right page
          null

      if side is 'spread'
        advertPreview = @spreadPreviews spread, 'Adverts'
        furniturePreview = @spreadPreviews spread, 'Furniture'
      else
        advertPreview = [
          @previewsPositioning spread[side + 'Page'], spread[side + 'AdvertsPreview']
        ]

        furniturePreview = [
          @previewsPositioning spread[side + 'Page'], spread[side + 'FurniturePreview']
        ]

      spreadObject = [
        spread[side + 'Page']
        spread.ref?.id
        adverts[side]
        spread[side + 'EditorialHash']
        advertPreview
        furniturePreview
        spread[side + 'PageLayout' or side + 'Layout']?.id
        barcode
        layout
        $filter('titleCase')(side)
      ]

      @[bookName].inside ?= []
      @[bookName].front ?= {}
      @[bookName].back ?= {}

      if index is 0
        if spread[side + 'Page'].folioNumber is 1
          spreadContainer = @[bookName].front
          spreadContainer.page ?= {}
        else
          spreadContainer = @[bookName].back
          spreadContainer.page ?= {}

        spreadContainer.info ?=
          spread_id: spread.ref?.id
          is_spread: spread.hasSharedContent or spread.spreadLayout isnt null
          layout_spread: spread.hasSharedLayoutTemplate
          edspace: spread.spreadEditorialHash

        if spreadContainer.page.page
          spreadContainer.page.update spreadObject
        else
          spreadContainer.page = new spreadEntity(@$queryStream, spreadObject...)
      else if side is 'spread'
        spreadContainer = @[bookName].inside[index - 1]

        delete spreadContainer.pages

        spreadObject[0].folioNumbers = [
          spread.leftPage.folioNumber
          spread.rightPage.folioNumber
        ]

        spreadObject[0].topsData =
          leftPage: spread.leftPage
          rightPage: spread.rightPage

        spreadObject[0].topsData.leftPage.spreadId = spread.ref.id
        spreadObject[0].topsData.rightPage.spreadId = spread.ref.id

        if spreadContainer.page
          spreadContainer.page.update spreadObject
        else
          spreadContainer.page = new spreadEntity(@$queryStream, spreadObject...)
      else
        spreadContainer = @[bookName].inside[index - 1]

        delete spreadContainer.page

        spreadContainer.pages ?= {}
        spreadContainer.pages[side] ?= {}

        if spreadContainer.pages[side].page
          spreadContainer.pages[side].update spreadObject
        else
          spreadContainer.pages[side] = new spreadEntity(@$queryStream, spreadObject...)

      null

    update: (data, meta) ->
      @sync data, meta
      return null

    active:
      get: null
      set: (pageNum) -> @get = pageNum

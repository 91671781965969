angular.module 'nn.dragndropsvg.draggable', []

.directive 'dndDraggable', (dndHelpers, $document, $parse, $timeout, SVGDragUtils, SVGDragImage) ->
  restrict: 'A'
  link: (scope, element, attr) ->

    if window.navigator.pointerEnabled
      eventDown = 'pointerdown'
      eventMove = 'pointermove'
      eventUp = 'pointerup'
    else
      eventDown = 'mousedown touchstart'
      eventMove = 'mousemove touchmove'
      eventUp = 'mouseup touchend touchcancel'

    onMove = (event) ->
      event.preventDefault()

      if angular.isObject event.originalEvent
        event = event.originalEvent

      if event.touches
        event.clientX = event.touches[0].clientX
        event.clientY = event.touches[0].clientY

      SVGDragImage.translateDragImage event.clientX, event.clientY
      SVGDragUtils.synthesizeEnterLeave event

      return

    onUp = (event) ->
      event.preventDefault()

      SVGDragUtils.dispatchDragEnd event

      $document.off eventMove, onMove
      $document.off eventUp, onUp

      $timeout ->
        SVGDragImage.destroy()
        SVGDragUtils.clearDragData()

      return

    onDown = (event) ->
      dragData = scope.$eval attr.dndDraggable

      SVGDragUtils.setDragData dragData

      SVGDragImage.createDragImage element[0]

      $document.on eventMove, onMove
      $document.on eventUp, onUp

      return

    element.on eventDown, onDown

    onClick = (event) ->
      event = event.originalEvent or event

      scope.$apply ->
        $parse(attr.dndSelected) scope, event: event

      event.stopPropagation()

    element.on 'click', onClick
    return

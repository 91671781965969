'use strict'

angular.module 'nn.page-thumbnail.directives', []

  .directive 'nnPageThumbnail', ($state, $stateParams, nnSidenavService, IssuePresenter, AppState, $location, Explorer, PageExplorer, $timeout) ->
    templateUrl: '/templates/page-thumbnail.html'
    restrict: 'A'
    require: ['^canvasSchema']
    scope: {
      book: '='
      issue: '='
      schema: '='
      page: '='
      spread: '='
      pages: '='
      viewAll: '='
      viewPreview: '='
    }
    controller: ($scope, PageThumbnailStyle) ->
      scalingNumber = 0.335

      $scope.pageStyle = (page, layout) ->
        height = (page.height + page.margins.top + page.margins.bottom) / 2 * scalingNumber
        width = (page.width + page.margins.inside + page.margins.outside) / 2 * scalingNumber

        width: width + 'px', height: height + 'px'

    link: (scope) ->

      scope.select = (page, book) ->
        if $state.includes "**.pages.**"
          $state.go 'app.pages.issue.page', book: book, page: page
        else
          $location.path('/pages/' + scope.issue.pub + '/' + scope.issue.date)
          $state.go 'app.pages.issue.page', book: book, page: page, date: scope.issue.date, pub: scope.issue.pub

        promise = $timeout ->
          PageExplorer.flatPlanState = false
          if scope.page.layout.template
            Explorer.data.collapsed = true
          else
            PageExplorer.showTemplates()
        , 0

        promise.then ->
          nnSidenavService('pageinfo')?.open?()

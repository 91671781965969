#
# Form editor (for submission)
# ============================
#  
angular.module "nn.form-submit-editor.directives", [] 
#
# Field editor directive. This simply pulls the template onto
# the form. The selectedItem in the controller is used to
# populate the items in the template...
# 
.directive "nnFormSubmitEditor", ->
    restrict: "E"
    scope: {}
    templateUrl: "/templates/form-submit-editor.html"
    controller: ($scope, FormModel, StorageService) ->  
        # Here we just pass the form model up to the
        # view...
        $scope.formModel = FormModel



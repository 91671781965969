'use strict'

angular.module 'nn.modal-dialog.directives', []

  .directive 'nnModal', (PubSub) ->
    restrict: 'EA'
    scope: true
    replace: true
    templateUrl: '/templates/modal-dialog.html',
    link: (scope, element, attrs) ->
      scope.show = false

      scope.hideModal = ->
        scope.show = false

      PubSub.subscribe 'ModalDialog', (event, data) ->
        if event == 'open'
          scope.show = true
          element.empty()
          element.append(data.body)
        else if event == 'close'
          scope.hideModal()
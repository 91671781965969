'use strict'

angular.module 'nn.file-upload.directives', []

  .directive 'nnFileUpload', ->
    templateUrl: '/templates/file-upload.html'
    restrict: 'E'
    scope: {}
    controller: ($scope, Uploader, $q, $timeout, FileExplorer) ->
        $scope.files = Uploader.getInstance()
        $scope.selectSection = (section) ->
            FileExplorer.menuItems = true
            FileExplorer.menuItemSelected = section

angular.module "nn.form-builder.routes", []
    .config ($stateProvider) ->
        #
        # Configure the stateProvider for the
        # form builder app...
        #
        $stateProvider
            .state 'app.forms',
                abstract: true
                url: 'forms'
                resolve:
                    acl: (Roles, $q) ->
                        checkRole = ->
                            if !Roles.canGo('forms') then $q.reject 'unauthorized'
                        if Roles.hasRoles() then checkRole() else Roles.attachRoles().then -> checkRole()
                onEnter: (AppState, Explorer, Sections) ->
                    AppState.section = Sections[4]
                    Explorer.data.active = true
            #
            # Main form landing page...
            #
            .state 'app.forms.home',
                url: '/home'
                onEnter: (Explorer, AppState, FormExplorer, FormModel, $rootScope, $state) ->
                    FormExplorer.menuItems = false
                    FormExplorer.menuItemSelected = null
                    FormExplorer.formMenu = false
                    FormModel.editMode = false
            #
            # Main form create new...
            #
            .state 'app.forms.new',
                url: '/new'
                onEnter: (Explorer, AppState, FormExplorer, FormModel) ->
                    FormExplorer.menuItems = true
                    FormExplorer.menuItemSelected = 4
                    FormExplorer.formMenu = true
                    FormModel.editMode = true
                    AppState.canvas = "form"
                    FormModel.enabled = true
            #
            # Form template search
            #
            .state 'app.forms.search',
                url: '/search'
                onEnter: (FormExplorer, AppState, Explorer, FormModel) ->
                    FormExplorer.menuItems = true
                    FormExplorer.menuItemSelected = 0
                    FormExplorer.formMenu = true
                    FormModel.editMode = false
            #
            # Form template search with ID
            #
            .state 'app.forms.search.form',
                url: '/:form'
                onEnter: (FormExplorer, Explorer, $stateParams, AppState, FormModel) ->
                    AppState.canvas = 'form'
                    url = $stateParams.form
                    AppState.form = id:url
                    if url
                        FormModel.load(url)
                    FormExplorer.menuItems = true
                    FormExplorer.menuItemSelected = 0
                    FormExplorer.selectFields = false
                    FormExplorer.formMenu = true
                    FormModel.editMode = false
                    FormModel.resetSubmitId()
            #
            # Form template search with ID
            #
            .state 'app.forms.search.form.edit',
                url: '/edit'
                onEnter: (FormExplorer, Explorer, $stateParams, AppState, FormModel) ->
                    AppState.canvas = 'form'
                    url = $stateParams.form
                    AppState.form = id:url
                    if url
                        FormModel.load(url)
                    FormExplorer.formMenu = true
                    FormModel.editMode = true
                    FormExplorer.menuItems = true
                    FormExplorer.menuItemSelected = 4
                    FormModel.resetSubmitId()
            #
            # Form submission page
            #
            .state 'app.forms.submissions',
                url: '/submissions'
                onEnter: (FormExplorer, Explorer, FormModel) ->
                    FormExplorer.menuItems = true
                    FormExplorer.menuItemSelected = 1
                    FormExplorer.formMenu = true
                    FormModel.editMode = false
            #
            # Form submission search page
            #
            .state 'app.forms.submissions.search',
                url: '/search'
                onEnter: (FormExplorer, Explorer, FormModel) ->
                    FormExplorer.menuItems = true
                    FormExplorer.menuItemSelected = 1
                    FormExplorer.formMenu = true
                    FormModel.editMode = false
            #
            # Form submission selection page
            #
            .state 'app.forms.submissions.search.form',
                url: '/:form'
                onEnter: (AppState, $stateParams, FormExplorer, Explorer, FormModel) ->
                    AppState.canvas = 'form'
                    url = $stateParams.form
                    AppState.form = url
                    if url
                        FormModel.loadSubmission(url)
                    FormExplorer.menuItems = true
                    FormExplorer.menuItemSelected = 1
                    FormExplorer.formMenu = true
                    FormModel.editMode = false
            #
            # Form recents page
            #
            .state 'app.forms.recent',
                url: '/recent'
                onEnter: (FormExplorer, Explorer) ->
                    FormExplorer.menuItems = true
                    FormExplorer.menuItemSelected = 2
                    FormExplorer.formMenu = true
            #
            # Form favourites page
            #
            .state 'app.forms.favourites',
                url: '/favourites'
                onEnter: (FormExplorer, Explorer) ->
                    FormExplorer.menuItems = true
                    FormExplorer.menuItemSelected = 3
                    FormExplorer.formMenu = true
            #
            # Form history page
            #
            .state 'app.forms.history',
                url: '/history'
                onEnter: (FormExplorer, Explorer) ->
                    FormExplorer.menuItems = true
                    FormExplorer.menuItemSelected = 4

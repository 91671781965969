#
# Field editor
# =============
# Controller to load up the edit panel when a field is selected...
#
angular.module "nn.form-editor-panel.directives", []
#
# Field editor directive. This simply pulls the template onto
# the form. The selectedItem in the controller is used to
# populate the items in the template...
#     
.directive "nnFormEditorPanel", ->
    restrict: "E"
    scope: {}
    templateUrl: "/templates/form-editor-panel.html"
    controller: ($scope, FormModel) ->   
        # Here we just pass the form model up to the
        # view...
        $scope.fm = FormModel

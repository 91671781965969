'use strict'

angular.module 'nn.issue-list.directives', []

  .directive 'nnIssueList', ->
    templateUrl: '/templates/issue-list.html'
    restrict: 'E'
    scope:
      'issues': '='
      'select': '='
    controller: ($scope) ->
      activeIssue = null
      $scope.setActive = (issue) ->
        $scope.clicked == false
        activeIssue = issue
      $scope.isActive = (issue) ->
        issue == activeIssue

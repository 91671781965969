'use strict'

angular.module 'nn.sentry', []

  .run ($injector) ->
    window.sentryOnLoad = () =>
      window.Sentry.init
        release: 'manage-client@' + __GIT_COMMIT_HASH__
        environment: __ENV_NAME__
        sampleRate: __SENTRY_SAMPLE_RATES__
        tracesSampleRate: __SENTRY_SAMPLE_RATES__
        debug: false
        beforeSend: (event) ->
          # Available levels are  ["fatal", "error", "warning", "log", "info", "debug", "critical"]
          sendErrorLevels = ['error', 'fatal', 'critical']
          if event.level and not sendErrorLevels.includes(event.level)
              return null
          event

    $injector.get('Auth').user().then (user) ->
      window.Sentry.configureScope (scope) ->
        scope.setUser(user)
        scope.setTags({
          package: 'manage-client',
          commitHash: __GIT_COMMIT_HASH__,
        })

  .config ($provide) ->
    $provide.decorator '$exceptionHandler', ($delegate) ->
      (exception, cause) ->
        $delegate exception, cause
        window.Sentry.captureException exception

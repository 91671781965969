'use strict'

angular.module 'nn.task.container.directives', []

  .directive 'nnTaskContainer', (TaskService, NotificationService, Roles) ->
    require: 'ngModel'
    scope: { page: '=', schema: '=', type: '=', task: '=?', showOnly: '=', active: '=?', printPreviewPaths: '=?'  }
    templateUrl: '/templates/task-container.html'
    link: (scope, element, attrs, ngModelController) -> 
      scope.loaded = false

      passTasktoScope = (task, error = false) ->
        scope.taskerror = error
        scope.loaded = true   
        scope.task = task

        if task?.notes?[0]?.text and not task.description 
          scope.task.description = task.notes[0].text

      scope.readOnly = not Roles.can 'modifyTask'

      notNull = (value) ->
        value isnt null or angular.isDefined value

      scope.canUnflag = ->
        scope.task?.flagged and not Roles.can 'Unflag'

      renderCurrentValue = ->
        if notNull(ngModelController.$viewValue?.id) and notNull(ngModelController.$viewValue?.story)
          scope.taskerror = false
          scope.loaded = false 

          taskError = ->  passTasktoScope null, true
            
          taskStorySuccess = (task) ->
            if task.results.length
              passTasktoScope task.results[0]
            else passTasktoScope null

          task = ngModelController.$viewValue

          if task 
            switch
              when task.id then TaskService.one(task.id, scope.type).then passTasktoScope, taskError
              when task.story then TaskService.oneForStory(task.story).then taskStorySuccess, taskError
              else passTasktoScope null
          else passTasktoScope null  
        
        else passTasktoScope null

      ngModelController.$render = renderCurrentValue
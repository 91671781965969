'use strict'

angular.module 'nn.publication-editor.directives', []

.directive 'nnPublicationEditor', (PublicationService) ->
  templateUrl: '/templates/publication-editor.html'
  restrict: 'E'
  scope:
    pubcode : '='
  controller: (AppState, $scope, $state, $stateParams, AdonisService, PublicationProvinces, PublicationFields, NotificationService, PublicationCleanFields, $attrs, Uploader, PublicationBarcodeDays, BARCODES_BUCKET, HOSTS, Auth) ->

      notifyNewrelic = ->
        Auth.user().then (user) ->
          newrelic.interaction().setAttribute("userid", "#{user.id}")
        .catch ->
          newrelic.interaction().setAttribute("userid", "unknown")
        .finally ->
          newrelic.interaction().setName('Publication Save').save()

      origPublication = {}
      editedFields = {}
      $scope.provinces = PublicationProvinces
      $scope.pubFields = PublicationFields
      $scope.barcodeDays = PublicationBarcodeDays
      $scope.files = Uploader.getInstance(BARCODES_BUCKET)

      $scope.send = ->
        pubCode = if $scope.pubcode then $scope.pubcode else $scope.publication.code.toUpperCase()
        msg = if $scope.pubcode then 'Warning: making changes to Admin pages during business hours may result in downtime' else 'Are you sure you want to create a new publication?'
        NotificationService.confirm(msg).then ->
          $scope.files.removeResults()
          files = $scope.barcodeDays.filter((day) -> day.file).map (day) -> day.file
          $scope.files.selectUpload(files).then (result) ->
            numUploadErrors = (result.filter (item) -> !item?).length
            if numUploadErrors > 0
              NotificationService.error "There was an error uploading the barcode."
              return
            else
              result
                .filter (item) -> item?
                .map (item, index) ->
                  if item
                    {name} = item
                    relatedDay = files.filter (i) -> i.customName == item.title
                    if relatedDay.length
                      day = relatedDay[0].day
                      $scope.setDetail($scope.pubFields.barcodes, [day, BARCODES_BUCKET, name])
              if $scope.pubcode
                PublicationService.sendToPublication(pubCode, editedFields).then ->
                  notifyNewrelic()
                  if editedFields[$scope.pubFields.removeSection]
                    for theme in editedFields[$scope.pubFields.removeSection]
                      $scope.publication.defaultThemeVariants.splice($scope.publication.defaultThemeVariants.indexOf(theme),1)
                  if editedFields[$scope.pubFields.removeBarcode]
                    for day in editedFields[$scope.pubFields.removeBarcode]
                      delete $scope.publication.barcodes[day]
                    $scope.barcodeDays.map (day) -> delete day.remove
                  if editedFields[$scope.pubFields.barcodes]
                    for barcode in editedFields[$scope.pubFields.barcodes]
                      {day, bucket, key} = barcode
                      location =
                        v:
                          host: HOSTS.valencia
                          key: key
                          bucket: bucket
                          url: "#{HOSTS.valencia}/media/#{bucket}/#{key}"
                      $scope.publication.barcodes[day] = {location:{}} if !$scope.publication.barcodes[day]
                      $scope.publication.barcodes[day]['location'] = location
                      $scope.barcodeDays.map (day) -> day.file = null
                  NotificationService.success "Publication changes saved"
                  origPublication = angular.copy($scope.publication)
                  editedFields = {}
                  $scope.reset()
              else
                AdonisService.once('PublicationPresenter', pubCode).then (data) ->
                  if data.name
                    NotificationService.confirm('A Publication with this code already exists would you like to edit it?').then ->
                      PublicationService.setPublication(pubCode, data)
                      $state.go 'app.publications.publication', { pubcode: pubCode }
                  else
                    AdonisService.subscribe ['PublicationPresenter', pubCode], (result) ->
                      if result.website
                        NotificationService.success "Publication Created."
                        PublicationService.setPublication(pubCode, result)
                        AdonisService.unsubscribe 'PublicationPresenter', pubCode
                        $state.go 'app.publications.publication', { pubcode: pubCode }
                      PublicationService.sendToPublication(pubCode, editedFields).then ->
                        notifyNewrelic()

      $scope.reset = ->
        $scope.files.removeAll()
        $scope.files.removeResults()
        $scope.barcodeDays.map (day) ->
          day.file = null
          delete day.remove
        $scope.publication = angular.copy(origPublication)
        $scope.publication.code = if $scope.pubcode then $scope.pubcode else $stateParams.code
        $scope.pubForm.$setPristine()

      $scope.setDetail = (property, value) ->
        switch property
          when $scope.pubFields.themeVariant
            editedFields[property] = {} if !editedFields.hasOwnProperty(property)
            editedFields[property][value[0]] = value[1] if angular.isDefined(value[0])
          when $scope.pubFields.removeSection, $scope.pubFields.removeBarcode
            editedFields[property] = [] if !editedFields.hasOwnProperty(property)
            if editedFields[property].indexOf(value) > -1
              editedFields[property].splice(editedFields[property].indexOf(value),1)
            else
              editedFields[property].push(value)
          when $scope.pubFields.barcodes
            editedFields[property] = [] if !editedFields.hasOwnProperty(property)
            [day, bucket, key] = value
            barcode =
              day: day,
              bucket: bucket,
              key: key
            if editedFields[property].indexOf(barcode) > -1
              editedFields[property].splice(editedFields[property].indexOf(barcode),1)
            else
              editedFields[property].push(barcode)
          else editedFields[property] = value

      $scope.setSectionName = (command, cur, orig) ->
        #Creating a new section - requires a variant to be set before valid.
        $scope.setDetail(command, [cur[0], cur[1]]) if angular.isDefined(cur[1])
        #If a original section exists and is renamed we remove the old section.
        $scope.setDetail($scope.pubFields.removeSection, orig) if orig.hasOwnProperty('section')

      $scope.selectedSections = ->
        editedFields[$scope.pubFields.removeSection]

      $scope.clone = ->
        NotificationService.confirm("Are you sure you want to clone this publication?").then ->
          $scope.pubcode = null
          editedFields = {}

          cleanedPubClone = ->
            pub = angular.copy(origPublication)
            delete pub[field] for field in PublicationCleanFields
            return pub

          buildCommand = (field) ->
            $attrs.$normalize('set-' + field).replace /s$/, ''

          for field, value of cleanedPubClone()
            field = buildCommand(field)
            switch field
              when $scope.pubFields.themeVariant then $scope.setDetail field, [obj.section,obj.variant] for obj in value
              when $scope.pubFields.defaultTheme then $scope.setDetail field, value.id
              else $scope.setDetail field, value

          PublicationService.clonePublication(cleanedPubClone())
          $state.go 'app.publications.clone', { pubcode: $stateParams.pubcode }
          NotificationService.success "Publication Cloned."

      $scope.addSectionElement = ->
        $scope.publication.defaultThemeVariants = [] if !$scope.publication.defaultThemeVariants
        $scope.publication.defaultThemeVariants.push({})

      $scope.onBarcodeFileSelect = (file, pub, day) ->
        if file.length && file[0].type == 'application/pdf'
          $scope.barcodeDays.filter((item) ->
            item.key == day
          ).map (item) ->
            file = file[0]
            file.customName = "barcode-#{pub}-#{day}.pdf"
            file.day = day
            item['file'] = file
          $scope.pubForm.$setDirty()

      $scope.resetBarcodeDay = (day) ->
        $scope.files.remove(day.file)
        $scope.barcodeDays[$scope.barcodeDays.indexOf(day)].file = null
        if !$scope.barcodeDays.filter((day) -> day.file).length && !Object.keys(editedFields).length
          $scope.pubForm.$setPristine()

      $scope.genPreviewUri = (component) ->
        "/#{component.bucket}/#{component.key}"

      $scope.$watch 'pubcode', (newPub) ->
        origPublication = {}
        $scope.barcodeDays.map (day) -> day.file = null
        $scope.publication = PublicationService.getStoredPublication()
        $scope.publication.code = if $scope.pubcode then $scope.pubcode else $stateParams.code
        $scope.pubForm.$setPristine()
        $scope.formTitle = if $scope.pubcode then 'Edit Publication' else 'Create Publication'
        if newPub
          PublicationService.retrievePublication(newPub).then (data) ->
            barcodes = {}
            data.barcodes.map (item) ->
              {host, bucket, key} = item[1].location.v
              item[1].location.v['url'] = "https://#{host}/media/#{bucket}/#{key}"
              barcodes[item[0]] = item[1]
            $scope.publication = angular.copy(data)
            $scope.publication.barcodes = barcodes
            origPublication = angular.copy(data)
            $scope.publication.code = if $scope.pubcode then $scope.pubcode else $stateParams.code

'use strict'

angular.module 'nn.dashboard.directives', []

  .directive 'nnDashboard', ->
    templateUrl: '/templates/dashboard.html'
    restrict: 'E'
    controller: ($scope, $sce, EDITORIAL_SHEET_EMBEDS, Auth, ProfileAPI, FeatureAccess, FeatureAccessTags) ->
      $scope.date = date = moment().format 'dddd, MMMM D'
      $scope.user = {}
      $scope.profile = null
      $scope.selection = {}
      $scope.editorialWebEmbeds = EDITORIAL_SHEET_EMBEDS
      $scope.hasDigitalAccess = false
      $scope.trustSrc = (src) ->
          $sce.trustAsResourceUrl(src)
      $scope.createRunsheetUrl = (orgId) ->
        "#{EDITORIAL_SHEET_EMBEDS.runsheet}-#{orgId}"
      Auth.user().then (user) =>
        $scope.user = user
      ProfileAPI.load().then (profiles) ->
        $scope.profile = profiles[0]
        if $scope.profile?.default_organization
          FeatureAccess.hasFeatureAccess(FeatureAccessTags.DIGITAL_PACKAGE, org: $scope.profile.default_organization).then (result) ->
            $scope.hasDigitalAccess = Boolean(result)
      $scope.getGreeting = ->
        hour = moment().hour()
        if hour > 16
          return 'Good evening'
        if hour > 11
          return 'Good afternoon'
        'Good morning'
'use strict'

angular.module 'nn.sports-manager.routes', []
    .config ($stateProvider) ->
        $stateProvider
            .state 'app.sports',
                abstract: true
                url: ''
                resolve:
                    acl: (Roles, $stateParams, $q) ->
                        checkRole = ->
                            if !Roles.canGo('sports') then $q.reject 'unauthorized'
                        if Roles.hasRoles() then checkRole() else Roles.attachRoles().then -> checkRole()
                onEnter: (AppState, Explorer, Sections) ->
                    AppState.section = Sections[7]
                    AppState.canvas = 'sports'
            .state 'app.sports.results',
                url: 'sports/results'

'use strict'

angular.module 'nn.publication-manager.services', []

.factory 'PublicationService', ($q, AdonisService, PublicationFields) ->
  code = null
  publication = {}

  retrievePublication: (pubcode) ->
    if pubcode isnt code
      code = pubcode
      $q.when publication = AdonisService.once('PublicationPresenter', pubcode)
    else $q.when publication

  sendToPublication: (pubCode, editedFields) ->
    deferred = $q.defer()
    AdonisService.editPublication pubCode, (publication) ->
      for field, value of editedFields
        switch field
          when PublicationFields.themeVariant
            for section, variant of value
              publication[field](section, variant)
          when PublicationFields.removeSection
            for theme in value
              publication[field](theme.section)
          when PublicationFields.removeBarcode
            for day in value
              publication[field](day)
          when PublicationFields.barcodes
            for barcode in value
              {day, bucket, key} = barcode
              publication[field](day, bucket, key)
          else
            publication[field]?(value.toString())
      deferred.resolve()
    deferred.promise

  clonePublication: (pub) ->
    publication = pub

  getStoredPublication: ->
    publication

  setPublication: (pubCode, pub) ->
    code = pubCode
    publication = pub

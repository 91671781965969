'use strict'

angular.module 'nn.story-breakingnews.directives', []

    .directive 'nnStoryBreakingnews', ->
        templateUrl: '/templates/story-breakingnews.html'
        restrict: 'E'
        scope:
            headline: '@'
        controller: ($scope, AppState, $attrs, Auth, Stories, StoryStatus, StoryUsers, StoryTime, User, BreakingNews, nnSidenavService, NotificationService, moment) ->
            # stories
            $scope.templates = [{name: 'Send Article Summary Only', value: 'SUMMARY'}, {name: 'Send Full Article Email', value: 'FULL'}]
            $scope.selectedTemplate = {name: 'Send Article Summary Only', value: 'SUMMARY'}
            $scope.warningMessageWithSiteNames = []
            $scope.msideCtrl = nnSidenavService 'mside'
            $scope.subjectChange = () ->
                if $('#email_subject').val().length > 0
                    $('#accept-button').prop('disabled', false)
                else
                    $('#accept-button').prop('disabled', true)
                return
            $scope.reset = () ->
                BreakingNews.showUpdate = false
                $("#templateSelect").val("SUMMARY").change()
                $('#email_subject').val(BreakingNews.title)
                return
            $scope.loading = false
            $scope.resetSubject = ->
                BreakingNews.resetSubject
            $scope.$watch 'resetSubject()', (nv, ov) ->
                if nv is true
                    $scope.reset()
                    BreakingNews.resetSubject = false
            $scope.selectChange = (selectedTemplate) ->
                $scope.selectedTemplate = selectedTemplate
            $scope.onAcceptClick = () ->
                $scope.loading = true
                BreakingNews.resetSubject = false
                BreakingNews.getSitesForTargetLists().then (res) ->
                    targetListIds = AppState.story.targetLists.map((uri) => parseInt(uri.split("/")[3], 10))
                    sites = res.objects.filter(
                        (site) =>
                            site.domain.startsWith("www") &&
                            !site.domain.includes("canberratimes") &&
                            site.target_lists.filter((id) => targetListIds.includes(id)).length
                    )
                    AppState.story.siteIds = sites.filter (site) ->
                        site.mail_list_id &&
                        Object.values(site.mail_groups).includes("Breaking")
                    .map (site) => site.id
                    BreakingNews.warningMessageWithSiteNames = null
                    $scope.warningMessageWithSiteNames = []
                    $scope.usedAllowance = []
                    BreakingNews.getSentBreakingNews().then (count) ->
                        for k,v of AppState.story.siteIds
                            sentNum = 0
                            date_sent_arr = []
                            count.results.filter (items) ->
                                if items.site_id is v
                                    date_sent_arr.push items.date_sent
                                    sentNum++
                            date_sent_arr = date_sent_arr.map (item) => moment(item)
                            theLatestSent = moment.max(date_sent_arr)
                            sentObj =
                                id: v
                                usedAllowance: sentNum
                                theLatestSent: theLatestSent
                            $scope.usedAllowance.push sentObj
                            res.objects.filter (site) ->
                                if site.id is v
                                    $scope.warningMessageWithSiteNames.push site.name
                                    $scope.usedAllowance[k].mail_provider = site.mail_provider
                                    $scope.usedAllowance[k].name = site.name
                        # construct json here
                        allowance = BreakingNews.getAllowance()
                        allowanceAr = []
                        cutoverAr = []
                        for k,v of $scope.usedAllowance
                            if v.mail_provider is 1 and allowance.length > 0
                                allowance.filter (item) ->
                                    if item.id is v.id and v.usedAllowance < item.allowance
                                        obj =
                                            id: v.id
                                            name: v.name
                                            allowance: "Allowance: " + item.allowance
                                            usedAllowance: " Used: " + v.usedAllowance
                                        allowanceAr.push obj
                                    else if item.id is v.id and v.usedAllowance >= item.allowance
                                        obj =
                                            id: v.id
                                            name: v.name
                                            nextAvailable: v.theLatestSent.add(24,'hours').format("DD-MM-YYYY hh:mm:ss")
                                        cutoverAr.push obj
                            else
                                obj =
                                    id: v.id
                                    name: v.name
                                allowanceAr.push obj
                        AppState.breakingNews =
                                story_id: AppState.story.storyDetail.id
                                snapshot_id: AppState.story.storyDetail.snapshot
                                email_subject: $('#email_subject').val()
                                template_type: $( "#templateSelect" ).val()
                                siteIds: AppState.story.siteIds
                                usedAllowance: $scope.usedAllowance
                                allowanceAr: allowanceAr
                                cutoverAr: cutoverAr
                        $scope.loading = false
                        AppState.storySaved = false
                        BreakingNews.showUpdate = true
                        BreakingNews.template = $( "#templateSelect" ).val()
                        BreakingNews.subject = $('#email_subject').val()
                        BreakingNews.msgAllowance = allowanceAr
                        BreakingNews.msgNotAllowance = cutoverAr
                        $scope.msideCtrl.close()
                .catch ->
                    $scope.loading = false
                    NotificationService.create 'error', 'Server Error:', 'Error retrieving target lists, try again later.', 0
                    window.Sentry.captureMessage 'Breaking news error - Error retrieving target lists'

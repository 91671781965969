'use strict'

angular.module 'nn.sidebar', []

  .factory 'ComponentRegistry', ($log, $q) ->
    instances = []
    pendings = {}

    isValidID = (handle) ->
      handle and handle isnt ''

    notFoundError: (handle) -> $log.error 'No instance found for handle', handle
    getInstances: -> instances

    get: (handle) ->
      if !isValidID(handle) then return null

      for instance in instances
        if instance.nnHandle is  handle
          check = instance
        return instance  if instance.nnHandle is handle
      null

    register: (instance, handle) ->
      deregister = ->
        index = instances.indexOf(instance)
        instances.splice index, 1  if index isnt -1

      resolveWhen = ->
        dfd = pendings[handle]
        if dfd
          dfd.resolve instance
          delete pendings[handle]

      instance.nnHandle = handle
      instances.push instance
      resolveWhen()
      deregister

    when: (handle) ->
      if isValidID handle
        deferred = $q.defer()
        instance = @get handle
        
        if instance
          deferred.resolve instance
        else 
          pendings[handle] = deferred
        
        return deferred.promise

      $q.reject 'Invalid `id` value.'

  .factory 'nnSidenavService', ($timeout, ComponentRegistry, $q) ->
    (handle) ->
      errorMsg = 'nnSidenavService "' + handle + '" is not availabe!'
      instance = ComponentRegistry.get handle

      unless instance
        return false
        
      waitForInstance = ->
        ComponentRegistry.when(handle).then (it) ->
          instance = it
          it

      name: if instance then instance.name else undefined
      expanded: -> if instance then instance.expanded()
      active: -> instance and instance.active()
      expand: (width) -> instance and instance.expand(width)
      toggle: -> if instance then instance.toggle() else $q.reject(errorMsg)
      open: -> if instance then instance.open() else $q.reject(errorMsg)
      close: -> if instance then instance.close() else $q.reject(errorMsg)
      then: (callbackFn) ->
        promise = if instance then $q.when(instance) else waitForInstance()
        promise.then callbackFn or angular.noop

  .directive 'nnSidebar', ($q, $animate, $document, $timeout) ->
    scope: { isOpen: '=?nnIsOpen', isExpanded: '=?' }
    compile: (element) ->
      element.attr 'tabIndex', '-1'

      (scope, element, attr, ctrl) ->
        promise = $q.when(true)

        updateIsOpen = (isOpen, wasOpen) ->
          addRemoveClass = if isOpen then 'addClass' else 'removeClass'    
          promise = $animate[addRemoveClass](element, 'aside-in')

        toggleOpen = (isOpen) ->
          if scope.isOpen is isOpen
            $q.when true
          else
            deferred = $q.defer()

            scope.isOpen = isOpen

            $timeout ->
              promise.then (result) ->
                deferred.resolve result
            , 0, false

            deferred.promise

        toggleExpand = (width) ->
          if element.width() is width or not width
            element.css width: ''
            scope.isExpanded = false
          else 
            scope.isExpanded = true
            element.css width: width

          return

        close = (ev) ->
          ev.preventDefault()
          ev.stopPropagation()
          ctrl.close()

        ctrl.name = attr.nnSidebar
        ctrl.$toggleOpen = toggleOpen
        ctrl.$toggleExpand = toggleExpand

        element.on '$destroy', ctrl.destroy

        scope.$watch 'isOpen', updateIsOpen
        scope.$watch 'isExpanded', toggleExpand

    controllerAs: 'aside'
    controller: ($scope, $element, $attrs, $parse, ComponentRegistry, $q) ->

      if $attrs.ctrl?
        $parse("#{$attrs.nnSidebar}Ctrl").assign($scope.$parent, @)

      @name = $attrs.nnSidebar
      @$toggleOpen = => $q.when $scope.isOpen
      @$toggleExpand = => $q.when $scope.isExpanded
      @active = => !!$scope.isOpen
      @expanded = => !!$scope.isExpanded
      @expand = (width = 0) => @$toggleExpand width
      @open = => @$toggleOpen true
      @close = => @$toggleExpand(); @$toggleOpen false
      @toggle = => @$toggleOpen !$scope.isOpen

      @destroy = ComponentRegistry.register @, $attrs.nnSidebar

      return
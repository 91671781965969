'use strict'

angular.module 'nn.page-builder.routes', []

    .config ($stateProvider) ->

        $stateProvider

            .state 'app.pages',
                abstract: true
                url: ''
                resolve:
                    acl: (Roles, $q) ->
                        checkRole = ->
                            if !Roles.canGo('pages') then $q.reject 'unauthorized'
                        if Roles.hasRoles() then checkRole() else Roles.attachRoles().then -> checkRole()
                onEnter: (AppState, Sections) ->
                    AppState.section = Sections[4]

            .state 'app.pages.issue',
                url: 'pages/:pub/:date'
                onEnter: ($stateParams, AppState, PageExplorer, AdonisData, PageTaskService) ->
                    if $stateParams.pub isnt AppState.page.issue?.pub or angular.isUndefined(AppState.page.issue)
                        PageTaskService.switchTo($stateParams.pub)

                    AppState.page.active = number: null, book: null, index: null
                    AppState.page.issue = pub: $stateParams.pub, date: $stateParams.date

                    PageExplorer.showPages()

            .state 'app.pages.issue.page',
                url: '/:book/:page'
                onEnter: ($stateParams, IssuePresenter, AppState, Explorer, FeatureAccess) ->
                    AppState.canvas = 'page'

                    AppState.page.active =
                        number: Number $stateParams.page
                        book: $stateParams.book

                    FeatureAccess.setCurrentOrganization($stateParams.pub).then( (newslistEnabled) -> AppState.page.active.newslistEnabled = newslistEnabled)

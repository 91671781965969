'use strict'

angular.module 'nn.file-info.directives', []

    .directive 'nnFileInfo', ->
        templateUrl: '/templates/file-info.html'
        restrict: 'E'
        scope:
            file: '=file'
        link: (scope) ->
            scope.$watch 'file', (nv, ov) ->
                createdBy = nv.created_by
                if createdBy?
                    userIdRegex = /\/(\d+)\//
                    if userIdRegex.test createdBy
                        scope.user_id = createdBy.match(userIdRegex)[1]
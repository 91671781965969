'use strict'

angular.module 'nn.page-explorer.services', []

  .factory 'PageExplorer', (Explorer, AppState, Sections) ->
    menuItems: false
    menuItemSelected: null
    selectIssue: false
    selectTemplates: false
    flatPlanState: false
    copyfitStoriesState: false

    showHome: ->
        AppState.section = Sections[4]
        Explorer.data.active = true
        @menuItemSelected = null
        @menuItems = false
        @selectTemplates = false
        @selectIssue = false
    showPages: ->
        AppState.section = Sections[4]
        Explorer.data.active = true
        Explorer.data.collapsed = false
        @menuItemSelected = null
        @menuItems = true
        @selectTemplates = false
        @selectIssue = true
    showTemplates: ->
        AppState.section = Sections[4]
        Explorer.data.active = true
        Explorer.data.collapsed = false
        @menuItemSelected = null
        @menuItems = true
        @selectTemplates = true
        @selectIssue = false

  .constant 'PageExplorerSections'

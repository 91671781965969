'use strict'

NewslistData = require('@fairfaxmedia/newslist-data');

angular.module 'nn.newslist-explorer', [
    'nn.newslist-explorer.services'
    'nn.newslist-explorer.directives'
]

.constant 'NEWSLIST_PILOT_MODE_ENABLED', NewslistData.PilotModeEnabled

.constant 'NEWSLIST_PILOT_ORGANISATION_IDS', NewslistData.PilotOrganisationIds

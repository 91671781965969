'use strict'

angular.module 'adonis.issue.collections.pagelayouts.elements.stories', []

.factory 'pageLayoutStories', (BasePresenter, elementsParser, CopyfitAssistantService, NotificationService, AppState, FeatureAccess, FeatureAccessTags) ->
  class pageLayoutStories extends BasePresenter
    constructor: ($queryStream, element, holeId) ->
      super $queryStream

      @sync element, holeId

    sync: (elem, holeId) ->
      @holeId = holeId

      if elem.story?.id
        @task = parseInt elem.story?.id?.split('-')[1]
      else @task = null

      @elements = elem.template.id

      angular.extend @, elementsParser.createHole elem.bounds

    assignId: (id) ->
      @query 'assign' + @getCommandType() + 'StoryTask', @holeId, parseInt(id)

    clear: (message, schema, task)  ->

      NotificationService.confirm(message || 'Are you sure you want to remove this task?').then =>
        @query 'clear' + @getCommandType() + 'StoryTask', @holeId, @task
        FeatureAccess.hasFeatureAccess(FeatureAccessTags.PRINT_PACKAGE, {user: 'self', org: task.organization}).then (result) ->
          if result and task.content_object.id
            CopyfitAssistantService.delete(task.content_object.id, parseInt(task.organization)).then resolve, reject
          if AppState?.page?.active?.newslistEnabled?.newslist
            schema.setActiveHole 'runsheet', 'runsheet'
'use strict'

NewslistData = require('@fairfaxmedia/newslist-data');

angular.module 'nn.page-info', [
    'nn.page-info.directives'
    'nn.page-info.services'
  ]

.constant 'RUNSHEET_PAGE_SIZE_OFFSET', 10

.constant 'NEWSLIST_DATE_RANGE_TODAY', NewslistData.DATE_RANGE_TODAY

.constant 'NEWSLIST_DATE_RANGE_HELD_OVER', NewslistData.DATE_RANGE_HELD_OVER

.constant 'NEWSLIST_DATE_RANGE_NEXT_MONTH', NewslistData.DATE_RANGE_NEXT_MONTH

.constant 'NEWSLIST_DATE_RANGE_NEXT_WEEK', NewslistData.DATE_RANGE_NEXT_WEEK

.constant 'NEWSLIST_DATE_RANGE', NewslistData.DateRanges

.constant 'NEWSLIST_STORY_TYPE', NewslistData.StoryTypesPrint

.constant 'NEWSLIST_SECTION', NewslistData.StorySections

.constant 'NEWSLIST_CHANNELS', NewslistData.StoryChannels

.constant 'NEWSLIST_SESSIONS', NewslistData.StorySessions
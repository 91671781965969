'use strict'


angular.module 'nn.copyfit-assistant-panel.directives', []

  .constant 'CopyfitAssistantRequestType',
    COPYFIT_SET_PREVIEW: 'COPYFIT_SET_PREVIEW'

  .directive 'nnCopyfitAssistantPanel', () ->
    templateUrl: '/templates/story-info-copyfit-assistant.html'
    restrict: 'E'
    scope:
      story: '='
      storypad: '='
    controller: (
      $q,
      $scope,
      $rootScope,
      $window,
      CopyfitAssistantRequestType,
      StorypadMessageType,
      StorypadRequestType,
      CopyfitAssistantPanelService,
      NotificationService,
      CopyfitAssistantService,
    ) ->
      $scope.storySaved = false
      $scope.printPreviewPath = {}

      onStorypadMessageReceived = (event) =>
        event = event.originalEvent || event
        if typeof event.data == 'object'
          {type, value} = event.data
        else
          if event.data.substring(0, 6) is 'ima://'
            {type, value} = JSON.parse event.data.substr(6)
          else
            try
              {type, value} = JSON.parse event.data
            catch er
              type = null
              value = null
        switch type
          when StorypadRequestType.STORY_SAVE
            saveState = value.state
            if saveState is 'success'
              sendMessage StorypadMessageType.SET_PRINT_PREVIEW_PATH, null
              sendMessage StorypadMessageType.SET_REGENERATE_COPYFIT_ELEMENTS, null
              $scope.storySaved = true
              return

      angular.element($window).on 'message', onStorypadMessageReceived

      sendMessage = (type, value) ->
        $scope.storypad.sendMessage type, value

      $scope.storyId = $scope.story?.id

      $scope.$watch 'story', (newValue) ->
        if newValue?
          $scope.storyId = newValue.id

      $scope.$on CopyfitAssistantRequestType.COPYFIT_SET_PREVIEW, (_, taskId, previewPath) ->
        $scope.printPreviewPath[taskId] = previewPath

      $scope.handleUpdate = (data, allocate) ->
        if $scope.printPreviewPath and $scope.story?.storyDetail?.storytask?
          printPreviewPath = $scope.printPreviewPath[$scope.story.storyDetail.storytask] or null
          sendMessage StorypadMessageType.SET_PRINT_PREVIEW_PATH, printPreviewPath
        if allocate
          CopyfitAssistantService.generate([$scope.story.storyDetail.storytask])
        else
          sendMessage StorypadMessageType.SET_REGENERATE_COPYFIT_ELEMENTS, data

      $scope.resetSaveStatus = () ->
        $scope.storySaved = false
        $scope.printPreviewPath = {}

      $scope.handleApply = (type, value) ->
        message = 'Apply changes? Just double-checking: do you want to apply your changes now?'
        if type == 'body'
          message = 'Apply changes? This action will replace all the paragraphs with the new content on top. Do you want to apply your changes now?'
        NotificationService.confirm(message).then =>
          sendMessage StorypadRequestType.TEXT_FIELD_UPDATE, {type: type, message: value}

      $scope.handleFeedback = (isLiked, element, comment, revisionNumber) ->
        $q (resolve, reject) =>
          CopyfitAssistantPanelService.sendFeedback(
            $scope.storyId, $scope.story.storyDetail.organization, revisionNumber, isLiked, element, comment
          ).then (response) ->
            NotificationService.success "Thank you for your feedback."
            resolve(response)
          , (error) ->
            NotificationService.error "An error occurred while sending your feedback."
            reject(error)


  .directive 'nnCopyfitAssistantPanelReactApp', (reactDirective) ->
    reactDirective(window.copyfitAssistantPanel.CopyfitAssistantPanel, [
        'storyId',
        'orgId',
        'handleUpdate',
        'handleApply',
        'resetSaveStatus',
        'isStorySaved',
        'handleFeedback',
      ])

angular.module 'nn.dragndropsvg.dragging', []

.factory 'dragging', ($rootScope, mouseCapture) ->

  threshold = 5

  startDrag: (evt, config) ->
    dragging = false

    x = evt.pageX
    y = evt.pageY

    mouseMove = (evt) ->
      if !dragging
        if Math.abs(evt.pageX - x) > threshold or Math.abs(evt.pageY - y) > threshold
          dragging = true

          if config.dragStarted
            config.dragStarted x, y, evt

          if config.dragging
            config.dragging evt.pageX, evt.pageY, evt
      else
        if config.dragging
          config.dragging evt.pageX, evt.pageY, evt

        x = evt.pageX
        y = evt.pageY

      return

    released = ->
      if dragging
        if config.dragEnded
          config.dragEnded()
      else
        if config.clicked
          config.clicked()

      return

    mouseUp = (evt) ->
      mouseCapture.release()

      evt.stopPropagation()
      evt.preventDefault()

      return

    mouseCapture.acquire evt,
      mouseMove: mouseMove
      mouseUp: mouseUp
      released: released

    evt.stopPropagation()
    evt.preventDefault()

    return

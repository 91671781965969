'use strict'

###
    Usage:
    Add this <script> tag to the end of the <body>:
    <script defer nn-google-analytics></script>
###

angular.module 'nn.google-analytics', []

    .factory 'GoogleAnalyticsTrackingID', (Environment, ENVIRONMENTS) ->
        switch Environment
            when ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.PRODUCTION_INTERNAL, ENVIRONMENTS.PRODUCTION_TEMP, ENVIRONMENTS.PRODUCTION_DR
                'UA-54094337-1'
            when ENVIRONMENTS.UPDATE, ENVIRONMENTS.UPDATE_INTERNAL
                'UA-54094337-1'
            when ENVIRONMENTS.UAT, ENVIRONMENTS.UAT2, ENVIRONMENTS.UAT3, ENVIRONMENTS.TRAINING
                'UA-54094337-2'
            # when ENVIRONMENTS.TRAINING
            #     'UA-54094337-5'
            when ENVIRONMENTS.ADONIS_LOCAL
                'UA-54094337-3'
            when ENVIRONMENTS.ADONIS_MASTER
                'UA-54094337-3'
            when ENVIRONMENTS.STAGING, ENVIRONMENTS.STAGING2
                'UA-54094337-3'
            when ENVIRONMENTS.DEV, ENVIRONMENTS.DEV2, ENVIRONMENTS.LOCAL
                'UA-54094337-4'
            else
                'UA-54094337-4'

    .directive 'nnGoogleAnalytics', (Auth, GoogleAnalyticsTrackingID, GOOGLE_ANALYTICS_SCRIPT) ->
        restrict: 'A'
        scope: {}
        link: (scope, element) ->
            element.html GOOGLE_ANALYTICS_SCRIPT
            Auth.user().then (user) ->
                ga? 'create', GoogleAnalyticsTrackingID, 'auto'
                ga? 'set', '&uid', user.id
                ga? 'require', 'displayfeatures'

    .config ($provide, $httpProvider, TIMING_REQUESTS) ->
        # exception logging
        $provide.decorator '$exceptionHandler', ($delegate) ->
            (exception, cause) ->
                $delegate exception, cause
                exceptionInfo =
                  'exDescription': exception?.message
                  'exFatal': false
                ga? 'send', 'exception', exceptionInfo
        # navigation timing
        $httpProvider.interceptors.push ->
            request: (config) ->
                for obj in TIMING_REQUESTS
                    if config.url.match obj.regex
                        config.requestTimestamp = new Date().getTime()
                        break
                config
            response: (response) ->
                for obj in TIMING_REQUESTS
                    if response.config.url.match obj.regex
                        time = new Date().getTime() - response.config.requestTimestamp
                        location = new URL(response.config.url)
                        timingInfo =
                            'hitType': 'timing'
                            'timingValue': time
                            'timingCategory': obj.service
                            'timingLabel': obj.label
                            'timingVar': response.config.url
                            # 'page': location.pathname
                        ga 'send', timingInfo
                        break
                response

    .constant 'GOOGLE_ANALYTICS_SCRIPT',
        '''
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
        '''

    .constant 'TIMING_REQUESTS', [
        # monza
        {
            service: 'monza'
            label: '/tokeninfo'
            regex: /monza.*\/o\/tokeninfo/
        }, {
            service: 'monza'
            label: '/api/v2/user'
            regex: /monza.*\/orgs\/api\/v2\/user/
        }, {
            service: 'monza'
            label: '/api/v2/user_store'
            regex: /monza.*\/api\/v2\/user_store/
        }, {
            service: 'monza'
            label: '/api/v2/organization'
            regex: /monza.*\/api\/v2\/organization/
        },
        # apws
        {
            service: 'apws'
            label: '/presenters/IssuePresenter'
            regex: /apws.*\/presenters\/IssuePresenter/
        }, {
            service: 'apws'
            label: '/presenters/LayoutTemplateShapePresenter'
            regex: /apws.*\/presenters\/LayoutTemplateShapePresenter/
        }, {
            service: 'apws'
            label: '/presenters/StoryFitLookupPresenter'
            regex: /apws.*\/presenters\/StoryFitLookupPresenter/
        },
        # silverstone
        {
            service: 'silverstone'
            label: '/api/v2/story'
            regex: /silverstone.*\/api\/v2\/story/
        },
        {
            service: 'silverstone'
            label: '/api/v2/storytask'
            regex: /silverstone.*\/api\/v2\/storytask/
        },
        {
            service: 'silverstone'
            label: '/api/v2/workflow'
            regex: /silverstone.*\/api\/v2\/workflow/
        },
        {
            service: 'silverstone'
            label: '/api/v2/user'
            regex: /silverstone.*\/api\/v2\/user/
        },
        {
            service: 'silverstone'
            label: '/api/v2/organization'
            regex: /silverstone.*\/api\/v2\/organization/
        },
        {
            service: 'silverstone'
            label: '/api/v2/forms'
            regex: /silverstone.*\/api\/v2\/forms/
        },
        {
            service: 'silverstone'
            label: '/api/v2/formdata'
            regex: /silverstone.*\/api\/v2\/formdata/
        },
        # valencia
        {
            service: 'valencia'
            label: '/api/v1/search'
            regex: /valencia.*\/api\/v1\/search/
        },
        {
            service: 'valencia'
            label: '/api/v1/bucket'
            regex: /valencia.*\/api\/v1\/bucket/
        },
        {
            service: 'valencia'
            label: '/api/v1/object'
            regex: /valencia.*\/api\/v1\/object/
        },
        # suzuka
        {
            service: 'suzuka'
            label: '/api/v1/site'
            regex: /suzuka.*\/api\/v1\/site/
        }
    ]
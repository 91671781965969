'use strict'

angular.module 'nn.site-explorer.services', []

.factory 'SiteExplorer', ->
    menuItems: false
    menuItemSelected: null

.constant 'SITE_SECTIONS', {}

.factory 'SITE_SECTIONS_NAV', (SITE_SECTIONS) ->
    return []

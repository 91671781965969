'use strict'

angular.module 'adonis.service', []

.factory 'adonisUtils', (md5) ->
  makeBoundingPath: ({x, y, height, width}) ->
    'M' + x + ' ' + y + 'H' + (x + width) + 'V' + (y + height) + 'H' + x + ' Z'

  unpackBoundingBox: ({topLeft, bottomRight}, offset = x: 0, y: 0) ->
    x: (topLeft.x.value + offset.x), y: (topLeft.y.value + offset.y), height: (bottomRight.y.value - topLeft.y.value), width: (bottomRight.x.value - topLeft.x.value)

  generateHash: (id, dimensions) ->
    md5.createHash id + ' ' + (dimensions.x + dimensions.y + dimensions.width + dimensions.height).toString()

  pageId: (page) ->
    if page < 10 then '0' + page else page

.factory 'AdonisService', ($q, $document, Stream, $timeout, $rootScope, $http, Auth, HOSTS, NotificationService) ->
  class AdonisStream
    constructor: (@url) ->
      @queue = []
      @retrying = false

      loader = (createElement) ->
        ->
          deferred = $q.defer()
          element = createElement "#{HOSTS.adonis}/js/adonis-apws-opt.js?DIGEST"

          element.onload = element.onreadystatechange = (e) ->
            $timeout -> deferred.resolve e

          element.onerror = (e) ->
            $timeout -> deferred.reject e
          deferred.promise

      @loadScript = loader (src) ->
        script = $document[0].createElement 'script'
        script.src = src
        $document[0].body.appendChild script
        script

      @setup$queryStream()

      @open()

    setup$queryStream: ->
      @$queryStream ?= new Stream()

      @$queryStream.listen (query) =>
        if @adonis
          if query.command is 'clearPageStoryTask' and query.data.length > 2
            task_id = query.data[2]
          @send query.command, query.callback, query.data...
        else @queue.push query

    shutdown: ->
      @adonis.shutdown()

    open: (token) ->
      tokenFetcher = (cb) ->
        Auth.token().then cb

      tokenRefresher = (cb) ->
        Auth.refresh().then cb

      createInstance = =>
        @adonis = new Adonis tokenFetcher, tokenRefresher, "#{@url}/ws", -> alert("Unable to authenticate against Adonis.")

        window.AdonisInstance = @adonis

        @flush()
        NotificationService.clear()

      retryScript = (script) =>
        if not @retrying
          NotificationService.create 'error', 'NewsNow is', 'having trouble connecting, retrying.', 0
          @retrying = true

        $timeout =>
          @open()
        , 3000

      @loadScript().then createInstance, retryScript

    setFurnitureMetadata: (id, mastheadCodes, themeNames, sectionNames, callback) ->
      callback @adonis.furnitureTemplateCommandInterface(id).setMetadata(mastheadCodes, themeNames, sectionNames)

    editPublication: (name, callback) ->
      callback @adonis.publication name

    subscribe: ([method, id], callback) ->
      if @adonis
        @adonis.subscribe method, id, (resp) ->
          $rootScope.$applyAsync ->
            callback resp.args
            return
      else @queue.push { command: 'subscribe', callback: callback, data: [method, id] }

    unsubscribe: (method, id) ->
      if @adonis
        @adonis.unsubscribeAll method, id
      else @queue.push { command: 'unsubscribeAll', callback: null, data: [method, id] }

    send: (fnName, callback, args...) ->
      if fnName is 'subscribe'
        @subscribe args, callback
      else @adonis[fnName](args...)

    once: (presenter, id = null, cache = false) ->
      deferred = $q.defer()
      $http
        method: 'GET'
        url: "#{HOSTS.adonis}/presenters/#{presenter}/#{id}"
        cache: cache
        auth: true
      .success (data, status, headers, config) ->
        state = data.args.state
        deferred.resolve (state.v or state)
      .error (data, status, headers, config) ->
        deferred.reject data, status
      deferred.promise

    query: (view, id, cache = false) ->
      deferred = $q.defer()
      $http
        method: 'GET'
        url: "#{HOSTS.adonis}/query/#{view}/#{id}"
        cache: cache
        auth: true
      .success (data, status, headers, config) ->
        deferred.resolve data.args
      .error (data, status, headers, config) ->
        deferred.reject data, status
      deferred.promise

    assignLeftPageLayout: (destSpreadId, srcPageLayout) ->
      @adonis.assignLeftPageLayout destSpreadId, srcPageLayout

    assignRightPageLayout: (destSpreadId, srcPageLayout) ->
      @adonis.assignRightPageLayout destSpreadId, srcPageLayout

    restoreRightPageLayout: (spreadId) ->
      @adonis.restoreDefaultRightPageLayout spreadId

    restoreLeftPageLayout: (spreadId) ->
      @adonis.restoreDefaultLeftPageLayout spreadId

    clearHole: (holeId, layoutId) ->
      @adonis.clearPageStoryTask layoutId, holeId

    flush: ->
      while item = @queue.pop()
        {command, data, callback} = item
        @send command, callback, data...

  new AdonisStream HOSTS.adonis_ws
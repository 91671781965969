'use strict'

angular.module 'nn.task.channel', []

  .directive 'nnTaskChannel', (Workflow) ->
    templateUrl: '/templates/task-channel.html'
    link: (scope, element, attrs) ->
      scope.$watch 'task.available_to', ->
        availableToSingleChannel = scope.task.available_to?.length is 1
        availableToPrintChannel = scope.task.available_to?.length and 'print' in scope.task.available_to
        scope.printOnlySelected = availableToSingleChannel and availableToPrintChannel

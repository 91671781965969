'use strict'

angular.module 'adonis.issue.collections.pagelayouts.elements.furniture', []

.factory 'pageLayoutFurniture', (BasePresenter, elementsParser, TaskService, NotificationService) ->
  class pageLayoutFurniture extends BasePresenter
    constructor: ($queryStream, element, @holeId) ->
      super $queryStream

      @sync element

    sync: (elem) ->
      @name = elem.furnitureType
      @crop = elem.crop

      if elem.placement?.v
        @id = elem.placement.v.generatable?.v?.template?.id or null
        @task = elem.placement.v.ref?.taskId or null

      angular.extend @, elementsParser.createHole elem.bounds

      if elem.formConsumers?.length > 0
        @consumers = elem.formConsumers

      #for override, k in over
      #  if override?[1]?.boundingBox and angular.equals override[1].boundingBox, elem.bounds
      #    @override = elementsParser.createHole override[1].boundingBox, override[0]
      #    @override.task = parseInt override[1].utility.id

    overrideHole: ->
      x: { value: @override.x }, y: { value: @override.y }

    assignId: (id, page, override = false) ->
      if override or @consumers
        @query 'assign' + @getCommandType() + 'FurnitureElementTask', @holeId, @overrideHole(), parseInt(id)
      else @query 'assign' + @getCommandType() + 'FurnitureTask', @holeId, parseInt(id)

    clear: ->
      NotificationService.confirm('Are you sure you want to remove this task?').then =>
        if @override or @consumers
          @query 'clear' + @getCommandType() + 'FurnitureElementTask', @holeId, @overrideHole(), @override.task
        else @query 'clear' + @getCommandType() + 'FurnitureTask', @holeId, @task

    toggleAutoCrop: ->
      if @crop
        NotificationService.confirm('Are you sure you want to clear the crop for this furniture?').then =>
          @query 'clearEmbeddedFurnitureCrop', @holeId
      else
        NotificationService.confirm('Are you sure you want to set the crop for this furniture?').then =>
          @query 'setAutoCrop', @holeId

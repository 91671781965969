worker = ->

  'use strict'

  pathUtilities =
    round: (value) ->
      value.toFixed 3

    makeBoundingPath: ({x, y, height, width}) ->
      'M' + @round(x) + ' ' + @round(y) + 'H' + @round((x + width)) + 'V' + @round((y + height)) + 'H' + @round(x) + ' Z'

    unpackBoundingBox: ({topLeft, bottomRight}, offset = x: 0, y: 0) ->
      x: (topLeft.x.value + offset.x), y: (topLeft.y.value + offset.y), height: (bottomRight.y.value - topLeft.y.value), width: (bottomRight.x.value - topLeft.x.value)

    unpackLineObject: ({left, top, height, width}, offset) ->
      x: left.value + offset.x, y: top.value + offset.y, height: height.value, width: width.value

    unpackUndersetObject: ({topLeft, bottomRight}, cl, offset) ->
      x: (topLeft.x.value + offset.x), y: (cl.cursorY.value + offset.y), height: cl.vSpaceRemaining.value, width: (bottomRight.x.value - topLeft.x.value)

  copyfitElementColours =
    line: 'lightgrey'
    overset: '#e74c3c'
    underset: '#f1c40f'

  imageElementColours =
    valid: '#2ecc71'
    notvalid: '#e74c3c'

  functionsObject =
    compileStoryElements: (data, offset) ->
      path = ''

      for el, v of data
        path += pathUtilities.makeBoundingPath(
          x: (v.x + offset.x)
          y: (v.y + offset.y)
          width: v.width
          height: v.height
        )

      postMessage path

    buildPath: (data, offset) ->
      linePath = ''
      oversetPath = ''
      undersetPath = ''
      imagePaths = []

      wrapPath = (color, path) ->
        colour: color, path: path if path isnt null

      renderImageBox = (item, sc) =>
        boxColour = if sc.status.t is 'IsValid' then imageElementColours.valid else imageElementColours.notvalid
        boxPath = pathUtilities.makeBoundingPath pathUtilities.unpackBoundingBox(item.boundingBox, offset)

        image = wrapPath boxColour, boxPath
        image.validation = height: Math.round(sc.minHeight.value), width: Math.round(sc.minWidth.value)
        image

      gatherData = (status = 'NoContent', columns) =>
        if status isnt 'NoContent' and columns
          columnLength = columns.length - 1

          # gather each line of each columns copyfit
          for clIndex, column of columns
            lineLength = column.linesConsumed - 1

            for lineIndex, line of column.lines
              # if overset stop before it the last item and add it to the overset path list
              if status is 'Overset' and parseInt(clIndex) is columnLength and parseInt(lineIndex) is lineLength
                oversetPath += pathUtilities.makeBoundingPath pathUtilities.unpackLineObject(line, offset)
              else linePath += pathUtilities.makeBoundingPath pathUtilities.unpackLineObject(line, offset)

            # if underset figure out remaining vertical space and fill
            if status is 'Underset'
              undersetPath += pathUtilities.makeBoundingPath pathUtilities.unpackUndersetObject(column.bounds, column, offset)

        true

      renderElement = (item) ->
        sc = item.info.v
        gatherData sc.status?.t, sc.columns

      storyElements = (data) ->
        for key, item of data
          # capture copyfit elements that actually exist
          if Array.isArray item
            for idx, value of item
              renderElement value
          else if item?.info?.v?
            renderElement item

      figureElements = (figures) ->
        for key, figure of figures

          # capture caption details
          if figure.caption?.info?.v?
            sc = figure.caption.info.v
            gatherData sc.status?.t, sc.columns

          # caption image details
          if figure.image?.info?.v?
            imagePaths.push renderImageBox figure.image, figure.image.info.v

      copyfitObject = ->
        # wrap path element around the seperate items and join together
        lines: wrapPath copyfitElementColours.line, linePath
        overset: wrapPath copyfitElementColours.overset, oversetPath
        underset: wrapPath copyfitElementColours.underset, undersetPath
        images: imagePaths

      copyfitElements = [storyElements(data), figureElements(data.figures)]

      postMessage copyfitObject()

  self.onmessage = (oEvent) ->
    if oEvent.data instanceof Object and oEvent.data.hasOwnProperty('functionName') and oEvent.data.hasOwnProperty('functionArgs')
      functionsObject[oEvent.data.functionName].apply self, oEvent.data.functionArgs
    return

  return

module.exports = worker.toString()
'use strict'

angular.module 'ngQuickDate', [
  'ngQuickDate.directives'
]

.constant 'ngQuickDateConfig', 
  dateFormat: 'DD/MM/YYYY'
  timeFormat: 'h:mm A'
  labelFormat: null
  placeholder: 'Click to Set Date'
  hoverText: null
  disableOther: false
  closeButtonHtml: '<i class="fa fa-times"></i>'
  buttonIconHtml: '<i class="fa fa-clock-o"></i>'
  nextLinkHtml: '<i class="fa fa-chevron-right"></i>'
  prevLinkHtml: '<i class="fa fa-chevron-left"></i>'
  clearButtonHtml: 'Clear'
  okButtonHtml: 'Okay'
  dateLabelHtml: 'Date'
  timeLabelHtml: 'Time'
  disableTimepicker: false
  disableDatepicker: false
  disableClearButton: false
  disableOkButton: false
  defaultTime: null
  timeFormats: ['HH:mm', 'H:mm a', 'H:mm A', 'HH:mm a', 'ha', 'hA', 'HH:mm:SS', 'HH;mm', 'H;mm a', 'H;mm A', 'HH;mm a', 'HH;mm;SS', 'H.mm A', 'H.mm a', 'h.mm', 'H.mm']
  dayAbbreviations: ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa']
  dateFilter: null
  dateHighlighter: null
  defaultCalendarDate: null
  readOnly: false
  parseDateFunction: (str) ->
    m = moment(str)
    if !m.isValid()
      return null
    else m
      
.provider 'ngQuickDateDefaults', (ngQuickDateConfig) ->
  options: ngQuickDateConfig
  
  $get: -> @options

  set: (keyOrHash, value) ->
    if typeof(keyOrHash) == 'object'
      for k, v of keyOrHash
        @options[k] = v
    else @options[keyOrHash] = value





'use strict'

angular.module 'nn.roles', [
  'nn.roles.directives'
  'nn.roles.services'
  ]

.constant 'RolesConfig',
  editor:
    routes: ['dashboard', 'account', 'stories', 'pages', 'forms', 'files', 'communities', 'newslist', 'site', 'sports']
    permissions: ['archiveTask', 'deleteTask', 'modifyTask', 'addCmykImageToStory', 'Unflag', 'bypassImageSizeValidation', 'sendBreakingNews']

  editorial_production_coordinator:
    routes: ['dashboard', 'account', 'stories', 'pages', 'forms', 'files', 'communities', 'admin', 'site', 'newslist', 'sports']
    permissions: ['archiveTask', 'deleteTask', 'modifyTask', 'addCmykImageToStory', 'Unflag', 'bypassImageSizeValidation', 'administerBarcodes']

  technical_support:
    routes: ['dashboard', 'account', 'stories', 'pages', 'forms', 'files', 'communities', 'newslist', 'site', 'sports']
    permissions: ['archiveTask', 'deleteTask', 'modifyTask', 'addCmykImageToStory', 'Unflag', 'bypassImageSizeValidation']

  admin:
    routes: ['dashboard', 'account', 'stories', 'pages', 'forms', 'files', 'communities', 'admin', 'reports', 'newslist', 'adonis', 'site', 'sports']
    permissions: ['archiveTask', 'deleteTask', 'modifyTask', 'addCmykImageToStory', 'Unflag', 'bypassImageSizeValidation', 'administerPublication', 'administerBarcodes', 'sendBreakingNews']

  design_manager:
    routes: ['dashboard', 'account', 'stories', 'pages', 'forms', 'files', 'communities', 'admin', 'newslist', 'site', 'sports']
    permissions: ['archiveTask', 'deleteTask', 'modifyTask', 'bypassImageSizeValidation', 'administerBarcodes']

  designer:
    routes: ['dashboard', 'account', 'stories', 'pages', 'forms', 'files', 'communities', 'admin', 'newslist', 'site', 'sports']
    permissions: ['bypassImageSizeValidation', 'administerBarcodes']

  journalist:
    routes: ['dashboard', 'account', 'stories', 'forms', 'files', 'communities', 'newslist', 'sports']
    permissions: ['archiveTask', 'bypassImageSizeValidation']

  cadet:
    routes: ['dashboard', 'account', 'stories', 'files', 'communities', 'newslist', 'sports']
    permissions: ['archiveTask', 'bypassImageSizeValidation']

  photographer:
    routes: ['dashboard', 'account', 'stories', 'files', 'communities', 'newslist', 'sports']
    permissions: ['bypassImageSizeValidation']

  advertising_manager:
    routes: ['dashboard', 'account', 'pages', 'files', 'communities', 'newslist', 'site', 'sports']
    permissions: ['bypassImageSizeValidation']

  advertising_production:
    routes: ['dashboard', 'account', 'pages', 'files', 'communities', 'newslist', 'site', 'sports']
    permissions: ['bypassImageSizeValidation']

  author:
    routes: ['dashboard', 'account', 'stories', 'files', 'communities', 'newslist', 'sports']
    permissions: ['bypassImageSizeValidation']

  contributor:
    routes: ['dashboard', 'account', 'stories', 'files', 'communities', 'sports']
    permissions: ['archiveTask', 'bypassImageSizeValidation', 'addCmykImageToStory']

.run ($rootScope, $location) ->
  $rootScope.$on '$stateChangeError', (current, previous, rejection) ->
    $location.path '/' if rejection is 'unauthorized'

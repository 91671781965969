'use strict'

angular.module 'nn.publication-manager', [
  'nn.publication-manager.routes'
  'nn.publication-manager.services'
  #components
  'nn.publication-explorer'
  'nn.publication-editor'
  'nn.publication-lookup'
  'nn.publication-features'
]

.constant 'Publications', [
      { id:1, name: "Ararat Advertiser", code: "GRA" }
      { id:2, name: "Bendigo Advertiser", code: "BAT" }
      { id:3, name: "Cootamundra Herald", code: "CCH" }
      { id:4, name: "Eastern Riverina Chronicle", code: "ERC" }
      { id:5, name: "Harden Murrumburrah Express", code: "CHM" }
      { id:6, name: "Riverina Weekender", code: "WRW" }
      { id:7, name: "Southern Cross", code: "JSC" }
      { id:8, name: "Southern Weekly", code: "SWM" }
      { id:9, name: "Stawell Times News", code: "GRA" }
      { id:10, name: "Stock & Land", code: "STL" }
      { id:11, name: "The Advocate", code: "ADV" }
      { id:12, name: "The Area News", code: "GAN" }
      { id:13, name: "The Border Mail", code: "BMM" }
      { id:14, name: "The Courier", code: "BAL" }
      { id:15, name: "The Daily Advertiser", code: "WDA" }
      { id:16, name: "The Irrigator", code: "LEI" }
      { id:17, name: "The Leader", code: "RIL" }
      { id:18, name: "The Moyne Gazette", code: "WW" }
      { id:19, name: "The Observer", code: "CBO" }
      { id:20, name: "The Rural", code: "TR" }
      { id:21, name: "The Standard", code: "WS" }
      { id:22, name: "The Weekend Advertiser", code: "WKA" }
      { id:23, name: "Wimmera Mail Times", code: "WMT" }
]

.constant 'PublicationProvinces', [
      'ACT'
      'NSW'
      'NT'
      'QLD'
      'SA'
      'TAS'
      'VIC'
      'WA'
]

.constant 'BARCODES_BUCKET', 'BARCODES_BUCKET'

.constant 'PublicationFields', {
  name          : 'setName',
  printName     : 'setPrintName',
  province      : 'setProvince',
  defaultTheme  : 'setDefaultTheme',
  facebook      : 'setFacebook',
  twitter       : 'setTwitter',
  website       : 'setWebsite',
  printCentre   : 'setPrintCentre',
  organisations : 'setOrganisation',
  themeVariant  : 'setDefaultThemeVariant',
  removeSection : 'clearDefaultThemeVariant',
  barcodes      : 'setDefaultBarcode',
  removeBarcode : 'clearDefaultBarcode'
}

.constant 'PublicationBarcodeDays', [
  {key: 'MONDAY', label:'Monday', file:null},
  {key: 'TUESDAY', label: 'Tuesday', file:null},
  {key: 'WEDNESDAY', label: 'Wednesday', file:null},
  {key: 'THURSDAY', label: 'Thursday', file:null},
  {key: 'FRIDAY', label: 'Friday', file:null},
  {key: 'SATURDAY', label: 'Saturday', file:null},
  {key: 'SUNDAY', label: 'Sunday', file:null}
]

.constant 'PublicationCleanFields', [
      'code'
      'name'
      'facebook'
      'twitter'
      'website'
      'province'
      'printName'
      'printCentre'
      'organisations'
      'barcodes'
      'prices'
]

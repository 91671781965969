'use strict'

angular.module 'nn.app.services', []

    .constant 'HOSTS',
        __HOSTS__

    .constant 'Environment',
        __ENV_NAME__

    .constant 'ENVIRONMENTS',
        __ENV_NAMES__

'use strict'

angular.module 'nn.page-builder.filters', []

  .filter 'makeHuman', ->
    (input) ->
      switch input
        when 'Story'
          'Stories'
        when 'Advert'
          'Advertisements'
        else input

  .filter 'trustAs', ($sce) ->
      (input, type) ->
        if typeof input == "string"
          $sce.trustAs(type || 'html', input)
        else
          console.log("trustAs filter. Error. input isn't a string")
          ""
          
  .filter 'getPageTitle', ->
    (index, last) ->
      switch
        when index is 1
          'Cover'
        when last
          'Back'
        else index

  .filter 'holeAssignedColours', (advertElementColours, storyElementColours, furnitureElementColours) ->
    (input, type) ->
      switch type
        when 'story'
          (if input then storyElementColours.assigned else storyElementColours.unassigned)
        when 'furniture'
          (if input then furnitureElementColours.assigned else furnitureElementColours.unassigned)
        when 'advert'
          (if input is 'PUBLISHABLE' then advertElementColours.publishable else advertElementColours.notready)
        else 'transparent'

  .filter 'pagePreviews', (HOSTS) ->
    (uri, width = 250, height = 1, quality = '80') ->
      png = uri.match(/env\/(.*\/pipelines.*|.*\/exports.*)/)[1]
      "#{HOSTS.transform}/transform/v1/resize/nn-public/#{png}/w#{width.toFixed(0)}_h#{height.toFixed(0)}.png?quality=#{quality}"

'use strict'

angular.module 'nn.notification', [
    'nn.notification.directives'
    'nn.notification.services'
  ]

#  How To:
#
#  Dependency Inject "NotificationService"
#
#  Classes = success, error, info, wait, warning
#
#                          Class      Title    Body                             Fade  Body Type
#
#  NotificationService.create 'success', "title", '<ul><li>Body html</li></ul>', 5000, 'trustedHtml'
#  NotificationService.create 'error', "title", '<ul><li>Body html</li></ul>', null, 'trustedHtml'
#  NotificationService.create 'warning', "title", null, null, 'template'
#  NotificationService.create 'warning', "title", "myTemplate.html", null, 'template'
#  NotificationService.create 'note', "title", "My Cool Notification"
#
#  Confirmation Notification Dialog
#
#
#  NotificationService.confirm('title', 'body text').then ->
#     console.log 'confirmed'
#
#  notification =
#    notification:
#       body: "bleh"
#       bodyOutputType: "confirm"
#       deferred: deferred
#       id: 1
#       timeout: 0
#       title: "test"
#       type: "notification-info"

.constant 'NotificationConfig',
  limit: 0
  'tap-to-dismiss': true
  'close-button': true
  'newest-on-top': true
  'time-out': 3000
  'icon-classes':
    error: 'notification-error'
    info: 'notification-info'
    wait: 'notification-wait'
    success: 'notification-success'
    warning: 'notification-warning'

  'body-output-type': ''
  'body-template': 'notificationBodyTmpl.html'
  'confirm-template': 'confirmDialog.html'
  'icon-class': 'notification-info'
  'position-class': 'notification-top-right'
  'title-class': 'notification-title'
  'message-class': 'notification-message'

'use strict'

angular.module 'nn.page-builder', [
    'nn.page-builder.routes'
    'nn.page-builder.directives'
    'nn.page-builder.services'
    'nn.page-builder.filters'
    # components
    'nn.page-explorer'
    'nn.page-info'
    'nn.page-thumbnail'
    'nn.page-thumb-editor'
    'nn.page-list'
    'nn.page-editor'
    'nn.page-tops'
    'nn.issue-list'
    'nn.comments-list'
    'nn.validation-list'
    'nn.layout-list'
    'nn.print-stories-table'
  ]

.run (webworkerBlob) ->
    webworkerBlob.get()

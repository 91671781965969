'use strict'

angular.module 'adonis.issue.collections.spreads.spread.tops', []

.factory 'spreadsTops', (BasePresenter, $filter) ->
  class spreadsTops extends BasePresenter
    constructor: ($queryStream, initData) ->
      super $queryStream
      @sync initData

    sync: (obj) ->
      @banner = obj?.banner
      @tagline = obj?.tagline
      @title = obj?.title or obj?.sectionName
      @subtitle = obj?.subtitle or obj?.subsectionName
      # @title = $filter('properCase')(obj?.title or obj?.sectionName)
      # @subtitle = $filter('properCase')(obj?.subtitle or obj?.subsectionName)

    defaultAll: (side = '{{page}}') ->
      @setSectionToDefault(side)
      @setSubsectionToDefault(side)
      @setBannerToDefault(side)

    setSectionName: (side = '{{page}}') ->
      @query "set#{side}SectionName", @title
      @setSubsectionToDefault(side)
      @setBannerToDefault(side)

    setSectionToDefault: (side = '{{page}}') ->
      @query "set#{side}SectionToDefault"

    setSubsectionName: (side = '{{page}}') ->
      @query "set#{side}SubsectionName", @subtitle

    setSubsectionToDefault: (side = '{{page}}') ->
      @query "set#{side}SubsectionToDefault"

    setTagline: (side = '{{page}}')->
      @query "set#{side}Tagline", @tagline

    clearTagline: (side = '{{page}}') ->
      @query "clear#{side}Tagline"
      @tagline = null

    setBanner: (side = '{{page}}') ->
      @query "set#{side}Banner", @banner

    setBannerToDefault: (side = '{{page}}') ->
      @query "set#{side}BannerToDefault"
      @banner = null

angular.module 'nn.dragndropsvg.utils', []

.factory 'SVGDragUtils', (dndHelpers) ->
  lastEnter = null

  dragDataTypes = []

  setDragData: (data) ->
    dndHelpers.dragTypeWorkaround =
      dragType: 'node'
      isDragging: true
      block: data

    dndHelpers.dropEffectWorkaround.dropEffect = 'move'

    return

  clearDragData: ->
    dndHelpers.dragTypeWorkaround =
      dragType: 'node'
      isDragging: false
      block: null

    dndHelpers.dropEffectWorkaround.dropEffect = 'none'

    return

  synthesizeEnterLeave: (event) ->
    target = event.target

    if target != lastEnter
      if lastEnter
        @dispatchLeave event

      lastEnter = target

      if lastEnter
        @dispatchEnter event

    if lastEnter
      @dispatchOver event

    return

  dispatchDrop: (target, event) ->
    dropEvt = document.createEvent 'Event'
    dropEvt.initEvent 'drop', true, true

    dropEvt.dataTransfer =
      types: dragDataTypes
      dropEffect: 'move'

    dropEvt.offsetX = event.pageX
    dropEvt.offsetY = event.pageY

    dropEvt.preventDefault = ->
      angular.noop()

    angular.element(document).one 'drop', ->
      angular.noop()

    target.dispatchEvent dropEvt

    return

  dispatchEnter: (event) ->
    enterEvt = document.createEvent 'Event'
    enterEvt.initEvent 'dragenter', true, true

    enterEvt.dataTransfer =
      types: dragDataTypes

    enterEvt.pageX = event.pageX
    enterEvt.pageY = event.pageY

    lastEnter.dispatchEvent enterEvt

    return

  dispatchDragEnd: (event) ->
    element = event.target

    if lastEnter
      @dispatchLeave event

    target = event.target

    if target
      @dispatchDrop target, event

    dragendEvt = document.createEvent 'Event'
    dragendEvt.initEvent 'dragend', true, true

    element.dispatchEvent dragendEvt

    return

  dispatchOver: (event) ->
    overEvt = document.createEvent 'Event'
    overEvt.initEvent 'dragover', true, true

    overEvt.dataTransfer =
      types: dragDataTypes

    overEvt.pageX = event.pageX
    overEvt.pageY = event.pageY

    lastEnter.dispatchEvent overEvt

    return

  dispatchLeave: (event) ->
    leaveEvt = document.createEvent 'Event'
    leaveEvt.initEvent 'dragleave', true, true

    leaveEvt.dataTransfer =
      types: dragDataTypes

    leaveEvt.pageX = event.pageX
    leaveEvt.pageY = event.pageY

    lastEnter.dispatchEvent leaveEvt

    lastEnter = null

    return

  dispatchDragStart: (element) ->
    evt = document.createEvent 'Event'
    evt.initEvent 'dragstart', true, true

    evt.dataTransfer =
      dropEffect: 'move'
      setData: (type, val) ->
        dragData[type] = val
        if dragDataTypes.indexOf(type) == -1
          dragDataTypes[dragDataTypes.length] = type
        val

    element.dispatchEvent evt

'use strict'

angular.module 'adonis.issue.collections.pagelayouts', [
	'adonis.issue.collections.pagelayouts.elements'
]

.factory 'pageLayoutsRepo', (BasePresenter) ->
  class pageLayoutsRepo extends BasePresenter
    constructor: ($queryStream, initData) ->
      super $queryStream, initData

      for pageId, pageTemplate of initData
        if @[pageId]
          @[pageId].sync pageTemplate, pageId
        else @[pageId] = @getEntity 'pageLayouts', pageTemplate, pageId

.factory 'pageLayoutsEntity', (BasePresenter, pageLayoutElements) ->
  class pageLayoutsEntity extends BasePresenter
    constructor: ($queryStream, initData, holeId) ->
      super $queryStream, initData

      @sync initData, holeId

      @$queryStream.listen (query) =>
        query.data.unshift holeId
        query

    sync: (initData, holeId) ->
      @template = initData.template?.v?.id or null

      if @elements
        @elements.sync initData.storyHoles, initData.furnitureHoles
      else @elements = new pageLayoutElements @$queryStream, initData.storyHoles, initData.furnitureHoles

'use strict'

angular.module 'nn.sports-results.directives', []

    .directive 'nnSportsResults', () ->
        templateUrl: '/templates/sports-results.html'
        restrict: 'E'
        scope: {}
        controller: ($scope, NotificationService) ->
            $scope.api =
                Notification: NotificationService

    .directive 'nnSportsResultsReactApp', (reactDirective) ->
        reactDirective(window.sports.PlayHQSportsResults, ['api'])
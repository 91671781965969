'use strict'

angular.module 'nn.form-builder.services', []
    .factory 'FormsUser', ->
        users = null
        return {
            all: ->
                users
            refresh: ->
                users = null
                users = [
                        { name:'all forms', value:'' }
                        { name:'my forms', value:'self' }
                    ]
                @
        }
    .factory 'FormsTime', ->
        times = null
        return {
            all: ->
                times
            refresh: ->
                times = null
                times = [
                        { name:'any time', value:'' }
                        { name:'past 24 hours', value:'-1d' }
                        { name:'past week', value:'-1w' }
                        { name:'past month', value:'-1m' }
                        { name:'past year', value:'-1y' }
                    ]
                @
        }
    .factory 'FormsStatus', ->
        statuses = null
        return {
            all: ->
                statuses
            refresh: ->
                statuses = null
                statuses = [
                        { name:'all status', value:'' }
                        { name:'draft', value:'d' }
                        { name:'published', value:'p' }
                        { name:'private', value:'r' }
                    ]
                @
        }
'use strict'

angular.module 'nn.copyfit-assistant-panel.services', []

.factory 'CopyfitAssistantPanelService', (CopyfitAssistantPanelServiceProvider) ->
  CopyfitAssistantPanelServiceProvider.getInstance()

.factory 'CopyfitAssistantPanelServiceProvider', ($http, $q, Auth, HOSTS) ->
  class CopyfitAssistantPanel
      @API_URL: "#{HOSTS.sochi}/proxy/copyfit/feedback"
      constructor
      sendFeedback: (story_id, organization_id, revisionNumber, isLiked, element, comment) ->
        [elementType, elementIndex] = element.split " "
        if elementType == "caption"
          data =
            "feedback": {
              "captions": [
                {
                  "liked": isLiked
                  "content": comment
                  "index": Number(elementIndex)+1
                }
              ]
            }
        else
          data =
            "feedback": {
              "#{element}": {
                "liked": isLiked
                "content": comment
              }
            }
        Auth.token().then (token) ->
          deferred = $q.defer()
          $http
            method: 'PATCH',
            url: "#{CopyfitAssistantPanel.API_URL}?storyId=#{story_id}&orgId=#{organization_id}&revisionNumber=#{revisionNumber}"
            data: JSON.stringify data
            cache: false
            headers: {
              "Content-type": "application/json"
              'Authorization': "Bearer #{token}"
            }
            auth: true
          .success (response) ->
            deferred.resolve response
          .error (response) ->
            deferred.reject response
  getInstance: ->
    new CopyfitAssistantPanel

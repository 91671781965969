'use strict'

angular.module 'nn.favourites-list.services', []

  .constant 'FavouritesType',
    STORY: 'story'
    FILE: 'files'
    FORM: 'forms'

  .factory 'Favourites', ($q, $http, Auth, HOSTS, NotificationService, FavouritesType) ->
    API_URI = HOSTS.monza + '/api/v2/user_store/'

    urls = {}
    urls[FavouritesType.STORY] = "#{HOSTS.silverstone}/api/v1/story/?id__in="
    urls[FavouritesType.FILE] = "#{HOSTS.valencia}/api/v1/object/?format=json&id__in="
    urls[FavouritesType.FORM] = "#{HOSTS.silverstone}/api/v2/forms/?format=json&id__in="

    labels = {}
    labels[FavouritesType.STORY] = 'Story'
    labels[FavouritesType.FILE] = 'File'
    labels[FavouritesType.FORM] = 'Form'

    callService = (deferred, type, response) ->
      ids = []
      for items in response.results
        ids.push items.value
      $http
        method: 'GET',
        url: urls[type] + ids.join(',')
        cache: false
        auth: true
      .success (res) ->
        deferred.resolve angular.extend res, results: response.results

    query = (config, id, type, list) ->
      filter = type
      if angular.isDefined(id) then URI = API_URI + id + '/' else URI = API_URI
      if angular.isDefined(type)
        type = '?type=' + type
      else
        type = ''

      deferred = $q.defer()

      defaults =
        url: URI + type
        cache: false
        auth: true

      angular.extend config, defaults

      getFavouriteLabelForType = (type) ->
        if type? then labels[type] else 'Favourite'

      $http(config)
      .success (response) ->
        label = getFavouriteLabelForType(response.type)
        switch config.method
          when 'POST'
            NotificationService.success label, 'added to favourites'
          when 'DELETE'
            NotificationService.success label, 'removed'
        if list
          callService deferred, filter, response
        else
          deferred.resolve response
      .error (response) ->
        deferred.reject response
      deferred.promise

    favourite =
      all: (type, list) -> query { method: 'GET' }, undefined, type, list
      add: (d, t, v) -> query { method: 'POST', data: { domain: d, type: t, value: v} }
      remove: (type, id) -> query { method: 'DELETE' }, id
      update: (id, value) -> query { method: 'GET'}

    favourite

'use strict'

angular.module 'nn.pubsub', [
    'nn.pubsub.services'
]

### *

PubSub.getCache '/event/hello'

id = PubSub.subscribe '/event/hello', (arg1, arg2) ->
  $scope.hello = arg1

  # first run  --> arg1 = 'Hello', arg2 = 'Hello2'
  # second run --> arg1 = 'World', arg2 = 'World2'

PubSub.unsubscribe id

$scope.$on '$destory', ->
  PubSub.unsubscribe id

PubSub.publish '/event/hello', 'Hello', 'Hello2'
PubSub.publish '/event/hello', 'World', 'World2'

###
'use strict'

angular.module 'nn.layout-list.directives', []

  .directive 'nnLayoutList', ->
    templateUrl: '/templates/layout-list.html'
    restrict: 'E'
    scope:
      page: '='
      issue: '='
      pages: '='
      active: '='
    controller: ($scope, PageTemplatesProvider, IssuePresenter, PageThumbnailStyle) ->
      scalingNumber = 0.335

      $scope.pageStyle = (page, layout) ->
        height = (page.height + page.margins.top + page.margins.bottom) / 2 * scalingNumber
        width = (page.width + page.margins.inside + page.margins.outside) / 2 * scalingNumber

        isSpread = $scope.spread?.info?.is_spread

        # as the layouts are rendered by the pages width this fixes displaying
        # non spread layouts if its currently a spread
        # spread layouts if its not currently a spread

        if isSpread and not layout.spread
          width = width / 2
        else if not isSpread and layout.spread
          width = width * 2

        width: width + 'px', height: height + 'px'

      templatesProvider = PageTemplatesProvider.getInstance()
      $scope.layouts = templatesProvider

      search = ->
        return unless $scope.spread?.info?.edspace and $scope.page?.edspace
        templatesProvider.spreadHash = $scope.spread.info.edspace
        templatesProvider.editorialSpace = $scope.page.edspace
        templatesProvider.searchQuery = ''
        templatesProvider.search()

      $scope.$watchCollection 'spread', (nv, ov) ->
        return unless nv? and ov?

        if nv.pages and ov.page or nv.page and ov.pages

          if nv.info.is_spread
            $scope.page = nv.page
          else
            $scope.page = nv.pages[$scope.active.side]

          search()

      $scope.$watch 'active', (nv, ov) ->
        if nv and nv.number and nv.book
          { spread, active, bookLength } = IssuePresenter.findIndex $scope.pages.books[nv.book], nv.number

          $scope.booklength = bookLength
          $scope.spread = spread

          if spread.info.is_spread
            $scope.page = $scope.spread.page
          else
            $scope.page = $scope.spread.pages[active]

          search()

      $scope.$watch 'layouts.searchQuery', (nv, ov) ->
        if nv != ov or !nv
          templatesProvider.search()

      $scope.handleFavClick = (e, layout) ->
        e.preventDefault()
        e.stopPropagation()
        templatesProvider.favourite(layout)
        layout['isFavourited'] = !layout['isFavourited']

      # $scope.filters = [
      #   { id: 'feat',  text: "Feature", selected: false }
      #   { id: 'llead', text: "Long Lead", selected: false }
      #   { id: 'lead', text: "Lead", selected: false }
      #   { id: 'slead',  text: "Short Lead", selected: false }
      #   { id: 'secon',  text: "Secondary", selected: false }
      #   { id: 'brief',  text: "Brief", selected: false }
      #   { id: 'supp',  text: "Supplementary", selected: false }
      #   { id: 'ssupp',  text: "Short Supplementary", selected: false }
      # ]

      # $scope.selection = []

      # $scope.selectedFilters = selectedFilters = ->
      #   filterFilter $scope.filters,
      #     selected: true

      # $scope.$watch "filters|filter:{selected:true}", (nv) ->
      #   $scope.selection = nv.map (filter) ->
      #     filter.text
      #   return
      # , true

'use strict'

angular.module 'adonis.base', []

.factory 'BasePresenter', ($q, Stream, $injector) ->
  class BasePresenter
    constructor: (parent$queryStream, initData = {}) ->
      @$queryStream = new Stream()

      @$listeners = []

      if parent$queryStream
        @$listeners.push @$queryStream.child parent$queryStream

    getInstance: (type, key, args...) ->
      if Instance = $injector.get "#{key}#{type}"
        return new Instance @$queryStream, args...
      else null

    getCommandType: -> if @spread then "Spread" else 'Page'

    getTemplate: (key, args...) ->
      @getInstance 'Template', key, args...

    getEntity: (key, args...) ->
      @getInstance 'Entity', key, args...

    getRepo: (key, args...) ->
      @getInstance 'Repo', key, args...

    query: (command, data...) ->
      if angular.isString data
        data = [data]

      @$queryStream.push { command: command, callback: null, data: data }

    subscribe: (presenter, id, callback = ->) ->
      @$queryStream.push { command: 'subscribe', callback: callback, data: [presenter, id] }

    unsubscribe: (presenter, id) ->
      @$queryStream.push { command: 'unsubscribeAll', callback: null, data: [presenter, id] }

    clone: ->
      constructor = Object.getPrototypeOf(@).constructor
      new constructor null, null, @

    destroy: ->
      while listener = @$listeners.pop()
        listener()

    resolve: $q.when

    reject: $q.reject

'use strict'

angular.module 'nn.form-editor-menu.directives', []
    #
    .directive 'nnFormEditorMenu', ->
        templateUrl: '/templates/form-editor-menu.html'
        restrict: 'E'
        scope: {}
        controller: ($scope, FieldTypes, FormModel) ->
            $scope.FieldMenus = []
            for item of FieldTypes
                $scope.FieldMenus.push FieldTypes[item]
            #
            # Functions available to the view...
            # Here we just need to add the item to the
            # form...
            #
            $scope.addItem = (itemType) -> 
                #
                # Add the object to the gridster page in the 
                # default location...
                #
                FormModel.addField itemType
                return
'use strict'

angular.module 'adonis.stream', []

.factory 'Stream', ->
  class Stream
    constructor: ->
      @listeners = []
      @children = []

    push: (data) ->
      changeData = (newValue) ->
        data = newValue

      for listener in @listeners
        data = listener(data, changeData) or data
          
      for childStream in @children
        childStream.push data

    listen: (listener) ->
      @listeners.push listener
      
      return =>
        @listeners = @listeners.filter (item) ->
          item isnt listener

    child: (stream) ->
      @children.push stream
      
      return =>
        @children = @children.filter (child) ->
          stream isnt child

    destroy: ->
      @listeners = []
      @children = []
      
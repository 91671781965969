'use strict'

angular.module 'nn.adonis', [
  # core components
  'adonis.stream'
  'adonis.service'
  'adonis.base'
  'adonis.presenters'

  #presenters
  'adonis.issue'
  'adonis.storyfit'
  'adonis.layoutpage'
]

###
 
  Form info, side panel
  ==========================

###
angular.module("nn.form-info.directives", [])
#
# Field info directive.
#
.directive "nnFormInfo", ->
    restrict: "E"
    scope: {}
    templateUrl: "/templates/form-info.html"
    controller: ($scope, FormModel, StorageService, $state, Requests) ->
        # Here we just pass the form model up to the
        # view...
        $scope.formModel = FormModel

        

#
# Field meta data
# ===============
# 
angular.module "nn.form-meta-panel.directives", []
#
# Field editor directive. This simply pulls the template onto
# the form. The selectedItem in the controller is used to
# populate the items in the template...
#    
.directive "nnFormMetaPanel", ->
    restrict: "E"
    scope: {}
    templateUrl: "/templates/form-meta-panel.html"
    controller: ($scope, FormModel) ->
        # Here we just pass the form model up to the
        # view...
        $scope.fm = FormModel

'use strict'

angular.module 'nn.chartbeat', [
    # common
    'nn.chartbeat.services'
    'nn.chartbeat.directives'
    # components
    'nn.chartbeat-list'
    'nn.chartbeat-stats'
]

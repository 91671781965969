'use strict'

angular.module 'nn.story-explorer.directives', []

    .directive 'nnStoryExplorer', ->
        templateUrl: '/templates/story-explorer.html'
        restrict: 'E'
        scope: {}
        controller: ($scope, $state, AppState, Explorer, StorySections, PageBuilderModuleTypes, TaskServiceProvider, TASK_ASSIGNEE, TASK_STATUS, StoryExplorer) ->

            tasksDeadlines = TaskServiceProvider.getInstance()
            tasksDeadlines.assignee = TASK_ASSIGNEE.USER
            tasksDeadlines.status = TASK_STATUS.OPEN

            tasksFlagged = TaskServiceProvider.getInstance()
            tasksFlagged.assignee = TASK_ASSIGNEE.USER
            tasksFlagged.status = TASK_STATUS.OPEN
            tasksFlagged.flagged = true

            $scope.tasksDeadlines = tasksDeadlines.refresh()
            $scope.tasksFlagged = tasksFlagged.refresh()


            $scope.moduletypes = PageBuilderModuleTypes
            $scope.sections = StorySections
            $scope.state = StoryExplorer
            $scope.selectSection = (section) ->
                StoryExplorer.menuItems = true
                StoryExplorer.menuItemSelected = StorySections.indexOf(section)
            $scope.back = ->
                StoryExplorer.menuItems = false
                StoryExplorer.menuItemSelected = null
            $scope.createStory = ->
                AppState.canvas = 'story'
                AppState.story = null

                $state.go 'app.stories.new'

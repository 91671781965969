'use strict'

angular.module 'nn.page-tops.services', []

  .constant 'PageFurnitureIdentifiers',
    CT_FOLIO_STRAP: 'CT-FS-Folio-Strap'
    FOLIO_STRAP: 'FS-Folio-Strap'
    PAGE_BANNER: 'BA-Page-Banner'
    SECTION_FRONT: 'BA-Section-Front-Banner'

  .constant 'PageFurnitureSections', {

    # CT Sections begin
    'Panorama': [
      'Books'
      'Capital Life'
      'Cover Story'
      'Film'
      'Food'
      'Travel'
      'The Planner'
      'Visual Art'
      'Review'
      'Puzzles'
      'Television'
      'History'
      'People'
    ]

    'Relax': [
      'Private Capital'
      'Gardening'
      'Home'
      'Socials'
      'Cover Story'
      'Arts & Culture'
      'People'
    ]

    'Food & Wine': [
      'Cover Story'
      'Kitchen Garden'
      'Morsels'
      'Recipes'
      'Review'
    ]

    'Public Sector Informant': [
      'Public Eye'
      'Personnel'
      'Management'
      'Politics'
      'Policy'
      'Integrity'
      'History'
      'Profile'
    ]

    'Forum': [
      'Commentary'
      'Feature'
      'Profile'
      'Comment & Letters'
    ]

    'Sunday Focus': [
      'Commentary'
      'Feature'
      'Profile'
      'Comment & Letters'
    ]
    # CT Sections end

    'News': [
      'Business'
      'Education'
      'Council'
      'Government'
      'Obituaries'
      'Federal Politics'
      'ACT Assembly'
      'Public Service'
    ]

    'Life': [
      'Community News'
      'People & Places'
      'Weddings'
      'Anniversaries'
      'History'
      'Pets'
      'Kids'
      'Entertainment'
      'Arts'
      'Community Notices'
      'Obituaries'
      'Babies'
      'School'
      'Reader Pictures'
      'Photo Gallery'
    ]

    'Comment': [
      'Opinion'
      'Your Opinion'
      'Our Opinion'
    ]

    'Sport': [
      'Junior Sport'
      'Cricket'
      'Fishing'
      'Football'
      'AFL'
      'NRL'
      'Racing'
      'Netball'
      'National'
      'World'
      'Tipping'
      'People & Places'
      'Basketball'
      'Soccer'
      'Hockey'
      'Country Football'
      'Rugby League'
      'Rugby Union'
    ]

    'Rural': [
      'News'
      'Opinion'
      'Review'
      'Agribusiness'
      'Sheep'
      'Cattle'
      'Crops'
      'Dairy'
      'Markets'
      'Reports'
      'People & Places'
    ]

    'State': [
      'ACT'
      'NSW'
      'NT'
      'Queensland'
      'Tasmania'
      'Victoria'
      'WA'
      'SA'
    ]

    'Regional': [
      'Northern'
      'Southern'
    ]

    'Weekender': [
      'What\'s On',
      'Entertainment',
      'TV Guide',
      'Puzzles',
      'Cover Story'
    ]
    'Domain': []
    'Auto': []
    'Classifieds': []
    'Trades & Services': []
    'TV Guide': []
    'Weather': []
    'Comics': []
    'Puzzles': []
    'Horoscopes': []
    'Advertising Feature': []
    'World': []
    'National': []
    'Markets': []
    'Agribusiness': []
    'On The Farm': []
    'Magazine': []
    'Livestock': []
    'Thoroughbreds': []
    'All Homes': []
    'Arts': []
    'Comment & Letters': []
    'Take 5': []
    'Business': []
    'Drive': []
    'review': []
    'Real Estate': []
  }

  # Role Types: author, editor, newsdirector, chiefofstaff, journalist, cadet, photographer', designmanager, designer, advertisingmanager
  # Upon Login set role - Roles.attachRole 'member'

'use strict'

angular.module 'nn.roles.services', []

#Debug purposes to test different roles
#.run (Roles, RolesConfig) ->
#    RolesConfig.debugMode = true
#    Roles.attachRole 'author'

.factory 'Roles', (RolesConfig, $q, HOSTS, Auth) ->

  data =
    roles: [] #author default role

  #Does the given role have permissions granted to it?
  roleHasPermissions = (role) ->
    typeof RolesConfig[role].permissions is 'object'
  roleHasRoutes = (role) ->
    typeof RolesConfig[role].routes is 'object'

  #Retrieve the permissions array for the given role
  getRolePermissions = (role) ->
    if roleHasPermissions(role) then RolesConfig[role].permissions else []
  getRoutePermissions = (role) ->
    if roleHasRoutes(role) then RolesConfig[role].routes else []

  hasRoles: ->
    data.roles.length

  attachRoles: ->
    defer = $q.defer()
    Auth.user('org').then (result) ->
      for o, orgs of result
        data.roles.push orgs.role if orgs.role not in data.roles
      defer.resolve data.roles
    defer.promise

  attachRole: (role) ->
    data.roles.push role if role not in data.roles

  flushRoles: ->
    data.roles = []

  hasRole: (role) ->
    role not in data.roles

  canGo: (route) ->
    for i, role of data.roles
      routes = getRoutePermissions role
      return true if route in routes
    false

  can: (permission) ->
    for i, role of data.roles
      permissions = getRolePermissions role
      return true if permission in permissions
    false
'use strict'

angular.module 'nn.form-explorer.services', []
    #
    .factory 'FormExplorer', ->
        menuItems: false
        menuItemSelected: null
        fieldMenu: false
        formMenu: false
    #
    .constant 'FormSections', [
            { 'id':'searchforms', label:'Search forms', icon:'fa-search', state:'app.forms.search', visible: true }
            { 'id':'searchsubmissions', label:'Search submissions', icon:'fa-search', state:'app.forms.submissions.search', visible: true }
            { 'id':'recent', label:'My recent submissions', icon:'fa-calendar', state:'app.forms.recent', visible: true }
            { 'id':'favourites', label:'My favourites', icon:'fa-star', badgeCount:5, state:'app.forms.favourites', visible: true }
            # { 'id':'history', label:'My history', icon:'fa-clock-o', state:'app.forms.history', visible: true }
            { 'id':'searchedit', label:'Add Fields', icon:'fa-clock-o', state:'app.forms.search.form.edit', visible: false }
        ]
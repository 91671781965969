'use strict'

angular.module 'nn.page-builder.services', []

  .factory 'PageThumbnailStyle', ->
    scalingNumber = 0.335

    (page, layout) ->
      height = (page.height + page.margins.top + page.margins.bottom) / 2 * scalingNumber
      width = (page.width + page.margins.inside + page.margins.outside) / 2 * scalingNumber

      width: width + 'px', height: height + 'px'

  .factory 'elementsParser', (pathUtilities) ->
    createHole: (item, offset = x: { value: 0 }, y: { value: 0 }) ->
      x1 = item?.topLeft?.x?.value or 0
      y1 = item?.topLeft?.y?.value or 0
      x2 = item?.bottomRight?.x?.value or 0
      y2 = item?.bottomRight?.y?.value or 0

      x: x1 + offset.x.value
      y: y1 + offset.y.value
      height: y2 - y1
      width: x2 - x1

    format: (hole, offset) ->
      objects = {}
      path = ''

      angular.forEach hole.structure, (item, key) =>
        if angular.isArray item
          for el, v of item
            path += pathUtilities.makeBoundingPath @createHole(v.boundingBox, offset)

            id = key + '-' + (parseInt(el) + 1)
            objects[id] = @createHole(v.boundingBox, offset)

            if key is 'figures'
              path += pathUtilities.makeBoundingPath @createHole(v.image.boundingBox, offset)
              path += pathUtilities.makeBoundingPath @createHole(v.caption.boundingBox, offset) if v.caption?.boundingBox

              obj =
                image: @createHole(v.image.boundingBox, offset)
                caption: @createHole(v.caption.boundingBox, offset) if v.caption?.boundingBox

              angular.extend objects[id], elements: obj
        else if item
          path += pathUtilities.makeBoundingPath @createHole(item.boundingBox, offset)
          objects[key] = @createHole(item.boundingBox, offset)

       [objects, path]

  .factory 'pathUtilities', ->
    round: (value) ->
      value.toFixed 3
    makeBoundingPath: ({x, y, height, width}) ->
      'M' + @round(x) + ' ' + @round(y) + 'H' + @round((x + width)) + 'V' + @round((y + height)) + 'H' + @round(x) + ' Z'
    unpackBoundingBox: ({topLeft, bottomRight}, offset = x: 0, y: 0) ->
      x: (topLeft.x.value + offset.x), y: (topLeft.y.value + offset.y), height: (bottomRight.y.value - topLeft.y.value), width: (bottomRight.x.value - topLeft.x.value)
    unpackLineObject: ({left, top, height, width}, offset) ->
      x: left.value + offset.x, y: top.value + offset.y, height: height.value, width: width.value
    unpackUndersetObject: ({topLeft, bottomRight}, cl, offset) ->
      x: (topLeft.x.value + offset.x), y: (cl.cursorY.value + offset.y), height: cl.vSpaceRemaining.value, width: (bottomRight.x.value - topLeft.x.value)
    intersectingLinesObject: ({topLeft, bottomRight}, offset) ->
      line1 = x1: topLeft.x.value + offset.x, x2: bottomRight.x.value + offset.x, y1: topLeft.y.value + offset.y, y2: bottomRight.y.value + offset.y
      line2 = x1: line1.x1 + (bottomRight.x.value - topLeft.x.value), x2: line1.x2 - (bottomRight.x.value - topLeft.x.value), y1: line1.y1, y2: line1.y2
      [line1, line2]

  .factory 'Copyfit', ($q, AdonisService, AdonisData) ->
    getFigures: (task_id) ->
      deferred = $q.defer()
      onIssueRequestSuccess = ({ holeId, issue, layout, spreadId, side }) =>
        if spreadId and side and holeId
          AdonisService.once('StoryRenderPresenter', spreadId + '-' + side + '-' + holeId).then ({ storyHole }) ->
            if storyHole?
              figures = []
              for f, figure of storyHole.figures
                image = figure.image.info.v
                width = image.minWidth.value
                height = image.minHeight.value
                figures.push width:width, height:height
              deferred.resolve figures
            else
              deferred.reject 'storyhole not found'
          , -> deferred.reject 'task for issue not found'
        else
          deferred.reject 'issue not found'
      onIssueRequestError = ->
        # need to revisit this - if adonis connection fails we just return
        # an empty array, even though the page module might actually have figures
        deferred.reject 'adonis connection failed'
      AdonisData.getStoryIssuesPresenter(task_id).then onIssueRequestSuccess, onIssueRequestError
      deferred.promise

  .factory 'webworkerBlob', ($http) ->
    blob: null
    get: ->
      webworker = require '../webworkers/webworker'
      windowURL = window.URL or window.webkitURL
      javascript = webworker
      try
        javascript = new Blob([ '(', javascript, ')()' ], type: 'application/javascript')
      catch e
        window.BlobBuilder = window.BlobBuilder or window.WebKitBlobBuilder or window.MozBlobBuilder
        javascript = new BlobBuilder
        javascript.append javascript
        javascript = javascript.getBlob()
      @blob = windowURL.createObjectURL javascript

  .factory 'copyfitWebWorker', ($q, $log, webworkerBlob) ->
    _getWorker = (functionName, functionParams) ->
      deferred = $q.defer()
      worker = new Worker webworkerBlob.blob
      worker.addEventListener 'message', (event) ->
        # $log.debug 'webworker message: ', event.data
        worker.terminate()
        worker = null
        deferred.resolve event.data
      worker.addEventListener 'error', (event) ->
        $log.debug 'webworker error: ', event.filename, event.lineno, event.message
      worker.postMessage
        functionName: functionName
        functionArgs: Array::slice.call(functionParams)
      deferred.promise

    run: (functionName, functionParams) ->
      if arguments.length < 2
        throw new TypeError('Not enough arguments. ' + 'The first param is a function name as string. ' + 'The second is an array of data types')
      if typeof arguments[0] != 'string'
        throw new TypeError('First parameter must be a string. ' + 'This is the name of the function')
      if !Array.isArray(arguments[1])
        throw new TypeError('Second parameter must be an array. ' + 'This is an array of data to be processed')
      _getWorker functionName, functionParams

'use strict'

angular.module 'nn.favourites-list.directives', []

  .directive 'nnFavouritesList', ->
    restrict: 'E'
    templateUrl: (element, attrs) ->
      '/templates/favourites-' + attrs.list + '.html'
    controller: ($scope, $attrs, Favourites, FavouritesType) ->
      $scope.favouritesType = FavouritesType
      Favourites.all($attrs.list, true).then (data) ->
        $scope.favourites = data

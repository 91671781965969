'use strict'

angular.module 'nn.file-editor.directives', []

    .directive 'nnFileEditor', ->
        templateUrl: '/templates/file-editor.html'
        restrict: 'E'
        scope:
            file: '=file'
        controller: ($scope, FileUtils) ->
            $scope.getFileType = (uri) ->
                FileUtils.getType uri
            $scope.getFileURL = (uri) ->
                url = FileUtils.getURL uri
                url

            # $scope.file = AppState.file
            # Files.getInstance().one($stateParams.file).then (file) ->
            #   $scope.file = file
            # $scope.$watch ->
            #   AppState.file
            # , (file) ->
            #   $scope.file = file
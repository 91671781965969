'use strict'

angular.module 'nn.form-list.directives', []

.directive 'nnFormList', ->
    templateUrl: '/templates/form-list.html'
    restrict: 'E'
    scope:
        templates: '='
        filter: '='
    controller: ($scope, $state, AppState, StorageService, FormModel, Favourites, FavouritesType) ->
        $scope.items = StorageService.keys
        $scope.selected = item: $scope.items[0]
        $scope.favouritesType = FavouritesType
        #
        # Check that the state is active...
        #
        $scope.isActive = (form) ->
            $state.includes "**.form.**", form:form.id.toString()
        #
        # Select the stories
        #
        $scope.select = (form) ->
            $state.go 'app.forms.search.form', form:form.id
        #
        # get the status class
        #
        $scope.getClass = (status) ->
            switch status
                when 'd' then 'secondary'
                when 'r' then 'alert'
                when 'p' then 'success'
        #
        # get any favourites from user_store
        #
        Favourites.all('forms').then (data) ->
            $scope.favourites = data

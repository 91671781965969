'use strict'

angular.module 'nn.report-viewer.directives', []

    .directive 'nnReportViewer', ->
        templateUrl: '/templates/report-viewer.html'
        restrict: 'E'
        scope: {}
        controller: ($scope, $state, Reports, moment, AppState, REPORT_FIELDS) ->
            dateFormat = 'DD/MM/YYYY'
            dateUrlFormat = 'DD-MM-YYYY'            
            reports = Reports.getInstance()
            $scope.accumulate = true
            $scope.sortColumn = 0            
            $scope.sortReverse = false
            $scope.today = moment() 
            $scope.reports = reports
            $scope.selection =
                range_from: if AppState.reports.range_from? then moment(AppState.reports.range_from, dateUrlFormat) else $scope.today
                range_to: if AppState.reports.range_to? then moment(AppState.reports.range_to, dateUrlFormat) else $scope.today
            reports.params().range_from = moment($scope.selection.range_from).format(dateFormat)
            reports.params().range_to = moment($scope.selection.range_to).format(dateFormat)
            reports.params().publications = AppState.reports.pubs
            refreshReports = ->
                isValid = -> 
                    # We only allow getting reports for a max 3 month range
                    diff = $scope.selection.range_from.diff($scope.selection.range_to, 'months')
                    valid = diff <= 3 and diff >= -3
                    $scope.rangeNotAllowed = !valid
                    valid

                if isValid()
                    reports.get().then ->
                        isRange = $scope.selection.range_from.diff($scope.selection.range_to, 'days') != 0
                        isMultiple = reports.all().length > 1
                        # NOTE: Ive restricted it to 200 results per page. I currently 
                        # only use the first page. The assumption at the moment is a maximum of a single
                        # report per day thus there is no need to account for multiple pages. Futhermore this
                        # assumes that every report fetch is for the same purpose (Publications Roudup). If 
                        # new report types are generated we will need to tweak the filtering.
                        reports.accumulate() if $scope.accumulate and isMultiple and isRange
                else
                    reports.remove()
            refreshReports()

            $scope.handleDateChange = (param, date) ->
                if date
                    $state.go 'app.reports.date', 
                        range_from: moment($scope.selection.range_from).format(dateUrlFormat), 
                        range_to: moment($scope.selection.range_to).format(dateUrlFormat)  
                    formattedDate = date.format(dateFormat)                    
                    reports.params()[param] = formattedDate
                    refreshReports()

            $scope.hasTooltip = (field) ->
                match = Object.keys(REPORT_FIELDS).map((k) -> REPORT_FIELDS[k])
                    .filter((_field) -> _field.name == field and _field.tooltip)
                return match[0].tooltip if match.length
                return false

            $scope.formatDate = (date, dateFormat, format) ->
                moment(date, dateFormat).format(format)

            $scope.sort = (column) ->
                (item) ->
                    item[column][1]
               
            $scope.$watch 'selection.pub', (newVal, oldVal) ->
                if newVal isnt oldVal
                    $state.go 'app.reports.date', pubs: newVal?.code or null
                    reports.params().publications = newVal?.code or null
                    refreshReports()
###

 Element module
 ==============
 Contains a constants and objects for individual elements
 of a field e.g. a text field can have editable tags,
 title, description and default text, so several text elements
 and a tag box element would be required for a single text field.

###
angular.module("nn.form-element", [])
#
# Element Module constant. This holds the individual element
# types. Note that a field is made up from several elements...
#   
.constant "ElementTypes",
    #
    # Currently only 6 types of inputs. These type fields correspond
    # to input types on HTML input tags.
    # 
    CHECKBOX:
        type: "checkbox"
        inputType: "checkbox"
    NUMBER:
        type: "number"
        inputType: "number"
    TAG:
        type: "tag"
        inputType: "text"
    DROPDOWN:
        type: "dropdown"
        inputType: "select"
    TEXT:
        type: "text"
        inputType: "text"
    RULE:
        type: "rule"
        inputType: "text"
    ARRAY:
        type: "array"
        inputType: "text"

.factory "ElementModel", (ElementTypes) ->
    #
    # Generally, a form has a list of fields,
    # and a field has a list of elements.
    # An element can be of type text or checkbox,
    # and this will control the field....
    #         
    _typeValid = (elemType) ->
        # 
        #  Function to check to ensure that a valid type
        #  is used to initialize the object..
        #                 
        for x of ElementTypes
            return true if ElementTypes[x] is elemType
        false

    Element = (elemType, id, name, meta) ->
        #
        #  construct the element based on an input type...
        #                 
        throw "Illegal type." unless _typeValid(elemType)
        
        # fill in the variables... 
        @name = name
        @type = elemType.type
        @inputType = elemType.inputType
        @id = id
        #
        # whether this element belongs on the meta
        # text field or not...
        #
        @meta = meta
        #
        # Raw data object...
        #
        @data = null

        @initialize = () ->
            # this is called when an element is created
            return
        # 
        #  Data types are different across the 
        #  element types...
        #                 
        switch elemType
            when ElementTypes.TEXT
                # Text element... 
                @data = ""
            when ElementTypes.CHECKBOX
                # Checkbox element... 
                @data = {}
            when ElementTypes.NUMBER
                # Tag element... 
                @data = null
            when ElementTypes.DROPDOWN
                # Tag element... 
                @data = {}
            when ElementTypes.TAG
                # Tag element...
                @data = ""
            when ElementTypes.RULE
                # Rule element, data is a regex string... 
                @data = ""
            when ElementTypes.ARRAY
                #
                # For the array element, the user input will
                # be a string, and we split on space, comma or 
                # pipe or tab.
                #
                @data = ""
                #
                # Here we hold a variable that will display the
                # array
                #
                @showData = []
                @initialize = () ->
                    if @data
                        @showData = @data.trim().split /[\s\,\|\t]+/
                    else
                        @showData = []
            else    
                # Error... 
                console.log "ERROR: Illegal type found " + elemType
                throw "Illegal type."
        return

    return Element

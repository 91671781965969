'use strict'

angular.module 'nn.page-editor.directives', []

  .directive 'nnPageEditorHeader', (nnSidenavService, $q, $timeout, $http) ->
    scope:
      pages: '='
      page: '='
      bookLength: '='
      schema: '='
      spread: '='
      showPreview: '='
      hybridMode: '='
      pageZoom: '='
      copyfitStatus: '='
    templateUrl: '/templates/page-editor-header.html'
    link: (scope, element, attr) ->
      $timeout ->
        scope.pageinfo = nnSidenavService('pageinfo')
    controller: ($scope, PageWorkFlows, $stateParams, $state, PageExplorer, NotificationService) ->
      $scope.workflows = PageWorkFlows
      $scope.explorer = PageExplorer

      $scope.handleDownloadPdfClick = (side, output, $event) ->
        $event.preventDefault()
        notification = NotificationService.createGet('wait', "", "Downloading, please wait...", 0)
        clickableParent = $event.target.parentElement.parentElement
        clickableParent.style.pointerEvents = 'none'
        url = ''
        switch side
          when 'left'
            if output and output[0]
              url = output[0].pdf
          when 'right'
            if output and output.length
              if output.length == 1
                url = output[0].pdf
              else
                url = output[1].pdf
          when 'spread'
            url = output
        document.body.style.cursor = 'wait'
        $http
          method: 'GET'
          url: url
          cache: false
        .success (response) ->
          NotificationService.clearWith(notification)
          clickableParent.style.pointerEvents = 'auto'
          document.body.style.cursor = 'default'
          base64String = btoa(
            new Uint8Array(response.data).reduce (data, byte) ->
              data + String.fromCharCode(byte)
            , ''
          )
          pdfAsDataUri = "data:application/pdf;base64," + base64String
          link = document.createElement('a')
          link.href = pdfAsDataUri
          link.download = response.fileName;
          link.click();
        .error (data, status, headers, config) ->
          NotificationService.clearWith(notification)
          clickableParent.style.pointerEvents = 'auto'
          document.body.style.cursor = 'default'
          errorTitle = 'Downloading PDF'
          errorMessage = ''
          if !data
            errorMessage = 'There are issues in the server. Please try again later.'
          else
            errorMessage = data.toString()
          NotificationService.create 'error', errorTitle, errorMessage, 0
          window.Sentry.captureMessage errorTitle + ': ' + errorMessage

      $scope.toggleInfo = (force = false) ->
        asidePanel = nnSidenavService 'aside'
        pageInfoPanel = nnSidenavService 'pageinfo'

        $scope.schema.setActiveHole()

        if pageInfoPanel.active() or force
          if asidePanel then asidePanel.close()
          if pageInfoPanel then pageInfoPanel.close()
        else pageInfoPanel.open()

      today = new Date()
      $scope.minDate =  new Date today.getFullYear(), today.getMonth(), today.getDate()

      $scope.togglePreview = ->
        if $scope.showPreview and not $scope.hybridMode
          $scope.hybridMode = true
        else
          $scope.hybridMode = false
          $scope.showPreview = !$scope.showPreview

      $scope.togglePageZoom = ->
        $scope.pageZoom = !$scope.pageZoom

      $scope.gotoPage = (page, max, abs) ->
        if page >= 1 and page <= max
          $state.go 'app.pages.issue.page', book: $stateParams.book, page: page

  .directive 'nnPageEditor', ($filter, $timeout, $q, nnSidenavService, IssuePresenter, $http, PageExplorer) ->
    restrict: 'E'
    require: ['^canvasSchema']
    scope: { state: '=' }
    templateUrl: '/templates/page-editor.html'
    controller: ($scope) ->
      $scope.copyfitStatus = data: {}
      $scope.showPreview = value: false, hybrid: false
      $scope.pageZoom = false
    link: (scope, element, attrs, ctrls) ->
      scope.schema = ctrls[0]
      scope.isLoading = true

      scope.outsideClick = (event, toggle = false) ->
        targetNode = event.target.nodeName
        if targetNode not in ['rect', 'path', 'svg', 'g']
          scope.schema.setActiveHole()
          nnSidenavService('pageinfo').close()

      doUnsubscribe = (issue) ->
        IssuePresenter.deleteSubscription issue

      resetPage = ->
        scope.pages = null

      updatePage = (page) ->
        scope.isLoading = false

        { spread, active, bookLength} = IssuePresenter.findIndex scope.pages.books[page[0]], page[1]

        scope.state.active.spread = spread.info
        scope.state.active.side = active

        scope.booklength = bookLength
        scope.spread = spread

      subscribePages = (issue, page) ->
        IssuePresenter.getSubscription(issue[0] + '-' + issue[1]).then (data) ->
          scope.schema.pubName = data.pubname
          scope.pages = data
          updatePage page

      scope.$watchCollection 'state', (nv, ov) ->
        if nv.active.book and nv.active.number and nv.issue.pub and nv.issue.date
          angular.extend scope.schema, nv
          newPage = [nv.active.book, nv.active.number]
          newIssue = [nv.issue.pub, nv.issue.date]

          #check if first load
          if not scope.page
            subscribePages newIssue, newPage
          #check if issue date and pub code is the same
          else if newIssue[0] isnt ov.issue.pub or newIssue[1] isnt ov.issue.date
            doUnsubscribe ov.issue.pub + '-' + ov.issue.date
            subscribePages newIssue, newPage
          #check if page and book number is the same
          else if newPage[0] isnt ov.active.book or newPage[1] isnt ov.active.number
            updatePage newPage

        else
          resetPage()

      scope.$on '$destroy', ->
        doUnsubscribe scope.state.issue.pub + '-' + scope.state.issue.date
        PageExplorer.selectTemplates = false
        PageExplorer.selectIssue = true

'use strict'

angular.module 'nn.task.list.directives', []

  .directive 'nnTaskList', ->
    templateUrl: '/templates/task-list.html'
    restrict: 'E'
    scope: {
      'tasks': '='
    }
    controller: ($scope, NotificationService, TASK_STATUS) ->
        $scope.toggleList = (index) ->
          if $scope.isOpen index 
            $scope.selected.item = null
          else $scope.selected.item = index 
          
        $scope.isOpen = (index) ->
          $scope.selected.item is index 

        $scope.archive = (task) ->
          NotificationService.confirm('Are you sure you want to archive this task?').then ->
            $scope.tasks.update(task.id, status:TASK_STATUS.ARCHIVED).then ->
              $scope.tasks.refresh()

        $scope.unarchive = (task) ->
          NotificationService.confirm('Are you sure you want to un-archive this task?').then ->
            $scope.tasks.update(task.id, status:TASK_STATUS.OPEN).then ->
              $scope.tasks.refresh()
    link: (scope, element) ->
      element.addClass 'nn-task-list'

  .directive 'taskListItem', ->
    restrict: 'E'
    replace: true
    transclude: true
    template: (iElem, tAttrs) ->
      """<span class="task-detail-item">
        <i class="grey-light1">#{tAttrs.label}:</i>
        <ng-transclude/>
      </span>"""

  .directive 'taskListActionItem', ->
    restrict: 'E'
    replace: true
    transclude: true
    template: (iElem, tAttrs) ->
      """<a class="status white bold label badges">
        <i class="fa fa-#{tAttrs.icon} nopadding nomargin"></i>
        <ng-transclude/>
      </a>"""

  .directive 'nnTaskListActions', ->
    template: '''
      <task-list-action-item ng-if="task.content_object.id" icon="pencil" ui-sref="app.stories.story({story:task.content_object.id})" title="View story">
        edit story
      </task-list-action-item>

      <task-list-action-item ng-if="!task.content_object.id" icon="plus" ui-sref="app.stories.new.forTask({task:task.id})">
        create story
      </task-list-action-item>

      <task-list-action-item ng-if="task.status == 'done'" icon="reply" ng-click="unarchive(task)" title="Unarchive task" nn-check-role='archiveTask'>
        unArchive
      </task-list-action-item>

      <task-list-action-item ng-if="task.status == 'new'" icon="times" ng-click="archive(task); archiving=true" title="Archive task" nn-check-role='archiveTask'>
        archive
      </task-list-action-item>'''
'use strict'

angular.module 'nn.dragndropsvg', [
  'nn.dragndropsvg.draggable'
  'nn.dragndropsvg.dragging'
  'nn.dragndropsvg.helpers'
  'nn.dragndropsvg.image'
  'nn.dragndropsvg.list'
  'nn.dragndropsvg.mouse'
  'nn.dragndropsvg.utils'
]
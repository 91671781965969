'use strict'

angular.module 'nn.account-explorer.directives', []

  .directive 'nnAccountExplorer', ->
    templateUrl: '/templates/account-explorer.html'
    restrict: 'E'
    controller: ($scope, AppState, AccountSections, AccountExplorer) ->
      $scope.sections = AccountSections
      $scope.state = AccountExplorer


'use strict'

angular.module 'nn.gravatar', []

.directive 'gravatarImage', (gravatarImageService) ->
    restrict: 'E'
    template: "<img class='gravatar-icon' ng-src='{{src}}'>"
    replace: true
    scope: {}
    link: (scope, element, attrs) ->
        attrs.$observe 'gravatarEmail', (value) ->
            email = value or ''
            size = attrs.gravatarSize or 32
            rating = attrs.gravatarRating or 'pg'
            useDefault = !email? or email is ''
            scope.src = gravatarImageService.getImageURL email, size, rating, useDefault

.factory 'gravatarImageService', ($location, md5) ->
    getImageURL: (email, size, rating, useDefault) ->
        hash = md5.createHash email.toLowerCase()
        prefix = if $location.protocol() is 'https' then 'https://secure' else 'http://www'
        url = "#{prefix}.gravatar.com/avatar/#{hash}"
        url += (if useDefault then '?f=y' else '?')
        if size? then url += '&s=' + size
        if rating? then url += '&r=' + rating
        url
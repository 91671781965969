'use strict'

angular.module 'nn.newslist-builder.routes', []

    .config ($stateProvider) ->

        $stateProvider

            .state 'app.newslist',
                abstract: true
                url: ''
                resolve:
                    acl: (Roles, $q) ->
                        checkRole = ->
                            if !Roles.canGo('newslist') then $q.reject 'unauthorized'
                        if Roles.hasRoles() then checkRole() else Roles.attachRoles().then -> checkRole()
                onEnter: (AppState, Explorer, Sections) ->
                    AppState.section = Sections[1]
                    AppState.canvas = 'newslist'

            .state 'app.newslist.ideasList',
                url: 'newslist/ideas/list'

            .state 'app.newslist.ideasCreate',
                url: 'newslist/ideas/create'

            .state 'app.newslist.ideasEdit',
                url: 'newslist/ideas/edit/:id'

            .state 'app.newslist.runsheetsRunsheet',
                url: 'newslist/runsheet/:org'

            .state 'app.newslist.runsheetsList',
                url: 'newslist/runsheets/list'

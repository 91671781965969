'use strict'

angular.module 'nn.page-list.directives', []

  .directive 'nnPageListMeta', ->
    scope: { spread: '=', number: '=', info: '=', output: '=', template: '=', viewPreview: '=' }
    template: '''
      <div class="page-meta {{info.workflow}}">
        <div class="right-box">
          <i class="fa" ng-class="{'fa-check-circle green': info.published, 'fa-exclamation orange': info.workflow.code === 'PrintReady' && !info.published, 'fa-circle grey': info.workflow.code === 'Draft', 'fa-circle orange': info.workflow.code === 'PendingApproval'}"></i>
          <i ng-show="output.png" class="fa fa-eye grey-light1 alignc" ng-class="{'active':viewPreview.value || viewAll.value}" ng-click="viewPreview.value = !viewPreview.value"></i>
        </div>

        <i class="fa fa-flag flagged" ng-class="{not: !info.isFlagged}"></i>
        <a download ng-href="{{output.pdf}}" ng-show="mouseOver && output.pdf && template" class="grey"><i class="fa fa-download grey-light1"></i></a>
        <small ng-hide="mouseOver && output.pdf && template" >{{ number | getPageTitle: $last }} {{ spread ? '- ' + (number + 1) : ''}}</small>
      </div>'''

  .directive 'nnPageList', ($state, $stateParams, IssuePresenter, AppState, nnSidenavService, $location, Explorer, PageExplorer, $timeout) ->
    templateUrl: '/templates/page-list.html'
    restrict: 'E'
    require: ['^canvasSchema']
    scope: {
      issue: '='
      page: '='
      pages: '='
      viewAll: '='
      hybridMode: '='
      pageStatusFilter: '='
    }
    link: (scope, element, attrs, ctrls) ->
      scope.schema = ctrls[0]
      scope.explorerState = PageExplorer

      doUnsubscribe = (issue) ->
        IssuePresenter.deleteSubscription issue

      scope.$watch 'issue', (nv, ov) ->
        if nv.pub and nv.date
          scope.isLoading = true
          scope.pages = null

          if nv.pub isnt ov.pub or nv.date isnt ov.date
            doUnsubscribe ov.pub + '-' + ov.date

          IssuePresenter.getSubscription(nv.pub + '-' + nv.date).then (data) ->
            scope.pages = data
            scope.isLoading = false

            if scope.page?.layout?.template and $stateParams.page
              Explorer.data.collapsed = true
              nnSidenavService('pageinfo').then (panel) ->
                  panel.open()

      scope.isActive = (page) ->
          AppState.page.active.number == page

      scope.$on '$destroy', ->
        doUnsubscribe scope.issue.pub + '-' + scope.issue.date
'use strict'

angular.module 'adonis.issue.collections.spreads.spread.output', []

.factory 'spreadsOutput', (BasePresenter, HOSTS, Auth) ->
  class spreadsOutput extends BasePresenter 
    constructor: ($queryStream, pdf, advertsPreview, furniturePreview) ->
      @sync pdf, advertsPreview, furniturePreview

    sync: (pdf, advertsPreview, furniturePreview) ->
      @adverts = advertsPreview
      @furniture = furniturePreview

      Auth.token().then (token) =>
        @pdf = "#{HOSTS.adonis}/pdf/preview/#{pdf}?token=#{token}"

'use strict'

angular.module 'nn.common.services', []

  .factory 'AppState', ->
    section: null
    subSection: null
    canvas: null
    story: {}
    file: null
    page: {}
    reports: {}
    site: null
    login:
      redirect_url: null
      error: null
      loading: false
    breakingNews: {}
    showBreakingConfimBox: false
    breakingnewsDateValidation: true
    storySaved: false
    sports: {}

  .factory 'PageInfo', ->
    active: false
    maximise: false

  .factory 'StoryInfo', ($timeout, $q) ->
    ovuUi: false
    active: false
    elements: false
    images: false
    settings: false
    infoWasActive: false
    reset: ->
      @ovuUi = false
      @active = false
      @elements = false
      @images = false
      @settings = false
      @infoWasActive = false
    toggleOvuUi: (state) ->
      $timeout =>
        if state?
          @ovuUi = state
        else
          @ovuUi = !@ovuUi
        @ovuUi
    toggleMedia: (state) ->
      $timeout =>
        if @settings
          @settings = false
        else
          if state?
            @images = state
          else
            @images = !@images
        @images
    toggleElements: (state) ->
      $timeout =>
        if state?
          @elements = state
        else
          @elements = !@elements
        @elements
    toggleSettings: (state) ->
      return $q.when(true) if state is true and @settings
      $timeout =>
        if !@images
          @images = true
        if @settings
          @images = false
        if state?
          @settings = state
        else
          @settings = !@settings
        @settings
    hide: ->
      if @settings
        @toggleSettings false
      else if @images
        @toggleMedia false

  .factory 'Explorer', ->
    data:
      active: false
      wide: true
      collapsed: false

  .factory 'ServicesAvailability', ->
    data:
      silverstone: true
      ServicesNotAvailable: 'SNA'

  .constant 'Sections', [
      { 'id':'dashboard', label:'Dashboard', icon:'fa-home', enabled:true }
      { 'id':'newslist', label:'Newslist', icon:'fa-lightbulb-o', enabled:true }
      { 'id':'stories', label:'Stories', icon:'fa-pencil', enabled:true }
      { 'id':'site', label:'Sites', icon:'fa-list-alt', enabled:true }
      { 'id':'pages', label:'Pages', icon:'fa-newspaper-o', enabled:true }
      { 'id':'forms', label:'Forms', icon:'fa fa-list-ul', enabled:false }
      { 'id':'files', label:'Files', icon:'fa-files-o', enabled:true }
      { 'id':'sports', label:'Sports', icon:'fa-soccer-ball-o', enabled:true }
      { 'id':'communities', label:'Learning Hub', icon:'fa-bullhorn', target:'_blank', enabled:true }
      { 'id':'admin', label:'Admin', icon:'fa-cog', enabled:true }
      { 'id':'reports', label:'Reports', icon:'fa-inbox', enabled:true }
      { 'id':'account', label:'Account', icon:'fa-bullhorn', enabled:true }
    ]

  .constant 'Allowance',
    PRODUCTION: [
      # {id: 468, allowance: 4} # Ararat Advertiser
      # {id: 478, allowance: 4} # Armidale Express
      # {id: 329, allowance: 4} # Batemans Bay Post
      # {id: 473, allowance: 4} # Bega District News
      # {id: 347, allowance: 4} # Cootamundra Herald
      # {id: 471, allowance: 4} # Goulburn Post
      # {id: 482, allowance: 4} # Great Lakes Advocate
      # {id: 479, allowance: 4} # Inverell Times
      # {id: 485, allowance: 4} # Katherine Times
      # {id: 489, allowance: 4} # Lithgow Mercury
      # {id: 462, allowance: 4} # Maitland Mercury
      # {id: 481, allowance: 4} # Manning River Times
      # {id: 490, allowance: 4} # Mudgee Guardian
      # {id: 477, allowance: 4} # Namoi Valley Independent
      # {id: 486, allowance: 4} # North West Star
      # {id: 483, allowance: 4} # Port Macquarie News
      # {id: 476, allowance: 4} # South Coast Register
      # {id: 472, allowance: 4} # Southern Highland News
      # {id: 469, allowance: 4} # Stawell Times News
      # {id: 317, allowance: 4} # The Area News
      # {id: 352, allowance: 4} # The Irrigator
      # {id: 484, allowance: 4} # The Macleay Argus
      # {id: 339, allowance: 4} # Ulladulla Times
      # {id: 470, allowance: 4} # Wimmera Mail-Times
      # {id: 466, allowance: 4} # Bendigo Advertiser
      # {id: 487, allowance: 4} # Central Western Daily
      # {id: 488, allowance: 4} # Daily Liberal
      # {id: 480, allowance: 4} # Northern Daily Leader
      # {id: 463, allowance: 4} # The Advocate
      # {id: 316, allowance: 4} # The Border Mail
      # {id: 465, allowance: 4} # The Courier
      # {id: 320, allowance: 4} # The Daily Advertiser
      # {id: 464, allowance: 4} # The Examiner
      # {id: 467, allowance: 4} # The Standard
      # {id: 495, allowance: 4} # The Western Advocate
      # {id: 529, allowance: 8} # Canberra Times
      # {id: 474, allowance: 8} # Illawarra Mercury
      # {id: 460, allowance: 8} # Newcastle Herald
    ]
    UAT: [
      {id: 317, allowance: 10}
    ]
    STAGING: [
      {id: 317, allowance:8}
    ]
    DEV: [
      {id: 317, allowance:3}
    ]

  .factory 'Organization', ($http, $q, HOSTS, Auth, NotificationService) ->
    organizations = null

    commands =
      all: ->
        organizations
      refresh: ->
        organizations = null
        # Auth.user().then (user) ->
        $http
          method: 'GET',
          # url: "#{HOSTS.monza}/orgs/api/v2/user/#{user.id}/",
          # url: "#{HOSTS.dijon}/api/list/?limit=0",
          url: "#{HOSTS.monza}/orgs/api/v2/organization/?limit=0"
          cache: false
          auth: true
        .success (response) ->
          orgs = response.objects
          orgs.unshift {id:null, name:'all sources'}
          organizations = orgs
          # organizations = response.organizations
        @

  .factory 'ServicesCheck', ($http, $q, HOSTS) ->
    getHealthCheck: (service) ->
      switch service
        when 'Valencia '
          deferred = $q.defer()
          url = "#{HOSTS.valencia}/api/v1/object/?format=json&id__in="
          $http
            method: 'GET',
            url: url
            cache: false
            auth: true
          .success (res) ->
            nonProd =
              health_status: 'green'
            deferred.resolve nonProd
          .error (e) ->
            nonProd =
              health_status: e
            deferred.reject nonProd
          return deferred.promise

  .factory 'BreakingNews', ($http, $q, HOSTS, Environment, ENVIRONMENTS, Allowance, moment) ->
    showUpdate: false
    warningMessageWithSiteNames: null
    msgAllowance: null
    msgNotAllowance: null
    resetSubject:null
    template: 'Send Article Summary Only'
    subject:''
    getAllowance: () ->
        switch Environment
          when ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.PRODUCTION_INTERNAL, ENVIRONMENTS.PRODUCTION_TEMP, ENVIRONMENTS.PRODUCTION_DR
              Allowance.PRODUCTION
          when ENVIRONMENTS.UPDATE, ENVIRONMENTS.UPDATE_INTERNAL
              Allowance.PRODUCTION
          when ENVIRONMENTS.UAT, ENVIRONMENTS.TRAINING, ENVIRONMENTS.UAT2, ENVIRONMENTS.UAT3
              Allowance.UAT
          when ENVIRONMENTS.STAGING, ENVIRONMENTS.STAGING2
              Allowance.STAGING
          when ENVIRONMENTS.DEV, ENVIRONMENTS.DEV2
              Allowance.DEV
          else
              Allowance.DEV
    getSentBreakingNews: () ->
        deferred = $q.defer()
        targetDate = moment.utc().subtract(24,"hours").format()
        url = "#{HOSTS.suzuka}/api/mail/breaking-news/?date_sent__gt=#{targetDate}"
        $http
          method: 'GET'
          url: url
          cache: true
          auth: true
        .success (res) ->
          deferred.resolve res
        .error (e) ->
          deferred.reject e
        deferred.promise
    getSitesForTargetLists: () ->
        deferred = $q.defer()
        url = "#{HOSTS.suzuka}/api/v1/site/?limit=500&fields=id,domain,name,mail_groups,mail_list_id,target_lists,mail_provider&visible=true"
        $http
          method: 'GET'
          url: url
          cache: true
          auth: true
        .success (res) ->
          deferred.resolve res
        .error (e) ->
          deferred.reject e
        return deferred.promise
    fetchExistingBreakingNewsEmail: (storyId) ->
        deferred = $q.defer()
        url = "#{HOSTS.suzuka}/api/mail/breaking-news/?story_id=#{storyId}"
        $http
          method: 'GET'
          url: url
          cache: false
          auth: true
        .success (res) ->
          deferred.resolve res
        .error (e) ->
          deferred.reject e
        return deferred.promise
    sendBreakingNewsEmailToSuzuka: (data) ->
        deferred = $q.defer()
        url = "#{HOSTS.suzuka}/api/mail/breaking-news/"
        $http
            method: 'POST'
            url: url
            data: data
            cache: false
            auth: true
        .success (data, status) ->
            deferred.resolve status
        .error (data, status) ->
            deferred.reject status
        deferred.promise

  .factory 'User', ($http, $q, HOSTS, Auth, ServicesAvailability) ->
    _sites = null
    _dyn = ''
    getMastheads: () ->
      deferred = $q.defer()
      url = "#{HOSTS.suzuka}/api/v1/site/?fields=domain,theme_dir&visible=True&limit=0&domain__regex=^(www)"
      $http
          method: 'GET'
          url: url
          cache: true
          auth: true
      .success (response) ->
        deferred.resolve response
      .error ->
        deferred.reject ServicesAvailability.data.ServicesNotAvailable
      deferred.promise

    getDynSites: (dynUrl) ->
      deferred = $q.defer()

      # if _sites != null
      #   deferred.resolve(_sites)
      # else
      $q.all([@getOrganizations(), Auth.user("all")]).then ([orgs, userData]) =>
        userOrgs = userData.orgs
        user = userData.user

        if !user_id?
          user_id = user.id
        # this is the place that whenever suzuka is down, and causes no menu displayed in newsnow
        url = "#{dynUrl}/api/v1/site/?limit=0&fields=id,domain,organization,name&visible=true" #&view=#{view || "newsnow"}"
        $http
          method: 'GET'
          url: url
          cache: true
          auth: true
        .success (response) ->
          isMatchingRole = (role) ->
            role == 'editor' || role == 'admin' || role == 'technical_support'

          isMatchingOrg = (org, site) ->
            site.organization == org.id &&
              org.feature_tags.includes("sitebuilder")

          isSuzuka2Site = (org, site) ->
            if /beta\./.test site.domain or /beta-/.test site.domain or /suzuka2/.test site.domain
              false
            else
              org.feature_tags.includes("force-suzuka2")

          filteredSites =
            response.objects.filter (site) ->
              isSuzuka2 = false

              isIncluded = !!orgs.find (org) ->
                userOrg = userOrgs.find( (userOrg) -> userOrg.organization.id == org.id)

                matchingOrg = userOrg &&
                  isMatchingOrg(org, site) &&
                    isMatchingRole(userOrg?.role)

                if matchingOrg && isSuzuka2Site(org, site)
                  isSuzuka2 = true

                matchingOrg

              if (isSuzuka2)
                site.domain = site.domain.replace(/suzuka/, 'suzuka2')

              isIncluded

          # _sites = filteredSites

          deferred.resolve filteredSites

        .error ->
          deferred.reject ServicesAvailability.data.ServicesNotAvailable

      deferred.promise

    getSites: (view) ->
      deferred = $q.defer()

      if _sites != null
        deferred.resolve(_sites)
      else
        $q.all([@getOrganizations(), Auth.user("all")]).then ([orgs, userData]) =>
          userOrgs = userData.orgs
          user = userData.user

          if !user_id?
            user_id = user.id
          # this is the place that whenever suzuka is down, and causes no menu displayed in newsnow
          url = "#{HOSTS.suzuka}/api/v1/site/?limit=0&fields=id,domain,organization,name&visible=true&domain__regex=^(www|beta)" #&view=#{view || "newsnow"}"

          $http
            method: 'GET'
            url: url
            cache: true
            auth: true
          .success (response) ->
            isMatchingRole = (role) ->
              role == 'editor' || role == 'admin' || role == 'technical_support'

            isMatchingOrg = (org, site) ->
              site.organization == org.id &&
                org.feature_tags.includes("sitebuilder")

            isSuzuka2Site = (org, site) ->
              if /beta\./.test site.domain or /beta-/.test site.domain or /suzuka2/.test site.domain
                false
              else
                org.feature_tags.includes("force-suzuka2")

            filteredSites =
              response.objects.filter (site) ->
                isSuzuka2 = false

                isIncluded = !!orgs.find (org) ->
                  userOrg = userOrgs.find( (userOrg) -> userOrg.organization.id == org.id)

                  matchingOrg = userOrg &&
                    isMatchingOrg(org, site) &&
                      isMatchingRole(userOrg?.role)

                  if matchingOrg && isSuzuka2Site(org, site)
                    isSuzuka2 = true

                  matchingOrg

                if (isSuzuka2)
                  site.domain = site.domain.replace(/suzuka/, 'suzuka2')

                isIncluded

            _sites = filteredSites

            deferred.resolve filteredSites

          .error ->
            deferred.reject ServicesAvailability.data.ServicesNotAvailable

      deferred.promise

    getDetails: (user_id) ->
      deferred = $q.defer()
      Auth.user().then (user) =>
        if !user_id?
          user_id = user.id
        url = "#{HOSTS.monza}/api/v2/user/#{user_id}/"
        $http
          method: 'GET'
          url: url
          cache: true
          auth: true
        .success (response) ->
          deferred.resolve response
      deferred.promise
    getMembers: (organization_id) ->
      deferred = $q.defer()
      Auth.user().then (user) =>
        $http
          method: 'GET'
          url: "#{HOSTS.monza}/api/v2/organization/#{organization_id}/users/?page_size=1000"
          cache: true
          auth: true
        .success (response) ->
          members = response?.results
          if members?.length > 0
            for member in members
              member.name = "#{member.first_name} #{member.last_name}"
            deferred.resolve members
          else
            deferred.reject 'No org members found'
        .error ->
          deferred.reject 'Error loading org members'
      deferred.promise
    getOrganizations: ->
      deferred = $q.defer()
      Auth.user().then (user) =>
        $http
          method: 'GET'
          url: "#{HOSTS.monza}/orgs/api/v2/user/#{user.id}/"
          cache: true
          auth: true
        .success (response) ->
          deferred.resolve response.organizations
      deferred.promise
    getPublications: ->
      deferred = $q.defer()
      Auth.token().then (token) ->
        $http
          method: 'GET'
          url: "#{HOSTS.adonis}/query/user-publications"
          cache: true
          auth: true
        .success (response) ->
          publications = response?.args
          if publications?.length > 0
            deferred.resolve publications
          else
            deferred.reject 'No user publications found'
        .error ->
          deferred.reject 'Error loading user pubs'
      deferred.promise
    isMemberOfPublication: (publicationCode) ->
      deferred = $q.defer()
      @getPublications().then (pubs) ->
        publication = pub for pub in pubs when pub.code is publicationCode
        isMember = publication?
        deferred.resolve isMember
      deferred.promise


  .factory 'Workflow', ($http, $q, HOSTS, Auth) ->
    getWorkflows: ->
      deferred = $q.defer()
      $http
        method: 'GET'
        url: "#{HOSTS.silverstone}/api/v2/workflow/story.status/"
        cache: false
        auth: true
      .success (response) ->
        deferred.resolve response.results
      deferred.promise
    getWorkflowsForUserInOrg: (user_id, organization_id) ->
      deferred = $q.defer()
      $http
        method: 'GET'
        url: "#{HOSTS.silverstone}/api/v2/workflow/story.status/?user=#{user_id}&organization=#{organization_id}"
        cache: false
        auth: true
      .success (response) ->
        deferred.resolve response.results
      deferred.promise
    getWorkflowForTask: (task_id) ->
      deferred = $q.defer()
      $http
        method: 'GET'
        # url: "#{HOSTS.silverstone}/api/v2/workflow/story.status/?user=#{user_id}&organization=#{organization_id}"
        url: "#{HOSTS.silverstone}/api/v2/workflow/story.status/custom/?reference_id=#{task_id}"
        cache: false
        auth: true
      .success (response) ->
        deferred.resolve response.results
      deferred.promise
    setWorkflowForTask: (task_id, workflow_id) ->
      deferred = $q.defer()
      Auth.user().then (user) =>
        $http
          method: 'POST'
          url: "#{HOSTS.silverstone}/api/v2/workflow/story.status/"
          data: { 'status_group':workflow_id, 'reference_id':task_id, 'user':user.id }
          cache: false
          auth: true
        .success (response) ->
          deferred.resolve response
      deferred.promise
    removeWorkflowForTask: (workflow) ->
      deferred = $q.defer()
      Auth.user().then (user) =>
        $http
          method: 'DELETE'
          url: "#{HOSTS.silverstone}/api/v2/workflow/story.status/custom/#{workflow.resultId}/"
          cache: false
          auth: true
        .success (response) ->
          deferred.resolve response
      deferred.promise
    updateWorkflowForTask: (task_id, workflow) ->
      deferred = $q.defer()
      Auth.user().then (user) =>
        $http
          method: 'PATCH'
          url: "#{HOSTS.silverstone}/api/v2/workflow/story.status/custom/#{workflow.resultId}/"
          data: { 'status_group': workflow.id, 'user': user.id, 'reference_id': task_id }
          cache: false
          auth: true
        .success (response) ->
          deferred.resolve response
      deferred.promise

  .directive 'nnRepeatSwitch', ($parse, $compile) ->
    priority: 1001
    required: 'nnRepeatSwitch'
    restrict: 'A'
    controller: ->
      @expression = null
      @cases = Object.create(null)
      return
    compile: (tElement, tAttributes) ->
      switchExpression = $parse tAttributes.nnRepeatSwitch
      switchCases = {}

      link = ($scope, element, attributes, controller) ->
        controller.expression = switchExpression
        controller.cases = switchCases

        $scope.$on '$destroy', ->
          controller.expression = null
          controller.cases = null
          controller = null

      tElement.children('[ nn-repeat-switch-when ]').remove().each ->
        node = angular.element(this)

        caseValue = node.attr('nn-repeat-switch-when')
        caseLinkFunction = $compile(node.removeAttr('nn-repeat-switch-when'))
        switchCases[caseValue] = caseLinkFunction

      tElement.children('[ nn-repeat-switch-default ]').remove().each ->
        node = angular.element(this)

        defaultLinkFunction = $compile(node.removeAttr('nn-repeat-switch-default'))
        switchCases['default'] = defaultLinkFunction

      tElement.empty()

      tElement = null
      tAttributes = null

      link

  .directive 'nnRepeatSwitch', ->
    priority: 999
    require: 'nnRepeatSwitch'
    restrict: 'A'
    link: (scope, element, attributes, switchController) ->
      scope.$watch switchController.expression, (newValue, oldValue) ->
        linkFunction = switchController.cases[newValue]

        if not linkFunction
          if switchController.cases['default']
            linkFunction = switchController.cases['default']
          else return element.empty()

        if newValue isnt oldValue then element.empty()

        linkFunction scope, (clone) -> element.append clone

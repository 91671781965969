'use strict'

angular.module 'nn.layout-list.services', []

  .factory 'PageTemplatesProvider', (AdonisData, AdonisService) ->

    class PageTemplates
      constructor: ->
        @templates = []
        @spreadHash = null
        @editorialSpace = null
        @loading = false
        @noResults = false
        @searchQuery = ''
        @pagination =
          limit: 20
          offset: 0
          remaining: null
      all: ->
        @templates
      favourite: (layout) ->
        id = layout.ref.v.id
        cmd = if layout.isFavourited? then "unfavourite" else "favourite"
        cmd = if layout.spread then "#{cmd}SpreadTemplate" else "#{cmd}PageTemplate"
        AdonisService.send(cmd, null, id)
      search: ->
        @reset()
        @refresh()
      reset: ->
        @templates = []
        @pagination.offset = 0
        @pagination.remaining = null
      more: =>
        @pagination.offset += @pagination.limit
        @refresh()
      refresh: =>
        return unless @pagination.remaining is null or
                      @pagination.remaining > 0
        @loading = true
        @noResults = false
        query = "?offset=#{@pagination.offset}&limit=#{@pagination.limit}"
        query = "#{query}&query=#{@searchQuery}" if @searchQuery
        AdonisData.getTemplates(@editorialSpace, @spreadHash, query).then (data) =>
          if data?.templates?.length         
            @templates = @templates.concat data.templates
            @pagination.remaining = data.remaining
          else
            @noResults = true
          @loading = false

    getInstance: ->
      new PageTemplates

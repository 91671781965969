'use strict'

angular.module 'nn.adonis-manager.routes', []

    .config ($stateProvider) ->

        $stateProvider
            .state 'app.adonis',
                abstract: true
                url: ''
                resolve:
                    acl: (Roles, $q) ->
                        checkRole = ->
                            if !Roles.canGo('adonis') then $q.reject 'unauthorized'
                        if Roles.hasRoles() then checkRole() else Roles.attachRoles().then -> checkRole()
                onEnter: (AppState, Explorer, Sections) ->
                    AppState.section = Sections[1]
                    AppState.canvas = 'adonis'

            .state 'app.adonis.stylesheets',
                url: 'adonis/stylesheets/list'

            .state 'app.adonis.themes',
                url: 'adonis/themes/list'

            .state 'app.adonis.spreadEditorialLayouts',
                url: 'adonis/spreads/list'

            .state 'app.adonis.editorialLayouts',
                url: 'adonis/editorial/list'

            .state 'app.adonis.pageTemplatesList',
                url: 'adonis/storyholes/list'

            .state 'app.adonis.furnitureTemplatesMeta',
                url: 'adonis/furniture/meta'

            .state 'app.adonis.furnitureTemplatesList',
                url: 'adonis/furniture/list'

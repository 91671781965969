'use strict'

angular.module 'nn.task.comments-list.directives', []

  .directive 'nnTaskCommentsList', ->
    templateUrl: '/templates/task-comments-list.html'
    restrict: 'E'
    scope: {
      'task':'=task'
      'limit':'=limit'
    }
    controller: ($scope, Note, Auth) ->
      Auth.user().then (data) ->
        $scope.user = data

      onCommentCreateSuccess = (data) ->
        $scope.submitting = false
        $scope.task.notes.push data
      onCommentCreateError = (data, status) ->
        $scope.submitting = false
        $scope.error = true
        $scope.errortext = data.story[0]
      $scope.commentSubmit = ->
        if $scope.comment
          $scope.submitting = true
          $scope.error = false
          input =
            text: $scope.comment
            type: 'comment'
          Note.create($scope.task, input).then onCommentCreateSuccess, onCommentCreateError

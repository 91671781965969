'use strict'

angular.module 'nn.newslist-editor.directives', []

    .directive 'nnNewslistEditor', ->
        templateUrl: '/templates/newslist-editor.html'
        restrict: 'E'
        scope: {}
        controller: ($scope, $rootScope, $state, User, NotificationService, FeatureAccess) ->
            currentRoute = name: $state.current.name, url: $state.href($state.current.name).replace(/^\//g,'')
            $scope.api =
                Notification: NotificationService
                User: User
                initialState: currentRoute
                onStoryDropped: (story) -> story
                getNewslistOrganizations: () -> FeatureAccess.loadOrganizations(['newslist'])
                onRouteChange: (toRoute, fromRoute) -> toRoute: toRoute, fromRoute: fromRoute

            $scope.api.onRouteChange currentRoute, currentRoute
            
            removeRouteChangeHook = $rootScope.$on '$stateChangeSuccess', 
                (event, toState, toParams, fromState, fromParams, options) ->
                    #Changing routes outside of react, doesnt trigger a route change inside.
                    #Workaround for now is to pass a route change event in to notify the react app
                    #the route has changed.
                    toRoute = name: toState.name, url: $state.href(toState.name).replace(/^\//g,'')
                    fromRoute = name: fromState.name, url: $state.href(fromState.name).replace(/^\//g,'')
                    $scope.api.onRouteChange(toRoute, fromRoute) if toRoute.url.indexOf('newslist') > -1

            $scope.handleStoryDrop = (data, e) ->
                if (data.hasOwnProperty 'json/story-object')
                    e.stopPropagation()
                    $scope.api.onStoryDropped data['json/story-object'].data

            $scope.handleStoryDragover = (data, e) ->
                #Stop dragover event propagating to react dnd layer which causes issues.
                e.stopPropagation()

            $scope.$on '$destroy', ->
                removeRouteChangeHook()

    .directive 'newslistReactApp', (reactDirective) ->
        if window.newslist and window.newslist.default.App
            reactDirective(window.newslist.default.App, ['api'])
        else
            () -> ({})

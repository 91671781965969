'use strict'

angular.module 'nn.task.info.services', []

.factory 'taskInfoService', ($q, $rootScope, PageTaskService, TaskService, CopyfitAssistantService, FurnitureObject, FeatureAccess, FeatureAccessTags) ->

  createTask: (task, type, workflow) ->
    $q (resolve, reject) =>
      if type is 'story'
        TaskService.create(task, type, workflow).then resolve, reject

      if type is 'furniture'
        FurnitureObject.create(task.content_object).success (data) ->
          task.content_object.id = data.id
          TaskService.create(task, type).then resolve, reject

  updateTask: (task, type, origTaskData, workflow, origWorkflowData) ->
    $q (resolve, reject) =>
      if not angular.equals task, origTaskData and not angular.equals workflow, origWorkflowData
        if type is 'story'
            TaskService.update(task.id, task)
              .then (response) ->
                resolve response
                FeatureAccess.hasFeatureAccess(FeatureAccessTags.PRINT_PACKAGE, {user: 'self', org: task.organization}).then (result) ->
                  if result and origTaskData.content_object.id and task.content_object.id == null
                    CopyfitAssistantService.delete(origTaskData.content_object.id, parseInt(origTaskData.organization)).then resolve, reject
                $rootScope.$broadcast('storyTaskUpdated', {task: task})

              , reject
        
        if type is 'furniture'
          FurnitureObject.update origTaskData.content_object.id, task.content_object
          .success -> TaskService.update(task.id, task).then resolve, reject
          .error -> reject()
      
      else reject()
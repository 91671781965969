#
# Menu module
# =============
# 
angular.module "nn.form-submit-search.directives", []
# 
# This is used to load the list of forms and to select. These functions
# either control the current FormModel on the display, or handle
# the Storage Service for saving and loading...
#
.directive "nnFormSubmitSearch", ->
    templateUrl: "/templates/form-submit-search.html"
    restrict: "E"
    scope: {}
    controller: ($scope, FormsStatus, FormsUser, FormsTime, Organization, StorageService, FormModel) ->
        $scope.statuses = FormsStatus.refresh()
        $scope.users = FormsUser.refresh()
        $scope.times = FormsTime.refresh()
        $scope.organizations = Organization.refresh()
        # load the submissions from the server...
        $scope.loader = StorageService.submitLoader
        $scope.loader.setParser StorageService.submissionParser
        #
        # start it up when controller is initialized...
        #
        $scope.loader.search()
'use strict'

angular.module 'nn.page-editor', [
    'nn.page-editor.directives'
  ]

  .constant 'PageBuilderConfig',
    gridspace: 18
    columnspace: 130
    inchmark: 4 # inch mark 4 grid spaces apart

  .constant 'PageBuilderDropTypes',
    STORY: 'json/story-object'
    FILE: 'json/file-object'
    FORM: 'json/form-object'

  .constant 'PageBuilderModuleTypes',
    STORY: 'story'
    UTILITY: 'utility'
    FURNITURE: 'furniture'
    FORM: 'form'

  .constant 'PageWorkFlows',
     Draft: 
      name: "Draft"
      func: "SetToDraft"
      code: "Draft"
      css: 'secondary'

     PendingApproval: 
      name: "Ready for EPC"
      func: "SetToPendingApproval"
      code: "PendingApproval"
      css: 'alert'
      msg: { type: 'warning', text: 'is waiting for approval' }

     PrintReady: 
      name: "Editorial Complete"
      func: "SetToPrintReady"
      code: "PrintReady"
      css: 'success'
      msg: { type: 'success', text: 'is now Print ready' }

  .constant 'PageBuilderDropTypes',
    STORY: 'json/story-object'
    FILE: 'json/file-object'
    FORM: 'json/form-object'

  .constant 'PageBuilderModuleTypes',
    STORY: 'story'
    UTILITY: 'utility'
    FURNITURE: 'furniture'
    FORM: 'form'

  .constant 'copyfitElementColours',
    line: 'lightgrey'
    overset: '#e74c3c'
    underset: '#f1c40f'

  .constant 'storyElementColours',
    unassigned: '#FFFFFF'
    assigned: '#FFFED4' 

  .constant 'furnitureElementColours', 
    unassigned: '#2C3E50'
    assigned: '#6AB4FD' 

  .constant 'advertElementColours', 
    publishable: '#BEFFBE'
    notready: '#FDE0E0'

  .constant 'imageElementColours', 
    valid: '#2ecc71' 
    notvalid: '#e74c3c'

'use strict'

angular.module 'nn.story-snapshots.directives', []

    .directive 'nnStorySnapshots', ->
        templateUrl: '/templates/story-snapshots.html'
        restrict: 'E'
        scope:
            story: '='
        controller: ($scope, $state, Auth, Snapshots, EventLog, AppState) ->            
            events = EventLog.getInstance()
            $scope.events = events
            events.params().story_id = $scope.story.id
            events.params().event_type = null
            events.get()            

            snapshots = Snapshots.getInstance()
            $scope.snapshots = snapshots

            $scope.isActive = (snapshot) ->
                $state.params.snapshot is snapshot.id.toString()

            $scope.isWebHead = (id) ->
                $scope.snapshots.approvedWebSnapshot()?.id is id or $scope.snapshots.forApprovalWebSnapshot()?.id is id
            $scope.isPrintHead = (id) ->
                $scope.snapshots.approvedPrintSnapshot()?.id is id or $scope.snapshots.forApprovalPrintSnapshot()?.id is id

            # Debugging logs for the snapshot functions
            $scope.select = (snapshot) ->
                if $state.params.snapshot?
                    $state.go '^.snapshot', snapshot:snapshot.id
                else
                    $state.go '.snapshot', snapshot:snapshot.id

            $scope.getSnapshotRedflagEvent = (id) ->
                #Get all red flag events pertaining to the snapshot id for this story and return the first result. 
                #Events from the API are ordered by -createdOn by default(newest to oldest).
                events.all().filter((event)->event.meta.hasOwnProperty('snapshot_id'))
                    .filter((event)->event.meta.snapshot_id == id.toString())[0] || []                   

            $scope.$watch 'story', (story) ->
                if story?.id?
                    snapshots.refresh story.id
                    events.params().story_id = $scope.story.id

'use strict'

angular.module 'nn.task.assignee', []

  .directive 'nnTaskAssignee', ($q, User, PageTaskService, $timeout, Workflow) ->
    templateUrl: '/templates/task-assignee.html'
    link: (scope, element, attrs) ->

      scope.selectAssignees = (assignee) ->
        scope.task.organization = PageTaskService.currentOrg()

        scope.assignee =
          email: assignee.email
          first_name: assignee.first_name
          last_name: assignee.last_name

        scope.task.assignee = assignee.id

      loadCurrentMembers = ->
        if PageTaskService.currentOrg()
          PageTaskService.getMembersForOrg().then (data) ->
            scope.assignees = data[0]
            scope.task.organization = data[1]

      scope.workflow.refreshOrgMembers = loadCurrentMembers

      if scope.type is 'story'

        if scope.task.organization
          org = scope.task.organization?.id or scope.task.organization
          if PageTaskService.currentOrg() isnt org
            User.getMembers(org).then (members) ->
              scope.assignees = members
          else loadCurrentMembers()
        else loadCurrentMembers()


'use strict'

angular.module 'nn.file-search.directives', []

    .directive 'nnFileSearch', ->
        templateUrl: '/templates/file-search.html'
        restrict: 'E'
        scope: {}
        controller: ($scope, Files, User, FileType, FileUser, FileTime) ->
            $scope.organizations = []
            $scope.files = Files.getInstance().search()
            $scope.filetypes = FileType.refresh()
            $scope.fileusers = FileUser.refresh()
            $scope.filetimes = FileTime.refresh()

            User.getOrganizations().then (orgs) ->
                $scope.organizations = orgs


'use strict'

angular.module 'adonis.issue.collections.spreads.spread.details', []

.factory 'spreadsDetails', (BasePresenter) ->
  class spreadsDetails extends BasePresenter
    constructor: ($queryStream, pageSide, [@pub, @year, @month, @day, @book, @id, @side]) ->
      @side = @side or pageSide
    base: -> "#{@pub}-#{@year}-#{@month}-#{@day}"
    spread: -> "#{@base()}-#{@book}-#{@id}"
    spreadLayout: -> "#{@spread()}#{if @side is 'spread' then '' else "-#{@side}"}"
    layout: -> "#{@spread()}-#{if @side is 'spread' then 'left' else @side}"
    sync: (pageSide, [@pub, @year, @month, @day, @book, @id, @side]) ->
      @side = @side or pageSide
'use strict'

angular.module 'nn.onetime-notification.services', []

  .factory 'OneTimeNotification', ($q, $http, Auth, HOSTS, NotificationService) ->
    API_URI = HOSTS.monza + '/api/v2/user_store/'

    callService: (type) ->
      deferred = $q.defer()
      $http
        method: 'GET',
        url: API_URI + "?type=#{type}"
        cache: false
        auth: true
      .success (response) ->
        deferred.resolve response
      .error (response) ->
        deferred.reject response
      deferred.promise

    updateService: (type) ->
        deferred = $q.defer()
        $http
            method: 'POST'
            url: API_URI
            data: { domain: "newsnow.io", type: type, value: "true" }
            cache: false
            auth: true
        .success (response) ->
            deferred.resolve response
        .error (response) ->
            deferred.reject response
        deferred.promise

  .constant 'EDITORIAL_SHEET_EMBEDS',
    shared: 'https://links.acm.media/shared'
    runsheet: 'https://links.acm.media/runsheet'
    guidebook: 'https://links.acm.media/guidebook'
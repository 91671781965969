'use strict'

angular.module 'nn.roles.directives', []

.directive 'nnCheckRole', (Roles, RolesConfig) ->
  (scope, element, attrs) ->
    checkRole = ->
        if not Roles.can(attrs.nnCheckRole)
            if RolesConfig.debugMode
                height = element.height()
                element.css('background-color', 'red').css('height',  height + 'px').css('border', '2px solid white')
            else
                element.remove()

    if Roles.hasRoles() then checkRole() else Roles.attachRoles().then -> checkRole()

.directive 'nnCheckRoute', (Roles, RolesConfig) ->
  (scope, element, attrs) ->
    checkRole = ->
        if not Roles.canGo(attrs.nnCheckRoute)
            if RolesConfig.debugMode
                height = element.height()
                element.css('background-color', 'red').css('height',  height + 'px').css('border', '2px solid white')
            else
                element.remove()

    if Roles.hasRoles() then checkRole() else Roles.attachRoles().then -> checkRole()
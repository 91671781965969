'use strict'

angular.module 'nn.story-search.services', []

    .factory 'StoryStatus', ->
        statuses = null
        all: ->
            statuses
        refresh: ->
            statuses = null
            statuses = [
                    { name:'all status', value:'' }
                    { name:'draft', value:'d' }
                    { name:'published', value:'p' }
                    { name:'private', value:'r' }
                ]
            @

    .factory 'StoryUsers', (Auth, User) ->
        users = null
        all: ->
            users
        refresh: (orgId) ->
            if orgId
                User.getMembers(orgId).then (members) =>
                    users = null
                    transformedUsers = members.map (member) ->
                        { name: member.name, value: member.id }
                    users = [
                        { name:'all stories', value:'' }
                        transformedUsers...
                    ]
            else
                Auth.user().then (user) =>
                    users = null
                    users = [
                        { name:'all stories', value:'' }
                        { name:'my stories', value:user.id }
                    ]
            @

    .factory 'StoryTime', ->
        times = null
        all: ->
            times
        refresh: ->
            times = null
            times = [
                    { name:'past month', value:'-1m' }
                    { name:'past 24 hours', value:'-1d' }
                    { name:'past week', value:'-1w' }
                    { name:'past year', value:'-1y' }
                    { name:'any time', value: '' }
                ]
            @

    .factory 'StoryTags', ($http, $q, Auth, HOSTS, SilverstoneConfig) ->
        tags = null
        all: ->
            tags
        refresh: (all = false) ->
            deferred = $q.defer()
            url = "#{SilverstoneConfig.api_url}tag/"
            if all
                url += '?all=true'
            $http
                method: 'GET'
                url: url
                cache: true
                auth: true
            .success (response) ->
                deferred.resolve response[1..]
            deferred.promise

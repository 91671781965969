'use strict'

angular.module 'nn.validation-list.directives', []

  .directive 'nnValidationList', ->
    templateUrl: '/templates/validation-list.html'
    restrict: 'E'
    replace: true
    scope: { holeId: '=' }
    controller: ($scope, Copyfit, StorypadMessageType, StoryFitPresenter, AppState) ->

      $scope.validation_error = null
      $scope.loaded = false 
      
      $scope.notExcluded = (key) ->
        key not in ['embeds', 'figures']

      doUnsubscribe = (holeId) ->
        StoryFitPresenter.deleteSubscription holeId
   
      $scope.isarray = (item) ->
        angular.isArray item

      $scope.length = (value) ->
        value == null || value.length == 0

      $scope.getPercentForArray = (item) ->
        i = 0
        for it, v of item
          i++ unless v != 'IsValid'
        Math.round(i / item.length * 100) or 0
        
      $scope.imageIsValid = (value) ->
        value?.info?.v?.status?.t not in ['ImageCorrectionPending', 'ImageTooSmall', 'ImageNotRgb', 'ImageCorrectionError', 'NoContent']

      $scope.getPercentForValue = (value) ->
        percent = 0
        if !angular.isArray(value) and value?.info?.v
          percent = value.info.v.filled * 100
        percent.toFixed(0)

      $scope.isRequired = (value) ->
        value?.info?.v?.required

      $scope.getStatusForValue = (value) ->
        status = value?.info?.v?.status?.t or 'NoContent'

      goCopyfit = (holeId) ->
        StoryFitPresenter.getSubscription(holeId).then (data) ->
          $scope.loaded = true
          $scope.validation = data

      $scope.$watch 'holeId', (newHole, oldHole) ->
        if newHole
          if oldHole and newHole isnt oldHole
            doUnsubscribe oldHole
            $scope.loaded = false 
          goCopyfit newHole  
        else $scope.validation = null

      goCopyfit $scope.holeId

      $scope.$on '$destroy', -> doUnsubscribe $scope.holeId
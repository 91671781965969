'use strict'

angular.module 'nn.story-editor.directives', []

    .directive 'nnStoryEditor', ->
        templateUrl: '/templates/story-editor.html'
        restrict: 'E'
        scope:
            story: '='
        transclude: true
        link: (scope, element, attrs) ->
            scope.hideModal = () ->
                scope.showOvuModal = false
        controller: (
            $http,
            $scope,
            $element,
            $log,
            $rootScope,
            $timeout,
            $sce,
            $state,
            $window,
            HOSTS,
            STORY_VISIBILITY,
            STORY_CHANNEL,
            AppState,
            Auth,
            AuthEvent,
            StorypadService,
            OvuUiService,
            OvuUiMessageType,
            OvuUiRequestMessageType,
            StorypadRequestType,
            StorypadMessageType,
            StoryAssistanceRequestType,
            TaskService,
            Copyfit,
            Story,
            NotificationService,
            Bucket,
            StoryInfo,
            Roles,
            nnSidenavService,
            FeatureAccess,
            FeatureAccessTags,
            Environment,
            BreakingNews,
            ProfileAPI,
            StoryPreview
        ) ->
            storypad = StorypadService.getInstance $element.find('iframe')[0].contentWindow
            ovu = OvuUiService.getInstance $element.find('iframe')[1].contentWindow
            ovuReady = false

            $scope.ovuSrc = ''
            $scope.storyinfo = StoryInfo
            $scope.storypad = storypad
            $scope.showOvuModal = false

            $scope.mobileLayout = ->
                $("#mobile-layout").addClass("white")
                $("#story-layout").removeClass()
                $("#story-layout").addClass("mobile")
                $("#tablet-layout").removeClass("white")
                $("#desktop-layout").removeClass("white")
                $("#mobile-layout-header").show()
                $("#story-layout-iframe").attr('src', $('#story-layout-iframe').attr('src'))
                return
            $scope.tabletLayout = ->
                $("#tablet-layout").addClass("white")
                $("#story-layout").removeClass()
                $("#story-layout").addClass("tablet")
                $("#mobile-layout").removeClass("white")
                $("#desktop-layout").removeClass("white")
                $("#mobile-layout-header").hide()
                $("#story-layout-iframe").attr('src', $('#story-layout-iframe').attr('src'))
                return
            $scope.desktopLayout = ->
                $("#desktop-layout").addClass("white")
                $("#story-layout").removeClass()
                $("#story-layout").addClass("large-screen")
                $("#mobile-layout").removeClass("white")
                $("#tablet-layout").removeClass("white")
                $("#mobile-layout-header").hide()
                $("#story-layout-iframe").attr('src', $('#story-layout-iframe').attr('src'))
                return
            $scope.openInNewTab = ->
                previewUrl = $("#story-layout-iframe").attr("src")
                $window.open previewUrl
                return
            $scope.exitLayout = ->
                $("#mobile-layout").addClass("white")
                $("#tablet-layout").removeClass("white")
                $("#desktop-layout").removeClass("white")
                $("#nn-editor-container").removeClass("hide-left-bar")
                $("#story-editor-container").removeClass("flexlayout")
                $(".hide-element").show()
                $("#layuout-tool-bar").hide()
                $("#story-layout").hide()
                $("#mobile-layout-header").hide()
                nnSidenavService('storyinfo').toggle().then (storyInfoActive) ->
                    if storyInfoActive
                        StoryInfo.hide()
                        if scope.task?.content_object?.id?
                            nnSidenavService('mside').open()
                    else
                        nnSidenavService('mside').close()
                return

            $scope.toggleOvu = () ->
                if !$scope.storyModel
                    storypad.sendMessage StorypadMessageType.STORY_NOT_SAVE, 'Story not saved yet'
                else
                    storypad.sendMessage StorypadMessageType.GET_LATEST_BODY, ''
                    $("#overlay").attr('style', 'display: inline-block !important')
                    $scope.showOvuModal = !$scope.showOvuModal
                    if ovuReady
                        $timeout =>
                            updatesStoryInOvuIframe()
                        , 100

            $scope.$watch 'story', (story, oldStory) ->
                if !storypad.storyChangeDueToSave
                    storypad.changeStory story, oldStory
                    StoryInfo.hide()
                    nnSidenavService('storyinfo').open()
                storypad.storyChangeDueToSave = false

            updatesStoryInOvuIframe = () ->
                storyTitle = $scope.storyModel.title
                storyBody = ''
                $scope.storyModel.body.elements.map (el, index) ->
                    if el.type is 'paragraph'
                        storyBody = storyBody + el.text
                storyTags = ''
                $scope.storyModel.tags.map (el, index) ->
                    if index is 0
                      storyTags = storyTags + el
                    else
                      storyTags = storyTags + ', ' + el
                story =
                    storyTitle: storyTitle
                    storyBody: storyBody
                    storyId: $scope.storyModel.id
                    storyLede: $scope.storyModel.summary
                    storyStatus: $scope.storyModel.status
                    storyTags: storyTags
                    storyUserid: $scope.storyModel.updated_by
                ovu.sendMessage OvuUiMessageType.STORY_MODEL, story

            onStorypadMessageReceived = (event, data) =>
                event = event.originalEvent || event
                if typeof event.data == 'object'
                    {type, value} = event.data
                else
                    if event.data.substring(0, 6) is 'ima://'
                        {type, value} = JSON.parse event.data.substr(6)
                    else
                        try
                            {type, value} = JSON.parse event.data
                        catch er
                            type = null
                            value = null
                # $log.log 'MANAGE CLIENT: [received message]', type, value
                switch type
                    when OvuUiRequestMessageType.CLOSE
                        $("#overlay").css("display","none");
                    when OvuUiRequestMessageType.READY
                        ovuReady = true
                        ovu.sendMessage OvuUiMessageType.ENVIRONMENT, Environment
                    when StorypadRequestType.TOKEN
                        Auth.token().then (token) ->
                            storypad.sendMessage StorypadMessageType.TOKEN, token
                    when StorypadRequestType.TOKEN_REFRESH
                        Auth.refresh()
                    when StorypadRequestType.USER
                        Auth.user().then (user) ->
                            ProfileAPI.load().then (profile) ->
                                if profile.length > 0
                                    defaultOrg = profile[0].default_organization
                                else
                                    defaultOrg = ''
                                obj =
                                    details: user
                                    defaultOrg: defaultOrg
                                    permissions:
                                        addCmykImageToStory: Roles.can 'addCmykImageToStory'
                                        bypassImageSizeValidation: Roles.can 'bypassImageSizeValidation'
                                storypad.sendMessage StorypadMessageType.USER, obj
                    when StorypadRequestType.TASK
                        if $state.params.task
                            storypad.sendMessage StorypadMessageType.TASK, $state.params.task
                        else if $state.params.story
                            TaskService.oneForStory($state.params.story).then (response) ->
                                storyHasTask = response.results.length isnt 0
                                if storyHasTask
                                    storypad.sendMessage StorypadMessageType.TASK, response.results[0].id
                                else
                                    storypad.sendMessage StorypadMessageType.TASK, null
                        else
                            storypad.sendMessage StorypadMessageType.TASK, null
                    when StorypadRequestType.BUCKET
                        Bucket.get().then (bucket) ->
                            storypad.sendMessage StorypadMessageType.BUCKET, name:bucket.name
                    when StorypadRequestType.HOSTS
                        storypad.sendMessage StorypadMessageType.HOSTS, HOSTS
                    when StorypadRequestType.SETTINGS
                        SETTINGS =
                            STORY_VISIBILITY: STORY_VISIBILITY
                            STORY_CHANNEL: STORY_CHANNEL
                        storypad.sendMessage StorypadMessageType.SETTINGS, SETTINGS
                    when StorypadRequestType.ANALYTICS
                        Auth.user().then (user) ->
                            storypad.sendMessage StorypadMessageType.ANALYTICS,
                                sentry:
                                    user: user
                    when StorypadRequestType.FIGURES
                        task_id = value
                        if task_id?
                            Copyfit.getFigures(task_id).then (figures) ->
                                storypad.sendMessage StorypadMessageType.FIGURES, figures
                            , (error) ->
                                storypad.sendMessage StorypadMessageType.FIGURES, []
                        else
                            storypad.sendMessage StorypadMessageType.FIGURES, []
                    when StorypadRequestType.STORY_SAVE
                        saveState = value.state
                        if saveState is 'saving'
                            $scope.$apply ->
                                storypad.saving = true
                        else
                            $scope.$apply ->
                                storypad.saving = false
                            if saveState is 'success'
                                $scope.storyModel = value.data
                                $scope.storypad.loadStory value.data
                                storypad.sendMessage StorypadMessageType.STORY_LOADED
                                storypad.sendMessage StorypadMessageType.UPDATED_STORY_RESPONSE, value.data
                                $scope.$broadcast(StoryAssistanceRequestType.STORY_LOADED, value.data)
                                storypad.storyChangeDueToSave = true
                                story_id = value.data.id
                                snapshot_id = value.data.snapshot
                                AppState.storySaved = true
                                if $state.includes "**.stories.new.**"
                                    task_id = $state.params.task
                                    if task_id
                                        FeatureAccess.hasFeatureAccess(FeatureAccessTags.PRINT_PACKAGE, {user: 'self', org: $scope.storyModel.organization}).then (result) ->
                                            TaskService.update(task_id, {content_object: {id: story_id, type: 'story'}}).then ->
                                                $state.go 'app.stories.story.snapshot', {story:story_id, snapshot:snapshot_id}
                                    else
                                        $state.go 'app.stories.story', {story:story_id}
                                else if $state.includes "**.stories.story.**"
                                    $state.go 'app.stories.story.snapshot', {story:story_id, snapshot:snapshot_id}
                    when StorypadRequestType.STORY_LOAD
                        loadState = value.state
                        if loadState is 'success'
                            $scope.$apply ->
                                storypad.loaded = true
                                $scope.storyModel = value.data
                                if $scope.storyModel
                                    AppState.showBreakingConfimBox = $scope.storyModel.status
                                    $scope.$broadcast(StoryAssistanceRequestType.STORY_LOADED, value.data)
                                $scope.storypad.loadStory value.data
                                storypad.sendMessage StorypadMessageType.STORY_LOADED
                        else
                            switch value?.data?.status
                                when 403
                                    NotificationService.create 'error', 'This is a private story and cannot be accessed', '', 0
                                when 404
                                    NotificationService.error "There was a problem loading this story - please check the URL and try again"
                    when StorypadRequestType.EDIT_IMAGE
                        $scope.$apply ->
                            storypad.editingImage = true
                            if nnSidenavService('storyinfo').active()
                                StoryInfo.infoWasActive = true
                                nnSidenavService('mside').close()
                                nnSidenavService('storyinfo').close()
                    when StorypadRequestType.EDIT_IMAGE_COMPLETE
                        $scope.$apply ->
                            storypad.editingImage = false
                            if StoryInfo.infoWasActive
                                StoryInfo.infoWasActive = false
                                nnSidenavService('storyinfo').open()
                                if $scope.task?.content_object?.id
                                    nnSidenavService('mside').open()
                    when StorypadRequestType.TEXT_FIELD_UPDATE
                        data = JSON.parse event.data
                        $scope.storypad.updateStoryModelField data.value.type, data.value.message
                    when StorypadRequestType.ADD_IMAGE
                        data = JSON.parse event.data
                        $scope.storypad.addImage data.value
                    when StorypadRequestType.REMOVE_IMAGE
                        data = JSON.parse event.data
                        $scope.storypad.removeImage data.value
                    when StorypadRequestType.UPDATE_STORY_BODY
                        data = JSON.parse event.data
                        $scope.$broadcast(StoryAssistanceRequestType.RECEIVED_LATEST_BODY, data.value.data)
                        $scope.storypad.updateStoryModelField 'story_body', data.value.data
                    when StorypadRequestType.ACTIVATE_OVU_UI
                        $scope.toggleOvu()
                    when StorypadRequestType.HAS_OVU_ACCESS
                        if $scope.storyModel
                            FeatureAccess.hasFeatureAccess(FeatureAccessTags.OOVVUU_VIDEO, org: $scope.storyModel.organization).then (result) ->
                                if result
                                    $scope.ovuSrc = '/storypad/ovu.html'
                                else
                                    $scope.ovuSrc = ''
                                storypad?.sendMessage StorypadMessageType.HAS_OVU_ACCESS, result

                    when StorypadRequestType.ROTATE_IMAGE
                        data = JSON.parse event.data
                        $scope.storypad.rotateImage(data.value.data.token, data.value.data.imageId.id, data.value.data.bucketName.name, data.value.data.degree).then (result) ->
                            storypad.sendMessage StorypadMessageType.ROTATE_IMAGE, response: result
                        , (error) ->
                            storypad.sendMessage StorypadMessageType.ROTATE_IMAGE, error:'Fail to rotate images via Valencia services'
                    when StorypadRequestType.DIJON_API_STATUS
                        data = JSON.parse event.data
                        if !data.value.state
                            NotificationService.create 'error', "'Share with' service (Dijon) is not available", 'Please contact your Local IT', 0
                    when StorypadRequestType.UPDATE_TARGETLISTS
                        data = JSON.parse event.data
                        data = data.value.data
                        AppState.story.targetLists = data
                    when StorypadRequestType.SHOW_BREAKING_CONFIRM_BOX
                        data = JSON.parse event.data
                        data = data.value.data
                        AppState.showBreakingConfimBox = data
                    when StorypadRequestType.BREAKINGNEWS_DATE_VALIDATION
                        data = JSON.parse event.data
                        data = data.value.state
                        AppState.breakingnewsDateValidation = data
                    when StorypadRequestType.UPDATE_STORY_PUBLISH_STATE
                        data = JSON.parse event.data
                        data = data.value.data
                        for k, v of data
                            AppState.storyPublishState[k] = v
                    when StorypadRequestType.SOURCE_UPDATED
                        data = JSON.parse event.data
                        data = data.value.data
                        $scope.$broadcast(StoryAssistanceRequestType.SOURCE_UPDATED, data)
                    when StorypadRequestType.STORY_ON_SAVE_IS_INVALID
                        $scope.$broadcast(StoryAssistanceRequestType.STORY_ON_SAVE_IS_INVALID)
                    when StorypadRequestType.DISPLAYING_SETTINGS_PANEL
                        $scope.$broadcast(StoryAssistanceRequestType.DISPLAYING_SETTINGS_PANEL)


            angular.element($window).on 'message', onStorypadMessageReceived

            deregisterTokenRefreshed = $rootScope.$on AuthEvent.TOKEN_REFRESHED, (event, data) ->
                storypad.sendMessage StorypadMessageType.TOKEN_REFRESHED, data.token

            $timeout ->
                $scope.storyinfoCtrl = nnSidenavService 'storyinfo'

            $scope.$on '$destroy', ->
                angular.element($window).off 'message', onStorypadMessageReceived
                deregisterTokenRefreshed()
                StoryInfo.reset()
                storypad.destroy()
                storypad = null



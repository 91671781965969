angular.module 'nn.dragndropsvg.image', []

.factory 'SVGDragImage', (dndHelpers) ->

  _css = (el, prop, val) ->
    style = el and el.style

    if style
      if val == undefined
        if document.defaultView and document.defaultView.getComputedStyle
          val = document.defaultView.getComputedStyle(el, '')
        else if el.currentStyle
          val = el.currentStyle
        return if prop == undefined then val else val[prop]
      else
        if !(prop of style)
          prop = '-webkit-' + prop
        style[prop] = val + (if typeof val == 'string' then '' else 'px')
    return

  dragImage = null
  dragImageTransform = null
  dragImageWebKitTransform = null

  translateDragImage: (x, y) ->
    translate = 'translate(' + x + 'px,' + y + 'px) '

    if dragImageWebKitTransform != null
      dragImage.style['-webkit-transform'] = translate + dragImageWebKitTransform

    if dragImageTransform != null
      dragImage.style.transform = translate + dragImageTransform

    return

  destroy: ->
    if dragImage != null
      dragImage.parentNode.removeChild dragImage
      dragImage = null
      dragImageTransform = null
      dragImageWebKitTransform = null

  createDragImage: (element) ->
    dragImage = element.cloneNode true

    _css dragImage, 'position', 'absolute'
    _css dragImage, 'left', '0px'
    _css dragImage, 'top', '0px'
    _css dragImage, 'margin', '0'
    _css dragImage, 'zIndex', '999999'
    _css dragImage, 'pointerEvents', 'none'

    transform = dragImage.style.transform

    if typeof transform != 'undefined'
      dragImageTransform = ''

      if transform != 'none'
        dragImageTransform = transform.replace(/translate\(\D*\d+[^,]*,\D*\d+[^,]*\)\s*/g, '')

    webkitTransform = dragImage.style['-webkit-transform']

    if typeof webkitTransform != 'undefined'
      dragImageWebKitTransform = ''

      if webkitTransform != 'none'
        dragImageWebKitTransform = webkitTransform.replace(/translate\(\D*\d+[^,]*,\D*\d+[^,]*\)\s*/g, '')

    @translateDragImage -9999, -9999

    document.querySelector('body').appendChild dragImage

    return

'use strict'

angular.module 'adonis.issue.collections.pagelayouts.elements.barcode', []

.factory 'spreadsBarcode', (BasePresenter, NotificationService, BARCODES_BUCKET, Uploader) ->

  BARCODE_COMMANDS =
    CLEAR: 'clearBarcode'
    RESTORE_DEFAULT: 'restoreDefaultBarcode'
    SET: 'setBarcode'

  NOTIFICATIONS =
    CLEAR: 'Are you sure you want to remove this barcode?'
    CONFIRM_DEFAULT: 'Are you sure you want to remove the existing barcode and restore the default?'
    UPLOAD_ERROR: 'Upload error, please try again later'

  class spreadsBarcode extends BasePresenter

    constructor: ($queryStream, spreadId, barcode) ->
      super $queryStream
      @reset()
      @uploader = Uploader.getInstance BARCODES_BUCKET
      @sync spreadId, barcode

    sync: (spreadId, barcode) ->
      @spreadId = spreadId
      @barcode = barcode
      @exists = barcode?.resource?.v?
      if @exists
        { host, bucket, key } = barcode.resource.v
        @url = "//#{host}/media/#{bucket}/#{key}"
        @uri = "/#{bucket}/#{key}"

    clear: ->
      NotificationService.confirm NOTIFICATIONS.CLEAR
        .then =>
          @query BARCODE_COMMANDS.CLEAR, @spreadId

    restoreDefault: ->
      doRestore = =>
        @query BARCODE_COMMANDS.RESTORE_DEFAULT, @spreadId
      if @exists
        NotificationService.confirm NOTIFICATIONS.CONFIRM_DEFAULT
          .then doRestore
      else
        doRestore()

    upload: ->
      @uploader.upload()
        .then (result) =>
          if result?[0]?
            { name } = result[0]
            @query BARCODE_COMMANDS.SET, @spreadId, BARCODES_BUCKET, name
          else
            NotificationService.error NOTIFICATIONS.UPLOAD_ERROR
          @reset()

    assign: ->
      { filename } = @fileModel
      @query BARCODE_COMMANDS.SET, @spreadId, BARCODES_BUCKET, filename
      @reset()

    reset: ->
      @fileModel = {}
      @fileSelection = false

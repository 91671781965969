'use strict'
require('../styles/StorySocial.css')

ReactSocial = require('@fairfaxmedia/storypad/lib/components/StorySocial/StorySocial').default

angular.module 'nn.story-info.directives', []
    .value 'ReactSocial', ReactSocial
    .constant 'StoryInfoMenuType',
        BREAKINGNEWS:
            id: 'breakingnews'
            label: 'Create Breaking News Email'
        UPDATEBREAKINGNEWS:
            id: 'updatebreakingnews'
            label: 'Update Breaking News Email'
        STORY:
            id: 'story'
            label: 'Story task'
        SNAPSHOTS:
            id: 'snapshots'
            label: 'Snapshots'
        # SOCIAL:
        #    id: 'social'
        #    label: 'Social Sharing'
        COPYFIT:
            id: 'copyfit'
            label: 'Page copyfit & render'
        DIGITALQUALITYCHECKLIST:
            id: 'digitalqualitychecklist'
            label: 'Digital Quality Checklist'
        SEO:
            id: 'seo'
            label: 'SEO Headline and Meta Description'

    .directive 'nnSocialForm', () ->
        templateUrl: '/templates/story-social.html'
        restrict: 'E'
        controller: ($scope, $timeout, StorypadService) ->
            $scope.storypad = StorypadService.getInstance()

            $scope.reactProps =
                disabled: false
                title: ''
                summary: ''
                images: [],
                socialTitle: '',
                socialSummary: '',
                socialImage: '',
                loaded: false,
                setStoryModelField: (name, value) -> $scope.storypad.updateStoryModelField(name, value)

            onModelChange = (model) =>
                $timeout () ->
                    $scope.$apply () ->
                        $scope.reactProps.title = model.title
                        $scope.reactProps.summary = model.summary
                        $scope.reactProps.loaded = true
                        if model.body
                            $scope.reactProps.socialTitle = model.body.social_title
                            $scope.reactProps.socialSummary = model.body.social_summary
                            $scope.reactProps.socialImage = model.body.social_url

            onImageChange = (images) =>
                $timeout () ->
                    $scope.$apply () ->
                        $scope.reactProps.images = images

            $scope.storypad.on 'modelChange', onModelChange

            $scope.storypad.on 'imageChange', onImageChange


    .directive 'nnStoryInfo', (AppState) ->
        templateUrl: '/templates/story-info.html'
        restrict: 'E'
        scope:
            story: '='
            storypad: '='
            task: '='
            hasDigitalPackageAccess: '='
        controller: ($scope, $rootScope, $stateParams, $timeout, Story, StoryPreview, StoryInfoMenuType, StoryUtils, StoryAssistanceMessageType, StoryAssistanceRequestType, AdonisService, ChartbeatLiveQuery, moment, FeatureAccess, FeatureAccessTags, Roles, AppState, BreakingNews, NotificationService) ->
            $scope.menuSelected = null
            $scope.menuType = StoryInfoMenuType
            $scope.active = {}
            $scope.issue = {}
            $scope.collapsibleOpen = false
            $scope.collapsibleUpdateBreakingNewsOpen = false
            $scope.hasEngagementMetricsAccess = false
            $scope.hasBreakingNewsAccess = false
            $scope.hasDigitalPackageAccess = false
            $scope.hasPrintPackageAccess = false
            $scope.engagementMetricsFeatureTag = "engagement-metrics"
            $scope.showBreakingNews = Roles.can 'sendBreakingNews'
            $scope.showUpdateBreakingNewsWarning = false
            $scope.breakingNewsSent = false
            $scope.selectedLayout =
                mobile: true
                desktop: false

            $scope.socialMedia = false
            $scope.loading = true
            $scope.requestingStoryMetrics = false
            $scope.queryData = null
            $scope.query_domain = null
            $scope.today = moment().format('YYYY-MM-DD')
            $scope.warningMsg = ""
            $scope.liveMode = true
            $scope.showButton = false
            $scope.storyIsWebOnly = false
            $scope.storyIsPrintOnly = false
            $scope.sidePanelWidth = '421px';
            $scope.showCopyfitPanel = false

            $scope.$on 'showCopyfitPanel', (event) ->
                $scope.showCopyfitPanel = true

            $scope.$watch 'hasDigitalPackageAccess', (newVal, oldVal) ->
                $scope.$broadcast 'hasDigitalPackageAccess', newVal

            $scope.$watch 'storyIsWebOnly', (newVal, oldVal) ->
                $scope.$broadcast 'storyIsWebOnly', newVal

            $scope.$watch 'storyIsPrintOnly', (newVal, oldVal) ->
                $scope.$broadcast 'storyIsPrintOnly', newVal

            resetTask = ->
                $scope.storyDetail = null
                $scope.storytask = null
                $scope.page = null
                $scope.active = null
                $scope.hasBreakingNewsAccess = false
                $scope.hasDigitalPackageAccess = false
                $scope.hasPrintPackageAccess = false
                AppState.breakingNews = {}
                AppState.showBreakingConfimBox = null
                BreakingNews.showUpdate = false
                BreakingNews.subject = ''
                $scope.template = 'Send Article Summary Only'
                $scope.msideCtrl.close()

            ordinal_suffix_of = (i) =>
                j = i % 10
                k = i % 100
                if j is 1 and k isnt 11
                    i + "st"
                else if j is 2 and k isnt 12
                    i + "nd"
                else if j is 3 and k isnt 13
                    i + "rd"
                else
                    i + "th"

            $scope.useMobileLayout = () ->
                $scope.selectedLayout.mobile = true
                $scope.selectedLayout.desktop = false

            $scope.useDeskTopLayout = () ->
                $scope.selectedLayout.mobile = false
                $scope.selectedLayout.desktop = true

            $scope.toggleLayout = () ->
                $scope.selectedLayout.mobile = !$scope.selectedLayout.mobile
                $scope.selectedLayout.desktop = !$scope.selectedLayout.desktop

            $scope.getSidePanelStyle = () ->
                if $scope.selectedLayout.mobile
                    {'width': '421px'}
                else
                    {'width': '95%'}

            displaySeo = ->
                $scope.menuSelected = StoryInfoMenuType.SEO
                $scope.msideCtrl.open()
                $scope.useMobileLayout()

            $scope.updateStoryAssistancePhase = ->
                $scope.$broadcast(StoryAssistanceRequestType.UPDATE_PHASE)

            $scope.resetStoryAssistance = ->
                $scope.$broadcast(StoryAssistanceRequestType.RESET_STORY_ASSISTANCE)

            $scope.$on StoryAssistanceMessageType.UPDATE_DIGITAL_PACKAGE_ACCESS, (_, hasAccess) ->
                $scope.hasDigitalPackageAccess = hasAccess

            $scope.$on StoryAssistanceMessageType.DISPLAY_STORY_ASSISTANCE, ->
                if !$scope.storyinfoCtrl.active()
                    $scope.storyinfoCtrl.open().then ->
                        displaySeo()
                else
                    if $scope.msideCtrl.active()
                        $scope.msideCtrl.close()
                    displaySeo()

            $scope.getBreakingNewsShowUpdate = ->
                BreakingNews.showUpdate

            $scope.getMsgAllowance = ->
                BreakingNews.msgAllowance

            $scope.getMsgNotAllowance = ->
                BreakingNews.msgNotAllowance

            $scope.getBreakingTemplate = ->
                BreakingNews.template

            $scope.getBreakingNewsSubject = ->
                BreakingNews.subject

            $scope.openDetails = ->
                window.open("https://chartbeat.com/publishing/dashboard/#{$scope.query_domain}/?sortColumns=engaged_time::pageviews_today#path=%2Fstory%2F#{$scope.storyDetail.id}%2F")
                return

            $scope.goHistorical = ->
                window.open("https://chartbeat.com/publishing/historical/#{$scope.query_domain}/article/%2Fstory%2F#{$scope.storyDetail.id}%2F?enddate=#{$scope.today}&startdate=#{$scope.publishDate}&pageHost=#{$scope.query_domain}")
                return

            $scope.refreshLiveData = (isInitial)->
                if isInitial
                    $scope.loading = true
                else
                    $("#refresh_bt").addClass("fa-spin")
                onQuerySuccess = (response) ->
                    $scope.queryData = response.data
                    if isInitial
                        $scope.loading = false
                        if !$scope.queryData.stats.people
                            $scope.showButton = true
                            $scope.warningMsg = "This article currently has no visitors on it, click below to see historical data in Chartbeat"
                    else
                        $("#refresh_bt").removeClass("fa-spin")
                onQueryError = ->
                    $scope.warningMsg = "Unable to retrieve data from Chartbeat, try later"
                    if isInitial
                        $scope.loading = false
                    else
                        $("#refresh_bt").removeClass("fa-spin")
                ChartbeatLiveQuery.Query($scope.query_domain, "/story/#{$scope.storyDetail.id}/").then onQuerySuccess, onQueryError

            $scope.loadingCharbeatLiveData = ->
                if $scope.requestingStoryMetrics is false
                        $scope.requestingStoryMetrics = true
                        originalStatus = $scope.storyDetail.snapshot_pointers.web.approved
                        isAfter = moment($scope.storyDetail.publish_from).isAfter(moment.utc())
                        $scope.publishDate = moment($scope.storyDetail.publish_from).format('YYYY-MM-DD')
                        if originalStatus and isAfter is false
                            if $scope.storyDetail.id?
                                onPreviewSuccess = (response) ->
                                    {siteFound, url, domain} = response
                                    $scope.query_domain = domain
                                    $scope.query_domain = $scope.query_domain.replace("www.", "")
                                    if siteFound && $scope.liveMode
                                        $scope.refreshLiveData(true)
                                onPreviewError = ->
                                    NotificationService.error 'An error has occurred trying to get the readership metrics'
                                Story.previewURL($scope.storyDetail.id).then onPreviewSuccess, onPreviewError
                            else
                                NotificationService.error "Unsaved or unpublished stories. Please save and publish your story and try again"
                        else
                            $scope.queryData = null
                            $scope.warningMsg = "This article is not published to web"
                            $scope.requestingStoryMetrics = false
                            $scope.loading = false
                return

            $scope.openCollasible = ->
                if $(".collapsible-content").height() > 0
                    $(".collapsible-content").css('max-height', '0px')
                    $scope.collapsibleOpen = false
                else
                    $(".collapsible-content").css('max-height', '1200px')
                    $scope.collapsibleOpen = true
                return

            $scope.openUpdateBreakingNewsCollasible = ->
                if $(".update-email-collapsible-content").height() > 0
                    $(".update-email-collapsible-content").css('max-height', '0px')
                    $scope.collapsibleUpdateBreakingNewsOpen = false
                else
                    $(".update-email-collapsible-content").css('max-height', '1200px')
                    $scope.collapsibleUpdateBreakingNewsOpen = true
                return

            $scope.$watch 'getBreakingNewsShowUpdate()', (nv, ov) ->
                if nv isnt ov
                    $scope.showUpdate = nv

            $scope.$watch 'getMsgAllowance()', (nv, ov) ->
                $scope.msgAllowance = nv

            $scope.$watch 'getMsgNotAllowance()', (nv, ov) ->
                $scope.msgNotAllowance = nv

            $scope.$watch 'getBreakingTemplate()', (nv, ov) ->
                if nv is 'FULL'
                    $scope.template = 'Send Full Article in Email'
                else
                    $scope.template = 'Send Article Summary Only'

            $scope.$watch 'getBreakingNewsSubject()', (nv, ov) ->
                if nv isnt ''
                    $scope.headline = nv

            $scope.$watch 'msideCtrl.active()', (nv, ov) ->
                if !nv and nv != ov
                    $rootScope.$broadcast(StoryPreview.TOGGLE_WEB_PREVIEW, false)

            $scope.$watch 'story', (newStory, oldStory) ->
                if newStory?.id
                    $scope.storytask =
                        story: newStory.id
                    BreakingNews.showUpdate = false
                    BreakingNews.subject = ''
                    Story.load(newStory.id, newStory.snapshot).then (story) ->
                        $scope.storyDetail = story
                        $scope.headline = story.title
                        BreakingNews.resetSubject = true
                        AppState.story.targetLists = $scope.storyDetail.target_lists
                        AppState.story.storyDetail = story
                        BreakingNews.title = "Breaking: " + AppState.story.storyDetail.title
                        $scope.storyIsWebOnly = StoryUtils.storyIsWebOnly story
                        $scope.storyIsPrintOnly = StoryUtils.storyIsPrintOnly story
                        if window.location.hostname isnt "newsnow.io"
                            $scope.engagementMetricsFeatureTag = "engagement-metrics-test"
                        else
                            $scope.engagementMetricsFeatureTag = "engagement-metrics"
                        FeatureAccess.hasFeatureAccess(FeatureAccessTags.DIGITAL_PACKAGE, org: $scope.storyDetail.organization).then (result) ->
                            if result
                                $scope.hasDigitalPackageAccess = true
                                $scope.loadingIframeContent = true
                                if $scope.storyIsWebOnly
                                    $scope.menuSelected = StoryInfoMenuType.DIGITALQUALITYCHECKLIST
                                    if newStory.id isnt oldStory.id
                                        $scope.msideCtrl.close()
                                    else
                                        $scope.msideCtrl.open()
                            else
                                $scope.hasDigitalPackageAccess = false
                                if $scope.storyIsWebOnly
                                    $scope.msideCtrl.close()
                        FeatureAccess.hasFeatureAccess(FeatureAccessTags.BREAKING_NEWS, org: $scope.storyDetail.organization).then (result) ->
                            if result
                                BreakingNews.fetchExistingBreakingNewsEmail($scope.storyDetail?.id).then (res) ->
                                    if res.results and res.results.length > 0
                                        $scope.breakingNewsSent = res.results.map (item) =>
                                            if item.date_sent
                                                item.date_sent = moment(item.date_sent).format("hh:mma on DD/MM/YYYY")
                                            else
                                                item.date_sent = 'Pending'
                                            return item
                                        groupedTimelineItem = {}
                                        groupedTimelineItemArray = []
                                        for obj in $scope.breakingNewsSent
                                            groupedTimelineItem[obj['snapshot_id']] ?= []
                                            groupedTimelineItem[obj['snapshot_id']].push obj
                                        Object.keys(groupedTimelineItem).map (group) ->
                                            groupedTimelineItemArray.push groupedTimelineItem[group]
                                        groupedTimelineItemArray.map (value, index) =>
                                            value[0].prefix = ordinal_suffix_of(index + 1)
                                        $scope.breakingNewsSent = groupedTimelineItemArray
                                    else
                                        $scope.breakingNewsSent = false
                                .catch ->
                                    NotificationService.create 'error', 'Server Error:', 'Error retrieving mail data.', 0
                                    window.Sentry.captureMessage 'Breaking news error - Error retrieving mail data'

                                $scope.hasBreakingNewsAccess = result

                            else
                                $scope.hasBreakingNewsAccess = false
                        FeatureAccess.hasFeatureAccess(FeatureAccessTags.CHARTBEAT, org: $scope.storyDetail.organization, tag: $scope.engagementMetricsFeatureTag).then (result) ->
                            if result
                                $scope.hasEngagementMetricsAccess = result
                                $(".collapsible-content").css('max-height', '1200px')
                                $scope.requestingStoryMetrics = false
                                $scope.loading = true
                                $scope.loadingCharbeatLiveData()
                            else
                                $(".collapsible-content").css('max-height', '0px')
                                $scope.hasEngagementMetricsAccess = false
                            $scope.showButton = false
                        FeatureAccess.hasFeatureAccess(FeatureAccessTags.PRINT_PACKAGE, {user: 'self', org: $scope.storyDetail.organization}).then (result) ->
                            $scope.hasPrintPackageAccess = result
                            $scope.showCopyfitPanel = $scope.hasPrintPackageAccess
                            $scope.showButton = false
                else if newStory?.task
                    $scope.storytask =
                        id: newStory.task
                else
                    $(".collapsible-content").css('max-height', '0px')
                    resetTask()

            $scope.$watch 'task', (nv, ov) ->
                if ov?.content_object?.id?
                    AdonisService.unsubscribe 'StoryRenderLookupPresenter', 'task-' + ov.id
                if nv?.content_object?.id?
                    AdonisService.subscribe ['StoryRenderLookupPresenter', 'task-' + nv.id], (result) =>
                        if result?.fitViews?.length
                            if $scope.storyinfoCtrl.active()
                                # if ov is null, it can be assume that it is a new page
                                # and enable the COPYFIT menu as default
                                if !ov
                                    $scope.menuSelected = StoryInfoMenuType.COPYFIT
                                    $scope.msideCtrl.close()
                                    if !$scope.storyIsWebOnly
                                        $scope.msideCtrl.open()
                else
                    $scope.msideCtrl.close()

            $scope.$on '$destroy', ->
                if $scope.task && $scope.task.id
                    AdonisService.unsubscribe 'StoryRenderLookupPresenter', 'task-' + $scope.task.id
                resetTask()


  .directive 'closeSecondStoryPanel', (nnSidenavService) ->
    restrict: 'A'
    link: (scope, element) ->
      element.on 'click', (e) ->
        if e.target.id is 'aside-container' or e.target.nodeName is 'H4'
          scope.$apply nnSidenavService('mside').close()


'use strict'

angular.module 'nn.task.reassign.services', []

  .factory 'PageTaskService', ($q, $http, AdonisData, User) ->
    currentCode = {}
    assignees = undefined
    promises = []

    currentOrg: -> parseInt currentCode.organisations?[0]

    getMembersForOrg: ->
      defer = $q.defer()

      find = -> defer.resolve [assignees, currentCode.organisations[0]]

      if assignees.length is -1
        promises.push def = $q.defer()
        def.promise.then find()
      else find()

      defer.promise

    switchTo: (pubCode) ->
      if currentCode isnt pubCode or angular.isUndefined(assignees)
        AdonisData.getPublicationPresenter(pubCode).then (data) ->
          currentCode = data

          if currentCode.organisations?[0]
            User.getMembers(currentCode.organisations[0]).then (members) ->
              assignees = members

              angular.forEach promises, (promise) ->
                promise.resolve assignees
                delete promises[promise]

  .factory 'OrphanedTaskService', ($q) ->
    orphans = []

    removeTask: (id, index, type) ->
      orphans[index][type].splice orphans[index][type].indexOf(id), 1

    getOrphanedTasks: ->
      $q.when orphans

    storeTasks: (tasks) ->
      orphans.push tasks

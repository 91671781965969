'use strict'

angular.module 'nn.validation-list', [
    'nn.validation-list.directives'
  ]

.filter 'ImageValidation', ->
  (status) ->
    switch status
      when 'IsValid' then return 'valid'
      when 'ImageCorrectionPending' then return 'processing'
      when 'ImageTooSmall' then return 'too small'
      when 'ImageNotRgb' then return 'not RGB'
      when 'ImageCorrectionError' then return 'failed'
      when 'NoContent' then return 'no image attached'
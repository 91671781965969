'use strict'

angular.module 'nn.task.workflow', []

  .directive 'nnTaskWorkflow', ($q, User, PageTaskService, $timeout, Workflow) ->
    templateUrl: '/templates/task-workflow.html'
    link: (scope, element, attrs) ->
      scope.workflow.id = null
      scope.workflowLoading = true

      Workflow.getWorkflows().then (workflows) ->
        scope.workflows.data = workflows
        scope.workflowLoading = false

      scope.workflow.check = ->
        Workflow.getWorkflowForTask(scope.task.id).then (data) ->
          scope.origWorkflowData.id = angular.copy(data[0]?.id or null)
          scope.workflow.id = data[0]?.status_group or null
          if data[0]?.id
            scope.workflow.resultId = data[0].id
            scope.origWorkflowData.resultId = data[0].id
            scope.workflow.customWorkflowSelected = true
            scope.origWorkflowData.customWorkflowSelected = true
          else
            scope.workflow.resultId = null
            scope.origWorkflowData.resultId = null
            scope.workflow.customWorkflowSelected = false
            scope.origWorkflowData.customWorkflowSelected = false
      if scope.task.id 
        scope.workflow.check()
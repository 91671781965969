'use strict'

angular.module 'nn.chartbeat-stats.directives', []

  .directive 'nnChartbeatStats', ->
    templateUrl: '/templates/chartbeat-stats.html'
    restrict: 'E'
    scope:
      'publication': '='
      'limit': '@'
    controller: ($scope, $timeout, Chartbeat) ->

      chartbeat = Chartbeat.getInstance $scope.publication.website
      $scope.data = chartbeat.data

      $scope.$watch 'publication', (newPub, oldPub) ->
        if newPub?.website?
          chartbeat.domain = newPub.website
          chartbeat.getStats()

      $scope.toggleAutoRefresh = ->
        chartbeat.toggleAutoRefresh 'stats', $scope.autoRefreshState

      $scope.animationTime = 10
      $scope.value = 2000
      $scope.maxValue = 3000
      $scope.gaugeOptions =
        lines: 12
        angle: 0.15
        lineWidth: 0.44
        pointer:
          length: 0.9
          strokeWidth: 0.035
          color: "#000000"
        limitMax: "false"
        colorStart: "#6FADCF"
        colorStop: "#8FC0DA"
        strokeColor: "#E0E0E0"
        generateGradient: true
